import { useMemo } from "react";
import { useDispatch } from "react-redux";

import { VehicleApi } from "./VehicleApi";
import { useApiBase } from "../ApiContext";
import { changeSelectedVehicle, ChangeSelectedVehicleMeta } from "../../reducers/vehicleReducer";

export interface VehicleContextProps {
  readonly VehicleApi: VehicleApi;

  readonly changeSelectedVehicle: (meta: ChangeSelectedVehicleMeta) => void;
}

export function useVehicleContext(): VehicleContextProps {
  const data = useApiBase();

  const dispatch = useDispatch();

  const api = useMemo(() => new VehicleApi(data), [data]);

  return {
    VehicleApi: api,

    changeSelectedVehicle: (meta) => dispatch(changeSelectedVehicle(meta)),
  };
}
