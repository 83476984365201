import { update } from "immupdate";

import { BaseApi, Options } from "../BaseApi";
import { ProductPlans } from "../../dto/EnumDTO";
import { RegisterProps, SignUpProps } from "../stripe/StripeDTO";

export interface AuthCustomerValuesProps {
  readonly vrm?: string;
  readonly name: string;
  readonly email: string;
  readonly phoneNumber?: string;
  readonly hasMarketing: boolean;
  readonly signupReason?: string;
  readonly hasAcceptedTC: boolean;
  readonly productId: ProductPlans;
}

export class AuthApi extends BaseApi {
  public authCustomer(values: AuthCustomerValuesProps): Promise<SignUpProps> {
    return this.post("customer/register", { json: values });
  }

  public authCustomerB(options?: Options): Promise<RegisterProps> {
    return this.post("customer/register-b", options);
  }

  public authCustomerTrial(values: AuthCustomerValuesProps, options: Options = {}): Promise<void> {
    return this.post("customer/register_trial", update(options, { json: values }));
  }
}
