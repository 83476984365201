import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import WrenchIcon from "../../../assets/img/reports/wrench.svg";
import MoneyBoxIcon from "../../../assets/img/reports/moneybox.svg";
import { format, formatFunds } from "../../../utils/number";
import withScrollTop from "../../../hoc/withScrollTop";
import * as actions from "../../../redux/actions/report";
import * as styles from "./styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TabContainer from "../TabContainer";

function Repairs({ report, token }) {
  const dispatch = useDispatch();
  const repairs = report.repairs;
  const { motTests = [] } = report.mots || {};

  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (!report.mots) {
      dispatch(actions.loadMOTs(report.id, token));
    }

    if (!repairs) {
      dispatch(actions.loadRepairs(report.id, token));
    }
  }, [token]);

  const hasPassedMot = useMemo(
    () => (motTests || []).reduce((acc, item) => item.testResult === "PASSED", true),
    [motTests],
  );

  if (!repairs) {
    return [];
  }

  return (
    <styles.Container>
      <div className="d-flex flex-column">
        <div className="d-flex d-md-none flex-column">
          <Tabs
            className="d-block"
            value={currentTab}
            onChange={(_, tab) => setCurrentTab(tab)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Wear and tear" value={0} />
            <Tab label="MOT repairs" value={1} />
          </Tabs>

          <TabContainer active={currentTab} tab={0}>
            <div className="mt-3 wear-and-tear">
              <div className="section-description">
                We have used industry maintenance data to calculate what repairs should have been
                done on this vehicle by now and what may be needed in the near future.
              </div>
              <div className="d-flex flex-column flex-sm-row wear-and-tear-repairs">
                <div className="mb-3 mb-sm-0 necessary-repairs">
                  <div className="repairs-icon">
                    <img src={WrenchIcon} />
                  </div>
                  <div className="repairs-title">
                    Repairs that should have been done for a vehicle of this mileage:
                  </div>
                  <div className="repairs-estimates">
                    {(repairs &&
                      repairs.currentRepairs &&
                      repairs.currentRepairs.length &&
                      repairs.currentRepairs.map((estimate, index) => (
                        <div key={index} className="estimate-item">
                          <div className="estimate-icon">
                            <InfoOutlinedIcon />
                          </div>
                          <div className="estimate-content">
                            <div className="estimate-description">
                              {estimate.title} ({estimate.details}) at {format(estimate.mileage)}{" "}
                              miles
                            </div>
                            <div className="estimate-price">
                              <span className="price-value">£{formatFunds(estimate.price)}</span>{" "}
                              (estimate)
                            </div>
                          </div>
                        </div>
                      ))) || <div className="no-repairs">We don’t have data for this vehicle</div>}
                  </div>
                </div>
                <div className="next-repairs">
                  <div className="repairs-icon">
                    <img src={MoneyBoxIcon} />
                  </div>
                  <div className="repairs-title">
                    Repairs that will possibly need doing in the next 30k miles:
                  </div>
                  <div className="repairs-estimates">
                    {(repairs &&
                      repairs.futureRepairs &&
                      repairs.futureRepairs.length &&
                      repairs.futureRepairs.map((estimate, index) => (
                        <div key={index} className="estimate-item">
                          <div className="estimate-icon">
                            <InfoOutlinedIcon />
                          </div>
                          <div className="estimate-content">
                            <div className="estimate-description">
                              {estimate.title} ({estimate.details}) at {format(estimate.mileage)}{" "}
                              miles
                            </div>
                            <div className="estimate-price">
                              <span className="price-value">£{formatFunds(estimate.price)}</span>{" "}
                              (estimate)
                            </div>
                          </div>
                        </div>
                      ))) || <div className="no-repairs">We don’t have data for this vehicle</div>}
                  </div>
                </div>
              </div>
            </div>
          </TabContainer>
          <TabContainer active={currentTab} tab={1}>
            <div className="mt-3 mot-repairs">
              {repairs && repairs.motRepairs && repairs.motRepairs.length ? (
                <div className="section-description">
                  Below are some of the more significant items that should have been repaired on
                  this vehicle based on historic MOT tests. Take note that not all MOT test stations
                  are as thorough as others so some items may still exist as problems on the
                  vehicle.
                </div>
              ) : motTests && motTests.length === 0 ? (
                <div className="section-description">
                  This vehicle hasn't had an MOT yet, therefore there are no MOT repairs needed.
                </div>
              ) : hasPassedMot ? (
                <div className="section-description">There are no repairs needed.</div>
              ) : (
                <div />
              )}
              <div className="d-flex flex-column flex-sm-row mot-repairs-grid">
                {(repairs &&
                  repairs.motRepairs &&
                  repairs.motRepairs.length &&
                  repairs.motRepairs.map((repair, index) => (
                    <div key={index} className="mot-repair">
                      <div className="mot-repair-icon">
                        <HelpOutlineIcon />
                      </div>
                      <div className="mot-repair-content">
                        <div className="mot-repair-title">{repair.year}</div>
                        <div className="mot-repair-items">
                          {repair.items.map((repairItem, index) => (
                            <div key={index} className="mot-repair-item">
                              {repairItem}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))) ||
                  null}
              </div>
            </div>
          </TabContainer>
        </div>

        <div className="d-none d-md-flex flex-column">
          <div className="wear-and-tear">
            <div className="section-title">Wear and tear</div>
            <div className="section-description">
              We have used industry maintenance data to calculate what repairs should have been done
              on this vehicle by now and what may be needed in the near future.
            </div>
            <div className="wear-and-tear-repairs">
              <div className="necessary-repairs">
                <div className="repairs-icon">
                  <img src={WrenchIcon} />
                </div>
                <div className="repairs-title">
                  Repairs that should have been done for a vehicle of this mileage:
                </div>
                <div className="repairs-estimates">
                  {(repairs &&
                    repairs.currentRepairs &&
                    repairs.currentRepairs.length &&
                    repairs.currentRepairs.map((estimate, index) => (
                      <div key={index} className="estimate-item">
                        <div className="estimate-icon">
                          <InfoOutlinedIcon />
                        </div>
                        <div className="estimate-content">
                          <div className="estimate-description">
                            {estimate.title} ({estimate.details}) at {format(estimate.mileage)}{" "}
                            miles
                          </div>
                          <div className="estimate-price">
                            <span className="price-value">£{formatFunds(estimate.price)}</span>{" "}
                            (estimate)
                          </div>
                        </div>
                      </div>
                    ))) || <div className="no-repairs">We don’t have data for this vehicle</div>}
                </div>
              </div>
              <div className="next-repairs">
                <div className="repairs-icon">
                  <img src={MoneyBoxIcon} />
                </div>
                <div className="repairs-title">
                  Repairs that will possibly need doing in the next 30k miles:
                </div>
                <div className="repairs-estimates">
                  {(repairs &&
                    repairs.futureRepairs &&
                    repairs.futureRepairs.length &&
                    repairs.futureRepairs.map((estimate, index) => (
                      <div key={index} className="estimate-item">
                        <div className="estimate-icon">
                          <InfoOutlinedIcon />
                        </div>
                        <div className="estimate-content">
                          <div className="estimate-description">
                            {estimate.title} ({estimate.details}) at {format(estimate.mileage)}{" "}
                            miles
                          </div>
                          <div className="estimate-price">
                            <span className="price-value">£{formatFunds(estimate.price)}</span>{" "}
                            (estimate)
                          </div>
                        </div>
                      </div>
                    ))) || <div className="no-repairs">We don’t have data for this vehicle</div>}
                </div>
              </div>
            </div>
          </div>
          <div className="mot-repairs">
            <div className="section-title">MOT repairs</div>
            {repairs && repairs.motRepairs && repairs.motRepairs.length ? (
              <div className="section-description">
                Below are some of the more significant items that should have been repaired on this
                vehicle based on historic MOT tests. Take note that not all MOT test stations are as
                thorough as others so some items may still exist as problems on the vehicle.
              </div>
            ) : motTests && motTests.length === 0 ? (
              <div className="section-description">
                This vehicle hasn't had an MOT yet, therefore there are no MOT repairs needed.
              </div>
            ) : hasPassedMot ? (
              <div className="section-description">There are no repairs needed.</div>
            ) : (
              <div />
            )}
            <div className="mot-repairs-grid">
              {(repairs &&
                repairs.motRepairs &&
                repairs.motRepairs.length &&
                repairs.motRepairs.map((repair, index) => (
                  <div key={index} className="mot-repair">
                    <div className="mot-repair-icon">
                      <HelpOutlineIcon />
                    </div>
                    <div className="mot-repair-content">
                      <div className="mot-repair-title">{repair.year}</div>
                      <div className="mot-repair-items">
                        {repair.items.map((repairItem, index) => (
                          <div key={index} className="mot-repair-item">
                            {repairItem}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))) ||
                null}
            </div>
          </div>
        </div>
      </div>
    </styles.Container>
  );
}

export default withScrollTop(Repairs);
