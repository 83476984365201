import { Action } from "redux";
import { PersistConfig } from "redux-persist";
import { deepUpdate, update } from "immupdate";

import { Dict } from "../dto/AppDTO";
import { VehicleProps } from "../api/vehicle/VehicleDTO";
import { createReducer, createRootReducer, PerformAction } from "../utils/ReducerUtils";

export const vehicleReducerPersistConfig: Partial<PersistConfig<VehicleReducerState>> = {
  whitelist: [""],
};

export interface ChangeSelectedVehicleMeta {
  readonly vrm: string;
  readonly state: boolean;
}

interface SetVehicleListMeta {
  readonly list: VehicleProps[];
}

enum ReducerActions {
  SetVehicleList = "Vehicle/SetVehicleList",

  ChangeSelectedVehicle = "Vehicle/ChangeSelectedVehicle",
  ClearSelectedVehicles = "Vehicle/ClearSelectedVehicles",
}

export interface VehicleReducerState {
  readonly list: VehicleProps[];
  readonly selectedVehicles: Dict<boolean>;
}

function getState(): VehicleReducerState {
  return {
    list: [],
    selectedVehicles: {},
  };
}

export const vehicleReducer = createRootReducer(
  getState(),

  createReducer([ReducerActions.ChangeSelectedVehicle], (state, { meta }) =>
    deepUpdate(state).at("selectedVehicles").at(meta.vrm).set(meta.state),
  ),

  createReducer([ReducerActions.ClearSelectedVehicles], (state) =>
    update(state, { selectedVehicles: {} }),
  ),

  createReducer([ReducerActions.SetVehicleList], (state, { meta }) =>
    update(state, { list: meta.list }),
  ),
);

// ==================
// Selectors
// ==================

export function hasCompareVehicleSelector(vrm: string) {
  return ({ vehicle }: any): boolean => Boolean(vehicle.selectedVehicles[vrm]);
}

export function countCompareVehiclesSelector({ vehicle }: any): number {
  const list = Object.values(vehicle.selectedVehicles);

  return list.filter(Boolean).length;
}

export function compareVehiclesSelector({ vehicle }: any): Dict<boolean> {
  return vehicle.selectedVehicles;
}

export function vehicleListSelector({ vehicle }: any): VehicleProps[] {
  return vehicle.list || [];
}

export function vehicleItemSelector(vrm?: string) {
  return ({ vehicle }: any): VehicleProps | undefined =>
    vehicle.list.find((item: VehicleProps) => item.vrm === vrm);
}

// ==================
// Actions
// ==================

export function changeSelectedVehicle(
  meta: ChangeSelectedVehicleMeta,
): PerformAction<ChangeSelectedVehicleMeta> {
  return { meta, type: ReducerActions.ChangeSelectedVehicle };
}

export function clearSelectedVehicles(): Action {
  return { type: ReducerActions.ClearSelectedVehicles };
}

export function setVehicleList(meta: SetVehicleListMeta): PerformAction<SetVehicleListMeta> {
  return { meta, type: ReducerActions.SetVehicleList };
}
