import React from "react";
import { Text, View, StyleSheet, Image, Line, Svg } from "@react-pdf/renderer";
import { PageLayout } from "./PageLayout";
import { formatFunds } from "../../../../utils/number";

import WrenchIcon from "../assets/wrench.png";
import TaxIcon from "../assets/tax.png";
import ServiceIcon from "../assets/service.png";
import DepreciationIcon from "../assets/depreciation.png";
import FuelIcon from "../assets/fuel.png";
import MOTIcon from "../assets/mot.png";
import MoneyBoxIcon from "../assets/moneybox.png";
import VehicleBG from "../assets/vehicle-bg.png";
import { runningCostsPageStyles } from "../styles/runningCostsPageStyles";

const styles = StyleSheet.create(runningCostsPageStyles);

function getTopBoxes(context) {
  return [
    {
      title: "Servicing",
      className: "servicing-box",
      value: context.servicePerMonth,
      icon: ServiceIcon,
    },
    {
      title: "Depreciation",
      className: "depreciation-box",
      value: context.deprValuePerMonth,
      icon: DepreciationIcon,
    },
    {
      title: "MOT",
      className: "mot-box",
      value: context.motPerMonth,
      icon: MOTIcon,
    },
  ];
}

function getBottomBoxes(context) {
  return [
    {
      title: "Repairs",
      className: "repairs-box",
      value: context.repairsPerMonth,
      icon: WrenchIcon,
    },
    {
      icon: TaxIcon,
      title: "Tax",
      className: "tax-box",
      value: context.taxPerMonth,
      description: "This includes the 5% surcharge for paying monthly/6-monthly",
    },
    {
      title: "Fuel",
      className: "fuel-box",
      value: context.fuelPerMonth,
      icon: FuelIcon,
    },
  ];
}

export const RunningCostsPage = ({ report }) => {
  const displayInfo = report.runningCosts || {};

  const topBoxes = getTopBoxes(displayInfo);
  const bottomBoxes = getBottomBoxes(displayInfo);

  return (
    <PageLayout id={report.id}>
      <View>
        <Text style={styles.pageTitle}>RUNNING COSTS</Text>
        <Text style={styles.costsTitle}>Costs per month</Text>
        <Text style={styles.orderText}>
          Based on average mileage of 12k miles per year and fuel costs at £
          {formatFunds(displayInfo.fuelCostPerLitre)} per litre.
        </Text>

        <View style={styles.vehicleWrapper}>
          <View style={styles.vehicleTop}>
            {topBoxes.map((box, index) => {
              const gap = [180, 130, 80];

              return (
                <View key={index} style={styles.vehicleBox}>
                  <View style={styles.vehicleBoxTitle}>
                    <Text>{box.title}</Text>
                  </View>
                  <View style={styles.vehicleBoxPrice}>
                    <Text>{box.value ? `£${formatFunds(box.value)}` : `N/A`}</Text>
                  </View>
                  <View style={styles.appendix}>
                    <Svg height="200" width="5" style={{ position: "absolute" }}>
                      <Line x1="0" y1={gap[index]} x2="0" y2="0" strokeWidth={5} stroke="rgb(249,202,36)" />
                    </Svg>
                    <Image src={box.icon} style={[styles.vehicleBoxIcon, { top: gap[index] }]} />
                  </View>
                </View>
              );
            })}
          </View>

          <View style={styles.vehicleBgWrapper}>
            <Image src={VehicleBG} style={styles.vehicleBg} />
          </View>

          <View style={styles.vehiclebottom}>
            {bottomBoxes.map((box, index) => {
              const gap = [80, 130, 180];

              return (
                <View key={index} style={styles.vehicleBox}>
                  <View style={styles.appendix}>
                    <Image src={box.icon} style={[styles.vehicleBoxIcon, { bottom: gap[index] }]} />
                    <Svg height="200" width="5" style={{ position: "absolute", top: -gap[index] }}>
                      <Line x1="0" y1="0" x2="0" y2={gap[index]} strokeWidth={5} stroke="rgb(249,202,36)" />
                    </Svg>
                  </View>
                  <View style={styles.vehicleBoxTitle}>
                    <Text>{box.title}</Text>
                  </View>
                  <View style={styles.vehicleBoxPrice}>
                    <Text>{box.value ? `£${formatFunds(box.value)}` : `N/A`}</Text>
                    <Text style={styles.vehicleBoxDescription}>{box.description}</Text>
                  </View>
                </View>
              );
            })}
          </View>
        </View>

        <View style={styles.totalCostBox}>
          <View style={styles.totalCostTitle}>
            <View>
              <Image src={MoneyBoxIcon} style={styles.totalCostIcon} />
            </View>
            <Text>Total cost per month</Text>
          </View>
          <Text style={styles.totalCostValue}>£{formatFunds(displayInfo.totalPerMonth)}</Text>
        </View>
      </View>
    </PageLayout>
  );
};
