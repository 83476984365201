import React, { useMemo } from "react";

import { SubscriptionStatus } from "../../dto/EnumDTO";
import { UserAccountProps } from "../../api/user/UserDTO";
import { SubscriptionDetails } from "../subscriptions/SubscriptionDetails";

interface Props {
  readonly loading: boolean;
  readonly account?: UserAccountProps;
  readonly onUpgradeClick: () => void;
}

export function AccountPlans({ account, onUpgradeClick, loading }: Props) {
  const subscriptionStatus = useMemo(() => account?.subscription?.status, [account?.subscription]);

  const isMonthlyAccess = useMemo(() => subscriptionStatus === SubscriptionStatus.MonthlyAccess, [
    subscriptionStatus,
  ]);
  const isNoSubscription = useMemo(() => subscriptionStatus === SubscriptionStatus.NoSubscription, [
    subscriptionStatus,
  ]);
  const isSubscriptionExpired = useMemo(
    () => subscriptionStatus === SubscriptionStatus.SubscriptionExpired,
    [subscriptionStatus],
  );

  const hasCredits = useMemo(
    () => Boolean(account?.credits?.report && account?.credits?.report > 0),
    [account?.credits],
  );

  if (loading) {
    return null;
  }

  return (
    <SubscriptionDetails
      account={account}
      onUpgradeClick={onUpgradeClick}
      isExpired={isSubscriptionExpired}
      isMonthlyAccess={isMonthlyAccess}
      withCredits={isNoSubscription && hasCredits}
      withoutCredits={isNoSubscription && !hasCredits}
    />
  );
}
