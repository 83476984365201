import styled from "styled-components";

export const Container = styled.div`
  &.info-block {
    border-right: 1px solid #e4e4e48a;
    padding: 0 20px;
    width: 100%;

    .info-block-icon {
      text-align: center;
      margin: 0 0 25px;
    }
    .info-item {
      margin: 0 0 15px;

      &.is-important {
        font-weight: 500;
      }
      .modal-button {
        text-decoration: underline;
        font-weight: 500;
        color: #30336b;
      }
    }

    &.is-dangerous {
      .info-item {
        color: #eb4d4b;
      }
    }
  }
`;
