import React from "react";
import { SvgIcon } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon/SvgIcon";

import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgIconProps {
  readonly size?: number;
}

export function EditIcon({ size = 36, ...props }: Props) {
  return (
    <SvgIcon
      width="36"
      height="36"
      viewBox="0 0 36 36"
      {...props}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        ...props.style,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="18" cy="18" r="18" fill={ColorPalette.Secondary} />
        <g
          stroke={ColorPalette.White}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <path d="M24.793 11.207a2.426 2.426 0 0 0-3.446.023l-9.666 9.667L10.5 25.5l4.603-1.18 9.667-9.667a2.426 2.426 0 0 0 .023-3.446zM21.071 11.507l3.422 3.422M19.675 12.903l3.422 3.422M11.681 20.897l3.426 3.419" />
        </g>
      </g>
    </SvgIcon>
  );
}
