import { isNaN, isString, toNumber } from "lodash";

import { TokenProps } from "../dto/AuthDTO";

const reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isValidEmail(str: string) {
  return reEmail.test(String(str).toLowerCase());
}

export function validToken(token?: TokenProps): boolean {
  if (token) {
    const { accessToken } = token;

    const expires = accessToken?.payload?.exp || 0;

    return expires * 1000 > Date.now();
  }

  return false;
}

export function createStringValidator(message: string) {
  return (data) => {
    if (!isString(data)) {
      return message;
    }
  };
}

export function createNumberValidator(message: string) {
  return (data) => {
    const value = toNumber(data);

    if (isNaN(value)) {
      return message;
    }
  };
}

export function createEmailValidator(message: string) {
  return (data) => {
    if (!isValidEmail(data)) {
      return message;
    }
  };
}
