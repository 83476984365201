import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { formatVehicleInfoTitle } from "../../../../utils/VehicleHalpers";
import { detailsPageStyles } from "../styles/detailsPageStyles";
import { PageLayout } from "./PageLayout";

const styles = StyleSheet.create(detailsPageStyles);

export const DetailsPage = ({ carDetails }) => {
  const renderertable = (table) => {
    let fields = [];

    for (let key in table) {
      let valideValue;

      if (table[key] === true) {
        valideValue = "Yes";
      }
      if (table[key] === false) {
        valideValue = "No";
      }

      fields.push({
        key,
        title: formatVehicleInfoTitle(key),
        value: valideValue ? valideValue : table[key],
      });
    }

    return fields;
  };

  return (
    <PageLayout id={carDetails.reportId}>
      <View>
        <Text style={styles.pageTitle}>
          {carDetails.make} {carDetails.model}
        </Text>
        <View style={styles.detailsHeader}>
          <Image
            style={styles.mainImg}
            src={{
              uri: carDetails.imageUrl,
              method: "GET",
              headers: {
                "Cache-Control": "no-cache",
              },
            }}
          />
          <View style={styles.mainInfo}>
            <Text>{carDetails.vrm}</Text>
            <Text>Colour: {carDetails.basicDetails.colour}</Text>
            <Text>Registered: {carDetails.keyDates.dateOfRegistration}</Text>
            <Text>Estimated Mileage: {carDetails.basicDetails.mileage}</Text>
          </View>
        </View>

        <View style={styles.tableBlock}>
          <View wrap={false} style={styles.table}>
            <Text style={styles.tableTitle}>Economy</Text>

            <View style={styles.tableBody}>
              {renderertable(carDetails.economy).map((item, index) => {
                if (index % 2) {
                  return (
                    <View key={item.key} style={styles.tableLine}>
                      <Text style={styles.tableLineName}>{item.title}:</Text>
                      <Text style={styles.tableLineValue}>{item.value}</Text>
                    </View>
                  );
                }

                return (
                  <View key={item.key} style={[styles.tableLine, styles.bgGray]}>
                    <Text style={styles.tableLineName}>{item.title}:</Text>
                    <Text style={styles.tableLineValue}>{item.value}</Text>
                  </View>
                );
              })}
            </View>
          </View>
          <View wrap={false} style={styles.table}>
            <Text style={styles.tableTitle}>Key Dates</Text>

            <View style={styles.tableBody}>
              {renderertable(carDetails.keyDates).map((item, index) => {
                if (index % 2) {
                  return (
                    <View key={item.key} style={styles.tableLine}>
                      <Text style={styles.tableLineName}>{item.title}:</Text>
                      <Text style={styles.tableLineValue}>{item.value}</Text>
                    </View>
                  );
                }

                return (
                  <View key={item.key} style={[styles.tableLine, styles.bgGray]}>
                    <Text style={styles.tableLineName}>{item.title}:</Text>
                    <Text style={styles.tableLineValue}>{item.value}</Text>
                  </View>
                );
              })}
            </View>
          </View>
          <View wrap={false} style={styles.table}>
            <Text style={styles.tableTitle}>Provenance</Text>

            <View style={styles.tableBody}>
              {renderertable(carDetails.provenance).map((item, index) => {
                if (index % 2) {
                  return (
                    <View key={item.key} style={styles.tableLine}>
                      <Text style={styles.tableLineName}>{item.title}:</Text>
                      <Text style={styles.tableLineValue}>{item.value}</Text>
                    </View>
                  );
                }

                return (
                  <View key={item.key} style={[styles.tableLine, styles.bgGray]}>
                    <Text style={styles.tableLineName}>{item.title}:</Text>
                    <Text style={styles.tableLineValue}>{item.value}</Text>
                  </View>
                );
              })}
            </View>
          </View>
          <View wrap={false} style={styles.table}>
            <Text style={styles.tableTitle}>Basic Details</Text>

            <View style={styles.tableBody}>
              {renderertable(carDetails.basicDetails).map((item, index) => {
                if (index % 2) {
                  return (
                    <View key={item.key} style={styles.tableLine}>
                      <Text style={styles.tableLineName}>{item.title}:</Text>
                      <Text style={styles.tableLineValue}>{item.value}</Text>
                    </View>
                  );
                }

                return (
                  <View key={item.key} style={[styles.tableLine, styles.bgGray]}>
                    <Text style={styles.tableLineName}>{item.title}:</Text>
                    <Text style={styles.tableLineValue}>{item.value}</Text>
                  </View>
                );
              })}
            </View>
          </View>
          <View wrap={false} style={styles.table}>
            <Text style={styles.tableTitle}>Component Details</Text>

            <View style={styles.tableBody}>
              {renderertable(carDetails.componentDetails).map((item, index) => {
                if (index % 2) {
                  return (
                    <View key={item.key} style={styles.tableLine}>
                      <Text style={styles.tableLineName}>{item.title}:</Text>
                      <Text style={styles.tableLineValue}>{item.value}</Text>
                    </View>
                  );
                }

                return (
                  <View key={item.key} style={[styles.tableLine, styles.bgGray]}>
                    <Text style={styles.tableLineName}>{item.title}:</Text>
                    <Text style={styles.tableLineValue}>{item.value}</Text>
                  </View>
                );
              })}
            </View>
          </View>
          <View wrap={false} style={styles.table}>
            <Text style={styles.tableTitle}>Engine Specs</Text>

            <View style={styles.tableBody}>
              {renderertable(carDetails.engineSpecs).map((item, index) => {
                if (index % 2) {
                  return (
                    <View key={item.key} style={styles.tableLine}>
                      <Text style={styles.tableLineName}>{item.title}:</Text>
                      <Text style={styles.tableLineValue}>{item.value}</Text>
                    </View>
                  )
                }

                return (
                  <View key={item.key} style={[styles.tableLine, styles.bgGray]}>
                    <Text style={styles.tableLineName}>{item.title}:</Text>
                    <Text style={styles.tableLineValue}>{item.value}</Text>
                  </View>
                );
              })}
            </View>
          </View>
          <View wrap={false} style={styles.table}>
            <Text style={styles.tableTitle}>Dimensions</Text>

            <View style={styles.tableBody}>
              {renderertable(carDetails.dimensions).map((item, index) => {
                if (index % 2) {
                  return (
                    <View key={item.key} style={styles.tableLine}>
                      <Text style={styles.tableLineName}>{item.title}:</Text>
                      <Text style={styles.tableLineValue}>{item.value}</Text>
                    </View>
                  );
                }

                return (
                  <View key={item.key} style={[styles.tableLine, styles.bgGray]}>
                    <Text style={styles.tableLineName}>{item.title}:</Text>
                    <Text style={styles.tableLineValue}>{item.value}</Text>
                  </View>
                );
              })}
            </View>
          </View>
        </View>
      </View>
    </PageLayout>
  );
};
