import { useMemo } from "react";

import { StripeApi } from "./StripeApi";
import { useApiBase } from "../ApiContext";

export interface StripeContextProps {
  readonly StripeApi: StripeApi;
}

export function useStripeContext(): StripeContextProps {
  const { host, token } = useApiBase();

  const api = useMemo(() => new StripeApi({ host, token }), [host, token]);

  return {
    StripeApi: api,
  };
}
