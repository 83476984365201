import fp from "lodash/fp";
import { camelCase, snakeCase } from "lodash";

function createMapper<T>(changeCase: (value: string) => string): (value: T[] | T) => T[] | T {
  // eslint-disable-next-line no-use-before-define
  const mapArray = fp.map(mapKeysDeep);
  const mapObject = fp.flow(
    fp.mapKeys(changeCase),
    // eslint-disable-next-line no-use-before-define
    fp.mapValues(mapKeysDeep),
  );

  // eslint-disable-next-line no-use-before-define
  return mapKeysDeep as (value: T[] | T) => T[] | T;

  function mapKeysDeep(value: T[] | T): any[] | {} {
    if (fp.isArray(value)) {
      return mapArray(value);
    }

    if (fp.isPlainObject(value)) {
      return mapObject(value);
    }

    return value;
  }
}

export function toCamelCase<T>(value: T[] | T): T[] | T {
  return createMapper<T>(camelCase)(value);
}

export function toSnakeCase<T>(value: T[] | T): any {
  return createMapper<T>(snakeCase)(value);
}
