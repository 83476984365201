import "./assets/product-detail-item.scss";

import React from "react";
import cx from "classnames";
import {CheckCircleOutlineRounded} from "@material-ui/icons";

interface Props {
  readonly isSpecial: boolean;
  readonly description: string;
}

export function ProductDetailItem({ isSpecial, description }: Props) {
  return (
    <div
      className={cx("product-detail-item d-flex justify-content-left", { special: isSpecial })}
    >
      <CheckCircleOutlineRounded color={isSpecial ? "error" : "primary"} fontSize="small" />
      <span className={cx("ml-3 text-primary font-weight-light", { "font-weight-bold": isSpecial })}>
        {description}
      </span>
    </div>
  );
}
