import "./assets/vehicle-salvage-dialog.scss";

import React, { useMemo } from "react";

import { Dialog } from "../ui/Dialog";
import { ActionButton } from "../ui/ActionButton";
import { VehicleSalvageItem } from "./VehicleSalvageItem";
import { VehicleSalvageProps } from "../../api/vehicle/VehicleDTO";
import { APP_SALVAGE_IMAGE_URL } from "../../constants/AppConstants";

interface Props {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly salvage: VehicleSalvageProps;
}

export function VehicleSalvageDialog({ open, salvage, onClose }: Props) {
  const damage = useMemo(
    () => [salvage?.primaryDamage, salvage?.secondaryDamage].filter(Boolean).join(", "),
    [salvage],
  );

  const imageURL = useMemo(
    () => [APP_SALVAGE_IMAGE_URL, "?id=", salvage?.id].filter(Boolean).join(""),
    [salvage],
  );

  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Salvage Record"
      className="vehicle-salvage-dialog"
      actions={
        <div className="d-flex flex-column flex-sm-row justify-content-end">
          <ActionButton onClick={onClose}>OK</ActionButton>
        </div>
      }
    >
      {Boolean(imageURL) && (
        <img alt="vehicle" src={imageURL} className="w-100 mb-3 align-self-center vehicle-image" />
      )}

      <VehicleSalvageItem title="Date" value={salvage.date || "Unknown"} />
      <VehicleSalvageItem title="Match on" value="VIN" />
      <VehicleSalvageItem title="Type" value={salvage.type} />
      <VehicleSalvageItem title="Damage" value={damage} />
    </Dialog>
  );
}
