import React from "react";
import CheckIcon from "@material-ui/icons/Check";

interface Props {
  readonly title: string;
}

export function SubscriptionFeaturesItem({ title }: Props) {
  return (
    <div className="d-flex mb-2 font-weight-medium">
      <CheckIcon className="text-secondary mr-2" />
      {title}
    </div>
  );
}
