import { useCallback, useEffect, useState } from "react";

export function useOptimizeExperiment(id: string) {
  const [variant, setVariant] = useState();

  const pushHandler = useCallback(async (x) => {
    try {
      // @ts-ignore
      const activated = await window.dataLayer.push({ event: "optimize.activate" });

      let interval: any;

      if (activated) {
        interval = setInterval(() => {
          // @ts-ignore
          if (window.google_optimize !== undefined) {
            // @ts-ignore
            const response = window.google_optimize.get(x);

            setVariant(response);

            if (interval) {
              clearInterval(interval);
            }
          }
        });
      }

      return interval;
    } catch (e) {
      //
    }
  }, []);

  useEffect(() => {
    let interval: any;

    // @ts-ignore
    if (window.dataLayer && window.dataLayer.push) {
      pushHandler(id).then((x) => (interval = x));
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [id]);

  return variant;
}
