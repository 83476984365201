import { AuthProductProps } from "../../api/auth/AuthDTO";
import { useRegisterSocket } from "../../hooks/useRegisterSocket";
import { LookupVehicleProps } from "../../api/vehicle/VehicleDTO";

interface Props {
  readonly reason?: string;
  readonly isApple?: boolean;
  readonly username?: string;
  readonly onDone: () => void;
  readonly product?: AuthProductProps;
  readonly vehicle?: LookupVehicleProps;
  readonly onError: (message?: string) => void;
  readonly onProcess: (progress: number) => void;
}

export function EmailRegisterSocket({
  onDone,
  reason,
  isApple,
  vehicle,
  onError,
  product,
  username,
  onProcess,
}: Props) {
  useRegisterSocket({
    onDone,
    reason,
    isApple,
    vehicle,
    product,
    onError,
    username,
    onProcess,
    skip: Boolean(!username),
  });

  return null;
}
