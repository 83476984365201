import {BaseApi, Options} from "../BaseApi";
import {UserAccountProps, UserDataProps} from "./UserDTO";

export class UserApi extends BaseApi {
  public getUserData(): Promise<UserDataProps> {
    return this.get("customer/get");
  }

  public saveUserData(values: Partial<UserDataProps>): Promise<void> {
    return this.put("customer/save", { json: values });
  }

  public getUserAccount(options?: Options): Promise<UserAccountProps> {
    return this.get("customer/account", options);
  }

  public getSubscriptionStatus(): Promise<UserAccountProps> {
    return this.get("customer/subscription_status");
  }
}
