import "./assets/edit-vehicle-dialog.scss";

import React, { useMemo } from "react";

import { Dialog } from "../ui/Dialog";
import { EditVehicleForm } from "./EditVehicleForm";
import { EditVehicleFormProps, VehicleProps } from "../../api/vehicle/VehicleDTO";

export interface EditVehicleDialogProps {
  readonly open: boolean;
  readonly onDismiss: () => void;
  readonly vehicle?: VehicleProps;
  readonly onSubmit: (values: EditVehicleFormProps) => Promise<void>;
}

export function EditVehicleDialog({ open, vehicle, onSubmit, onDismiss }: EditVehicleDialogProps) {
  const title = useMemo(() => `${vehicle?.make} ${vehicle?.model}`, [vehicle]);

  return (
    <Dialog
      open={open}
      title={title}
      onClose={onDismiss}
      paperClassName="dialog-paper"
      className="edit-vehicle-dialog"
    >
      <EditVehicleForm
        onSubmit={onSubmit}
        onDismiss={onDismiss}
        defaultValues={vehicle?.basicDetails}
      />
    </Dialog>
  );
}
