import qs from "qs";
import Cookies from "js-cookie";
import { DELETE } from "immupdate";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

import { AuthState } from "../dto/EnumDTO";
import { useQuery } from "../hooks/useQuery";
import { useIntercom } from "../hooks/useIntercom";
import { validToken } from "../utils/ValidateUtils";
import { useAuthContext } from "../api/auth/AuthContext";
import { useScrollMemory } from "../hooks/useScrollMemory";
import { ProductField } from "../components/form/ProductField";
import { AppRouteLayout } from "../components/app/AppRouteLayout";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { authStateSelector, tokenSelector } from "../reducers/authReducer";
import { useOptimizeActivate } from "../hooks/useOptimizeActivate";

interface Query {
  readonly reason?: string;
  readonly fromEmail: string;
}

export function RegisterProductContainer() {
  useIntercom();
  useScrollMemory();

  const history = useHistory();
  const query = useQuery<Query>();

  const token = useShallowEqualSelector(tokenSelector);
  const authState = useShallowEqualSelector(authStateSelector);

  const { clearRegister } = useAuthContext();

  useEffect(() => {
    if (!validToken(token) && authState === AuthState.Unauthorized) {
      Cookies.set("user_registered", "0", { domain: "carguide.co.uk" });

      clearRegister();

      history.replace("/auth/register");
    } else if (validToken(token) && authState === AuthState.Authorized) {
      history.replace("/shortlist");
    }
  }, [token, history, clearRegister, authState]);

  useOptimizeActivate();

  return (
    <AppRouteLayout withFooter={false} title="Registration Product">
      <div className="container d-flex flex-column flex-1 mt-4 mt-md-5">
        <h1 className="d-none d-md-block text-center mb-3 mx-2">Choose your product</h1>
        <h2 className="d-block d-md-none text-center mb-3 mx-2">Choose your product</h2>

        <span className="text-center mx-2 mb-4 mb-md-4">
          All you need to do is simply choose the product below, securely enter your card payment
          details and click proceed - we'll then do the rest.
        </span>

        <span className="font-weight-bold mb-3">
          Not sure which option to choose? Let us guide you:
        </span>

        <ul>
          <li>
            If you are in the market of buying a used car and are likely to be deciding between a
            number of motors – go for <span className="font-weight-bold">30 days access</span> – you
            will save money in the long run!
          </li>
          <li>
            If you have your heart set on your next car then choose{" "}
            <span className="font-weight-bold">Pay As you Go</span> – you get one fully
            comprehensive buyer’s guide included
          </li>
          <li>
            If you’re unsure whether you want a fully comprehensive buyer’s report which uncovers a
            vehicle’s history and its future then choose{" "}
            <span className="font-weight-bold">Free Trial</span> – we won’t get offended!
          </li>
        </ul>

        <ProductField
          onChange={(product) =>
            history.replace(
              [
                "/auth/checkout",
                qs.stringify({
                  product: product.id,
                  fromEmail: query?.fromEmail,
                  reason: query?.reason || DELETE,
                }),
              ]
                .filter(Boolean)
                .join("?"),
            )
          }
        />
      </div>
    </AppRouteLayout>
  );
}
