import styled from "styled-components";

export const ChartTooltip = styled.div`
  background: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.5);

  .tooltip-title {
    margin: 0 0 5px;
    font-weight: 500;
  }
  .tooltip-content {
    .tooltip-attribute-name {
      font-weight: 500;
    }
  }
`;
