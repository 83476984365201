import { Action } from "redux";
import { PersistConfig } from "redux-persist";
import { deepUpdate, DELETE, update } from "immupdate";

import { AuthState } from "../dto/EnumDTO";
import { AuthProductProps } from "../api/auth/AuthDTO";
import { LookupVehicleProps } from "../api/vehicle/VehicleDTO";
import { RegisterStateProps, TokenProps } from "../dto/AuthDTO";
import { createReducer, createRootReducer, PerformAction } from "../utils/ReducerUtils";

export const authReducerPersistConfig: Partial<PersistConfig<AuthReducerState>> = {
  whitelist: ["token", "authState", "registerState", "registerVehicle", "registerProduct"],
};

export interface SetTokenMeta {
  readonly token: TokenProps;
}

export interface ChangeAuhStateMeta {
  readonly authState: AuthState;
}

export interface SetRegisterVehicleMeta {
  readonly vehicle?: LookupVehicleProps;
}

export interface SetRegisterStateMeta {
  readonly state: Partial<RegisterStateProps>;
}

export interface SetRegisterTemporaryVehicleMeta {
  readonly vehicle: LookupVehicleProps;
}

interface SetRegisterProductMeta {
  readonly product: AuthProductProps;
}

enum ReducerActions {
  SetToken = "Auth/SetToken",
  ClearToken = "Auth/ClearToken",

  ChangeAuhState = "Auth/ChangeAuhState",

  SetRegisterState = "Auth/SetRegisterState",
  ClearRegisterState = "Auth/ClearRegisterState",

  SetTemporaryRegisterVehicle = "Auth/SetTemporaryRegisterVehicle",
  ClearTemporaryRegisterVehicle = "Auth/ClearTemporaryRegisterVehicle",

  SetRegisterVehicle = "Auth/SetRegisterVehicle",
  ClearRegisterVehicle = "Auth/ClearRegisterVehicle",

  SetRegisterProduct = "Auth/SetRegisterProduct",
  ClearRegisterProduct = "Auth/ClearRegisterProduct",
}

export interface AuthReducerState {
  readonly token?: TokenProps;
  readonly authState: AuthState;
  readonly registerVehicle?: string;
  readonly registerProduct?: AuthProductProps;
  readonly registerState?: RegisterStateProps;
  readonly temporaryRegisterVehicle?: LookupVehicleProps;
}

function getState(): AuthReducerState {
  return {
    authState: AuthState.Unauthorized,
  };
}

export const authReducer = createRootReducer(
  getState(),

  createReducer([ReducerActions.SetToken], (state, { meta }) =>
    update(state, {
      token: meta.token,
    }),
  ),

  createReducer([ReducerActions.ClearToken], (state) =>
    update(state, {
      token: DELETE,
    }),
  ),

  createReducer([ReducerActions.ChangeAuhState], (state, { meta }) =>
    update(state, {
      authState: meta.authState,
    }),
  ),

  createReducer([ReducerActions.SetRegisterState], (state, { meta }) =>
    deepUpdate(state)
      .at("registerState")
      .withDefault({} as RegisterStateProps)
      .modify((x) => update(x, meta.state)),
  ),

  createReducer([ReducerActions.ClearRegisterState], (state) =>
    update(state, {
      registerState: DELETE,
    }),
  ),

  createReducer([ReducerActions.SetTemporaryRegisterVehicle], (state, { meta }) =>
    update(state, {
      temporaryRegisterVehicle: meta.vehicle,
    }),
  ),

  createReducer([ReducerActions.ClearTemporaryRegisterVehicle], (state) =>
    update(state, {
      temporaryRegisterVehicle: DELETE,
    }),
  ),

  createReducer([ReducerActions.SetRegisterVehicle], (state, { meta }) =>
    update(state, { registerVehicle: meta.vehicle }),
  ),

  createReducer([ReducerActions.ClearRegisterVehicle], (state) =>
    update(state, { registerVehicle: DELETE }),
  ),

  createReducer([ReducerActions.SetRegisterProduct], (state, { meta }) =>
    update(state, { registerProduct: meta.product }),
  ),

  createReducer([ReducerActions.ClearRegisterProduct], (state) =>
    update(state, { registerProduct: DELETE }),
  ),
);

// ==================
// Selectors
// ==================

export function tokenSelector({ newAuth }: any): TokenProps | undefined {
  return newAuth.token;
}

export function authStateSelector({ newAuth }: any): AuthState {
  return newAuth.authState;
}

export function hasAuthorizedSelector({ newAuth }: any): boolean {
  const accessToken = newAuth.token?.accessToken;
  const refreshToken = newAuth.token?.refreshToken;

  if (accessToken && refreshToken) {
    const expires = accessToken.payload?.exp || 0;

    const tokenExpired = expires * 1000 > Date.now();

    return Boolean(refreshToken.token && accessToken.jwtToken && tokenExpired);
  }

  return false;
}

export function registerUsernameSelector({ newAuth }: any): string | undefined {
  const accessToken = newAuth.token?.accessToken;

  return accessToken?.payload.username;
}

export const registerStateSelector = ({ newAuth }: any): RegisterStateProps | undefined =>
  newAuth.registerState;
export const temporaryRegisterVehicleSelector = ({
  newAuth,
}: any): LookupVehicleProps | undefined => newAuth.temporaryRegisterVehicle;

export const registerVehicleSelector = ({ newAuth }: any): LookupVehicleProps | undefined =>
  newAuth.registerVehicle;
export const registerProductSelector = ({ newAuth }: any): AuthProductProps | undefined =>
  newAuth.registerProduct;

// ==================
// Actions
// ==================

export function setToken(meta: SetTokenMeta): PerformAction<SetTokenMeta> {
  return { meta, type: ReducerActions.SetToken };
}

export function clearToken(): Action {
  return { type: ReducerActions.ClearToken };
}

export function changeAuthState(meta: ChangeAuhStateMeta): PerformAction<ChangeAuhStateMeta> {
  return { meta, type: ReducerActions.ChangeAuhState };
}

export function setRegisterState(meta: SetRegisterStateMeta): PerformAction<SetRegisterStateMeta> {
  return { meta, type: ReducerActions.SetRegisterState };
}

export function setTemporaryRegisterVehicle(
  meta: SetRegisterTemporaryVehicleMeta,
): PerformAction<SetRegisterTemporaryVehicleMeta> {
  return { meta, type: ReducerActions.SetTemporaryRegisterVehicle };
}

export function clearRegisterState(): Action {
  return { type: ReducerActions.ClearRegisterState };
}

export function clearTemporaryRegisterVehicle(): Action {
  return { type: ReducerActions.ClearTemporaryRegisterVehicle };
}

export function setRegisterVehicle(
  meta: SetRegisterVehicleMeta,
): PerformAction<SetRegisterVehicleMeta> {
  return { meta, type: ReducerActions.SetRegisterVehicle };
}

export function setRegisterProduct(
  meta: SetRegisterProductMeta,
): PerformAction<SetRegisterProductMeta> {
  return { meta, type: ReducerActions.SetRegisterProduct };
}

export function clearRegisterVehicle(): Action {
  return { type: ReducerActions.ClearRegisterVehicle };
}

export function clearRegisterProduct(): Action {
  return { type: ReducerActions.SetRegisterProduct };
}
