import { useDispatch } from "react-redux";
import { useCallback, useMemo } from "react";

import { AuthApi } from "./AuthApi";
import { useApiBase } from "../ApiContext";
import { AuthState } from "../../dto/EnumDTO";
import { clearCognitoUser } from "../../reducers/userReducer";
import {
  changeAuthState,
  clearRegisterState,
  clearTemporaryRegisterVehicle,
  clearToken,
} from "../../reducers/authReducer";

export interface AuthContextProps {
  readonly AuthApi: AuthApi;

  readonly clearRegister: () => void;
}

export function useAuthContext(): AuthContextProps {
  const dispatch = useDispatch();

  const { host, token } = useApiBase();

  const api = useMemo(() => new AuthApi({ host, token }), [host]);

  const clearRegisterHandler = useCallback(() => {
    dispatch(clearToken());
    dispatch(clearCognitoUser());
    dispatch(clearRegisterState());
    dispatch(clearTemporaryRegisterVehicle());
    dispatch(changeAuthState({ authState: AuthState.Unauthorized }));
  }, [dispatch]);

  return {
    AuthApi: api,

    clearRegister: () => clearRegisterHandler(),
  };
}
