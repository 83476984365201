import React, { ReactNode } from "react";

import { WarningIcon } from "../icons/WarningIcon";
import { CheckRoundIcon } from "../icons/CheckRoundIcon";

interface Props {
  readonly checked: boolean;
  readonly checkedTitle: ReactNode;
  readonly uncheckedTitle: ReactNode;
}

export function VehicleReportInfoItem({ checked, checkedTitle, uncheckedTitle }: Props) {
  return (
    <div className="d-flex flex-1 align-items-center">
      <span className="d-inline-flex mb-lg-2 flex-1">
        <div className="mr-2 align-items-center">
          {checked ? <CheckRoundIcon /> : <WarningIcon />}
        </div>

        {checked ? checkedTitle : uncheckedTitle}
      </span>
    </div>
  );
}
