import React from "react";

import {BasicDetailsFirstRow} from "./BasicDetailsFirstRow";
import {BasicDetailsThirdRow} from "./BasicDetailsThirdRow";
import {BasicDetailsSecondRow} from "./BasicDetailsSecondRow";
import {BasicDetailsFourthRow} from "./BasicDetailsFourthRow";
import {
    AuthVehicleBasicDetailsProps,
    AuthVehicleKeyDatesProps,
    AuthVehicleProvenanceProps,
    VehicleBasicDetailsProps,
    VehicleKeyDataProps,
    VehicleProvenanceProps,
} from "../../api/vehicle/VehicleDTO";

interface Props {
  readonly provenance: VehicleProvenanceProps | AuthVehicleProvenanceProps;
  readonly basicDetails: VehicleBasicDetailsProps | AuthVehicleBasicDetailsProps;
  readonly keyDates: VehicleKeyDataProps | AuthVehicleKeyDatesProps;
}

export function CarBasicDetailsCard({ provenance, basicDetails, keyDates }: Props) {
  return (
    <div className="d-flex mx-lg-1 mx-xl-2 flex-column flex-1">
      <BasicDetailsFirstRow basicDetails={basicDetails} />
      <BasicDetailsSecondRow basicDetails={basicDetails} keyDates={keyDates}/>
      <BasicDetailsThirdRow provenance={provenance} basicDetails={basicDetails} />
      <BasicDetailsFourthRow provenance={provenance} keyDates={keyDates} />
    </div>
  );
}
