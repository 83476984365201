import qs from "qs";
import { update } from "immupdate";
import { useCallback } from "react";
import { useHistory, useLocation as useRouterLocation } from "react-router-dom";

import { Dict } from "../dto/AppDTO";
import { parseSearch } from "../utils/FormatUtils";

type QueryType = Dict<string | number>;

export interface HelpersLocationProps extends Location {
  readonly query: QueryType;
}

interface HelpersProps<T> {
  readonly pushLocation: (location: HelpersLocationProps) => void;
  readonly replaceLocation: (location: Partial<HelpersLocationProps>) => void;

  readonly pushNewQuery: (query: T | QueryType) => void;
  readonly replaceNewQuery: (query: T | QueryType) => void;

  readonly pushQuery: (query: T | QueryType) => void;
  readonly replaceQuery: (query: T | QueryType) => void;
}

export function useLocationHelpers<T>(): HelpersProps<T> {
  const history = useHistory();
  const routerLocation = useRouterLocation();

  const getUpdatedLocation = useCallback(
    ({ query: locationQuery = {}, ...location }, newQuery = {}) => {
      const search = parseSearch(location.search);

      const query = update(search, locationQuery);

      return update(location, { search: qs.stringify(update(query, newQuery)) });
    },
    [],
  );

  const getNewLocation = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ query, ...location }, newQuery = {}) => {
      return update(location, { search: qs.stringify(newQuery) });
    },
    [],
  );

  return {
    pushLocation: (l) => history.push(getUpdatedLocation(l)),
    replaceLocation: (l) => history.replace(getUpdatedLocation(l)),

    pushNewQuery: (query) => history.push(getNewLocation(routerLocation, query)),
    replaceNewQuery: (query) => history.replace(getNewLocation(routerLocation, query)),

    pushQuery: (query) => history.push(getUpdatedLocation(routerLocation, query)),
    replaceQuery: (query) => history.replace(getUpdatedLocation(routerLocation, query)),
  };
}
