import React from "react";
import {useHistory} from "react-router";
import {useDispatch} from "react-redux";

import {Routes} from "../../constants/Routes";
import {useScrollMemory} from "../../hooks/useScrollMemory";
import {setRegisterProduct} from "../../reducers/authReducer";
import {ProductField} from "../../components/form/ProductField";
import {AuthProduct} from "../../components/product/AuthProduct";
import {useOptimizeActivate} from "../../hooks/useOptimizeActivate";

export function AuthRegisterProductContainer() {
  useScrollMemory();

  const history = useHistory();

  const dispatch = useDispatch();

  useOptimizeActivate();
  
  return (
    <div className="d-flex bg-white">
      <div className="container d-flex flex-column flex-1 mt-4 mt-md-5 mb-6">
        <h1 className="d-none d-md-block text-center mb-3 mx-2">Choose your product</h1>
        <h2 className="d-block d-md-none text-center mb-3 mx-2">Choose your product</h2>

        <span className="text-center mx-2 mb-4 mb-md-4">
          All you need to do is choose your product below
        </span>

        <ProductField
          className="mb-6"
          withTrial={false}
          onChange={(product) => {
            dispatch(setRegisterProduct({ product }));

            history.push(Routes.AuthRegisterCheckout);
          }}
        />

        {false && (
          <AuthProduct
            onSelectProduct={(product) => {
              dispatch(setRegisterProduct({ product }));

              history.push(Routes.AuthRegisterCheckout);
            }}
          />
        )}
      </div>
    </div>
  );
}
