import styled from "styled-components";

export const ReportPageContainer = styled.div`
  padding: 0 0 50px;
  background: #fff;

  .report-page-content-container {
    margin: 20px auto 0;
    display: flex;

    .report-container {
      flex-grow: 1;
      margin: -10px 0 50px;

      .report-select-navigator {
        display: none;
        margin: 30px 0 0;
        overflow: hidden;
        border-bottom: 1px solid #22a6b3;
        background: #efefef;

        .MuiSelect-root {
          padding: 10px 6px 11px 6px;
        }
      }

      .MuiTabs-flexContainer {
        justify-content: space-between;

        .MuiTab-root {
          min-width: auto;
          padding: 0;
          flex-grow: 1;

          &.MuiTab-textColorPrimary.Mui-selected {
            color: #22a6b3;
          }
        }
      }
      .MuiTabs-indicator {
        background-color: #22a6b3;
      }
    }

    @media (min-width: 1201px) {
      width: 1140px;
    }

    @media (min-width: 976px) and (max-width: 1200px) {
      width: 960px;

      .report-container {
        .MuiTabs-scrollButtons {
          width: fit-content;
        }
        .MuiTabs-root {
          .MuiButtonBase-root {
            padding: 6px 12px;
          }
        }
      }
    }

    @media (min-width: 481px) and (max-width: 975px) {
      width: auto;
      margin: 20px 20px 0;

      .report-container {
        .MuiTabs-scrollButtons {
          width: fit-content;
        }
        .MuiTabs-root {
          .MuiButtonBase-root {
            padding: 6px 12px;
          }
        }
      }
    }

    @media (min-width: 320px) and (max-width: 480px) {
      width: auto;
      flex-direction: column;
      margin: 0;

      .report-container {
        margin: -10px 15px 50px;

        .report-select-navigator {
          display: block;
        }
        .MuiTabs-root {
          display: none;
        }
      }
    }
  }
`;
