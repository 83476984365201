import "./assets/auth-vehicle-info.scss";

import React from "react";

import { AppError } from "../../utils/AppError";
import { LoaderProgress } from "../ui/LoaderProgress";
import { AuthVehicleInfoItem } from "./AuthVehicleInfoItem";
import { LookupVehicleProps } from "../../api/vehicle/VehicleDTO";

interface Props {
  readonly error?: AppError;
  readonly loading: boolean;
  readonly onBackClick: () => void;
  readonly vehicle?: LookupVehicleProps;
  readonly isMyCar?: boolean;
}

export function AuthVehicleInfo({ loading, error, vehicle, onBackClick, isMyCar }: Props) {
  return (
    <div className="d-flex flex-column auth-vehicle-info">
      <h2 className="text-left mb-3 mb-md-5 vehicle-info-title">
        {isMyCar ? `Here's your vehicle:` : `Here's the vehicle you want to check:`}
      </h2>

      {Boolean(loading && !vehicle && !error) && (
        <div className="position-relative mx-auto progress-container">
          <LoaderProgress className="mx-auto" />
        </div>
      )}

      {Boolean(!loading && error) && (
        <div className="d-flex flex-column mb-3 mb-md-5">
          <span className="text-center">{error?.message}</span>
          <span className="text-center">
            Please{" "}
            <span
              role="button"
              onClick={onBackClick}
              className="text-decoration-underline cursor-pointer"
            >
              click here to retry
            </span>
          </span>
        </div>
      )}

      {Boolean(!loading && vehicle) && (
        <div className="d-flex flex-column mb-3 mb-md-5">
          <AuthVehicleInfoItem title="Make & Model" value={`${vehicle?.make} ${vehicle?.model}`} />
          <AuthVehicleInfoItem title="Colour" value={vehicle?.colour} />
          <AuthVehicleInfoItem last={true} title="Date of Registration" value={vehicle?.regDate} />

          <span className="text-right not-right-vehicle mt-2">
            Not the right vehicle?{" "}
            <span
              role="button"
              onClick={onBackClick}
              className="text-decoration-underline cursor-pointer"
            >
              Click here to retry
            </span>
          </span>
        </div>
      )}
    </div>
  );
}
