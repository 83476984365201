export const motsPageStyles = {
  pageTitle: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
    color: "#22A6B3",
    marginBottom: 10,
  },
  motTitle: {
    fontSize: 14,
    marginBottom: 10,
    fontWeight: 700,
  },
  orderText: {
    fontSize: 12,
    marginBottom: 10,
  },
  motDateBlock: {
    border: "1px solid #22A6B3",
    padding: 15,
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  issueBlock: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  issueCard: {
    width: 270,
    marginBottom: 5,
  },
  issueCardTitle: {
    height: 30,
    color: "#FFFFFF",
    paddingLeft: 5,
    display: "flex",
    justifyContent: "center",
  },
  issueCardContent: {
    margin: 5,
  },
  secondaryBg: {
    backgroundColor: "#22A6B3",
  },
  dangerousBg: {
    backgroundColor: "#EB4D4B",
    color: "#FFFFFF",
  },
  warningBg: {
    backgroundColor: "#F9CA24",
    color: "#FFFFFF",
  },
  normalBg: {
    backgroundColor: "#E0E0E0",
  },
  previousMOTsBlock: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  prevMOT: {
    width: 270,
    display: "flex",
    flexDirection: "row",
  },
  icon: {
    height: 16,
    width: 16,
    marginRight: 5,
  },
  commentBlock: {
    width: 240,
    margin: 10,
  },
  comment: {
    marginBottom: 5,
  },
  commentType: {
    padding: 2,
    width: 70,
    borderRadius: 15,
    fontSize: 10,
    textAlign: "center",
    color: "#000000",
    marginTop: 1,
  },
  failText: {
    color: "#EB4D4B",
  },
};
