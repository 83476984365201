import React from "react";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { PageLayout } from "./PageLayout";
import { format, formatFunds } from "../../../../utils/number";

import infoIcon from "../assets/info-circle.png";
import helpIcon from "../assets/help-circle.png";
import wrenchIcon from "../assets/wrench.png";
import moneyboxIcon from "../assets/moneybox.png";
import { repairsPageStyles } from "../styles/repairsPageStyles";

const styles = StyleSheet.create(repairsPageStyles);

export const RepairsPage = ({ report }) => {
  const repairs = report.repairs;
  const { motTests = [] } = report.mots || {};

  return (
    <PageLayout id={report.id}>
      <Text style={styles.pageTitle}>REPAIRS</Text>

      <View>
        <Text style={styles.repairsTitle}>Wear and tear</Text>
        <Text style={styles.orderText}>
          We have used industry maintenance data to calculate what repairs should have been done on
          this vehicle by now and what may be needed in the near future.
        </Text>

        <View style={styles.estimateWrapper}>
          <View style={styles.estimateCard}>
            <Image style={styles.estimateIcon} src={wrenchIcon} />
            <Text style={styles.orderText}>
              Repairs that should have been done for a vehicle of this mileage:
            </Text>
            {(repairs &&
              repairs.currentRepairs &&
              repairs.currentRepairs.length &&
              repairs.currentRepairs.map((estimate, index) => (
                <View key={index} style={styles.estimateCardContent}>
                  <View>
                    <Image style={styles.icon} src={infoIcon} />
                  </View>
                  <View>
                    <Text>
                      {estimate.title} ({estimate.details}) at {format(estimate.mileage)} miles
                    </Text>
                    <Text style={styles.estimateCardContentPrice}>
                      <Text style={styles.estimateCardContentPriceValue}>£{formatFunds(estimate.price)}</Text>
                      (estimate)
                    </Text>
                  </View>
                </View>
              ))) || <Text style={[styles.orderText, {fontStyle: "italic"}]}>We don’t have data for this vehicle</Text>}
          </View>

          <View style={styles.estimateCard}>
            <Image style={styles.estimateIcon} src={moneyboxIcon} />
            <Text style={styles.orderText}>
              Repairs that will possibly need doing in the next 30k miles:
            </Text>
            {(repairs &&
              repairs.futureRepairs &&
              repairs.futureRepairs.length &&
              repairs.futureRepairs.map((estimate, index) => (
                <View key={index} style={styles.estimateCardContent}>
                  <View>
                    <Image style={styles.icon} src={infoIcon} />
                  </View>
                  <View>
                    <Text>
                      {estimate.title} ({estimate.details}) at {format(estimate.mileage)} miles
                    </Text>
                    <Text style={styles.estimateCardContentPrice}>
                      <Text style={styles.estimateCardContentPriceValue}>£{formatFunds(estimate.price)}</Text>
                      (estimate)
                    </Text>
                  </View>
                </View>
              ))) || <Text style={[styles.orderText, {fontStyle: "italic"}]}>We don’t have data for this vehicle</Text>}
          </View>
        </View>
      </View>

      <View>
        <Text style={styles.repairsTitle}>MOT repairs</Text>
        {repairs && repairs.motRepairs && repairs.motRepairs.length ? (
          <Text style={styles.orderText}>
            Below are some of the more significant items that should have been repaired on this
            vehicle based on historic MOT tests. Take note that not all MOT test stations are as
            thorough as others so some items may still exist as problems on the vehicle.
          </Text>
        ) : motTests && motTests.length === 0 ? (
          <Text style={styles.orderText}>
            This vehicle hasn't had an MOT yet, therefore there are no MOT repairs needed.
          </Text>
        ) : (
          <Text style={styles.orderText}>There are no repairs needed.</Text>
        )}

        <View style={styles.motRepairsWrapper}>
          {(repairs &&
            repairs.motRepairs &&
            repairs.motRepairs.length &&
            repairs.motRepairs.map((repair, index) => (
              <View key={index} wrap={false} style={styles.motRepairCard}>
                <View>
                  <Image style={styles.icon} src={helpIcon} />
                </View>
                <View>
                  <Text style={styles.repairsTitle}>{repair.year}</Text>
                  <View>
                    {repair.items.map((repairItem, index) => (
                      <Text key={index} style={styles.repairsContent}>{repairItem}</Text>
                    ))}
                  </View>
                </View>
              </View>
            ))) ||
            null}
        </View>
      </View>
    </PageLayout>
  );
};
