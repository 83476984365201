import React, { useEffect } from "react";

import { createAmplifyConfig } from "../../utils/AuthUtils";
import { useScrollMemory } from "../../hooks/useScrollMemory";
import { APP_REDIRECT_SIGN_IN_B } from "../../constants/AppConstants";
import { StripeContainer } from "../../components/stripe/StripeContainer";
import { EmailRegisterFormWrapper } from "../../components/auth/EmailRegisterFormWrapper";

export function AuthRegisterCheckoutContainer() {
  useScrollMemory();

  useEffect(() => {
    createAmplifyConfig(APP_REDIRECT_SIGN_IN_B!);
  }, []);

  return (
    <div className="d-flex py-5 flex-column bg-white">
      <StripeContainer>
        <EmailRegisterFormWrapper />
      </StripeContainer>
    </div>
  );
}
