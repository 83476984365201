import React from "react";
import { Tabs, TabsProps } from "../ui/Tabs";

export enum AccountItems {
  Plan = "plan",
  Details = "details",
}

const ITEMS = [
  {
    label: "My Details",
    value: AccountItems.Details,
  },
  {
    label: "My Plan",
    value: AccountItems.Plan,
  },
];

type Props = Omit<TabsProps, "items">;

export function AccountTabs({ value, onChange, ...props }: Props) {
  return <Tabs {...props} value={value} items={ITEMS} onChange={onChange} />;
}
