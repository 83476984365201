import "./assets/error-boundary.scss";

import { connect } from "react-redux";
import * as Sentry from "@sentry/browser";
import React, { Component, ReactNode } from "react";

import Button from "../CustomButtons/Button";
import { APP_BRANCH_NAME, APP_DEPLOY, APP_SENTRY_DSN, IS_DEV } from "../../constants/AppConstants";

interface Props {
  readonly id?: string;
  readonly email?: string;
  readonly username?: string;
  readonly children: ReactNode;
}

interface State {
  readonly eventId?: string;
  readonly hasError: boolean;
}

const enhancer = connect(({ user, auth }: any) => ({
  id: user.userData && user.userData.id,
  email: user.userData && user.userData.email,
  username: auth.context && auth.context.username,
}));

export const ErrorBoundary = enhancer(
  class ErrorBoundary extends Component<Props, State> {
    public state: State = {
      hasError: false,
    };

    public static getDerivedStateFromError() {
      return {
        hasError: true,
      };
    }

    public componentDidMount() {
      if (typeof APP_SENTRY_DSN !== "undefined") {
        Sentry.init({
          dsn: APP_SENTRY_DSN,
          environment: APP_DEPLOY || "local",
          release: APP_BRANCH_NAME,
        });
      }
    }

    public componentDidCatch(error: Error) {
      if (!IS_DEV) {
        const { id, username, email } = this.props;

        Sentry.withScope((scope) => {
          scope.setUser({ id, username, email });

          const eventId = Sentry.captureException(error);

          this.setState({ eventId });
        });
      }
    }

    public render() {
      const { children } = this.props;
      const { hasError, eventId } = this.state;

      if (hasError) {
        return (
          <div className="d-flex align-items-center justify-content-center flex-1 bg-white error-boundary">
            <div className="error-report-content">
              <h2>Whoops, sorry, an error has occurred!</h2>
              <div className="mb-3">
                We encountered an error and apologise for the inconvenience. Our team has been
                notified about this case but you can add more details by clicking on the button
                below.
              </div>
              {/*
              //@ts-ignore */}
              <Button color="danger" onClick={() => Sentry.showReportDialog({ eventId })}>
                Report a problem!
              </Button>
            </div>
          </div>
        );
      }

      return children;
    }
  },
);
