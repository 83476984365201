import React from "react";
import { Form, Formik } from "formik";
import { object, ref, string } from "yup";

import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";
import { EmailSignUpFormProps } from "../../dto/AuthDTO";
import { FormikTextField } from "../form/FormikTextField";

const validationSchema = object({
  phone: string(),
  password: string().required("Password is required"),
  lastName: string().required("Last name is required"),
  firstName: string().required("First name is required"),
  email: string().email("Please provide valid email").required("Email is required"),
  confirmPassword: string()
    .oneOf([ref("password")], "Passwords must match")
    .required("Confirmation password is required"),
});

interface Props {
  readonly onSignInClick: () => void;
  readonly onForgotClick: () => void;
  readonly onVerifyClick: () => void;
  readonly onCancelClick: () => void;
  readonly onSubmit: (values: EmailSignUpFormProps) => void;
}

export function EmailSignUpForm({ onSubmit, onForgotClick, onSignInClick, onCancelClick }: Props) {
  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={{
        email: "",
        phone: "",
        password: "",
        lastName: "",
        firstName: "",
        confirmPassword: "",
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form className="d-flex flex-column" onSubmit={handleSubmit}>
          <h3 className="text-center">Sign Up</h3>
          <FormikTextField required={true} className="mb-4" name="firstName" label="First Name" />
          <FormikTextField required={true} className="mb-4" name="lastName" label="Last Name" />
          <FormikTextField
            name="email"
            label="Email"
            required={true}
            className="mb-4"
            inputProps={{
              autoCorrect: "off",
              autoCapitalize: "off",
            }}
          />
          <FormikTextField
            name="phone"
            label="Phone"
            className="mb-4"
            inputProps={{
              autoCorrect: "off",
              autoCapitalize: "off",
            }}
          />
          <FormikTextField
            name="password"
            type="password"
            required={true}
            label="Password"
            className="mb-4"
            inputProps={{
              autoCorrect: "off",
              autoCapitalize: "off",
            }}
          />
          <FormikTextField
            type="password"
            required={true}
            className="mb-3"
            name="confirmPassword"
            label="Confirm Password"
            inputProps={{
              autoCorrect: "off",
              autoCapitalize: "off",
            }}
          />

          <span className="mb-5">* Required fields</span>

          <ActionButton
            type="submit"
            className="mb-3"
            loading={isSubmitting}
            color={ColorPalette.Success}
          >
            Sign Up
          </ActionButton>

          <ActionButton onClick={onCancelClick} className="mb-3" color={ColorPalette.Primary}>
            Cancel
          </ActionButton>

          <div className="d-flex flex-column">
            <button
              type="button"
              onClick={onSignInClick}
              className="mb-2 border-0 bg-transparent outline-none text-primary"
            >
              Already got an account? Click here to sign in
            </button>
            <button
              type="button"
              onClick={onForgotClick}
              className="mb-2 border-0 bg-transparent outline-none text-primary"
            >
              Forgot your password?
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
