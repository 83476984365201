import { useMemo } from "react";
import { identity, isBoolean, pickBy } from "lodash";

import { useMatchMedia } from "./useMatchMedia";

export enum OrientationType {
  Portrait = "portrait",
  Landscape = "landscape",
  Undetermined = "undetermined",
}

export interface DeviceSizeProps {
  readonly width: number;
  readonly height: number;
}

export interface MediaQuerySizesProps {
  readonly mediaDownXs?: boolean;
  readonly mediaDownSm?: boolean;
  readonly mediaDownMd?: boolean;
  readonly mediaDownLg?: boolean;
  readonly mediaDownXl?: boolean;

  readonly mediaUpSm?: boolean;
  readonly mediaUpMd?: boolean;
  readonly mediaUpLg?: boolean;
  readonly mediaUpXl?: boolean;
  readonly mediaUpXxl?: boolean;

  readonly mediaOnlySm?: boolean;
  readonly mediaOnlyMd?: boolean;
  readonly mediaOnlyLg?: boolean;
  readonly mediaOnlyXl?: boolean;
}

export interface MediaQueryProps extends MediaQuerySizesProps {
  readonly isMobile: boolean;

  // readonly size: DeviceSizeProps;

  readonly orientation: OrientationType;
}

type MediaQuerySizesType = "none" | "down" | "up" | "only" | "all" | keyof MediaQuerySizesProps;

interface MediaQueryOptionsProps {
  readonly sizes?: MediaQuerySizesType;
}

function detectMobile() {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}

export function useMediaQuery({ sizes = "none" }: MediaQueryOptionsProps = {}): MediaQueryProps {
  const upSizes = useMemo(() => sizes === "up", [sizes]);
  const allSizes = useMemo(() => sizes === "all", [sizes]);
  const downSizes = useMemo(() => sizes === "down", [sizes]);
  const onlySizes = useMemo(() => sizes === "only", [sizes]);
  const querySizes: MediaQuerySizesProps = useMemo(
    () => ({
      mediaDownXs: sizes === "mediaDownXs",
      mediaDownSm: sizes === "mediaDownSm",
      mediaDownMd: sizes === "mediaDownMd",
      mediaDownLg: sizes === "mediaDownLg",
      mediaDownXl: sizes === "mediaDownXl",

      mediaUpSm: sizes === "mediaUpSm",
      mediaUpMd: sizes === "mediaUpMd",
      mediaUpLg: sizes === "mediaUpLg",
      mediaUpXl: sizes === "mediaUpXl",
      mediaUpXxl: sizes === "mediaUpXxl",

      mediaOnlySm: sizes === "mediaOnlySm",
      mediaOnlyMd: sizes === "mediaOnlyMd",
      mediaOnlyLg: sizes === "mediaOnlyLg",
      mediaOnlyXl: sizes === "mediaOnlyXl",
    }),
    [sizes],
  );

  const mediaDownXs = useMatchMedia("(max-width: 575.98px)", {
    skip: !downSizes && !allSizes && !querySizes.mediaDownXs,
  });
  const mediaDownSm = useMatchMedia("(max-width: 767.98px)", {
    skip: !downSizes && !allSizes && !querySizes.mediaDownXs,
  });
  const mediaDownMd = useMatchMedia("(max-width: 991.98px)", {
    skip: !downSizes && !allSizes && !querySizes.mediaDownXs,
  });
  const mediaDownLg = useMatchMedia("(max-width: 1199.98px)", {
    skip: !downSizes && !allSizes && !querySizes.mediaDownXs,
  });
  const mediaDownXl = useMatchMedia("(max-width: 1399.98px)", {
    skip: !downSizes && !allSizes && !querySizes.mediaDownXs,
  });

  const mediaUpSm = useMatchMedia("(min-width: 576px)", {
    skip: !upSizes && !allSizes && !querySizes.mediaUpSm,
  });
  const mediaUpMd = useMatchMedia("(min-width: 768px)", {
    skip: !upSizes && !allSizes && !querySizes.mediaUpMd,
  });
  const mediaUpLg = useMatchMedia("(min-width: 992px)", {
    skip: !upSizes && !allSizes && !querySizes.mediaUpLg,
  });
  const mediaUpXl = useMatchMedia("(min-width: 1200px)", {
    skip: !upSizes && !allSizes && !querySizes.mediaUpXl,
  });
  const mediaUpXxl = useMatchMedia("(min-width: 1400px)", {
    skip: !upSizes && !allSizes && !querySizes.mediaUpXxl,
  });

  const mediaOnlySm = useMatchMedia("(min-width: 576px) and (max-width: 767.98px)", {
    skip: !onlySizes && !allSizes && !querySizes.mediaOnlySm,
  });
  const mediaOnlyMd = useMatchMedia("(min-width: 768px) and (max-width: 991.98px)", {
    skip: !onlySizes && !allSizes && !querySizes.mediaOnlyMd,
  });
  const mediaOnlyLg = useMatchMedia("(min-width: 992px) and (max-width: 1199.98px)", {
    skip: !onlySizes && !allSizes && !querySizes.mediaOnlyLg,
  });
  const mediaOnlyXl = useMatchMedia("(min-width: 1200px) and (max-width: 1399.98px)", {
    skip: !onlySizes && !allSizes && !querySizes.mediaOnlyXl,
  });

  const orientationQuery = useMatchMedia("(orientation: portrait)");

  const orientation = useMemo(() => {
    if (isBoolean(orientationQuery)) {
      return orientationQuery ? OrientationType.Portrait : OrientationType.Landscape;
    }

    return OrientationType.Undetermined;
  }, [orientationQuery]);

  const isMobile = useMemo(() => detectMobile(), [
    mediaDownXs,
    mediaDownSm,
    mediaDownMd,
    mediaDownLg,
    mediaDownXl,

    mediaUpSm,
    mediaUpMd,
    mediaUpLg,
    mediaUpXl,
    mediaUpXxl,

    mediaOnlySm,
    mediaOnlyMd,
    mediaOnlyLg,
    mediaOnlyXl,
  ]);

  const result = useMemo(
    () => ({
      isMobile,

      orientation,

      mediaDownXs,
      mediaDownLg,
      mediaDownMd,
      mediaDownSm,
      mediaDownXl,

      mediaUpSm,
      mediaUpLg,
      mediaUpMd,
      mediaUpXl,
      mediaUpXxl,

      mediaOnlyLg,
      mediaOnlyMd,
      mediaOnlySm,
      mediaOnlyXl,
    }),
    [
      isMobile,

      orientation,

      mediaDownXs,
      mediaDownLg,
      mediaDownMd,
      mediaDownSm,
      mediaDownXl,

      mediaUpSm,
      mediaUpLg,
      mediaUpMd,
      mediaUpXl,
      mediaUpXxl,

      mediaOnlyLg,
      mediaOnlyMd,
      mediaOnlySm,
      mediaOnlyXl,
    ],
  );

  return useMemo(() => pickBy(result, identity) as any, [result]);
}
