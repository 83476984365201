import React from "react";

interface Props {
  readonly onInfoPress: () => void;
}

export function ShortListHeader({ onInfoPress }: Props) {
  return (
    <div className="container d-flex flex-column mb-3 mb-lg-5">
      <span className=" text-center text-md-left header-sub-title-text">
        Add any cars that you want to check and compare. You can either enter a car registration
        plate (VRM), or copy it from a car search site. For how to do this{" "}
        <div
          role="button"
          onClick={onInfoPress}
          className="d-inline-block text-decoration-underline cursor-pointer"
        >
          see here
        </div>
        .
      </span>
    </div>
  );
}
