import React from "react";
import { IconButton } from "@material-ui/core";

import { BackIcon } from "../icons/BackIcon";
import { useHistory } from "react-router";

interface Props {
  readonly onClick?: () => void;
}

export function BackButton({ onClick }: Props) {
  const history = useHistory();

  return (
    <IconButton
      type="button"
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          history.goBack();
        }
      }}
    >
      <BackIcon />
    </IconButton>
  );
}
