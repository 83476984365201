import "./assets/app-drop-down-item.scss";

import cx from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import React, { useRef, useState } from "react";

import { useOutsideClick } from "../../hooks/useOutsideClick";

AppDropDownItem.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  absolute: PropTypes.bool,
  onClose: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.object),
};

AppDropDownItem.defaultProps = {
  absolute: true,
};

export function AppDropDownItem({ title, onClose, items = [], absolute }) {
  const [show, setShow] = useState(false);

  const ref = useRef(null);

  useOutsideClick({
    ref,
    active: show,
    onClick: () => {
      if (onClose) {
        onClose();
      }
      setShow(false);
    },
  });

  return (
    <div
      ref={ref}
      className="d-flex flex-column app-drop-down-item position-relative app-drop-down-item"
    >
      <a
        href="#"
        onClick={event => {
          event.preventDefault();

          setShow(x => !x);
        }}
        className="d-flex py-1 px-3 align-items-center item-title"
      >
        {title}
      </a>

      {show && (
        <div
          className={cx("bg-white d-flex flex-column py-2 items-container", {
            "position-absolute": absolute,
          })}
        >
          {items.map(x => {
            if (x.onClick) {
              return (
                <button
                  key={x.title}
                  className="d-flex py-1 px-4 item-sub-link item-button"
                  onClick={() => {
                    if (onClose) {
                      onClose();
                    }
                    setShow(false);
                    x.onClick();
                  }}
                >
                  {x.title}
                </button>
              );
            }

            if (x.native) {
              return (
                <Link
                  to={x.url}
                  key={x.title}
                  className="py-1 px-4 item-sub-link"
                  onClick={() => {
                    if (onClose) {
                      onClose();
                    }
                    setShow(false);
                  }}
                >
                  {x.title.replace("&#8211;", "-")}
                </Link>
              );
            }

            return (
              <a
                href={x.url}
                key={x.title}
                className="py-1 px-4 item-sub-link"
                onClick={() => {
                  if (onClose) {
                    onClose();
                  }

                  setShow(false);
                }}
              >
                {x.title.replace("&#8211;", "-")}
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
}
