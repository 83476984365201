import React, { useMemo } from "react";

import { UserAccountProps } from "../../api/user/UserDTO";

interface Props {
  readonly account?: UserAccountProps;
}

export function SubscriptionDetailsMonthlyAccess({ account }: Props) {
  const reportCount = useMemo(() => account?.credits?.report || 0, [account?.credits]);

  return (
    <div className="flex-1">
      <div className="mb-3 text-center text-md-left">
        Your subscription ends on the {account?.subscription?.expiryDate}.
      </div>
      <div className="mb-3 text-center text-md-left">
        You have {reportCount} buyers report {reportCount === 1 ? "credit" : "credits"} remaining on
        your current plan
      </div>
      <div className="mb-3 text-center text-md-left">
        We will send you an email a few days before it is due to expire
      </div>
    </div>
  );
}
