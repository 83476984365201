import * as types from "../types/shortlist";

// TODO: should be moved to ENVs
const MOTORS_WEBSITE_URL = "www.motors.co.uk";
const AUTOTRADER_WEBSITE_URL = "www.autotrader.co.uk";

const PROVIDED_MANUALLY = 0;
const PROVIDED_BY_MOTORS = 1;
const PROVIDED_BY_AUTOTRADER = 2;

const handlers = {
  [types.SET_ERROR]: (state, { payload: error }) => {
    return {
      ...state,
      error,
    };
  },
  [types.CLEAN_ERROR]: (state) => {
    return {
      ...state,
      error: null,
    };
  },
  [types.ADD_VEHICLE_PENDING]: (state) => {
    return {
      ...state,
      newVehicle: {
        ...state.newVehicle,
        loading: true,
        error: null,
      },
    };
  },
  [types.ADD_VEHICLE_FULFILLED]: (state) => {
    return {
      ...state,
      newVehicle: {
        ...state.newVehicle,
        loading: false,
        error: null,
      },
    };
  },
  [types.ADD_VEHICLE_REJECTED]: (state, { payload: error }) => {
    return {
      ...state,
      error,
      newVehicle: {
        ...state.newVehicle,
        loading: false,
        error,
      },
    };
  },
  [types.LOAD_VEHICLES_PENDING]: (state) => {
    return {
      ...state,
      vehicles: {
        ...state.vehicles,
        loading: true,
        error: null,
      },
    };
  },
  [types.LOAD_VEHICLES_FULFILLED]: (state, { payload: entities }) => {
    return {
      ...state,
      vehicles: {
        ...state.vehicles,
        entities,
        loading: false,
        error: null,
      },
    };
  },
  [types.LOAD_VEHICLES_REJECTED]: (state, { payload: error }) => {
    return {
      ...state,
      error,
      vehicles: {
        ...state.vehicles,
        loading: false,
        error,
      },
    };
  },
  [types.FIND_VEHICLE_PENDING]: (state, { payload: input }) => {
    let providerId = PROVIDED_MANUALLY;

    if (input.includes(MOTORS_WEBSITE_URL)) {
      providerId = PROVIDED_BY_MOTORS;
    } else if (input.includes(AUTOTRADER_WEBSITE_URL)) {
      providerId = PROVIDED_BY_AUTOTRADER;
    }

    return {
      ...state,
      newVehicle: {
        ...state.newVehicle,
        input,
        providerId,
        loading: true,
        error: null,
      },
    };
  },
  [types.FIND_VEHICLE_FULFILLED]: (state, { payload: entity }) => {
    return {
      ...state,
      newVehicle: {
        ...state.newVehicle,
        entity,
        loading: false,
        error: null,
      },
    };
  },
  [types.FIND_VEHICLE_REJECTED]: (state, { payload: error }) => {
    return {
      ...state,
      error,
      newVehicle: {
        ...state.newVehicle,
        loading: false,
        error,
      },
    };
  },
};

const initialState = {
  error: null,
  vehicles: {
    loading: false,
    entities: [],
    error: null,
  },
  newVehicle: {
    loading: false,
    entity: {},
    input: "",
    error: null,
  },
};

export default function Reducer(state = initialState, payload) {
  const handler = handlers[payload.type];
  return (handler && handler(state, payload)) || state;
}
