import "./assets/auth-product-item.scss";

import React from "react";
import cx from "classnames";

import Button from "../CustomButtons/Button";
import {ProductDetailItem} from "./ProductDetailItem";
import {AuthProductProps} from "../../api/auth/AuthDTO";

interface Props {
  readonly className?: string;
  readonly onChange: () => void;
  readonly product: AuthProductProps;
}

export function AuthProductItem({ product, onChange, className }: Props) {
  return (
    <div
      className={cx("d-flex my-1 my-md-3 flex-1 flex-column border-0 auth-product-item", className)}
    >
      <div style={{ backgroundColor: product.color }} className="d-flex flex-column py-3 px-4">
        <h3 className="text-center text-white fs-4 m-0 product-item-title">{product.title}</h3>
        {/*<span className="text-center text-white product-item-sub-title">{product.subtitle}</span>*/}
      </div>

      <div className="d-flex flex-column mt-4 mb-3 my-md-5 align-items-center">
        <div className="d-flex">
          <span className="mt-n1 product-price-sub">{product.price.currency}</span>
          <span className="product-price-sup">{product.price.priceInteger}</span>
          {product.price.priceFractional && (
            <span className="product-price-sup">
              .{product.price.priceFractional}
            </span>
          )}
        </div>
        {product.price.priceHint && (
          <div className="product-price-hint mt-0">{product.price.priceHint}</div>
        )}
      </div>

      <div className="d-flex flex-column px-5 mb-3">
        {/*<span className="mb-4 text-center">{product.detailsTitle}</span>*/}
        {product.details.map((productDetail, productDetailIndex) => {
          const isSpecial = product.specialDetails.includes(productDetail);

          return (
            <ProductDetailItem
              isSpecial={isSpecial}
              key={productDetailIndex}
              description={productDetail}
            />
          );
        })}
      </div>

      <Button
        // @ts-ignore
        round={true}
        onClick={onChange}
        id={product.buttonId}
        style={{ backgroundColor: product.color }}
        className="align-self-center product-item-button"
      >
        {product.button}
      </Button>

      {Boolean(product.description) && (
        <span className="mt-3 mx-5 mb-4 text-primary text-center font-weight-light product-item-description">
          {product.description}
        </span>
      )}
    </div>
  );
}
