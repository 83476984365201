import "./assets/stripe-card-element.scss";

import React from "react";
import cx from "classnames";
import { FormControl, FormHelperText } from "@material-ui/core";
import { CardElement, ReactStripeElements } from "react-stripe-elements";

export interface StripeCardElementProps extends ReactStripeElements.ElementProps {
  readonly error?: boolean;
  readonly helperText?: string;
  readonly wrapperClassName?: string;
}

export function StripeCardElement({
  className,
  error,
  wrapperClassName,
  helperText,
  id,
  ...props
}: StripeCardElementProps) {
  return (
    <FormControl
      id={id}
      error={error}
      variant="outlined"
      className={cx("select-ui", wrapperClassName)}
    >
      <CardElement
        {...props}
        className={cx(
          "d-flex flex-column justify-content-center pl-2 stripe-card-element",
          { "border-danger": error },
          className,
        )}
      />
      {Boolean(helperText) && <FormHelperText className="mx-0">{helperText}</FormHelperText>}
    </FormControl>
  );
}
