import "./assets/mot-test.scss";

import cx from "classnames";
import {uid} from "react-uid";
import {capitalize, isNumber} from "lodash";
import React, {useMemo} from "react";
import Chip from "@material-ui/core/Chip";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import {MotTestCommentType, MotTestStatus} from "../../dto/EnumDTO";
import {formatGroupNumber, formatSecondsToString} from "../../utils/FormatUtils";

interface Props {
  readonly test: any;
}

export function MotTest({ test }: Props) {
  const emptyComments = useMemo(() => test.comments?.length === 0, [test.comments]);
  const testPassed = useMemo(() => test.testResult === MotTestStatus.Passed, [test]);
  const title = useMemo(() => {
    const date = isNumber(test.completedDate)
      ? formatSecondsToString(test.completedDate)
      : test.completedDate;

    return `${capitalize(test.testResult)}: ${date}`;
  }, [test]);

  const mileage = useMemo(() => {
    return `Mileage: ${formatGroupNumber(test.odometerValue)} ${test.odometerUnit}`;
  }, [test]);

  return (
    <div className="d-flex border-right border-bottom p-3 border-muted mot-test">
      <div className="mr-2">
        {testPassed && <CheckCircleOutlineIcon className="text-secondary" />}
        {!testPassed && <HighlightOffIcon className="text-danger" />}
      </div>

      <div className="d-flex flex-column">
        <span>{title}</span>
        <span>{mileage}</span>

        {!emptyComments && (
          <ul className="pl-3">
            {(test.comments || test.rfrAndComments || [])?.map((comment) => (
              <li key={uid(comment)}>
                <span className="mot-comment-text">{comment.text}</span>
                <div>
                  <Chip
                    size="small"
                    label={capitalize(comment.type)}
                    className={cx({
                      "bg-orange text-white": comment.type === MotTestCommentType.Major,
                      "bg-danger text-white":
                        comment.dangerous ||
                        comment.type === MotTestCommentType.Dangerous ||
                        comment.type === MotTestCommentType.Fail,
                    })}
                  />
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}
