import * as types from "../types/ui";

const handlers = {
  [types.SET_FOOTER_MENU_ITEMS]: (state, { payload: menuItems }) => {
    return {
      ...state,
      footer: {
        menuItems,
      },
    };
  },
};

const initialState = {
  footer: {
    menuItems: [],
  },
};

export default function Reducer(state = initialState, payload) {
  const handler = handlers[payload.type];
  return (handler && handler(state, payload)) || state;
}
