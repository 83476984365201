import "./assets/register-provenance-item.scss";

import React, { ReactNode } from "react";

interface Props {
  readonly title: string;
  readonly children?: ReactNode;
}

export function RegisterProvenanceItem({ title, children }: Props) {
  return (
    <tr className="register-provenance-item">
      <th scope="row" className="text-nowrap table-header py-1">
        {title}
      </th>

      {children}
    </tr>
  );
}
