import styled from "styled-components";

export const Container = styled.div`
  background: #30336b;
  min-height: 362px;
  color: #fff;
  display: flex;
  justify-content: center;

  .footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 60px 0px 45px;

    .copyright-text-mobile {
      display: none;
    }

    @media (min-width: 576px) {
      max-width: 540px;
    }
    @media (min-width: 768px) {
      max-width: 720px;
    }
    @media (min-width: 992px) {
      max-width: 960px;
    }
    @media (min-width: 1200px) {
      max-width: 1140px;
    }

    .footer-section {
      &.footer-copyright {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .copyright-text {
          font-weight: 500;
        }
      }
      .footer-links {
        list-style: none;
        columns: 2;

        li {
          margin: 0 0 10px;

          a {
            color: #fff;
            font-weight: 500;
          }
        }
      }
      &.footer-socials {
        display: flex;
        justify-content: space-between;

        a {
          color: #fff;
          margin: 0 15px 0;

          svg {
            font-size: 45px;
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    height: 500px;

    .footer-content {
      flex-direction: column;
      padding: 30px;
      margin: 0;

      .copyright-text {
        display: none;
      }
      .copyright-text-mobile {
        display: block;
      }
      .footer-links {
        padding: 0;
      }
      .footer-socials {
        a {
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
`;

export const LogoImage = styled.div`
  background-image: url('${(props) => props.src}');
  width: 241px;
  height: 40px;
`;
