import { update } from "immupdate";
import { isBoolean } from "lodash";
import { useCallback, useEffect, useState } from "react";

export enum OrientationType {
  Undetermined,
  Portrait,
  Landscape,
}

export enum DeviceType {
  Undetermined,
  Mobile,
  Desktop,
  Tablet,
  LargeDesktop,
}

export interface MediaQueryProps {
  readonly device: DeviceType;
  readonly orientation: OrientationType;
}

export function useMediaQueryLegacy(): MediaQueryProps {
  const [state, setState] = useState<MediaQueryProps>({
    device: DeviceType.Undetermined,
    orientation: OrientationType.Undetermined,
  });

  //====================== Orientation ==========================
  const changeOrientationHandler = useCallback((mql: MediaQueryList) => {
    setState((x) =>
      update(x, {
        orientation: isBoolean(mql?.matches)
          ? mql.matches
            ? OrientationType.Portrait
            : OrientationType.Landscape
          : OrientationType.Undetermined,
      }),
    );
  }, []);

  useEffect(() => {
    const mql = window.matchMedia("(orientation: portrait)");

    if (mql) {
      changeOrientationHandler(mql);
    }

    if (mql?.addListener) {
      // @ts-ignore
      mql.addListener(changeOrientationHandler);
    }

    return () => {
      if (mql?.removeListener) {
        // @ts-ignore
        mql.removeListener(changeOrientationHandler);
      }
    };
  }, [changeOrientationHandler]);
  //====================== Orientation ==========================

  //========================= Device ============================
  const changeMobileDeviceHandler = useCallback((mql: MediaQueryList) => {
    if (mql.matches) {
      setState((x) =>
        update(x, {
          device: DeviceType.Mobile,
        }),
      );
    }
  }, []);
  const changeTabletDeviceHandler = useCallback((mql: MediaQueryList) => {
    if (mql.matches) {
      setState((x) =>
        update(x, {
          device: DeviceType.Tablet,
        }),
      );
    }
  }, []);
  const changeDesktopDeviceHandler = useCallback((mql: MediaQueryList) => {
    if (mql.matches) {
      setState((x) =>
        update(x, {
          device: DeviceType.Desktop,
        }),
      );
    }
  }, []);
  const changeLargeDesktopDeviceHandler = useCallback((mql: MediaQueryList) => {
    if (mql.matches) {
      setState((x) =>
        update(x, {
          device: DeviceType.LargeDesktop,
        }),
      );
    }
  }, []);

  useEffect(() => {
    const mobileMql = window.matchMedia(
      "(min-width: 320px) and (max-width: 480px), (min-width: 481px) and (max-width: 767px)",
    );
    const tabletMql = window.matchMedia("(min-width: 768px) and (max-width: 1024px)");
    const desktopMql = window.matchMedia("(min-width: 1025px) and (max-width: 1200px)");
    const largeDesktopMql = window.matchMedia("(min-width: 1201px)");

    if (mobileMql) {
      changeMobileDeviceHandler(mobileMql);
    }

    if (tabletMql) {
      changeTabletDeviceHandler(tabletMql);
    }

    if (desktopMql) {
      changeDesktopDeviceHandler(desktopMql);
    }

    if (largeDesktopMql) {
      changeLargeDesktopDeviceHandler(largeDesktopMql);
    }

    if (mobileMql?.addListener) {
      // @ts-ignore
      mobileMql.addListener(changeMobileDeviceHandler);
    }

    if (tabletMql?.addListener) {
      // @ts-ignore
      tabletMql.addListener(changeTabletDeviceHandler);
    }

    if (desktopMql?.addListener) {
      // @ts-ignore
      desktopMql.addListener(changeDesktopDeviceHandler);
    }

    if (largeDesktopMql?.addListener) {
      // @ts-ignore
      largeDesktopMql.addListener(changeLargeDesktopDeviceHandler);
    }

    return () => {
      if (mobileMql?.removeListener) {
        // @ts-ignore
        mobileMql.removeListener(changeMobileDeviceHandler);
      }

      if (tabletMql?.removeListener) {
        // @ts-ignore
        tabletMql.removeListener(changeTabletDeviceHandler);
      }

      if (desktopMql?.removeListener) {
        // @ts-ignore
        desktopMql.removeListener(changeDesktopDeviceHandler);
      }

      if (largeDesktopMql?.removeListener) {
        // @ts-ignore
        largeDesktopMql.removeListener(changeLargeDesktopDeviceHandler);
      }
    };
  }, [changeMobileDeviceHandler]);
  //========================= Device ============================

  return state;
}
