import styled from "styled-components";

export const Container = styled.div`
  margin: 15px 0 0;

  .services-title {
    font-weight: 500;
    font-size: 20px;
    margin: 0 0 5px;
  }
  .services-description {
    margin: 0 0 15px;
  }
  .services-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2vw;
    margin: 0 0 20px;

    .service-block {
      .service-title {
        color: #fff;
        padding: 10px;
        font-weight: 500;
      }
      .service-info-item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dadada94;
        padding: 10px;
      }
    }
  }
  .due-services {
    .service-title {
      background: #eb4d4b;
    }
  }
  .previous-services {
    .service-title {
      background: #22a6b3;
    }
  }
`;
