import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { createSelector } from "reselect";
import FacebookIcon from "@material-ui/icons/Facebook";
import LogoImage from "../../assets/img/footer-logo.svg";
import footerAPI from "../../api-legacy/footerAPI";
import * as types from "../../redux/types/ui";
import * as styles from "./styles";

const MENU_COLUMN = Object.freeze({
  FIRST: 3,
  SECOND: 4,
});

const menuItemsSelector = createSelector(
  ({
    ui: {
      footer: { menuItems },
    },
  }) => menuItems,
  items => items,
);

export default function Footer() {
  const [year, setYear] = useState();
  const dispatch = useDispatch();
  const menuItems = useSelector(menuItemsSelector);

  useEffect(() => {
    setYear(moment().year());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const [{ data: firstData }, { data: secondData }] = await Promise.all([
        footerAPI.footerMenu(MENU_COLUMN.FIRST),
        footerAPI.footerMenu(MENU_COLUMN.SECOND),
      ]);

      dispatch({
        type: types.SET_FOOTER_MENU_ITEMS,
        payload: ((firstData && firstData.items) || []).concat(
          (secondData && secondData.items) || [],
        ),
      });
    };

    fetchData();
  }, []);

  return (
    <styles.Container>
      <div className="footer-content">
        <div className="footer-section footer-copyright">
          <a href="/">
            <styles.LogoImage src={LogoImage} />
          </a>
          <div className="copyright-text">©{year} CarGuide</div>
        </div>
        <div className="footer-section">
          <ul className="footer-links">
            {menuItems.map((link, index) => (
              <li key={index}>
                <a href={link.url} target="_blank" rel="noreferrer noopener">
                  {link.title}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="footer-section footer-socials">
          <a href="https://www.facebook.com/CarGuideUK" target="_blank" rel="noreferrer noopener">
            <FacebookIcon />
          </a>
        </div>
        <div className="footer-section copyright-text-mobile">©{year} CarGuide</div>
      </div>
    </styles.Container>
  );
}
