import { uid } from "react-uid";
import React, { useMemo, useState } from "react";

import { useResource } from "../hooks/useResource";
import { BackButton } from "../components/ui/BackButton";
import { useUserContext } from "../api/user/UserContext";
import ErrorModal from "../views/ShortlistPage/ErrorModal";
import { userDataSelector } from "../reducers/userReducer";
import { useResponseHandler } from "../hooks/useResponseHandler";
import { AccountPlans } from "../components/account/AccountPlans";
import { AppRouteLayout } from "../components/app/AppRouteLayout";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { UserDetailsForm } from "../components/account/UserDetailsForm";
import { AccountItems, AccountTabs } from "../components/account/AccountTabs";
import {
  UpgradePlanDialogWrapper,
  UpgradePlanType,
} from "../components/ui/UpgradePlanDialogWrapper";
import { SelectPlanDialogWrapper } from "../components/ui/SelectPlanDialogWrapper";

export function AccountContainer() {
  const [error, setError] = useState();
  const [tab, setTab] = useState(AccountItems.Details);
  const [loading, setLoading] = useState(false);
  const [showSelectDialog, setShowSelectDialog] = useState(false);
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);

  const userData = useShallowEqualSelector(userDataSelector);

  const { UserApi, setUserData, setUserAccount } = useUserContext();

  const userDataResponse = useResource(() => UserApi.getUserData(), []);
  const userAccountResponse = useResource(() => UserApi.getUserAccount(), []);

  useResponseHandler(userAccountResponse, (data, error) => {
    if (data && !error) {
      setUserAccount({ account: data });
    }
  });
  useResponseHandler(userDataResponse, (data, error) => {
    if (data && !error) {
      setUserData({ data });
    }
  });

  const isPlanTab = useMemo(() => tab === AccountItems.Plan, [tab]);
  const isDetailsTab = useMemo(() => tab === AccountItems.Details, [tab]);

  return (
    <AppRouteLayout
      withFooter={false}
      screenTitle="My Account"
      contentClassName="container"
      leftComponent={<BackButton />}
      loading={userDataResponse.loading || loading || userAccountResponse.loading}
    >
      <AccountTabs value={tab} onChange={setTab} className="mb-4" />

      {isDetailsTab && (
        <UserDetailsForm
          key={uid(userData || "")}
          loading={userDataResponse.loading || loading}
          defaultValues={{
            name: userData?.name,
            email: userData?.email,
            viaSms: userData?.marketing.viaSms,
            phoneNumber: userData?.phoneNumber,
            viaPush: userData?.marketing.viaPush,
            viaEmail: userData?.marketing.viaEmail,
          }}
          onSubmit={(values) => {
            setLoading(true);

            return UserApi.saveUserData({
              name: values.name,
              email: values.email,
              phoneNumber: values.phoneNumber,
              marketing: {
                viaSms: Boolean(values.viaSms),
                viaPush: Boolean(values.viaPush),
                viaEmail: Boolean(values.viaEmail),
              },
            })
              .then(() => {
                setLoading(false);
                userDataResponse.refetch();
              })
              .catch((e) => {
                setLoading(false);
                setError(e);
              });
          }}
        />
      )}

      {isPlanTab && (
        <>
          <AccountPlans
            account={userAccountResponse.data}
            loading={userAccountResponse.loading}
            onUpgradeClick={() => setShowSelectDialog(true)}
          />
          <SelectPlanDialogWrapper
            title="Upgrade Account"
            open={showSelectDialog}
            onDismiss={() => setShowSelectDialog(false)}
            onUpgradeClick={() => {
              setShowSelectDialog(false);
              setShowUpgradeDialog(true);
            }}
          />
          <UpgradePlanDialogWrapper
            open={showUpgradeDialog}
            type={UpgradePlanType.Upgrade}
            onDismiss={() => setShowUpgradeDialog(false)}
            onComplete={() => {
              userAccountResponse.refetch();
              setShowUpgradeDialog(false);
            }}
          />
        </>
      )}

      <ErrorModal error={error} onClose={() => setError(undefined)} />
    </AppRouteLayout>
  );
}
