import { WPClient } from "../utils/http";
import { APP_WP_URL } from "../constants/AppConstants";

const footerAPI = {
  footerMenu(id) {
    return WPClient.get(`${APP_WP_URL}/wp-json/menus/v1/menus/${id}`)
      .then((response) => response)
      .catch((err) => err.response);
  },
};

export default footerAPI;
