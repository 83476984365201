import React from "react";
import { Tabs, TabsProps } from "../ui/Tabs";

export enum MotInfoItems {
  Future = "future",
  Previous = "previous",
}

const ITEMS = [
  {
    label: "Future MOTs",
    value: MotInfoItems.Future,
  },
  {
    label: "Previous MOTs",
    value: MotInfoItems.Previous,
  },
];

type Props = Omit<TabsProps, "items">;

export function MotInfoTabs(props: Props) {
  return <Tabs {...props} items={ITEMS} />;
}
