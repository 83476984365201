import React, { useMemo } from "react";

import { DueMot } from "./DueMot";
import { LikelihoodIssue } from "./LikelihoodIssue";
import { MotPredicatedIssueProps, ReportCarInfoProps } from "../../api/report/ReportDTO";

interface Props {
  readonly carInfo?: ReportCarInfoProps;
  readonly onImpairInfoClick: () => void;
  readonly predicatedIssues?: MotPredicatedIssueProps[];
}

export function FutureMots({ carInfo, predicatedIssues = [], onImpairInfoClick }: Props) {
  const emptyIssues = useMemo(() => !predicatedIssues?.length || predicatedIssues?.length === 0, [
    predicatedIssues,
  ]);

  return (
    <div className="d-flex flex-column mb-md-6">
      <h5>Future MOTs</h5>

      {emptyIssues && (
        <span className="text-center text-md-left">
          Unfortunately impair.ai doesn't have MOT prediction data on this vehicle yet.
        </span>
      )}

      {!emptyIssues && (
        <>
          <span className="mb-3">The next MOT is due on:</span>

          {carInfo && <DueMot carInfo={carInfo} />}

          <span className="text-center mb-4">
            We’ve used the impair.ai platform to predict the likelihood of issues that may occur on
            this vehicle in the next 12 months. For more information{" "}
            <a
              href="#"
              className="text-decoration-underline"
              onClick={(event) => {
                event.preventDefault();
                onImpairInfoClick();
              }}
            >
              click here
            </a>
          </span>

          <div className="d-flex flex-column flex-sm-row flex-sm-wrap justify-content-sm-between">
            {predicatedIssues?.map((issue) => (
              <LikelihoodIssue key={`${issue.component}-${issue.risk}`} issue={issue} />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
