import "./assets/email-register-form-wrapper.scss";

import { head, isEmpty } from "lodash";
import { Form, Formik } from "formik";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { object, ref, string } from "yup";
import { DELETE, update } from "immupdate";
import { injectStripe } from "react-stripe-elements";
import React, { useCallback, useState } from "react";

import { Dialog } from "../ui/Dialog";
import { PaymentForm } from "./PaymentForm";
import { ActionButton } from "../ui/ActionButton";
import { toSnakeCase } from "../../utils/CaseUtils";
import { ColorPalette } from "../../theme/ColorPalette";
import { EmailRegisterForm } from "./EmailRegisterForm";
import { setCognitoUser } from "../../reducers/userReducer";
import { useAuthContext } from "../../api/auth/AuthContext";
import { EmailRegisterSocket } from "./EmailRegisterSocket";
import { AuthState, ProductPlans } from "../../dto/EnumDTO";
import { SocialGroupContainer } from "./SocialGroupContainer";
import { AuthRegisterFormProps } from "../../api/auth/AuthDTO";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { SocialRegisterButtonsWrapper } from "./SocialRegisterButtonsWrapper";
import {
  changeAuthState,
  registerProductSelector,
  registerVehicleSelector,
  setToken as setAuthToken,
} from "../../reducers/authReducer";
import { Analytics } from "../../utils/Analytics";
import { useOptimizeActivate } from "../../hooks/useOptimizeActivate";

const validationSchema = object({
  phone: string(),
  name: string().required("Name is required"),
  reason: string().test({
    message: "Please select reason",
    test: (x) => Boolean(x && x !== "none"),
  }),
  password: string()
    .min(6, "Password must have at least 6 characters")
    .when("provider", (field, schema) =>
      field !== "Facebook" && field !== "Google" ? schema.required("Password is required") : schema,
    ),
  email: string().email("Please provide valid email").required("Email is required"),
  confirmPassword: string().when("provider", (field, schema) =>
    field !== "Facebook" && field !== "Google"
      ? schema
          .oneOf([ref("password")], "Passwords must match")
          .required("Confirmation password is required")
      : schema,
  ),
});

export const EmailRegisterFormWrapper = injectStripe(({ stripe, elements }) => {
  const [user, setUser] = useState();
  const [isApple, setIsApple] = useState(false);
  const [token, setToken] = useState<string | undefined>();
  const [cardError, setCardError] = useState(false);
  const [progress, setProgress] = useState<number | undefined>();
  const [username, setUsername] = useState<string | undefined>();
  const [showPayment, setShowPayment] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const [dialog, setDialog] = useState({ title: "", message: "", open: false });

  const history = useHistory();
  const dispatch = useDispatch();

  const registerVehicle = useShallowEqualSelector(registerVehicleSelector);
  const registerProduct = useShallowEqualSelector(registerProductSelector);

  const [initialValues, setInitialValues] = useState<AuthRegisterFormProps>({
    name: "",
    phone: "",
    email: "",
    password: "",
    stripe: false,
    reason: "none",
    tncAccepted: false,
    confirmPassword: "",
    marketingAccepted: false,
  });

  const { AuthApi } = useAuthContext();

  const stripeHandler = useCallback(async (secret: string, email: string) => {
    return new Promise((resolve, reject) => {
      if (stripe) {
        const cardElement = elements?.getElement("card");

        if (cardElement) {
          stripe
            .confirmCardPayment(
              secret,
              toSnakeCase({
                receiptEmail: email,
                paymentMethod: { card: cardElement },
              }),
            )
            .then((x) => {
              if (x.error?.message) {
                const e = new Error(x.error.message);

                // @ts-ignore
                e.isStripe = true;

                reject(e);
              } else {
                resolve();
              }
            })
            .catch((err = {}) => {
              const e = new Error(err?.error?.message || "Stripe error");

              // @ts-ignore
              e.isStripe = true;

              reject(e);
            });
        } else {
          const e = new Error("Invalid stripe element");

          // @ts-ignore
          e.isStripe = true;

          reject(e);
        }
      } else {
        const e = new Error("Invalid stripe element");

        // @ts-ignore
        e.isStripe = true;

        reject(e);
      }
    });
  }, []);

  const paymentHandler = useCallback((ev, clientSecret) => {
    if (stripe && clientSecret) {
      stripe
        .confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false },
        )
        .then(({ error }) => {
          if (error) {
            ev.complete("fail");

            alert("Confirm: " + JSON.stringify(error));
          } else {
            ev.complete("success");

            stripe.confirmCardPayment(clientSecret);
          }
        });
    }
  }, []);

  const registerHandler = useCallback(
    async (values) => {
      const withoutStripe = Boolean(values.payment);

      const registerResponse = await AuthApi.authCustomerB({
        headers: token ? { Authorization: `Bearer ${token}` } : {},
        json: update(values, {
          stripe: DELETE,
          payment: DELETE,
          provider: DELETE,
          confirmPassword: DELETE,
          productId: registerProduct?.id,
          vrm: registerVehicle?.registration,
          password:
            values.provider !== "Google" && values.provider !== "Facebook"
              ? values.password
              : DELETE,
        }),
      });

      if (registerResponse.username || values.email) {
        const analytics = new Analytics();

        if (registerResponse.username) {
          analytics.setGoogleVariable("userId", registerResponse.username);
        }

        if (values.email) {
          analytics.setGoogleVariable("userEmail", values.email);
        }

        if (values.reason) {
          analytics.setGoogleVariable("dimension4", values.reason);
        }
      }

      setUsername(registerResponse.username);
      setClientSecret(registerResponse.clientSecret);

      if (!withoutStripe) {
        await stripeHandler(registerResponse.clientSecret, values.email);
      } else {
        setIsApple(true);

        await paymentHandler(values.payment, registerResponse.clientSecret);
      }
    },
    [AuthApi, registerVehicle, registerProduct, dispatch, token],
  );

  useOptimizeActivate();

  return (
    <Formik<AuthRegisterFormProps>
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, helpers) => {
        try {
          if (cardError && clientSecret) {
            await stripeHandler(clientSecret, values.email);
          } else if (cardError && !clientSecret) {
            helpers.setFieldError("stripe", "Invalid client secret");

            return;
          } else {
            await registerHandler(values);
          }

          setProgress(1);
        } catch (e) {
          if (e.isStripe) {
            helpers.setFieldError("stripe", e.message);

            setCardError(true);
          } else {
            if (values.payment) {
              values.payment.complete("fail");
            }

            helpers.setErrors({ name: e.data?.detail });
            setShowPayment(false);
          }
        }
      }}
    >
      {({ values, errors, setErrors, setTouched, handleSubmit, setFieldValue, validateForm }) => (
        <SocialGroupContainer
          progress={progress}
          progressClassName="socket-progress"
          className="align-self-center email-register-form-wrapper"
        >
          <Form className="d-flex flex-column" onSubmit={handleSubmit}>
            <EmailRegisterSocket
              isApple={isApple}
              username={username}
              reason={values.reason}
              onProcess={setProgress}
              product={registerProduct}
              vehicle={registerVehicle}
              onError={(e) => {
                setProgress(undefined);
                setErrors(update(errors, { stripe: e }));
              }}
              onDone={() => {
                setProgress(undefined);

                if (values.provider === "Google" || values.provider === "Facebook") {
                  if (user) {
                    dispatch(changeAuthState({ authState: AuthState.Authorized }));
                    dispatch(setCognitoUser({ user }));
                    dispatch(setAuthToken({ token: user! }));
                    history.replace("/shortlist");
                  }
                } else {
                  setDialog((x) =>
                    update(x, {
                      title: "Sign Up",
                      message:
                        "Registration was successful. Please check your email to verify your email address",
                      open: true,
                    }),
                  );
                }
              }}
            />

            {!showPayment && (
              <>
                <h4 className="text-center font-weight-bold mb-5">Please provide your details</h4>

                <span className="text-center mb-5">
                  To view your report you can use Google, Facebook or simply provide your email
                  address below:
                </span>

                <SocialRegisterButtonsWrapper
                  onError={() => alert("Something went wrong. Social auth")}
                  onSuccess={({ signInUserSession }) => {
                    const { idToken, accessToken } = signInUserSession || {};

                    if (signInUserSession) {
                      setUser(signInUserSession);
                    }
                    if (accessToken.jwtToken) {
                      setToken(accessToken.jwtToken);
                    }
                    if (idToken?.payload) {
                      const { email, name, identities } = idToken.payload;

                      const provider = head(identities) as any;

                      setInitialValues((x) =>
                        update(x, { name, email, provider: provider?.providerName }),
                      );
                    }
                  }}
                />

                <span className="text-center font-weight-bold my-3">OR</span>

                <EmailRegisterForm
                  onContinueClick={() => {
                    setTouched(
                      {
                        name: true,
                        email: true,
                        reason: true,
                        payment: true,
                        password: true,
                        provider: true,
                        tncAccepted: true,
                        confirmPassword: true,
                      },
                      true,
                    );

                    validateForm().then((x) => {
                      if (isEmpty(x) && values.tncAccepted) {
                        setShowPayment(true);
                      } else if (!values.tncAccepted) {
                        setErrors(
                          update(errors, {
                            stripe: !values.stripe ? "Provide valid card data" : DELETE,
                            tncAccepted: !values.tncAccepted
                              ? "You must accept Terms and Conditions"
                              : DELETE,
                          }),
                        );
                      }
                    });
                  }}
                />
              </>
            )}

            {showPayment && (
              <>
                <h4 className="text-center font-weight-bold mb-5">Last step - Payment details</h4>

                <div className="d-flex flex-column border border-info p-3 mb-5">
                  <span>
                    Product:{" "}
                    {registerProduct?.id === ProductPlans.SubsSignUp
                      ? "30 Days Access"
                      : "Pay As you GO"}
                  </span>
                  <span>
                    Amount: £{registerProduct?.id === ProductPlans.SubsSignUp ? "14.99" : "9.99"}
                  </span>
                </div>

                <span className="text-center mb-5">
                  You can use Google or Apple Pay to purchase your report, or simply enter your
                  payment card details below:
                </span>

                <PaymentForm
                  stripe={stripe}
                  progress={progress}
                  cardError={cardError}
                  product={registerProduct}
                  onSubmit={() => handleSubmit()}
                  onPayment={(ev) => {
                    setFieldValue("payment", ev);

                    handleSubmit();
                  }}
                />
              </>
            )}
          </Form>

          <Dialog
            open={dialog.open}
            title={dialog.title}
            actions={
              <div className="align-self-center">
                <ActionButton
                  color={ColorPalette.Primary}
                  onClick={() => {
                    setDialog((x) =>
                      update(x, {
                        title: "",
                        open: false,
                        message: "",
                      }),
                    );

                    if (dialog.title !== "Validate") {
                      history.replace("/auth/register?emailAuthType=sign-in");
                    }
                  }}
                >
                  Ok
                </ActionButton>
              </div>
            }
          >
            {dialog.message}
          </Dialog>
        </SocialGroupContainer>
      )}
    </Formik>
  );
});
