const LOGIN = "LOGIN";
const ERROR = "ERROR";
const initialState = {
  errors: [],
  error: null,
};
const Error = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      const temp = state.errors;
      const title = action.errors.title;
      const detail = action.errors.detail;
      temp.push({ title, detail });
      // errors.push(action.error)
      return {
        ...state,
        errors: [...temp],
      };
    }
    case ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};
export const catchError = (errors) => ({ type: LOGIN, errors });
export const setError = (error) => ({ type: ERROR, error });
export default Error;
