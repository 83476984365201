import React from "react";
import cx from "classnames";
import { uid } from "react-uid";

import { VehicleListTable } from "./VehicleListTable";
import { VehicleCompareItemProps } from "../../api/vehicle/VehicleDTO";

interface Props {
  readonly list: VehicleCompareItemProps[][];
  readonly onBuyReportClick: (vrm: string) => void;
}

export function VehicleList({ list, onBuyReportClick }: Props) {
  return (
    <>
      {list.map((column, compareIdx) => {
        return (
          <div
            key={uid(column)}
            className={cx("track my-6", {
              "border-right border-light-aqua": compareIdx < list.length - 1,
            })}
          >
            {column.map((x, idx) => {
              const empty = list.every((v) => v[idx]?.empty);

              if (empty) {
                return;
              }

              return (
                <VehicleListTable
                  id={x.index}
                  key={uid(x)}
                  title={x.title}
                  upsel={x.upsel}
                  values={x.values}
                  firstColumn={compareIdx === 0}
                  onBuyReportClick={() => onBuyReportClick(x.vrm)}
                />
              );
            })}
          </div>
        );
      })}
    </>
  );
}
