import React from "react";
import { FieldError } from "react-hook-form";
import { TextField as MUITextField } from "@material-ui/core";
import { StandardTextFieldProps } from "@material-ui/core/TextField/TextField";

import { FieldProps } from "../../dto/FormDTO";

type ExtendsProps = StandardTextFieldProps & FieldProps<string>;

interface Props extends Omit<ExtendsProps, "error"> {
  readonly name: string;
  readonly error?: FieldError;
}

export function TextField({ error, helperText, value, ...props }: Props) {
  return (
    <MUITextField
      {...props}
      error={Boolean(error)}
      value={value != null ? value : ""}
      helperText={error?.message || helperText}
    />
  );
}
