import "./assets/select-plan-dialog.scss";

import React, {useEffect} from "react";

import {Dialog} from "./Dialog";
import Button from "../CustomButtons/Button";
import {Analytics} from "../../utils/Analytics";
import {ColorPalette} from "../../theme/ColorPalette";
import {SubscriptionFeaturesList} from "../subscriptions/SubscriptionFeaturesList";
import {BUY_PRODUCT_CATEGORY, BuyProductActions} from "../../constants/AnalyticsConstants";

export interface SelectPlanDialogProps {
  readonly open: boolean;
  readonly title?: string;
  readonly onDismiss: () => void;
  readonly hasSubscription: boolean;
  readonly onUpgradeClick: () => void;
  readonly onBuySingleClick?: () => void;
}

export function SelectPlanDialog({
  open,
  title = "You're out of buyers report credits!",
  onDismiss,
  onUpgradeClick,
  hasSubscription,
  onBuySingleClick,
}: SelectPlanDialogProps) {
  useEffect(() => {
    if (open) {
      const analytics = new Analytics();

      analytics.googleEvent("event", {
        eventLabel: "upgrade-popup",
        eventCategory: BUY_PRODUCT_CATEGORY,
        eventAction: BuyProductActions.BuyProductPopup,
      });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      title={title}
      onClose={onDismiss}
      className="select-plan-dialog"
      titleClassName="text-center justify-content-center justify-content-sm-start text-sm-left"
      actions={
        <div className="d-flex justify-content-between flex-column-reverse flex-sm-row">
          <Button
            // @ts-ignore
            id="upgrade-cancel-trackbutton"
            style={{ backgroundColor: "#999999" }}
            onClick={onDismiss}
          >
            Cancel
          </Button>

          <div className="d-flex flex-column-reverse flex-sm-row">
            {onBuySingleClick && (
              <Button
                // @ts-ignore
                id="upgrade-single-trackbutton"
                onClick={onBuySingleClick}
                className="mr-1"
                style={{ backgroundColor: ColorPalette.Success }}
              >
                Single report {hasSubscription ? "£4.99" : "£5.99"}
              </Button>
            )}
            <Button
              // @ts-ignore
              color="danger"
              className="ml-1"
              onClick={onUpgradeClick}
              id="upgrade-30days-trackbutton"
            >
              30-Days Access £14.99
            </Button>
          </div>
        </div>
      }
    >
      <div className="d-flex flex-column flex-sm-row">
        <div className="d-flex flex-column flex-1">
          <span className="mb-3 font-weight-medium text-center text-sm-left title-text">
            Upgrade your account
          </span>
          <span className="mb-3 text-center text-sm-left">
            Unfortunately you've used all available buyer report credits on your current plan.
          </span>
          <span className="mb-3 text-center text-sm-left">
            You can buy a single report or alternatively it may be more cost effective to upgrade
            your account to our <strong>30 days access plan</strong>.
          </span>
        </div>

        <SubscriptionFeaturesList />
      </div>
    </Dialog>
  );
}
