import React, { ReactElement, ReactNode, useContext, useMemo } from "react";
import { TokenProps } from "../dto/AuthDTO";
import { APP_API_URL } from "../constants/AppConstants";
import { tokenSelector } from "../reducers/authReducer";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";

export interface ApiContextDataProps {
  readonly token?: TokenProps;
}

export interface ApiContextProps {
  readonly host: string;
  readonly token?: TokenProps;
}

export interface ApiProviderProps {
  readonly children: ReactNode;
}

function createContentValue(apiData: ApiContextDataProps): ApiContextProps {
  return {
    ...apiData,
    host: APP_API_URL!,
  };
}

export const ApiContext = React.createContext<ApiContextProps>(createContentValue({}));

export function ApiProvider(props: ApiProviderProps): ReactElement<object> {
  const token = useShallowEqualSelector(tokenSelector);

  const value = useMemo(() => createContentValue({ token }), [token]);

  return <ApiContext.Provider {...props} value={value} />;
}

export function useApiBase(): ApiContextProps {
  return useContext(ApiContext);
}
