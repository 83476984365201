import { connect } from "react-redux";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

import { AppHeader } from "./AppHeader";
import { urlDataThunk } from "../../reducers-legacy/customerReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { authStateSelector, hasAuthorizedSelector } from "../../reducers/authReducer";

const enhancer = connect(
  ({ loginPage }) => ({
    data: loginPage.data,
  }),
  {
    getUrls: urlDataThunk,
  },
);

export const AppHeaderWrapper = enhancer(({ data, getUrls }) => {
  const history = useHistory();

  const authState = useShallowEqualSelector(authStateSelector);
  const hasAuthorized = useShallowEqualSelector(hasAuthorizedSelector);

  useEffect(() => {
    getUrls();
  }, []);

  return (
    <AppHeader
      data={data}
      authState={authState}
      hasAuthorized={hasAuthorized}
      onSignOut={() => history.replace("/auth/logout")}
    />
  );
});
