import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import withScrollTop from "../../../hoc/withScrollTop";
import { format, formatFunds } from "../../../utils/number";
import * as actions from "../../../redux/actions/report";
import * as styles from "./styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TabContainer from "../TabContainer";

function ServiceSchedules({ token, report }) {
  const dispatch = useDispatch();
  const services = report.services;

  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (!services) {
      dispatch(actions.loadServices(report.id, token));
    }
  }, [token]);

  if (!services) {
    return [];
  }

  if (
    (!services.servicesPrev && !services.servicesDue) ||
    (!services.servicesPrev.length && !services.servicesDue.length)
  ) {
    return (
      <styles.Container>
        <div className="services-description">
          Our data providers don’t hold service schedule information for this vehicle. This could be
          due to a number of reasons, for example, the age of the vehicle.
        </div>
      </styles.Container>
    );
  }

  return (
    <styles.Container className="d-flex">
      <div className="d-flex d-md-none flex-column">
        <Tabs
          className="d-block"
          value={currentTab}
          textColor="primary"
          indicatorColor="primary"
          onChange={(_, tab) => setCurrentTab(tab)}
        >
          <Tab label="Services due" value={0} />
          <Tab label="Previous Services" value={1} />
        </Tabs>

        <TabContainer active={currentTab} tab={0}>
          <div className="due-services">
            <div className="services-title">Services due</div>
            <div className="services-description">
              Based on the manufacturer's service schedules, the below may be needed on this vehicle:
            </div>
            <div className="services-list d-flex flex-column">
              {services &&
                services.servicesDue &&
                services.servicesDue.map((service, index) => (
                  <div key={index} className="service-block">
                    <div className="service-title">{service.title}</div>
                    <div className="service-info-item">
                      <div className="service-info-title">Estimated Labour Time</div>
                      <div className="service-info-value">{service.labourTime} hours</div>
                    </div>
                    <div className="service-info-item">
                      <div className="service-info-title">Estimated Labour Cost</div>
                      <div className="service-info-value">£{formatFunds(service.labourCost)}</div>
                    </div>
                    <div className="service-info-item">
                      <div className="service-info-title">Estimated Parts Cost</div>
                      <div className="service-info-value">£{formatFunds(service.partsCost)}</div>
                    </div>
                    <div className="service-info-item">
                      <div className="service-info-title">Estimated Price</div>
                      <div className="service-info-value">£{formatFunds(service.totalCost)}</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </TabContainer>
        <TabContainer active={currentTab} tab={1}>
          <div className="previous-services">
            <div className="services-title">Previous Services</div>
            <div className="services-description">
              Based on manufacturer's schedules, the service book should look like this:
            </div>
            <div className="services-list d-flex flex-column">
              {services &&
                services.servicesPrev &&
                services.servicesPrev.map((service, index) => (
                  <div key={index} className="service-block">
                    <div className="service-title">{service.title}</div>
                    <div className="service-info-item">
                      <div className="service-info-title">Estimated Labour Time</div>
                      <div className="service-info-value">{service.labourTime} hours</div>
                    </div>
                    <div className="service-info-item">
                      <div className="service-info-title">Estimated Labour Cost</div>
                      <div className="service-info-value">£{formatFunds(service.labourCost)}</div>
                    </div>
                    <div className="service-info-item">
                      <div className="service-info-title">Estimated Parts Cost</div>
                      <div className="service-info-value">£{formatFunds(service.partsCost)}</div>
                    </div>
                    <div className="service-info-item">
                      <div className="service-info-title">Estimated Price</div>
                      <div className="service-info-value">£{formatFunds(service.totalCost)}</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </TabContainer>
      </div>

      <div className="d-none d-md-flex flex-column">
        <div className="due-services">
          <div className="services-title">Services due</div>
          <div className="services-description">
            Based on the manufacturer's service schedules, the below may be needed on this vehicle:
          </div>
          <div className="services-list">
            {services &&
              services.servicesDue &&
              services.servicesDue.map((service, index) => (
                <div key={index} className="service-block">
                  <div className="service-title">{service.title}</div>
                  <div className="service-info-item">
                    <div className="service-info-title">Estimated Labour Time</div>
                    <div className="service-info-value">{service.labourTime} hours</div>
                  </div>
                  <div className="service-info-item">
                    <div className="service-info-title">Estimated Labour Cost</div>
                    <div className="service-info-value">£{formatFunds(service.labourCost)}</div>
                  </div>
                  <div className="service-info-item">
                    <div className="service-info-title">Estimated Parts Cost</div>
                    <div className="service-info-value">£{formatFunds(service.partsCost)}</div>
                  </div>
                  <div className="service-info-item">
                    <div className="service-info-title">Estimated Price</div>
                    <div className="service-info-value">£{formatFunds(service.totalCost)}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div className="previous-services">
          <div className="services-title">Previous Services</div>
          <div className="services-description">
            Based on manufacturer's schedules, the service book should look like this:
          </div>
          <div className="services-list">
            {services &&
              services.servicesPrev &&
              services.servicesPrev.map((service, index) => (
                <div key={index} className="service-block">
                  <div className="service-title">{service.title}</div>
                  <div className="service-info-item">
                    <div className="service-info-title">Estimated Labour Time</div>
                    <div className="service-info-value">{service.labourTime} hours</div>
                  </div>
                  <div className="service-info-item">
                      <div className="service-info-title">Estimated Labour Cost</div>
                      <div className="service-info-value">£{formatFunds(service.labourCost)}</div>
                    </div>
                    <div className="service-info-item">
                      <div className="service-info-title">Estimated Parts Cost</div>
                      <div className="service-info-value">£{formatFunds(service.partsCost)}</div>
                    </div>
                  <div className="service-info-item">
                    <div className="service-info-title">Estimated Price</div>
                    <div className="service-info-value">£{formatFunds(service.totalCost)}</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </styles.Container>
  );
}

export default withScrollTop(ServiceSchedules);
