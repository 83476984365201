import "./assets/auth-social-button.scss";

import cx from "classnames";
import React, { useMemo } from "react";
import { MailIcon } from "../icons/MailIcon";
import { GoogleIcon } from "../icons/GoogleIcon";
import { AmazonIcon } from "../icons/AmazonIcon";
import { FacebookIcon } from "../icons/FacebookIcon";

export enum SocialColor {
  Back = "#ffffff",
  Email = "#22a6b3",
  Google = "#4285f4",
  Amazon = "#ff9900",
  Facebook = "#4268b2",
}

export enum SocialType {
  Back = "back",
  Email = "email",
  Google = "google",
  Amazon = "amazon",
  Facebook = "facebook",
}

interface Props {
  readonly id?: string;
  readonly type?: SocialType;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly onClick: () => void;
}

export function AuthSocialButton({
  id,
  type = SocialType.Google,
  onClick,
  disabled,
  className,
}: Props) {
  const backgroundColor = useMemo(
    () =>
      type === SocialType.Amazon
        ? SocialColor.Amazon
        : type === SocialType.Facebook
        ? SocialColor.Facebook
        : type === SocialType.Google
        ? SocialColor.Google
        : type === SocialType.Email
        ? SocialColor.Email
        : SocialColor.Back,
    [type],
  );
  const title = useMemo(
    () =>
      type === SocialType.Amazon
        ? "Use Amazon"
        : type === SocialType.Facebook
        ? "Use Facebook"
        : type === SocialType.Google
        ? "Use Google"
        : type === SocialType.Email
        ? "Use Email Address"
        : "Cancel",
    [type],
  );
  const icon = useMemo(
    () =>
      type === SocialType.Amazon ? (
        <AmazonIcon />
      ) : type === SocialType.Facebook ? (
        <FacebookIcon />
      ) : type === SocialType.Google ? (
        <GoogleIcon />
      ) : type === SocialType.Email ? (
        <MailIcon />
      ) : undefined,
    [type],
  );

  return (
    <button
      id={id}
      type="button"
      onClick={onClick}
      disabled={disabled}
      style={{ backgroundColor }}
      className={cx(
        "d-flex position-relative cursor-pointer align-items-center justify-content-center align-self-md-center auth-social-button",
        {
          disabled: disabled,
        },
        className,
      )}
    >
      {Boolean(icon) && (
        <div
          className={cx("position-absolute icon-container", {
            "d-flex align-items-center justify-content-center": type === SocialType.Facebook,
            "bg-white": type === SocialType.Google,
          })}
        >
          {icon}
        </div>
      )}

      <span className={cx("title", { "text-white": type !== SocialType.Back })}>{title}</span>
    </button>
  );
}
