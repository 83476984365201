import moment from "moment";
import "moment/locale/en-gb";

moment.locale("en-gb");

function Moment(date, ...args) {
  if (!date) {
    return moment(...args);
  }

  return moment(date, "DD.MM.YYYY");
}

export default Moment;
