import { useMemo } from "react";

import { useApiBase } from "../ApiContext";
import { PurchaseApi } from "./PurchaseApi";

export interface PurchaseContextProps {
  readonly PurchaseApi: PurchaseApi;
}

export function usePurchaseContext(): PurchaseContextProps {
  const { host, token } = useApiBase();

  const api = useMemo(() => new PurchaseApi({ host, token }), [host, token]);

  return {
    PurchaseApi: api,
  };
}
