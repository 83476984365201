import { useField } from "formik";
import React, { useMemo } from "react";

import { Select, SelectProps } from "../ui/Select";

interface Props extends Omit<SelectProps, "value" | "onChange" | "helperText"> {
  readonly name: string;
}

export function FormikSelectField({ name, ...props }: Props) {
  const [field, meta] = useField(name);

  const showError = useMemo(() => Boolean(meta.touched && meta.error), [meta]);

  return (
    <Select
      {...field}
      {...props}
      error={showError}
      helperText={showError ? meta.error : undefined}
    />
  );
}
