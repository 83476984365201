import React from "react";
import { Form, Formik } from "formik";

import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";
import { FormikTextField } from "../form/FormikTextField";
import { EmailForgotPasswordSubmitFormProps } from "../../dto/AuthDTO";

interface Props {
  readonly forgotEmail?: string;
  readonly onCancelClick: () => void;
  readonly onSubmit: (values: EmailForgotPasswordSubmitFormProps) => void;
}

export function EmailForgotPasswordSubmitForm({
  onSubmit,
  forgotEmail = "",
  onCancelClick,
}: Props) {
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ email: forgotEmail, password: "", confirmPassword: "" }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form className="d-flex flex-column" onSubmit={handleSubmit}>
          <h3 className="text-center">Forgot Password</h3>
          <FormikTextField
            name="email"
            label="Email"
            className="mb-4"
            inputProps={{
              autoCorrect: "off",
              autoCapitalize: "off",
            }}
          />
          <FormikTextField
            name="password"
            type="password"
            label="Password"
            className="mb-4"
            inputProps={{
              autoCorrect: "off",
              autoCapitalize: "off",
            }}
          />
          <FormikTextField
            type="password"
            className="mb-5"
            name="confirmPassword"
            label="Confirm Password"
            inputProps={{
              autoCorrect: "off",
              autoCapitalize: "off",
            }}
          />
          <ActionButton
            type="submit"
            className="mb-3"
            loading={isSubmitting}
            color={ColorPalette.Success}
          >
            Confirm
          </ActionButton>
          <ActionButton onClick={onCancelClick} color={ColorPalette.Primary}>
            Cancel
          </ActionButton>
        </Form>
      )}
    </Formik>
  );
}
