export const mileageAndRisksPageStyles = {
  pageTitle: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
    color: "#22A6B3",
    marginBottom: 10,
  },
  orderText: {
    fontSize: 12,
    marginBottom: 10,
  },
  boldText: {
    fontWeight: 700,
  },
  orderTitle: {
    fontSize: 14,
    marginBottom: 10,
    fontWeight: 700,
  },
  mileageTable: {
    margin: 5,
    border: "1px solid gray",
    borderRadius: 5,
    padding: 5,
    display: "flex",
    flexDirection: "column",
    width: "98%",
    fontSize: 10,
  },
  mileageTableHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontWeight: 700,
  },
  mileageTableBody: {
    display: "flex",
    flexDirection: "column",
  },
  mileageTableLine: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 10,
    padding: 2,
  },
  mileageTableCol1: {
    width: 30,
  },
  mileageTableCol2: {
    width: 85,
    textAlign: "center",
  },
  mileageTableCol3: {
    width: 85,
    textAlign: "center",
  },
  bgGray: {
    backgroundColor: "#E0E0E0",
  },
  lineMileageTable: {
    marginBottom: 10,
  },
  mileageInfo: {
    padding: 15,
    backgroundColor: "#22A6B3",
    color: "#FFFFFF",
    borderRadius: 5,
    marginBottom: 10,
  },
  icon: {
    width: 40,
    height: 40,
    marginBottom: 10,
  },
  risks: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  risk: {
    width: 265,
    margin: 5,
    padding: 12,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 3,
    textAlign: "center",
  },
  riskOk: {
    backgroundColor: "#C7ECEE",
  },
  riskAttention: {
    backgroundColor: "#FCD4D4",
    color: "#E95655",
  },
  riskWarning: {
    backgroundColor: "#FEF5D3",
  },
  riskTitle: {
    fontSize: 14,
    marginBottom: 10,
    fontWeight: 700,
  },
  riskDescription: {
    fontSize: 12,
  },
  warning: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    margin: 5,
    backgroundColor: "#EB4D4B",
    borderRadius: 5,
    color: "#FFFFFF",
  },
  warningTitle: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: 15,
  },
  descriptionWarning: {
    padding: "10px",
    fontStyle: "italic",
    color: "#FFFFFF",
    backgroundColor: "#EB4D4D",
    borderRadius: 3,
  },
};
