import cx from "classnames";
import {useFormikContext} from "formik";
import React, {useMemo} from "react";

import {ActionButton} from "../ui/ActionButton";
import {ColorPalette} from "../../theme/ColorPalette";
import {APP_WP_URL} from "../../constants/AppConstants";
import {FormikTextField} from "../form/FormikTextField";
import {FormikSelectField} from "../form/FormikSelectField";
import {AuthRegisterFormProps} from "../../api/auth/AuthDTO";
import {FormikCheckboxField} from "../form/FormikCheckboxField";

interface Props {
  readonly onContinueClick: () => void;
}

export function EmailRegisterForm({ onContinueClick }: Props) {
  const { values } = useFormikContext<AuthRegisterFormProps>();

  const showPassword = useMemo(
    () => values.provider !== "Google" && values.provider !== "Facebook",
    [values.provider],
  );

  return (
    <>
      <FormikTextField name="name" label="Name" required={true} className="mb-4" />
      <FormikTextField
        name="email"
        label="Email"
        required={true}
        className="mb-4"
        inputProps={{ autoCorrect: "off", autoCapitalize: "off" }}
      />

      {showPassword && (
        <>
          <FormikTextField
            name="password"
            type="password"
            required={true}
            label="Password"
            className="mb-4"
            inputProps={{ autoCorrect: "off", autoCapitalize: "off" }}
          />
          <FormikTextField
            type="password"
            required={true}
            className="mb-5"
            name="confirmPassword"
            label="Confirm Password"
            inputProps={{ autoCorrect: "off", autoCapitalize: "off" }}
          />

          <span className="mb-5">* Required fields</span>
        </>
      )}

      <FormikSelectField
        name="reason"
        required={true}
        wrapperClassName="mb-5"
        id="VehicleReasonControl"
        label="Is this your vehicle or are you looking at buying it?"
        className={cx({ "text-gray": values.reason === "none" })}
        items={[
          { label: "Pick an option", value: "none" },
          { label: "I’ve had it for a while", value: "I’ve had it for a while" },
          { label: "I’ve just bought it", value: "I’ve just bought it" },
          { label: "I’m buying it today", value: "I’m buying it today" },
          { label: "I’m buying it this week", value: "I’m buying it this week" },
          { label: "I’m just looking around", value: "I’m just looking around" },
        ]}
      />

      <FormikCheckboxField
        name="tncAccepted"
        label={
          <span>
            I confirm I am not a trade user and that I accept the{" "}
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="text-decoration-underline"
              href={`${APP_WP_URL}/terms-and-conditions`}
            >
              Terms and Conditions
            </a>
          </span>
        }
      />
      <FormikCheckboxField
        className="mb-5"
        name="marketingAccepted"
        label={
          <span>
            Keep me up to date with the latest news <strong>(Optional)</strong>
          </span>
        }
      />

      <ActionButton onClick={onContinueClick} color={ColorPalette.Success}>
        Continue to payment
      </ActionButton>
    </>
  );
}
