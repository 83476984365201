export const theTestDrivePageStyles = {
  pageTitle: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
    color: "#22A6B3",
    marginBottom: 10,
  },
  boldText: {
    fontWeight: 700,
  },
  testContainer: {
    border: "1px solid gray",
    borderRadius: 5,
    margin: 5,
    padding: 15,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  innerTestContainer: {
    border: "1px solid gray",
    borderRadius: 5,
    margin: 5,
    padding: 15,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    flexBasis: 255,
  },
  containerWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 5,
  },
  containerNumber: {
    width: 30,
    height: 30,
    marginBottom: 5,
    borderRadius: "50%",
    backgroundColor: "#F9CA24",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  containerNumberText: {
    color: "#FFFFFF",
    fontSize: 18,
    fontWeight: 700,
  },
  containerTitle: {
    fontSize: 14,
    marginBottom: 10,
  },
  cardText: {
    marginBottom: 10,
  },
  additionalCardText: {
    marginBottom: 10,
    fontStyle: "italic",
  },
  logBookContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  logBookContentCard: {
    position: "relative",
    backgroundColor: "#EFEFEF",
    margin: 5,
    width: 250,
  },
  textWrapper: {
    display: "flex",
    alignItems: "flex-start",
  },
  additionalInformation: {
    fontSize: 10,
    paddingLeft: 5,
    paddingRight: 5,
    textAlign: "left",
    fontStyle: "italic",
  },
  logBookContentCardTitle: {
    position: "relative",
    left: 0,
    top: 0,
    width: 80,
    padding: 5,
    textAlign: "center",
    backgroundColor: "#22A6B3",
    color: "#FFFFFF",
  },
  logBookContentText: {
    padding: 5,
  },
  infoBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#C7ECEE",
    borderRadius: 5,
    margin: 5,
    padding: 10,
  },
  infoBlockTitle: {
    margin: 10,
    fontSize: 16,
    fontWeight: 700,
  },
  vehicleIcon: {
    height: 50,
    width: 50,
  },
  coverImg: {
    width: 140,
    margin: 10,
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mileageTable: {
    margin: 5,
    border: "1px solid gray",
    borderRadius: 5,
    padding: 5,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    fontSize: 10,
  },
  mileageTableHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontWeight: 700,
  },
  mileageTableBody: {
    display: "flex",
    flexDirection: "column",
  },
  mileageTableLine: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: 10,
    padding: 2,
  },
  mileageTableCol1: {
    width: 30,
  },
  mileageTableCol2: {
    width: 85,
    textAlign: "center",
  },
  mileageTableCol3: {
    width: 85,
    textAlign: "center",
  },
  bgGray: {
    backgroundColor: "#E0E0E0",
  },
  lineMileageTable: {
    marginBottom: 10,
  },
  link: {
    textDecoration: "underline",
    fontStyle: "italic",
    fontSize: 12,
  },
};
