export const runningCostsPageStyles = {
  pageTitle: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
    color: "#22A6B3",
    marginBottom: 10,
  },
  boldText: {
    fontWeight: 700,
  },
  costsTitle: {
    fontSize: 14,
    marginBottom: 10,
    fontWeight: 700,
  },
  orderText: {
    marginBottom: 10,
  },
  totalCostBox: {
    padding: 15,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  totalCostTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  totalCostIcon: {
    margin: 10,
    width: 30,
    height: 30,
  },
  totalCostValue: {
    color: "#22A6B3",
    fontSize: 20,
  },
  vehicleWrapper: {
    padding: 15,
  },
  vehicleBgWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: 50,
    paddingTop: 50,
    zIndex: -1,
  },
  vehicleBg: {
    position: "relative",
    width: 400,
  },
  vehicleTop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  vehiclebottom: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  vehicleBox: {
    width: 140,
  },
  vehicleBoxTitle: {
    height: 30,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F9CA24",
  },
  vehicleBoxPrice: {
    height: 50,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#E0E0E0",
    fontSize: 14,
    fontWeight: 700,
    padding: 3,
  },
  vehicleBoxDescription: {
    fontSize: 9,
    fontStyle: "italic",
  },
  vehicleBoxIcon: {
    position: "absolute",
    width: 50,
    height: 50,
    left: 45,
  },
  appendix: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
};
