import {useEffect, useMemo} from "react";

import {useSocket} from "./useSocket";
import {Analytics} from "../utils/Analytics";
import {ProductProps} from "../dto/ProductDTO";
import {SOCKET_TOPIC} from "../constants/socket";
import {useShallowEqualSelector} from "./useShallowSelector";
import {hasSubscriptionSelector} from "../reducers/userReducer";
import {IntercomActions} from "../constants/AnalyticsConstants";

const analytics = new Analytics();

export interface GeneratingReportSocketProps {
  readonly vrm?: string;
  readonly skip?: boolean;
  readonly isApple?: boolean;
  readonly isUpgrade?: boolean;
  readonly product?: ProductProps;
  readonly onDone?: (key?: string) => void;
  readonly paymentType: "credit" | "purchase";
  readonly onError?: (message?: string) => void;
}

export function useGeneratingReportSocket(options?: GeneratingReportSocketProps) {
  const hasSubscription = useShallowEqualSelector(hasSubscriptionSelector);

  const { data: socketData } = useSocket([SOCKET_TOPIC.REPORT_GENERATION_PROCESS], {
    skip: options?.skip,
  });

  const generateReportSocket = useMemo(() => {
    if (socketData) {
      return socketData[SOCKET_TOPIC.REPORT_GENERATION_PROCESS];
    }
  }, [socketData]);

  useEffect(() => {
    if (options?.skip) {
      return;
    }

    if (generateReportSocket?.done) {
      if (!generateReportSocket?.error && options?.onDone) {
        options.onDone(generateReportSocket?.key);
      }

      if (generateReportSocket?.error) {
        if (options?.onError) {
          options.onError(generateReportSocket.message);
        }
      }
    }

    if (generateReportSocket?.done && !generateReportSocket?.error) {
      analytics.facebookEvent({
        event: "Purchase",
        value: Number(`${options?.product?.priceWhole}.${options?.product?.priceFractional}`),
      });

      if (options?.isUpgrade) {
        analytics.intercomEvent(IntercomActions.AccountUpgraded, {
          status: "success",
          amount: `${options?.product?.priceWhole}.${options?.product?.priceFractional}`,
        });
      }

      if (options?.paymentType !== "credit") {
        analytics.googleEcommerce({
          id: options?.product?.id,
          variant: hasSubscription ? "30-day Plan" : "PAYG Plan",
          price: `${options?.product?.priceWhole}.${options?.product?.priceFractional}`,
          title: options?.isApple ? "Buyers Report (apple/google pay)" : "Buyers Report",
        });

        analytics.microsoftAds({
          id: options?.product?.id,
          variant: hasSubscription ? "30-day Plan" : "PAYG Plan",
          price: `${options?.product?.priceWhole}.${options?.product?.priceFractional}`,
          title: options?.isApple ? "Buyers Report (apple/google pay)" : "Buyers Report",
        });

        analytics.webgainsAds({
          id: options?.product?.id,
          price: `${options?.product?.priceWhole}.${options?.product?.priceFractional}`,
          orderReference: generateReportSocket?.key,
        });
      }

      // if (options?.paymentType === "credit" && hasSubscription) {
      //   analytics.googleEcommerce({
      //     price: "0",
      //     title: "Buyers Report",
      //     variant: "30-day Plan",
      //     id: "subs_single_report (credit)",
      //   });
      //
      //   analytics.microsoftAds({
      //     price: "0",
      //     title: "Buyers Report",
      //     variant: "30-day Plan",
      //     id: "subs_single_report (credit)",
      //   });
      // }
    }

    if (generateReportSocket?.done && generateReportSocket?.error) {
      if (options?.isUpgrade) {
        analytics.intercomEvent(IntercomActions.AccountUpgraded, {
          status: "fail",
          message: generateReportSocket.message || "",
          amount: `${options?.product?.priceWhole}.${options?.product?.priceFractional}`,
        });
      }
    }
  }, [
    options?.vrm,
    options?.product,
    options?.isApple,
    options?.paymentType,
    generateReportSocket?.done,
    generateReportSocket?.error,
  ]);

  return {
    key: generateReportSocket?.key,
    done: generateReportSocket?.done,
    progress: generateReportSocket?.progress,
  };
}
