import React, { useEffect } from "react";

// eslint-disable-next-line
export default (Component) => (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <Component {...props} />;
};
