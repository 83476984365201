import React, { ReactNode } from "react";

import { FooterWrapper } from "../auth/FooterWrapper";
import { AppHeaderWrapper } from "./AppHeaderWrapper";

interface Props {
  readonly children?: ReactNode;
}

export function AppLayout({ children }: Props) {
  return (
    <div className="d-flex flex-column flex-1">
      <AppHeaderWrapper />

      <div className="d-flex flex-column flex-1">{children}</div>

      <FooterWrapper />
    </div>
  );
}
