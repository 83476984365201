import React, { useState } from "react";
import classnames from "classnames";
import RecallIcon from "../../../../assets/img/reports/recall.svg";
import ModalDialog from "../../../../commons/ModalDialog";
import * as styles from "./styles";
import { APP_WP_URL } from "../../../../constants/AppConstants";

function Container(props) {
  return (
    <styles.Container className={classnames("info-block text-center", props.className)}>
      <div className="info-block-icon">
        <img src={RecallIcon} width={80} />
      </div>
      {props.children}
    </styles.Container>
  );
}

export default function Recall({ info }) {
  const [isSMMTModalOpen, setSMMTModalOpen] = useState(false);
  const toggleSMMTModal = event => {
    event.preventDefault();
    setSMMTModalOpen(!isSMMTModalOpen);
  };

  if (!info.hasRecallsMarker) {
    return (
      <Container>
        We have checked this vehicle with the SMMT and there are no safety recalls recorded.
      </Container>
    );
  }

  return (
    <Container className="is-dangerous">
      <div className="info-item text-center">
        The{" "}
        <a href="#" onClick={toggleSMMTModal} className="modal-button">
          SMMT
        </a>{" "}
        reports there is an overdue recall on the vehicle as follows:
      </div>
      <div className="info-item is-important text-center">{info.vinRecallStatus}</div>
      <div className="info-item text-center">
        Ask for proof that this has been carried out. If not, it should be able to be carried out
        free of charge by your nearest dealership.
      </div>
      <ModalDialog
        sourcePath={`${APP_WP_URL}/wp-json/wp/v2/posts/1114`}
        open={isSMMTModalOpen}
        handleClose={toggleSMMTModal}
      />
    </Container>
  );
}
