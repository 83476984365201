import React, {useCallback, useMemo} from "react";
import {injectStripe} from "react-stripe-elements";

import {ProductPlans} from "../../dto/EnumDTO";
import {ActionButton} from "../ui/ActionButton";
import {ColorPalette} from "../../theme/ColorPalette";
import {useStripeContext} from "../../api/stripe/StripeContext";
import {useShallowEqualSelector} from "../../hooks/useShallowSelector";
import {StripePaymentRequestButton} from "../stripe/StripePaymentRequestButton";
import {hasCreditsSelector, hasSubscriptionSelector} from "../../reducers/userReducer";

interface Props {
  readonly id?: string;
  readonly vrm?: string;
  readonly stripe?: any;
  readonly className?: string;
  readonly onClick: () => void;
  readonly onComplete?: (product: ProductPlans) => void;
}

export const ShortListBuyReportButtonWrapper = injectStripe(
  ({ id, vrm, onClick, className, onComplete, stripe }: Props) => {
    const hasCredits = useShallowEqualSelector(hasCreditsSelector);
    const hasSubscription = useShallowEqualSelector(hasSubscriptionSelector);

    const { StripeApi } = useStripeContext();

    const title = useMemo(() => {
      if (hasCredits) {
        return "Buy Report";
      }

      if (hasSubscription) {
        return "Buy Report £4.99";
      }

      return "Buy Report £5.99";
    }, [hasCredits, hasSubscription]);

    const paymentHandler = useCallback(
      (ev) => {
        const productId = hasSubscription
          ? ProductPlans.SubsSingleReport
          : ProductPlans.PaygSingleReport;

        StripeApi.createPaymentIntent({ vrm: vrm!, productId }).then(({ clientSecret }) => {
          if (stripe) {
            stripe
              .confirmCardPayment(
                clientSecret,
                { payment_method: ev.paymentMethod.id },
                { handleActions: false },
              )
              .then(({ error }) => {
                if (error) {
                  ev.complete("fail");

                  alert("Confirm: " + JSON.stringify(error));
                } else {
                  ev.complete("success");

                  if (onComplete) {
                    onComplete(productId);
                  }

                  stripe.confirmCardPayment(clientSecret);
                }
              });
          }
        });
      },
      [vrm, hasSubscription],
    );

    return (
      <>
        {!hasCredits && (
          <StripePaymentRequestButton
            showOr={false}
            stripe={stripe}
            label="Buy Report"
            onPayment={paymentHandler}
            amount={hasSubscription ? 499 : 899}
          />
        )}

        <ActionButton id={id} onClick={onClick} className={className} color={ColorPalette.Success}>
          {title}
        </ActionButton>
      </>
    );
  },
);
