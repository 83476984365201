import React from "react";
import cx from "classnames";

interface Props {
  readonly title: string;
  readonly value?: string;
  readonly last?: boolean;
}

export function AuthVehicleInfoItem({ last, title, value = "N/A" }: Props) {
  return (
    <div
      className={cx("d-flex flex-column flex-md-row py-2", {
        "border-0": last,
      })}
    >
      <span className="text-black">{title}</span>
      <span className="text-md-right flex-1 ml-md-2">{value}</span>
    </div>
  );
}
