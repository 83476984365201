import React from "react";
import Logo from "./assets/motormay_logo.png";

interface Props {
  readonly className?: string;
  readonly height?: number;
}

export function MotorwayPowered({ className, height }: Props) {
  return <img alt="motorway" src={Logo} height={height} className={className} />;
}
