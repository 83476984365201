import React from "react";
import { useField } from "formik";

import { Checkbox, CheckboxProps } from "../ui/Checkbox";

interface Props extends Omit<CheckboxProps, "value" | "onChange"> {
  readonly name: string;
}

export function FormikCheckboxField({ name, ...props }: Props) {
  const [field, meta] = useField(name);

  return <Checkbox {...field} error={Boolean(meta.error)} {...props} />;
}
