import { useField } from "formik";
import React, { useMemo } from "react";

import { StripeCardElement, StripeCardElementProps } from "../ui/StripeCardElement";

interface Props
  extends Omit<StripeCardElementProps, "value" | "onChange" | "error" | "helperText"> {
  readonly name: string;
}

export function FormikStripeCardField({ name, ...props }: Props) {
  const [, meta, helpers] = useField(name);

  const showError = useMemo(() => Boolean(meta.error), [meta]);

  return (
    <StripeCardElement
      {...props}
      id={name}
      error={showError}
      helperText={showError ? meta.error : undefined}
      onChange={({ empty, complete }) => helpers.setValue(!empty && complete)}
    />
  );
}
