import styled from "styled-components";

export const Container = styled.div`
  margin: 20px 0 0;

  .container-title {
    font-size: 20px;
    margin: 0 0 15px;
    border-bottom: 1px solid #22a6b336;
    padding: 0 0 15px;
  }
  .container-subtitle {
    font-weight: 500;
    margin: 0 0 15px;
  }
  .questions-and-answers {
    margin: 0 0 30px;

    .question-and-answer {
      border: 1px solid #efefef;
      margin: 0 0 20px;

      .qa-icon {
        min-width: 30px;
        margin: 0 0 0 10px;
        font-size: 20px;
        height: 30px;
        align-items: center;
        display: flex;
      }
      a {
        color: #30336b;
        text-decoration: underline;
        font-weight: 500;
      }
      .question-block {
        background: #efefef;
        display: flex;
        font-weight: 500;
        padding: 10px;

        .question-icon {
          color: #22a6b3;
        }
        .question-content {
          margin: 4px 0 0;
        }
      }
      .answer-block {
        display: flex;
        padding: 10px;

        .answer-icon {
          color: #eb4d4b;
          font-weight: 500;
        }
        .answer-content {
          margin: 4px 0 0;

          ul {
            padding: 0 0 0 20px;
          }
        }
      }
    }
  }
  .other-info-section {
    display: flex;
    justify-content: space-between;

    .info-block {
      border-right: 1px solid #e4e4e48a;
      padding: 0 20px;
      width: 100%;

      .info-block-icon {
        text-align: center;
        margin: 0 0 25px;
      }
      .info-block-description {
        margin: 0 0 25px;
      }

      &:last-child {
        border: none;
        padding: 0 0 0 20px;
      }
      &:first-child {
        padding: 0 20px 0 0;
      }

      .running-costs-description {
        margin: 0 0 20px;
      }
      .running-costs-items {
        .running-cost {
          margin: 0 0 20px;

          .running-cost-value {
            color: #22a6b3;
            font-weight: 600;
            font-size: 22px;
          }
        }
      }
      .info-reference {
        a {
          font-weight: 500;
          color: #30336b;
          text-decoration: underline;
        }
      }

      .predicted-issues {
        margin: 20px 0 0;

        .predicted-issues-title {
          font-weight: 600;
          color: #30336b;
          margin: 0 0 5px;
        }
        .predicted-issues-list {
          .predicted-issue {
            display: flex;
            margin: 0 0 10px;

            .predicted-issue-percentage {
              color: #22a6b3;
              font-weight: 600;
              font-size: 22px;
            }
            .predicted-issue-description {
              margin: 0 0 0 5px;
            }

            &.is-dangerous {
              .predicted-issue-percentage {
                color: #eb4d4b;
              }
            }
          }
        }
      }
    }

    @media (max-width: 480px) {
      flex-wrap: wrap;

      .info-block {
        margin: 30px 0 0;
        padding: 0;
        border: none;

        &:last-child {
          padding: 0;
        }
        &:first-child {
          padding: 0;
        }
      }
    }
  }
`;
