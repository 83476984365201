import "./assets/short-list-card-report.scss";

import cx from "classnames";
import React, { useMemo } from "react";

import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";
import { VehicleReportInfo } from "./VehicleReportInfo";
import { VehicleProps } from "../../api/vehicle/VehicleDTO";
import { StripeContainer } from "../stripe/StripeContainer";
import { VehicleNoReportInfo } from "./VehicleNoReportInfo";
import { ShortListBuyReportButtonWrapper } from "./ShortListBuyReportButtonWrapper";
import { ProductPlans } from "../../dto/EnumDTO";

interface Props {
  readonly bullet1?: string;
  readonly item: VehicleProps;
  readonly onMorePress: () => void;
  readonly onBuyReportClick: () => void;
  readonly onViewReportClick: () => void;
  readonly onComplete?: (product: ProductPlans) => void;
  readonly isTrade?: boolean;
}

export function ShortListCardReport({
  item,
  bullet1,
  onComplete,
  onMorePress,
  onBuyReportClick,
  onViewReportClick,
  isTrade,
}: Props) {
  const hasReport = useMemo(() => Boolean(item.reportId), [item.reportId]);

  return (
    <div className="d-flex p-3 mx-2 mx-md-3 mx-lg-0 flex-column flex-lg-row short-list-card-report">
      <div className="d-flex flex-1 flex-column mr-lg-2">
        {!isTrade && !hasReport && (
          <span
            id="title_1"
            className="mb-3 text-secondary font-weight-medium text-center text-lg-left no-report-title"
          >
            Buy like a Pro and negotiate a better price using our personalised buyer's report.
          </span>
        )}
        {!hasReport && <VehicleNoReportInfo bullet1={bullet1} onMorePress={onMorePress} />}

        {hasReport && <VehicleReportInfo item={item} />}
      </div>
      <div className={cx("d-flex flex-column pl-lg-1", { "justify-content-center": !hasReport })}>
        {hasReport && (
          <ActionButton
            onClick={onViewReportClick}
            color={ColorPalette.Secondary}
            id="shortlist-view-report-trackbutton"
          >
            View Report
          </ActionButton>
        )}

        {!hasReport && (
          <StripeContainer>
            <ShortListBuyReportButtonWrapper
              vrm={item.vrm}
              onComplete={onComplete}
              onClick={onBuyReportClick}
              id="shortlist-buy-report-trackbutton"
            />
          </StripeContainer>
        )}
      </div>
    </div>
  );
}
