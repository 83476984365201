import "./assets/card-details-table.scss";

import React from "react";
import { isBoolean } from "lodash";
import PropTypes from "prop-types";
import { TableBuyReport } from "../report/TableBuyReport";
import { FREE_PROVENANCE_LIST_ITEMS } from "../../constants/VehicleConstants";

CarDetailsTable.propTypes = {
  hasReport: PropTypes.bool,
  onBuyReportClick: PropTypes.func,
  data: PropTypes.object.isRequired,
};

export function CarDetailsTable({ data, hasReport, onBuyReportClick }) {
  return (
    <div className="d-flex flex-column card-details-table">
      <span className="font-weight-bold mt-4 mb-3 card-title">{data.title}</span>

      <table className="table table-striped table-borderless">
        <tbody>
          {data.list.map((field, idx, arr) => {
            const upselRowCount = arr.length - FREE_PROVENANCE_LIST_ITEMS.length;

            const isUpsell = idx >= 0 && !hasReport && data.hasUpsellBoxes && idx < upselRowCount;

            if (isUpsell) {
              const firstRow = idx === 0;

              return (
                <tr key={field.index}>
                  <th scope="row" className="text-nowrap table-header">
                    {field.title}:
                  </th>

                  {firstRow && (
                    <td
                      rowSpan={upselRowCount}
                      className="font-weight-light position-relative bg-white table-data"
                    >
                      <TableBuyReport
                        id="text-buy-panel"
                        onClick={onBuyReportClick}
                        className="bg-transparent"
                      />
                    </td>
                  )}
                </tr>
              );
            }

            return (
              <tr key={field.index}>
                <th scope="row" className="text-nowrap table-header">
                  {field.title}:
                </th>
                <td className="font-weight-light table-data">
                  {isBoolean(field.value) ? (field.value ? "Yes" : "No") : field.value}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
