import React from "react";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";

import TAX from "../tax.enum";

export function getTaxInfo(status, sornCallback) {
  switch (status) {
    case TAX.NOT_TAXED_FOR_ON_ROAD_USE:
      return "Not taxed for on road use";
    case TAX.SORN:
      return (
        <span className="inline-info">
          Tax has expired (SORN){" "}
          <a href="#" onClick={sornCallback}>
            <ContactSupportIcon />
          </a>
        </span>
      );
    case TAX.UNTAXED:
      return "Untaxed";
    default:
      return "Tax information not provided";
  }
}
