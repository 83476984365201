import styled from "styled-components";

export const Container = styled.div`
  margin: 20px 0 0;

  .section-title {
    font-size: 20px;
    margin: 0 0 20px;
  }
  .tips-group {
    display: flex;
    justify-content: space-between;
    margin: 0 -15px 25px;

    .tip-block {
      width: 50%;
      margin: 0 15px;
    }

    @media (max-width: 480px) {
      flex-wrap: wrap;

      .tip-block {
        width: 100%;
        margin: 15px;
      }
    }
  }
  .tip-block {
    text-align: center;
    border-radius: 5px;
    padding: 35px;
    margin: 0 0 25px;

    &.with-shadow {
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
    }
    &.top-tip {
      color: #30336b;
      background: #c7ecee;
    }

    .tip-icon {
      display: inline-block;
      margin: 0 0 10px;

      .tip-circle {
        border-radius: 50%;
        background: #f9ca24;
        color: #fff;
        font-size: 30px;
        font-weight: 900;
        width: 54px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .tip-title {
      font-weight: 500;
      margin: 0 0 10px;
      font-size: 20px;
    }
    .tip-description {
      margin: 0 0 10px;
    }
    .tip-detailed-info {
      margin: 0 0 10px;
    }
    .tip-mileage-chart {
      margin: 0 -35px -15px;
    }
    .tip-reference {
      a {
        text-decoration: underline;
        font-weight: 500;
        color: #30336b;
      }
    }

    &.is-dangerous {
      border: 2px solid #eb4d4b;

      .tip-circle {
        background: #eb4d4b;
      }
    }
  }

  .second-tip {
    .tip-subtitle {
      font-weight: 500;
      margin: 0 0 20px;
    }
    .covers {
      display: flex;
      justify-content: space-between;

      .cover-block {
        background: #efefef;
        width: 345px;
        height: 100%;

        .cover-label {
          color: #fff;
          background: #22a6b3;
          padding: 5px 15px;
          width: fit-content;
        }
        .cover-photo {
          margin: 20px 15px 10px;
        }
        .cover-info-item {
          padding: 12px 20px;
          text-align: left;
          border-bottom: 1px solid #fff;
        }
      }

      @media (max-width: 480px) {
        flex-wrap: wrap;

        .back-cover {
          margin: 15px 0;
        }
      }
    }
  }
  .top-tip {
    .tip-title {
      font-size: 26px;
      font-weight: 700;
    }
    .tip-description {
      font-weight: 500;
    }
  }
`;
