import React from "react";

import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";
import { UserAccountProps } from "../../api/user/UserDTO";

interface Props {
  readonly onRenewClick: () => void;
  readonly account?: UserAccountProps;
}

export function SubscriptionDetailsExpired({ account, onRenewClick }: Props) {
  return (
    <div className="flex-1">
      <div className="mb-3 text-center text-md-left">
        Your subscription expired on the {account?.subscription?.expiryDate}.
      </div>
      <div className="mb-3 text-center text-md-left">
        To continue receiving unlimited checks and discounted reports you can continue your
        subscription below.
      </div>
      <ActionButton
        className="d-none d-md-block"
        color={ColorPalette.Danger}
        onClick={onRenewClick}
      >
        Renew Account
      </ActionButton>
    </div>
  );
}
