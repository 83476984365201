import "./assets/register-form.scss";

import * as yup from "yup";
import cx from "classnames";
import { Auth } from "aws-amplify";
import { injectStripe } from "react-stripe-elements";
import { Controller, useForm } from "react-hook-form";
import LinearProgress from "@material-ui/core/LinearProgress";
import React, { useCallback, useMemo, useState } from "react";
import Cookies from "js-cookie";

import { TextField } from "@material-ui/core";
import { PRODUCTS } from "../form/ProductField";
import { ProductPlans } from "../../dto/EnumDTO";
import { ActionButton } from "../ui/ActionButton";
import { toSnakeCase } from "../../utils/CaseUtils";
import { CheckboxLegacy } from "../ui/CheckboxLegacy";
import { RegisterUserProps } from "../../dto/AuthDTO";
import { AuthFormProps } from "../../api/auth/AuthDTO";
import { ColorPalette } from "../../theme/ColorPalette";
import { APP_WP_URL } from "../../constants/AppConstants";
import ErrorModal from "../../views/ShortlistPage/ErrorModal";
import { StripePoweredIcon } from "../icons/StripePoweredIcon";
import { InjectedStripeProps } from "../../api/stripe/StripeDTO";
import { AuthCustomerValuesProps } from "../../api/auth/AuthApi";
import { useRegisterSocket } from "../../hooks/useRegisterSocket";
import { StripeCheckoutForm } from "../stripe/StripeCheckoutForm";
import { LookupVehicleProps } from "../../api/vehicle/VehicleDTO";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { tokenSelector } from "../../reducers/authReducer";
import instance from "../../utils/http";
import { useHistory } from "react-router-dom";

const fullValidationSchema = yup.object().shape({
  email: yup.string().required("Enter Email Address").email("Invalid Email Address"),
  tncAccepted: yup.boolean().oneOf([true], "You must accept Terms and Conditions"),
  cardNumber: yup
    .boolean()
    .required("Enter Valid Card Details")
    .oneOf([true], "Enter Valid Card Details"),
});
const trialValidationSchema = yup.object().shape({
  email: yup.string().required("Enter Email Address").email("Invalid Email Address"),
  tncAccepted: yup.boolean().oneOf([true], "You must accept Terms and Conditions"),
});

interface Props extends InjectedStripeProps {
  readonly username?: string;
  readonly onComplete: () => void;
  readonly registerReason?: string;
  readonly user: RegisterUserProps;
  readonly productId?: ProductPlans;
  readonly registerVehicle?: LookupVehicleProps;
  readonly onSubmit: (values: Partial<AuthCustomerValuesProps>, isTrial: boolean) => Promise<any>;
}

export const RegisterForm = injectStripe(
  ({
    user,
    stripe,
    username,
    elements,
    onSubmit,
    productId,
    onComplete,
    registerReason,
    registerVehicle,
  }: Props) => {
    const history = useHistory();
    const [submitError, setSubmitError] = useState();
    const [completed, setCompleted] = useState(false);
    const [processing, setProcessing] = useState(false);

    const isTrialPlan = useMemo(() => productId === ProductPlans.TrialSignUp, [productId]);

    const token = useShallowEqualSelector(tokenSelector);

    // @ts-ignore
    const { errors, control, setValue, formState, clearErrors, handleSubmit, setError } = useForm<
      AuthFormProps
    >({
      mode: "onBlur",
      // @ts-ignore
      validationSchema: isTrialPlan ? trialValidationSchema : fullValidationSchema,
      defaultValues: {
        tncAccepted: false,
        ...user,
      },
    });

    const process = useMemo(() => Boolean(formState.isSubmitting || processing), [
      processing,
      formState.isSubmitting,
    ]);
    const product = useMemo(() => PRODUCTS.find(({ id }) => id === productId), [
      PRODUCTS,
      productId,
    ]);
    const payButtonTitle = useMemo(() => {
      if (product?.price) {
        return [
          "Click to pay",
          `£${product.price.priceInteger}.${product.price.priceFractional}`,
        ].join(" ");
      }

      return "Click to pay";
    }, [product]);

    const { progress } = useRegisterSocket({
      product,
      onDone: () => {
        setCompleted(true);
        onComplete();
      },
      reason: registerReason,
      vehicle: registerVehicle,
      skip: Boolean(!username || completed),
      // @ts-ignore
      onError: (message) => setError("stripe", "custom", message),
    });

    const checkStatus = async (id: string) => {
      await instance.get(`/progress/${id}/status`).then(async (r) => {
        if (r.status === 502) {
          await checkStatus(id);
        } else if (r.data.hasFinished) {
          setProcessing(false);
          history.replace("/shortlist");
          Cookies.remove("qst-answers");
        } else {
          const message = await r.data.message;
          // eslint-disable-next-line no-console
          console.log(message);
          await new Promise((resolve) => setTimeout(resolve, 3000));
          await checkStatus(id);
        }
      });
    };

    const registration = async () => {
      const getCookie = Cookies.get("qst-answers");

      if (getCookie) {
        const checkCookie = JSON.parse(getCookie);
        const data = {
          productId: "trial_signup",
          questions: checkCookie?.answers,
          myVrm: checkCookie?.vrm,
          checkVrm: checkCookie?.vrm2,
          email: token?.idToken?.payload?.email,
          name: token?.idToken?.payload?.name,
          hasAcceptedTC: control?.getValues()?.tncAccepted,
          hasMarketing: control?.getValues()?.marketingAccepted,
        };

        await instance
          .post("/customer/register-c", data, {
            headers: {
              Authorization: `Bearer ${token?.accessToken?.jwtToken}`,
            },
          })
          .then(({ data }) => {
            checkStatus(data.id);
          })
          // eslint-disable-next-line no-console
          .catch((error) => console.log("error", error));
      }
    };

    const currentError = useMemo(() => {
      // @ts-ignore
      const { email, tncAccepted, cardNumber, stripe } = errors;

      return cardNumber || email || tncAccepted || stripe;
    }, [errors]);

    const submit = useCallback(
      async (x: AuthFormProps) => {
        try {
          setProcessing(true);

          const user = await Auth.currentAuthenticatedUser();

          const userAttributes = {
            email: x.email,
            "custom:is_tnc_accepted": "1",
            "custom:is_mktg_accepted": x.marketingAccepted ? "1" : "0",
          };

          if (x.phoneNumber) {
            userAttributes["custom:phone_number"] = x.phoneNumber;
          }

          await Auth.updateUserAttributes(user, userAttributes);

          const values = {
            name: x.name,
            email: x.email,
            phoneNumber: x.phoneNumber,
            hasAcceptedTC: x.tncAccepted,
            hasMarketing: x.marketingAccepted,
          };

          const { clientSecret } = await onSubmit(values, isTrialPlan);

          if (!isTrialPlan && stripe) {
            const cardElement = elements?.getElement("card");

            if (cardElement) {
              const res = await stripe.confirmCardPayment(
                clientSecret,
                toSnakeCase({
                  receiptEmail: values.email,
                  paymentMethod: { card: cardElement },
                }),
              );

              if (res?.error?.message) {
                setProcessing(false);

                throw new Error(res.error.message);
              }
            } else {
              setProcessing(false);

              throw new Error("Invalid stripe element");
            }
          }
          setProcessing(false);
        } catch (e) {
          setProcessing(false);

          throw new Error(e.message);
        }
      },
      [isTrialPlan, elements, stripe, registerReason],
    );

    return (
      <div className="d-flex flex-column position-relative register-form">
        {process && (
          <LinearProgress
            color="secondary"
            variant="determinate"
            value={progress || 0}
            className="position-absolute linear-progress"
          />
        )}
        <div className="container d-flex flex-column flex-1 mt-4 mt-md-5">
          <span className="fs-4 text-center">Final step</span>

          <form
            className="d-flex flex-column flex-1 mt-4 mb-5"
            onSubmit={handleSubmit((x) =>
              // @ts-ignore
              submit(x).catch(({ message }) => setError("stripe", "custom", message)),
            )}
          >
            <div className="d-flex flex-column mx-2">
              {!isTrialPlan && (
                <>
                  <span className="mb-4 text-center description-text">
                    Please enter your payment details and confirm you have read our Terms and
                    Conditions
                  </span>

                  <StripeCheckoutForm
                    stripe={stripe}
                    userHasEmail={true}
                    amountLabel="Register"
                    showDisclaimer={false}
                    title="Payment Card Details"
                    className="mb-3 stripe-checkout-form"
                    amount={productId === ProductPlans.SubsSignUp ? 999 : 499}
                    onChange={(meta) => {
                      if (meta.complete && !meta.empty) {
                        setValue("cardNumber", true, { shouldValidate: true });
                        clearErrors("cardNumber");
                      } else {
                        setValue("cardNumber", false, { shouldValidate: true });
                      }
                    }}
                  />

                  <div>
                    <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
                      <StripePoweredIcon className="mb-4" />
                    </a>
                  </div>

                  <span className="text-black-50 stripe-secure-text mb-3">
                    This is a secure 128 bit SSL Encrypted connection. Card payments are handled by
                    Stripe.
                  </span>
                </>
              )}

              <Controller
                name="name"
                label="Name"
                as={TextField}
                className={cx("mb-3", { "d-none": Boolean(user?.name) })}
                control={control}
                defaultValue={user.name}
                rules={{ required: true }}
              />
              <Controller
                name="email"
                label="Email"
                as={TextField}
                className="mb-3"
                control={control}
                defaultValue={user.email}
                rules={{ required: true }}
              />
              <Controller
                as={TextField}
                control={control}
                name="phoneNumber"
                label="Phone Number (Optional)"
                defaultValue={user.phoneNumber}
              />

              {!isTrialPlan && (
                // @ts-ignore
                <Controller
                  name="cardNumber"
                  control={control}
                  as={CheckboxLegacy}
                  className="d-none"
                  rules={{ required: true }}
                />
                // @ts-ignore
              )}

              {/*
          // @ts-ignore */}
              <Controller
                className="mt-3"
                id="t-c-checkbox"
                control={control}
                name="tncAccepted"
                as={CheckboxLegacy}
                rules={{ required: true }}
                label={
                  <span>
                    I accept the{" "}
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      className="text-decoration-underline"
                      href={`${APP_WP_URL}/terms-and-conditions`}
                    >
                      Terms and Conditions
                    </a>
                  </span>
                }
              />
              {/*
          // @ts-ignore */}
              <Controller
                control={control}
                as={CheckboxLegacy}
                id="marketing-checkbox"
                name="marketingAccepted"
                className="mb-3 marketing-checkbox"
                label={
                  <span>
                    I would like to stay up-to-date with the latest car-related news and features by
                    email, SMS or push message <strong>(Optional)</strong>
                  </span>
                }
              />
              <div className="d-flex justify-content-center justify-content-md-start mb-3 ">
                <ActionButton
                  type="submit"
                  loading={process}
                  id="click-to-pay-button"
                  color={ColorPalette.Danger}
                  onClick={() => {
                    if (!Cookies.get("qst-answers")) {
                      return;
                    }
                    if (control?.getValues()?.tncAccepted) {
                      registration();
                      setProcessing(true);
                    } else {
                      alert("You do need to accept the Terms and Conditions");
                    }
                  }}
                >
                  {isTrialPlan ? "Continue" : payButtonTitle}
                </ActionButton>
              </div>
              <p className="text-center text-md-left gdpr-info">
                You can stop these messages at any time by clicking on the 'unsubscribe' link in any
                email or by contacting our customer support team. However, we'll still need to send
                you important service messages from time to time such as changes to your shortlist.
                We treat your personal data with care. To find out more, read our privacy policy.
              </p>
            </div>

            <ErrorModal
              error={currentError || submitError}
              onClose={() => {
                clearErrors();
                setSubmitError(undefined);
              }}
            />
          </form>
        </div>
      </div>
    );
  },
);
