import { deepUpdate, DELETE, update } from "immupdate";
import { format } from "../../utils/number";
import * as types from "../types/comparison";
import { head, isArray } from "lodash";
import {
  SORTING_CRITERIA,
  VEHICLE_CATEGORIES,
  VEHICLE_CATEGORY_FIELDS,
} from "../../constants/comparison";

function buildComparisonBody(vehicles) {
  const body = [];

  VEHICLE_CATEGORIES.forEach((category) => {
    const bodyCategory = {
      ...category,
      fields: [],
    };

    body.push(bodyCategory);

    // check whether the whole category is empty
    let isEmpty = true;

    VEHICLE_CATEGORY_FIELDS[category.dataIndex].forEach((field) => {
      const bodyField = {
        ...field,
        values: [],
      };

      bodyCategory.fields.push(bodyField);

      if (isArray(vehicles)) {
        vehicles.forEach((vehicle) => {
          let value = vehicle[category.dataIndex][field.dataIndex];

          if (typeof value === "number") {
            value = format(value);
          }

          bodyField.values.push(value);

          // check if at least 1 value is not empty
          if (isEmpty && (value || head(category.dataIndex) === "provenance")) {
            isEmpty = false;
          }
        });
      }
    });

    bodyCategory.isEmpty = isEmpty;
  });

  return body;
}

const handlers = {
  [types.LOAD]: (state, { payload: vehicles }) => {
    const body = buildComparisonBody(vehicles);

    return {
      ...state,
      vehicles,
      comparison: {
        head: vehicles,
        body,
      },
    };
  },
  [types.SORT]: (state, { payload: sort = "" }) => {
    const [key] = sort.split("-");

    const vehicles = state.vehicles.sort(SORTING_CRITERIA[key]);

    return {
      ...state,
      sort,
      comparison: {
        ...state.comparison,
        head: vehicles,
        body: buildComparisonBody(vehicles),
      },
    };
  },
  [types.SELECT]: (state, { payload: vrm }) => {
    const hasVrm = state.selected[vrm];
    const selected = {
      ...state.selected,
      [vrm]: !hasVrm,
    };

    const selectedList = [];

    for (const vrm in selected) {
      if (selected[vrm]) {
        selectedList.push(vrm);
      }
    }

    return {
      ...state,
      selectedList,
      selected,
    };
  },
  [types.CLEAR_SELECT]: (state) => update(state, initialState),
  [types.REMOVE]: (state, { payload: vrm }) => {
    const newState = deepUpdate(state)
      .at("selectedList")
      .withDefault([])
      .modify((x) => {
        const list = x.slice();

        const index = list.findIndex((v) => v === vrm);

        if (index >= 0) {
          list.splice(index, 1);
        }

        return list;
      });

    const newSelected = update(state.selected, {
      [vrm]: DELETE,
    });

    return {
      ...state,
      ...newState,
      selected: newSelected,
    };
  },
};

const initialState = {
  vehicles: [],
  sort: "",
  selected: {},
  selectedList: [],
  comparison: {
    head: [],
    body: [],
  },
};

export default function Reducer(state = initialState, payload) {
  const handler = handlers[payload.type];
  return (handler && handler(state, payload)) || state;
}
