import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import classnames from "classnames";
import Vehicle from "../../../assets/img/reports/good-vehicle.svg";
import FrontCover from "../../../assets/img/reports/front-photo-cover.png";
import BackCover from "../../../assets/img/reports/back-photo-cover.png";
import {formatFunds} from "../../../utils/number";
import withScrollTop from "../../../hoc/withScrollTop";
import MileageChart from "../common/MileageChart";
import ModalDialog from "../../../commons/ModalDialog";
import NoData from "../common/NoData";
import TAB from "../tab.enum";
import * as actions from "../../../redux/actions/report";
import * as styles from "./styles";
import {APP_WP_URL} from "../../../constants/AppConstants";

const MAX_PREVIOUS_SERVICES_TO_DISPLAY = 5;

function TestDrive({ token, report, handleTabChange }) {
  const dispatch = useDispatch();
  const { vehicle = {}, mileageAndRisks } = report;

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        dispatch(actions.loadRepairs(report.id, token)),
        dispatch(actions.loadServices(report.id, token)),
        dispatch(actions.loadMileageAndRisks(report.id, token)),
      ]);
    };

    if (!mileageAndRisks) {
      fetchData();
    }
  }, [token]);

  const [isSuppressedVINNumberModalOpen, setSuppressedVINNumberModalOpen] = useState(false);
  const toggleSuppressedVINNumberModal = event => {
    event.preventDefault();
    setSuppressedVINNumberModalOpen(!isSuppressedVINNumberModalOpen);
  };

  const [isCheckEngineNumberModalOpen, setCheckEngineNumberModalOpen] = useState(false);
  const toggleCheckEngineNumberModal = event => {
    event.preventDefault();
    setCheckEngineNumberModalOpen(!isCheckEngineNumberModalOpen);
  };

  const [isStartTheCarFromColdModalOpen, setStartTheCarFromColdModalOpen] = useState(false);
  const toggleStartTheCarFromColdModal = event => {
    event.preventDefault();
    setStartTheCarFromColdModalOpen(!isStartTheCarFromColdModalOpen);
  };

  const openServiceSchedulesSection = event => {
    event.preventDefault();
    handleTabChange(event, TAB.SERVICE_SCHEDULES);
  };

  const openRepairsSection = event => {
    event.preventDefault();
    handleTabChange(event, TAB.REPAIRS);
  };

  if (!mileageAndRisks) {
    return [];
  }

  const mileageDataCount =
    mileageAndRisks && mileageAndRisks.chartReadyData && mileageAndRisks.chartReadyData.length;

  const dataLength =
    mileageAndRisks &&
    mileageAndRisks.chartReadyData &&
    mileageAndRisks.chartReadyData.map(({ data }) => data).filter(Boolean).length;

  return (
    <styles.Container>
      <div className="section-title">
        When it’s time for a test drive, here are some important things to look out for:
      </div>
      <div className="tip-block first-tip with-shadow">
        <div className="tip-icon">
          <div className="tip-circle">1</div>
        </div>
        <div className="tip-title">Start the car from cold</div>
        <div className="tip-description">
          When arranging the test drive ask the seller to ensure the vehicle is cold.
        </div>
        <div className="tip-reference">
          <a href="#" onClick={toggleStartTheCarFromColdModal}>
            Find out why here
          </a>
        </div>
      </div>
      <div className="tip-block second-tip with-shadow">
        <div className="tip-icon">
          <div className="tip-circle">2</div>
        </div>
        <div className="tip-title">Check the V5C (Log Book)</div>
        <div className="tip-description">
          We do not recommend you buy a vehicle without inspecting the log book. If the seller says
          I’ve applied for it, wait for it to be in their possession before parting with your hard
          earned cash!
        </div>
        <div className="tip-subtitle">Check the log book matches the following:</div>
        <div className="covers">
          <div className="cover-block front-cover">
            <div className="cover-label">Front cover</div>
            <div className="cover-photo">
              <img src={FrontCover} width={216} />
            </div>
            <div className="cover-info-item">
              <span className="cover-info-title">
                <i>[Z]</i> Number of owners:
              </span>{" "}
              {vehicle.numberOfOwners}
            </div>
            <div className="cover-info-item">
              <span className="cover-info-title">
                <i>[Z.2]</i> Acquired vehicle on:
              </span>{" "}
              {vehicle.lastKeeperDate}
            </div>
          </div>
          <div className="cover-block back-cover">
            <div className="cover-label">2nd page</div>
            <div className="cover-photo">
              <img src={BackCover} width={216} />
            </div>
            <div className="cover-info-item">
              <span className="cover-info-title">
                <i>[B]</i> Date of first registration:
              </span>{" "}
              {vehicle.regDate}
            </div>
            <div className="cover-info-item">
              <span className="cover-info-title">
                <i>[D.1]</i> Make:
              </span>{" "}
              {vehicle.make}
            </div>
            <div className="cover-info-item">
              <span className="cover-info-title">
                <i>[D.3]</i> Model:
              </span>{" "}
              {vehicle.model}
            </div>
            <div className="cover-info-item">
              <span className="cover-info-title">
                <i>[E]</i> VIN:
              </span>{" "}
              {vehicle.vin}
              <div className="tip-reference">
                <a href="#" onClick={toggleSuppressedVINNumberModal}>
                  Only last 4 digits displayed why?
                </a>
              </div>
            </div>
            <div className="cover-info-item">
              <span className="cover-info-title">
                <i>[P.5]</i> Engine Number:
              </span>{" "}
              {vehicle.engineNumber}
              <div className="tip-reference">
                <a href="#" onClick={toggleCheckEngineNumberModal}>
                  How to check engine number
                </a>
              </div>
            </div>
            <div className="cover-info-item">
              <span className="cover-info-title">
                <i>[R]</i> Colour:
              </span>{" "}
              {vehicle.colour}
            </div>
            <div className="cover-info-item">
              <span className="cover-info-title">V5C issue date:</span> {vehicle.v5cIssueDate}
            </div>
          </div>
        </div>
      </div>
      <div className="tips-group">
        <div className="tip-block third-tip with-shadow">
          <div className="tip-icon">
            <div className="tip-circle">3</div>
          </div>
          <div className="tip-title">Proof of Repairs</div>
          <div className="tip-description">
            If the seller said they have invoices for the repaired items, ask to see them. They
            should have the following:
          </div>
          <div className="tip-description tip-reference">
            {(report.repairs &&
              report.repairs.proofOfRepairs &&
              report.repairs.proofOfRepairs.map((repair, index) => (
                <div key={index} className="info-item">
                  {repair.year} - {repair.details}
                </div>
              ))) || <NoData />}
            <a href="#" onClick={openRepairsSection}>
              View more repairs
            </a>
          </div>
        </div>
        <div className="tip-block fourth-tip with-shadow">
          <div className="tip-icon">
            <div className="tip-circle">4</div>
          </div>
          <div className="tip-title">Proof of Service</div>
          {!report.services ||
          (!report.services.servicesPrev && !report.services.servicesDue) ||
          (!report.services.servicesPrev.length && !report.services.servicesDue.length) ? (
            <div className="tip-description">
              Our data providers don’t hold service schedule information for this vehicle. This
              could be due to a number of reasons, for example, the age of the vehicle.
            </div>
          ) : (
            <React.Fragment>
              <div className="tip-description">
                Ask to see the service history book and make sure the full list of services have
                been done:
              </div>
              <div className="tip-title">Previous Services:</div>
              <div className="tip-description tip-reference">
                {(report.services &&
                  report.services.servicesPrev &&
                  report.services.servicesPrev.length &&
                  report.services.servicesPrev
                    .slice(0, MAX_PREVIOUS_SERVICES_TO_DISPLAY)
                    .map((service, index) => (
                      <div key={index} className="info-item">
                        {service.title} - £{formatFunds(service.totalCost)}
                      </div>
                    ))) || <NoData />}
                {(report.services &&
                  report.services.servicesPrev &&
                  report.services.servicesPrev.length > MAX_PREVIOUS_SERVICES_TO_DISPLAY && (
                    <a href="#" onClick={openServiceSchedulesSection}>
                      View more services
                    </a>
                  )) ||
                  null}
              </div>
              <div className="tip-title">Services due:</div>
              <div className="tip-description">
                {(report.services &&
                  report.services.servicesDue &&
                  report.services.servicesDue.length &&
                  report.services.servicesDue.map((service, index) => (
                    <div key={index} className="info-item">
                      {service.title} - £{formatFunds(service.totalCost)}
                    </div>
                  ))) || <NoData />}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      <div className="tip-block top-tip">
        <div className="tip-icon">
          <img src={Vehicle} />
        </div>
        <div className="tip-title">CarGuide top tip</div>
        <div className="tip-description">
          If the previous service has not been carried out then you should be able to negotiate a
          lower price. If the next service is due imminently you should also be able to negotiate a
          lower price.
        </div>
      </div>
      <div className="tips-group">
        <div
          className={classnames("tip-block", "fifth-tip", "with-shadow", {
            "is-dangerous": vehicle.hasMileageAnomaly,
          })}
        >
          <div className="tip-icon">
            <div className="tip-circle">5</div>
          </div>
          <div className="tip-title">Mileage History</div>
          <div className="tip-description">
            {vehicle.hasMileageAnomaly ? (
              <span>
                Here you can show the chart to the seller and ask them to explain the mileage
                anomaly.
              </span>
            ) : (
              dataLength > 1 && <span>There was no mileage anomaly detected.</span>
            )}
          </div>
          <div className="tip-mileage-chart">
            {mileageDataCount > 0 ? (
              <MileageChart data={mileageAndRisks.chartReadyData} />
            ) : (
              <NoData />
            )}
          </div>
        </div>
        <div className="tip-block sixth-tip with-shadow">
          <div className="tip-icon">
            <div className="tip-circle">6</div>
          </div>
          <div className="tip-title">
            Knocks or unusual noise could indicate worn suspension components.
          </div>
          <div className="tip-description">The engine should start first time.</div>
          <div className="tip-description">
            Check dashboard lights for any red or amber warning lights.
          </div>
          <div className="tip-description">
            When you have finished the test drive, let the car stand for 1 minute then try to
            restart the ignition - some 'hot start' issues can be costly to fix
          </div>
          <div className="tip-description tip-reference">
            Read our test drive guide for more details{" "}
            <a
              href={`${APP_WP_URL}/used-car-test-drive-checklist?utm_source=carguide&utm_medium=report&utm_campaign=testdrive`}
              target="_blank"
              rel="noreferrer noopener"
            >
              here
            </a>
            .
          </div>
        </div>
      </div>
      <ModalDialog
        sourcePath={`${APP_WP_URL}/wp-json/wp/v2/posts/946`}
        open={isCheckEngineNumberModalOpen}
        handleClose={toggleCheckEngineNumberModal}
      />
      <ModalDialog
        sourcePath={`${APP_WP_URL}/wp-json/wp/v2/posts/948`}
        open={isSuppressedVINNumberModalOpen}
        handleClose={toggleSuppressedVINNumberModal}
      />
      <ModalDialog
        sourcePath={`${APP_WP_URL}/wp-json/wp/v2/posts/979`}
        open={isStartTheCarFromColdModalOpen}
        handleClose={toggleStartTheCarFromColdModal}
      />
    </styles.Container>
  );
}

export default withScrollTop(TestDrive);
