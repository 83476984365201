import React, { useMemo } from "react";
import { useLocation } from "react-router";

import Footer from "../../commons/Footer";

export function FooterWrapper() {
  const location = useLocation();

  const showFooter = useMemo(() => location.pathname.indexOf("/auth") < 0, [location]);

  if (!showFooter) {
    return null;
  }

  return <Footer />;
}
