import cx from "classnames";
import {DateTime} from "luxon";
import React, {useMemo} from "react";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

import {ReportCarInfoProps} from "../../api/report/ReportDTO";

interface Props {
  readonly carInfo: ReportCarInfoProps;
}

export function DueMot({ carInfo }: Props) {
  const description = useMemo(() => {
    if (!carInfo.motDueDate)
      return "MOT due date is not recognized properly.";

    const date = DateTime.fromISO(carInfo.motDueDate.split(".").reverse().join("-"));

    if (carInfo.isMotExpired) {
      return `MOT expired ${date.toRelative({ locale: "en" })} (${carInfo.motDueDate})`;
    }

    if (date && date.isValid) {
      return `${DateTime.local()
        .toRelative({ base: date, locale: "en" })
        ?.replace("in ", "")
        .replace("ago", "")} left on current MOT (${carInfo.motDueDate})`;
    }

    return "MOT due date is not recognized properly.";
  }, [carInfo]);

  return (
    <div
      className={cx("d-flex p-3 mb-3 border align-items-center", {
        "border-danger": carInfo.isMotExpired,
        "border-secondary": !carInfo.isMotExpired,
      })}
    >
      <div className="mr-2">
        {carInfo.isMotExpired && <ReportProblemOutlinedIcon className="text-danger" width={24} />}
        {!carInfo.isMotExpired && <CheckCircleOutlineIcon className="text-secondary" width={24} />}
      </div>

      <span>{description}</span>
    </div>
  );
}
