import React from "react";
import { SvgIcon } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon/SvgIcon";

import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgIconProps {
  readonly size?: number;
}

export function PlusIcon({ size = 24, ...props }: Props) {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 40 40"
      {...props}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        ...props.style,
      }}
    >
      <g
        fill="none"
        strokeWidth="1.5"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke={ColorPalette.White}
      >
        <circle cx="20" cy="19.998" r="18.75" />
        <path d="M9.393 19.998h21.214M20 30.605V9.392" />
      </g>
    </SvgIcon>
  );
}
