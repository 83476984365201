import "./assets/auth-social-group.scss";
import React, { useEffect, useMemo, useState } from "react";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

import { ProductPlans } from "../../dto/EnumDTO";
import { CheckboxLegacy } from "../ui/CheckboxLegacy";
import { AuthVehicleReason } from "./AuthVehicleReason";
import { SocialGroupContainer } from "./SocialGroupContainer";
import { AuthVehicleInfoWrapper } from "./AuthVehicleInfoWrapper";
import { AuthSocialButton, SocialType } from "./AuthSocialButton";
import { EmailAuthType, EmailAuthWrapper } from "./EmailAuthWrapper";
import { useHistory } from "react-router";

interface Props {
  readonly hasVrm: boolean;
  readonly reason?: string;
  readonly authEmail?: string;
  readonly marketing?: boolean;
  readonly reasonError: boolean;
  readonly errorMessage?: string;
  readonly onEmailAuth: () => void;
  readonly productId?: ProductPlans;
  readonly emailConfirmCode?: string;
  readonly emailAuthType?: EmailAuthType;
  readonly onReasonChange: (reason: string) => void;
  readonly onConfirmAuth?: (user?: any, name?: string, userSub?: string, email?: string) => void;
  readonly onSocialPress: (
    provider: CognitoHostedUIIdentityProvider,
    marketingAccept: boolean,
  ) => void;
}

export function AuthSocialGroup({
  hasVrm,
  reason,
  authEmail,
  productId,
  marketing,
  onEmailAuth,
  reasonError,
  errorMessage,
  onConfirmAuth,
  emailAuthType,
  onSocialPress,
  onReasonChange,
  emailConfirmCode,
}: Props) {
  const [emailAuth, setEmailAuth] = useState(false);
  const [termsAccept, setTermsAccept] = useState(true);
  const [marketingAccept, setMarketingAccept] = useState(Boolean(marketing));

  const history = useHistory();

  const isFreeTrial = useMemo(() => productId === ProductPlans.TrialSignUp, [productId]);

  useEffect(() => {
    const types = Object.values(EmailAuthType);

    if (emailAuthType && types.includes(emailAuthType)) {
      setEmailAuth(true);
    }
  }, [emailAuthType]);

  return (
    <SocialGroupContainer errorMessage={errorMessage} className="overflow-hidden auth-social-group">
      {history.location.pathname !== "/auth/register-c" ? <AuthVehicleInfoWrapper /> : ""}

      {emailAuth && (
        <>
          {hasVrm && (
            <AuthVehicleReason error={reasonError} reason={reason} onChange={onReasonChange} />
          )}
          <EmailAuthWrapper
            authEmail={authEmail}
            onEmailAuth={onEmailAuth}
            initialType={emailAuthType}
            onConfirmAuth={onConfirmAuth}
            emailConfirmCode={emailConfirmCode}
            onCancelClick={() => setEmailAuth(false)}
          />
        </>
      )}

      {!emailAuth && (
        <>
          {!hasVrm && <h2 className="mb-5 auth-title">How do you want to access your account:</h2>}

          {/*{hasVrm && (*/}
          {/*  <AuthVehicleReason error={reasonError} reason={reason} onChange={onReasonChange} />*/}
          {/*)}*/}

          {isFreeTrial && (
            <div className="d-flex flex-column mb-4">
              {/*<CheckboxLegacy*/}
              {/*  label={*/}
              {/*    <span>*/}
              {/*      I confirm I am not a trade user and that I accept the{" "}*/}
              {/*      <a*/}
              {/*        target="_blank"*/}
              {/*        rel="noreferrer noopener"*/}
              {/*        className="text-decoration-underline"*/}
              {/*        href={`${APP_WP_URL}/terms-and-conditions`}*/}
              {/*      >*/}
              {/*        Terms and Conditions*/}
              {/*      </a>*/}
              {/*    </span>*/}
              {/*  }*/}
              {/*  name="tncAccepted"*/}
              {/*  value={termsAccept}*/}
              {/*  className="checkbox-field"*/}
              {/*  onChange={() => setTermsAccept((x) => !x)}*/}
              {/*/>*/}
              <CheckboxLegacy
                value={marketingAccept}
                name="marketingAccepted"
                className="checkbox-field"
                onChange={() => setMarketingAccept((x) => !x)}
                label={
                  <span>
                    Keep me up to date with the latest news <strong>(Optional)</strong>
                  </span>
                }
              />
            </div>
          )}

          {hasVrm && <h2 className="mb-5 auth-title">How do you want to access your account:</h2>}

          <AuthSocialButton
            className="mb-2"
            id="preauth-google-button"
            disabled={isFreeTrial && !termsAccept}
            onClick={() => onSocialPress(CognitoHostedUIIdentityProvider.Google, marketingAccept)}
          />
          <AuthSocialButton
            className="mb-2"
            type={SocialType.Facebook}
            id="preauth-facebook-button"
            disabled={isFreeTrial && !termsAccept}
            onClick={() => onSocialPress(CognitoHostedUIIdentityProvider.Facebook, marketingAccept)}
          />
          <AuthSocialButton
            className="mb-4"
            type={SocialType.Email}
            id="preauth-email-button"
            disabled={isFreeTrial && !termsAccept}
            onClick={() => setEmailAuth(true)}
          />
        </>
      )}
    </SocialGroupContainer>
  );
}
