import React from "react";
import { Dialog } from "../ui/Dialog";
import { ActionButton } from "../ui/ActionButton";

interface Props {
  readonly open: boolean;
  readonly onClose: () => void;
}

export function EstimatedMileageInfoDialog({ open, onClose }: Props) {
  return (
    <Dialog
      open={open}
      title="Estimated mileage"
      onClose={onClose}
      titleClassName="text-center justify-content-center justify-content-sm-start text-sm-left"
      actions={
        <div className="d-flex justify-content-center">
          <ActionButton onClick={onClose}>Ok</ActionButton>
        </div>
      }
    >
      <span className="text-center">
        We have taken mileage from the car’s last MOT and added 32 miles a day to estimate the cars
        current mileage (12000miles/365days). Please check the actual mileage with the car owner.
      </span>
    </Dialog>
  );
}
