import React from "react";

import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";
import { UserAccountProps } from "../../api/user/UserDTO";
import { SubscriptionFeaturesList } from "./SubscriptionFeaturesList";
import { SubscriptionDetailsExpired } from "./SubscriptionDetailsExpired";
import { SubscriptionDetailsWithCredits } from "./SubscriptionDetailsWithCredits";
import { SubscriptionDetailsMonthlyAccess } from "./SubscriptionDetailsMonthlyAccess";
import { SubscriptionDetailsWithoutCredits } from "./SubscriptionDetailsWithoutCredits";

interface Props {
  readonly account?: UserAccountProps;

  readonly onUpgradeClick: () => void;

  readonly isExpired: boolean;
  readonly withCredits: boolean;
  readonly withoutCredits: boolean;
  readonly isMonthlyAccess: boolean;
}

export function SubscriptionDetails({
  account,
  isExpired,
  withCredits,
  withoutCredits,
  onUpgradeClick,
  isMonthlyAccess,
}: Props) {
  return (
    <div className="d-flex mt-3 flex-column flex-md-row">
      {isExpired && <SubscriptionDetailsExpired onRenewClick={onUpgradeClick} account={account} />}
      {isMonthlyAccess && <SubscriptionDetailsMonthlyAccess account={account} />}
      {withCredits && (
        <SubscriptionDetailsWithCredits onUpgradeClick={onUpgradeClick} account={account} />
      )}
      {withoutCredits && <SubscriptionDetailsWithoutCredits onUpgradeClick={onUpgradeClick} />}

      {!isMonthlyAccess && <SubscriptionFeaturesList className="mb-4 mb-md-0" />}

      {!isMonthlyAccess && (
        <ActionButton
          onClick={onUpgradeClick}
          color={ColorPalette.Danger}
          className="d-block d-md-none mb-4"
        >
          {isExpired ? "Renew Account" : "Upgrade Account"}
        </ActionButton>
      )}
    </div>
  );
}
