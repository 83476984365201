import React, { useState } from "react";

import { AppError } from "../../utils/AppError";
import { VehicleProps } from "../../api/vehicle/VehicleDTO";
import { useVehicleContext } from "../../api/vehicle/VehicleContext";
import { createDeleteVehicleEvent } from "../../utils/AnalyticsHelpers";
import { DeleteVehicleDialog, DeleteVehicleDialogProps } from "./DeleteVehicleDialog";

interface Props
  extends Omit<DeleteVehicleDialogProps, "onDeletePress" | "loading" | "error" | "title"> {
  readonly vehicle: VehicleProps;
  readonly onSuccess: (vrm: string) => void;
}

export function DeleteVehicleDialogWrapper({ vehicle, onSuccess, ...props }: Props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<AppError | undefined>();

  const { VehicleApi } = useVehicleContext();

  if (!props.open) {
    return null;
  }

  return (
    <DeleteVehicleDialog
      {...props}
      error={error}
      loading={loading}
      title={`${vehicle?.make} ${vehicle?.model}`}
      onDeletePress={() =>
        VehicleApi.deleteVehicle(vehicle?.vrm)
          .then(() => {
            createDeleteVehicleEvent(vehicle?.vrm);

            setLoading(false);

            props.onDismiss();
            onSuccess(vehicle?.vrm);
          })
          .catch((x) => {
            createDeleteVehicleEvent(vehicle?.vrm, false, x.message);

            setError(x);
            setLoading(false);
          })
      }
    />
  );
}
