import { RiskLevel } from "../dto/EnumDTO";

export function getRiskLevel(risk: number): RiskLevel {
  switch (true) {
    case risk <= 29:
      return RiskLevel.Low;
    case risk <= 59:
      return RiskLevel.Medium;
    default:
      return RiskLevel.High;
  }
}
