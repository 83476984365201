export const questionsToAskPageStyle = {
  pageTitle: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
    color: "#22A6B3",
    marginBottom: 10,
  },
  columnBlock: {
    marginLeft: 10,
    marginRight: 10,
    flexDirection: "row",
    fontSize: 12,
    flexGrow: 1,
  },
  footer: {
    marginBottom: 10,
    marginLeft: 20,
    marginRight: 20,
    height: "5%",
    fontSize: 10,
    color: "#000000",
  },
  column: {
    width: "95%",
    textAlign: "center",
    margin: 10,
  },
  orderText: {
    fontSize: 12,
    marginBottom: 10,
  },
  boldText: {
    fontWeight: 700,
  },
  listEl: {
    marginTop: 7,
  },
  listAccent: {
    fontSize: 14,
    color: "#22A6B3",
  },
  listWarningAccent: {
    fontSize: 14,
    color: "#EB4D4B",
  },
  qa: {
    fontSize: 12,
  },
  qaTitle: {
    fontSize: 14,
  },
  qaText: {
    fontSize: 11,
  },
  lineQA: {
    marginTop: 10,
    marginBottom: 10,
  },
  columnIcon: {
    width: 50,
    height: 50,
  },
  columnIconWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
  },
  bgGray: {
    backgroundColor: "#EFEFEFEF",
  },
};
