import { useField } from "formik";
import React, { useMemo } from "react";
import { TextField as MUITextField } from "@material-ui/core";
import { StandardTextFieldProps } from "@material-ui/core/TextField/TextField";

interface Props extends StandardTextFieldProps {
  readonly name: string;
}

export function FormikTextField({ name, ...props }: Props) {
  const [field, meta] = useField(name);

  const showError = useMemo(() => Boolean(meta.touched && meta.error), [meta]);

  return (
    <MUITextField {...field} {...props} error={showError} helperText={showError && meta.error} />
  );
}
