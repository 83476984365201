import React, {useMemo} from "react";
import {VehicleProps} from "../../api/vehicle/VehicleDTO";
import {VehicleReportInfoItem} from "./VehicleReportInfoItem";

interface Props {
  readonly item: VehicleProps;
}

export function VehicleReportInfo({ item }: Props) {
  const hasOutstandingFinance = useMemo(() => Boolean(item.provenance.hasOutstandingFinance), [
    item.provenance.hasOutstandingFinance,
  ]);
  const hasOutstandingRecalls = useMemo(() => Boolean(item.provenance.hasOutstandingRecalls), [
    item.provenance.hasOutstandingRecalls,
  ]);
  const hasImportedMarker = useMemo(() => Boolean(item.provenance.hasImportedMarker), [
    item.provenance.hasImportedMarker,
  ]);
  const hasStolenMarker = useMemo(() => Boolean(item.provenance.hasStolenMarker), [
    item.provenance.hasStolenMarker,
  ]);
  const hasWrittenOffMarker = useMemo(() => Boolean(item.provenance.hasWrittenOffMarker), [
    item.provenance.hasWrittenOffMarker,
  ]);
  const hasPreviousColours = useMemo(() => Boolean(item.provenance.hasPreviousColours), [
    item.provenance.hasPreviousColours,
  ]);

  return (
    <div className="d-flex flex-column flex-lg-row mb-2 mb-lg-0 vehicle-report-info">
      <div className="d-flex flex-lg-column flex-1 mb-2 mb-lg-0">
        <VehicleReportInfoItem
          checked={!hasOutstandingFinance}
          uncheckedTitle="Outstanding Finance"
          checkedTitle="No Outstanding Finance"
        />
        <VehicleReportInfoItem
          uncheckedTitle="Imported"
          checkedTitle="Not Imported"
          checked={!hasImportedMarker}
        />
      </div>
      <div className="d-flex flex-lg-column flex-1 mb-2 mb-lg-0">
        <VehicleReportInfoItem
          checked={!hasOutstandingRecalls}
          uncheckedTitle="Outstanding Recalls"
          checkedTitle="No Outstanding Recalls"
        />
        <VehicleReportInfoItem
          checkedTitle="Not currently marked as stolen"
          checked={!hasStolenMarker}
          uncheckedTitle="Marked as Stolen"
        />
      </div>
      <div className="d-flex flex-lg-column flex-1 mb-2 mb-lg-0">
        <VehicleReportInfoItem
          uncheckedTitle="Write-off"
          checkedTitle="Not a Write-off"
          checked={!hasWrittenOffMarker}
        />
        <VehicleReportInfoItem
          checked={!hasPreviousColours}
          uncheckedTitle="Previous Colours"
          checkedTitle="No Previous Colours"
        />
      </div>
    </div>
  );
}
