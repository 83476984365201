import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { isMobile } from "react-device-detect";
import React, { useEffect, useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";

import { WPClient } from "../../utils/http";
import { APP_WP_URL } from "../../constants/AppConstants";

export default function ModalDialog(props) {
  const [content, setContent] = useState({});

  useEffect(() => {
    if (!props.sourcePath) {
      throw new Error("Source URL (sourcePath) path attribute not provided.");
    }

    const fetchData = async () => {
      const {
        data: {
          title: { rendered: title },
          content: { rendered: text },
        },
      } = await WPClient.get(props.sourcePath);

      // replace all local WP links with themselves patched by WP host url
      const enhancedText = text
        .replace(/href=\"\//gi, `href="${APP_WP_URL}/`)
        .replace(
          'video class="elementor-video"',
          `video class="elementor-video" width=${isMobile ? 260 : 550}`,
        );

      setContent({ title, text: enhancedText });
    };

    fetchData();
  }, []);

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      <DialogTitle id="alert-dialog-title" className="bg-secondary text-white">
        {content.title}
      </DialogTitle>
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: content.text }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary" autoFocus={true}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
