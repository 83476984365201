import cx from "classnames";
import React, { useState } from "react";

import * as styles from "./styles";
import NoData from "../../common/NoData";
import MileageChart from "../../common/MileageChart";
import ModalDialog from "../../../../commons/ModalDialog";
import { APP_WP_URL } from "../../../../constants/AppConstants";

function Container(props) {
  return (
    <styles.Container {...props}>
      <div className="mileage-title">MOT Mileage</div>

      {props.children}
    </styles.Container>
  );
}

export default function Mileage({ data, hasMileageAnomaly }) {
  const [isContactDVSAModalOpen, setContactDVSAModalOpen] = useState(false);

  if (!data || !data.chartReadyData || !data.chartReadyData.length) {
    return (
      <Container>
        <NoData />
      </Container>
    );
  }

  const toggleContactDVSAModal = event => {
    event.preventDefault();
    setContactDVSAModalOpen(!isContactDVSAModalOpen);
  };

  return (
    <Container className={cx({ "is-dangerous": hasMileageAnomaly })}>
      <div className="mileage-description">
        Below are the odometer (speedo) readings taken at each MOT test over the life of the
        vehicle. A vehicle typically does 12,000 miles per annum and although this can vary we have
        plotted a line to show where the latest mileage should approximately be.
      </div>
      <div className="mileage-chart">
        <div className="chart-header">
          <div className="chart-title">Current Mileage</div>
          <div className="chart-subtitle">
            Vehicles in the UK typically do 12,000 miles per annum.
          </div>
        </div>
        <MileageChart data={data.chartReadyData} />
      </div>
      <div className="mileage-note">
        If any odometer readings have been recorded in kilometres, we have automatically converted
        these to miles. If you suspect an issue with the mileage you can{" "}
        <a href="#" onClick={toggleContactDVSAModal}>
          contact the DVSA here
        </a>
        .
      </div>
      <ModalDialog
        open={isContactDVSAModalOpen}
        handleClose={toggleContactDVSAModal}
        sourcePath={`${APP_WP_URL}/wp-json/wp/v2/posts/958`}
      />
    </Container>
  );
}
