import React from "react";

import { VehicleNoReportItem } from "./VehicleNoReportItem";

interface Props {
  readonly bullet1?: string;
  readonly onMorePress: () => void;
}

export function VehicleNoReportInfo({
  onMorePress,
  bullet1 = "Outstanding finance & recalls",
}: Props) {
  return (
    <div className="d-flex flex-column flex-lg-row mb-2 mb-lg-0">
      <div className="d-flex flex-lg-column flex-1 mb-2 mb-lg-0">
        <VehicleNoReportItem id="bullet_1" title={bullet1} />
        <VehicleNoReportItem id="bullet_2" title="Predict MOT failure items" />
      </div>
      <div className="d-flex flex-lg-column flex-1 mb-2 mb-lg-0">
        <VehicleNoReportItem id="bullet_3" title="Comprehensive mileage history" />
        <VehicleNoReportItem id="bullet_4" title="Due Service & Repairs costs" />
      </div>
      <div className="d-flex flex-lg-column flex-1 mb-2 mb-lg-0">
        <VehicleNoReportItem id="bullet_5" title="CarGuide True Ownership Cost" />
        <VehicleNoReportItem
          id="bullet_6"
          title={
            <span>
              Scrapped & Stolen,{" "}
              <a
                href="#"
                className="text-decoration-underline"
                onClick={(event) => {
                  event.preventDefault();
                  onMorePress();
                }}
              >
                see more...
              </a>
            </span>
          }
        />
      </div>
    </div>
  );
}
