import { navbarAPI } from "../api-legacy/navbarAPI";

const TOGGLE_IS_FETCHING = "TOGGLE_IS_FETCHING";
const URL_DATA = "URL_DATA";
const SET_USERNAME = "SET_USERNAME";
const PROFILE_DETAILS = "PROFILE_DETAILS";
const initialState = {
  username: "",
  IsFetching: false,
  profileModification: {
    salutation: null,
    firstName: null,
    lastName: null,
  },
  data: {
    items: [
      {
        title: "Car Check",
        url: "https://staging.carguide.co.uk/index.php/car-check/",
      },
    ],
  },
};
const CustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERNAME: {
      return {
        ...state,
        username: action.username,
      };
    }
    case TOGGLE_IS_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case URL_DATA: {
      return {
        ...state,
        data: action.data,
      };
    }
    case PROFILE_DETAILS: {
      return {
        ...state,
        profileModification: {
          salutation: action.salutation,
          lastName: action.lastName,
          firstName: action.firstName,
        },
      };
    }
    default:
      return state;
  }
};

export const urlDataThunk = () => (dispatch) => {
  dispatch(setIsFetching(true));
  navbarAPI.navbar().then((response) => {
    dispatch(setIsFetching(false));

    if (response) {
      dispatch(setUrlData(response.data));
    }
  });
};
const setUrlData = (data) => ({ type: URL_DATA, data });
const setIsFetching = (isFetching) => ({ type: TOGGLE_IS_FETCHING, isFetching });
export default CustomerReducer;
