import * as types from "../types/report";
import moment from "../../utils/moment";

const MILE_TO_KILOMETERS = 1.60934;
const MEASUREMENT_TYPE = Object.freeze({
  MILES: "miles",
  KM: "km",
});

const handlers = {
  [types.SET_REPORT]: (state, { payload: reportId }) => {
    return {
      ...state,
      currentReport: {
        id: reportId,
      },
      entities: {
        ...state.entities,
        [reportId]: state.entities[reportId]
          ? { ...state.entities[reportId] }
          : {
              id: reportId,
            },
      },
    };
  },
  [types.SET_VEHICLE]: (state, { payload: { reportId, vehicle } }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [reportId]: {
          ...state.entities[reportId],
          vehicle,
        },
      },
    };
  },
  [types.SET_MILEAGE_AND_RISKS]: (state, { payload: { reportId, mileageAndRisks } }) => {
    const chartTree = {};
    const currentYear = moment().year();
    const mileage = mileageAndRisks.mileageInfo;

    if (mileage.standardSeries) {
      mileage.standardSeries.forEach((mileageItem) => {
        const year = moment(mileageItem.date, "DD.MM.YYYY").year();
        chartTree[year] = {
          year,
          measurementType: mileage.displayKm ? MEASUREMENT_TYPE.KM : MEASUREMENT_TYPE.MILES,
          expectedMileage: mileage.displayKm
            ? mileageItem.mileage * MILE_TO_KILOMETERS
            : mileageItem.mileage,
        };
      });
    }

    // TODO: work with moment and formats...

    if (mileage.vehicleSeries && mileage.vehicleSeries.length) {
      mileage.vehicleSeries.forEach((mileageItem) => {
        const year = moment(mileageItem.date, "DD.MM.YYYY").year();
        const leaf = chartTree[year];
        leaf.data = mileageItem;
        leaf.mileage = mileage.displayKm
          ? mileageItem.mileage * MILE_TO_KILOMETERS
          : mileageItem.mileage;
      });
    }

    if (mileage.anomalySeries && mileage.anomalySeries.length) {
      mileage.anomalySeries.forEach((mileageItem) => {
        const year = moment(mileageItem.date, "DD.MM.YYYY").year();
        const leaf = chartTree[year];
        leaf.data = mileageItem;
        leaf.anomalousMileage = mileage.displayKm
          ? mileageItem.mileage * MILE_TO_KILOMETERS
          : mileageItem.mileage;
      });
    }

    return {
      ...state,
      entities: {
        ...state.entities,
        [reportId]: {
          ...state.entities[reportId],
          mileageAndRisks: {
            serverResponse: mileageAndRisks,
            chartReadyData: Object.values(chartTree),
            approximatelyTotalMileage: chartTree[currentYear]
              ? chartTree[currentYear].expectedMileage
              : 0,
          },
        },
      },
    };
  },
  [types.SET_MOTS]: (state, { payload: { reportId, mots } }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [reportId]: {
          ...state.entities[reportId],
          mots,
        },
      },
    };
  },
  [types.SET_QUESTIONS_AND_ANSWERS]: (state, { payload: { reportId, questionsAndAnswers } }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [reportId]: {
          ...state.entities[reportId],
          questionsAndAnswers,
        },
      },
    };
  },
  [types.SET_SERVICES]: (state, { payload: { reportId, services } }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [reportId]: {
          ...state.entities[reportId],
          services,
        },
      },
    };
  },
  [types.SET_REPAIRS]: (state, { payload: { reportId, repairs } }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [reportId]: {
          ...state.entities[reportId],
          repairs,
        },
      },
    };
  },
  [types.SET_RUNNNING_COSTS]: (state, { payload: { reportId, runningCosts } }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [reportId]: {
          ...state.entities[reportId],
          runningCosts,
        },
      },
    };
  },
};

const initialState = {
  entities: {},
  currentReport: null,
};

export default function Reducer(state = initialState, payload) {
  const handler = handlers[payload.type];
  return (handler && handler(state, payload)) || state;
}
