import "./assets/add-vehicle-mobile-form.scss";

import React from "react";
import cx from "classnames";

import { AddVrmVehicleForm } from "./AddVrmVehicleForm";
import { FindVehicleFormProps } from "../../api/vehicle/VehicleDTO";

interface Props {
  readonly vrm?: string;
  readonly show: boolean;
  readonly loading: boolean;
  readonly onSubmit: (values: FindVehicleFormProps) => void;
}

export function AddVrmVehicleMobileForm({ vrm, show, onSubmit, loading }: Props) {
  return (
    <div
      className={cx(
        "d-none d-md-none flex-column bg-secondary overflow-hidden add-vehicle-mobile-form",
        { "d-flex": show },
      )}
    >
      <AddVrmVehicleForm
        vrm={vrm}
        loading={loading}
        onSubmit={onSubmit}
        className="d-flex bg-secondary"
      />
    </div>
  );
}
