import "./assets/tabs.scss";

import React from "react";
import cx from "classnames";
import { Tab, Tabs as MUITabs, TabsProps as MUITabsProps } from "@material-ui/core";

import { DeviceType, useMediaQueryLegacy } from "../../hooks/useMediaQueryLegacy";

export interface TabsProps extends Omit<MUITabsProps, "onChange"> {
  readonly onChange: (value: any) => void;
  readonly items: { label: string; value: string }[];
}

export function Tabs({ items, variant = "scrollable", onChange, className, ...props }: TabsProps) {
  const { device } = useMediaQueryLegacy();

  return (
    <MUITabs
      textColor="primary"
      scrollButtons="auto"
      indicatorColor="primary"
      className={cx("tabs", className)}
      variant={device === DeviceType.Mobile ? "fullWidth" : variant}
      {...props}
      onChange={(_event, index) => onChange(index)}
    >
      {items.map((item) => (
        <Tab className="tab" key={item.value} label={item.label} value={item.value} />
      ))}
    </MUITabs>
  );
}
