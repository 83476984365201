import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import IOSSwitch from "../../views/ShortlistPage/IOSSwitch";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { DeviceType, OrientationType, useMediaQueryLegacy } from "../../hooks/useMediaQueryLegacy";
import {
  changeSelectedVehicle,
  clearSelectedVehicles,
  countCompareVehiclesSelector,
  hasCompareVehicleSelector,
} from "../../reducers/vehicleReducer";

interface Props {
  readonly vrm: string;
}

export function ShortListCompareSwitchWrapper({ vrm }: Props) {
  const dispatch = useDispatch();

  const hasCompareVehicle = useShallowEqualSelector(hasCompareVehicleSelector(vrm));
  const compareVehiclesCount = useShallowEqualSelector(countCompareVehiclesSelector);

  const { orientation, device } = useMediaQueryLegacy();

  const maxCount = useMemo(() => {
    if (orientation === OrientationType.Portrait && device === DeviceType.Mobile) {
      return 2;
    }

    return 4;
  }, [orientation, device]);

  const canAddVehicle = useMemo(() => compareVehiclesCount < maxCount, [
    maxCount,
    compareVehiclesCount,
  ]);

  return (
    <FormControlLabel
      label="Compare"
      control={<IOSSwitch />}
      checked={hasCompareVehicle}
      className="text-secondary font-weight-medium mb-0 mx-2 ml-mb-0 mr-md-3 mr-xl-2"
      labelPlacement={device === DeviceType.LargeDesktop ? "bottom" : "start"}
      onChange={(_, state) => {
        if (canAddVehicle || !state) {
          dispatch(changeSelectedVehicle({ vrm, state }));

          if (compareVehiclesCount === 1 && !state) {
            dispatch(clearSelectedVehicles());
          }
        }
      }}
    />
  );
}
