import React, { useMemo } from "react";
import { object, string } from "yup";
import { Form, Formik } from "formik";

import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";
import { EmailSignInFormProps } from "../../dto/AuthDTO";
import { FormikTextField } from "../form/FormikTextField";

const validationSchema = object({
  password: string().required("Password is required"),
  email: string().email("Please provide valid email").required("Email is required"),
});

interface Props {
  readonly progress?: number;
  readonly onCancelClick: () => void;
  readonly onForgotClick: () => void;
  readonly onSubmit: (values: EmailSignInFormProps) => void;
}

export function EmailSignInForm({ progress, onSubmit, onCancelClick, onForgotClick }: Props) {
  const isAddingVehicle = useMemo(() => Boolean(progress && progress > 0), [progress]);

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={{
        email: "",
        password: "",
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form className="d-flex flex-column" onSubmit={handleSubmit}>
          <h3 className="text-center">Sign In</h3>
          <FormikTextField
            name="email"
            label="Email"
            className="mb-4"
            inputProps={{
              autoCorrect: "off",
              autoCapitalize: "off",
            }}
          />
          <FormikTextField
            name="password"
            type="password"
            className="mb-5"
            label="Password"
            inputProps={{
              autoCorrect: "off",
              autoCapitalize: "off",
            }}
          />
          <ActionButton
            type="submit"
            className="mb-3"
            color={ColorPalette.Success}
            loading={isSubmitting || isAddingVehicle}
          >
            Sign In
          </ActionButton>
          <ActionButton onClick={onCancelClick} className="mb-3" color={ColorPalette.Primary}>
            Cancel
          </ActionButton>

          <div className="d-flex flex-column">
            <button
              type="button"
              onClick={onForgotClick}
              className="mb-2 border-0 bg-transparent outline-none text-primary"
            >
              Forgot your password?
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
