import "./assets/app-mobile-menu-button.scss";

import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

AppMobileMenuButton.propTypes = {
  value: PropTypes.bool,
  onClick: PropTypes.func,
};

export function AppMobileMenuButton({ onClick, value }) {
  return (
    <div className="position-relative app-mobile-menu">
      <button onClick={onClick} className="menu-button">
        <span className={cx("menu-button-icon", { "toggle-icon": value })}>
          <span />
        </span>
      </button>
    </div>
  );
}
