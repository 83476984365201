/* eslint-disable */
import { IS_DEV } from "../constants/AppConstants";

export function createReduxLoggerMiddleware() {
  return (store) => (next) => (action) => {
    if (IS_DEV) {
      if (action.type === "@@router/LOCATION_CHANGE") {
        console.group(`${action.type} ${action.payload.pathname}`);
      } else {
        console.group(action.type);
      }

      console.info("dispatching", action);

      console.log("next state", store.getState());
      console.groupEnd(action.type);
    }

    try {
      return next(action);
    } catch (e) {
      throw e;
    }
  };
}
/* eslint-enable */
