import React from "react";
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";

import * as styles from "./styles";
import { format } from "../../../../utils/number";

function renderTooltip(options) {
  const { active, payload, label } = options;

  if (active) {
    const [expectedMileage, mileage] = payload;
    return (
      <styles.ChartTooltip>
        <div className="tooltip-title">Mileage in {label}</div>
        <div className="tooltip-content">
          <div className="expected-mileage">
            <span className="tooltip-attribute-name">Expected:</span>{" "}
            {format(expectedMileage.value, { maximumFractionDigits: 0 })}{" "}
            {expectedMileage.payload.measurementType}
          </div>
          {mileage && (
            <div className="real-mileage">
              <div className="real-mileage-value">
                <span className="tooltip-attribute-name">{mileage.name}:</span>{" "}
                {format(mileage.value, { maximumFractionDigits: 0 })}{" "}
                {mileage.payload.measurementType}
              </div>
            </div>
          )}
        </div>
      </styles.ChartTooltip>
    );
  }

  return null;
}

export default function MileageChart({ data }) {
  return (
    <ResponsiveContainer aspect={3}>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{ top: 30, right: 30, left: 30, bottom: 30 }}
      >
        <XAxis
          dataKey="year"
          axisLine={false}
          tickLine={false}
          padding={{ left: 30, right: 30 }}
          tickMargin={15}
        />
        <Tooltip content={renderTooltip} position={{ y: -60 }} />
        <Line type="monotone" dataKey="expectedMileage" stroke="#d3d3d3" />
        <Line type="monotone" name="Real" dataKey="mileage" stroke="#30336b" connectNulls={true} />
        <Line
          type="monotone"
          stroke="#eb4d4b"
          connectNulls={true}
          name="Probably fake"
          dataKey="anomalousMileage"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}
