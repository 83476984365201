import qs from "qs";
import { toString } from "lodash";
import ky, { Hooks, Options as KYOptions, ResponsePromise } from "ky";

import { apiLogger } from "../utils/ApiUtils";
import { AppError, AppErrorProps } from "../utils/AppError";
import { TokenProps } from "../dto/AuthDTO";

export interface ApiProps {
  readonly host: string;
  readonly token?: TokenProps;
}

interface Params {
  [key: string]: string | number | undefined;
}

export interface Options extends KYOptions {
  readonly query?: object;
  readonly params?: Params;
}

export class BaseApi {
  private readonly host: string;
  private readonly token?: TokenProps;

  constructor({ host, token }: ApiProps) {
    this.host = host;
    this.token = token;
  }

  private queryToString(query: object = {}): string {
    return qs.stringify(query);
  }

  private replaceParams(str: string, params: Params = {}) {
    return str.replace(/:[a-zA-Z]+\??/g, (value) => {
      const key = value.slice(1);

      const param = params[key];

      if (!param) {
        return "";
      }

      return toString(param);
    });
  }

  private createRequestUrl(url: string, query: object = {}, params: Params = {}): string {
    const formattedUrl = this.replaceParams(url, params);

    return [formattedUrl, this.queryToString(query)].filter(Boolean).join("?");
  }

  private createRequestOptions(options: KYOptions): KYOptions {
    const { hooks = {} as Hooks, headers = {} } = options;

    return {
      prefixUrl: this.host,
      ...options,
      hooks: {
        ...hooks,
        beforeRequest: [...(hooks?.beforeRequest || []), apiLogger],
      },
      headers: new Headers({
        ...(this.token?.accessToken && this.token.accessToken.jwtToken
          ? { Authorization: `Bearer ${this.token.accessToken.jwtToken}` }
          : {}),
        ...headers,
      }),
    };
  }

  private request(url: string, options: Options = {}): ResponsePromise {
    const { query, params, ...kyOptions } = options;

    const formattedOptions = this.createRequestOptions(kyOptions);
    const formattedUrl = this.createRequestUrl(url, query, params);

    return ky(formattedUrl, formattedOptions);
  }

  private jsonRequest<TData>(url: string, options?: Options): Promise<TData> {
    return new Promise<TData>((resolve, reject) => {
      this.request(url, options)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }

          return response
            .json()
            .then((data) => this.parseError(data))
            .then((error) => {
              throw error;
            });
        })
        .then(resolve)
        .catch((error) => {
          if (error instanceof AppError) {
            reject(error);
          } else if (error.response) {
            error.response
              .json()
              .then((data: Response) => reject(this.parseError(data)))
              .catch(() =>
                reject(
                  this.parseError({
                    ...error.response,
                    detail: "Unknown error",
                  }),
                ),
              );
          }
        });
    });
  }

  private parseError(response: Response): AppError {
    // @ts-ignore
    const error = new Error(response.detail) as AppErrorProps;

    error.data = response;
    error.status = response?.status;

    return new AppError(error);
  }

  protected get<TData = any>(url: string, options?: Options): Promise<TData> {
    return this.jsonRequest(url, { ...options, method: "get" });
  }

  protected post<TData = any>(url: string, options?: Options): Promise<TData> {
    return this.jsonRequest(url, { ...options, method: "post" });
  }

  protected put<TData = any>(url: string, options?: Options): Promise<TData> {
    return this.jsonRequest(url, { ...options, method: "put" });
  }

  protected delete<TData = any>(url: string, options?: Options): Promise<TData> {
    return this.jsonRequest(url, { ...options, method: "delete" });
  }
}
