import "./styles.scss";

import React from "react";
import cx from "classnames";
import { SvgIcon } from "@material-ui/core";

export function BackIcon(props) {
  return <SvgIcon width="25" height="25" viewBox="0 0 25 18" {...props} className={cx("icons", props.className)}>
    <g fill="none" fillRule="evenodd" stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5">
      <path d="M8 17L0 9l8-8M24 9H0"/>
    </g>
  </SvgIcon>
}
