import React from "react";

import { TrustpilotIcon } from "../icons/TrustpilotIcon";
import { TrustpilotStarsIcon } from "../icons/TrustpilotStarsIcon";

export function TrustpilotLogo() {
  return (
    <div className="d-flex align-items-center">
      <TrustpilotStarsIcon />
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="d-inline-flex"
        href="https://uk.trustpilot.com/review/motorway.co.uk?utm_medium=trustbox&utm_source=List"
      >
        <TrustpilotIcon />
      </a>
    </div>
  );
}
