import { useEffect, useMemo } from "react";

import { useSocket } from "./useSocket";
import { SOCKET_TOPIC } from "../constants/socket";
import { FindVehicleProps } from "../api/vehicle/VehicleDTO";
import { createAddVehicleEvents } from "../utils/AnalyticsHelpers";

export interface VehicleLookupSocketProps {
  readonly skip?: boolean;
  readonly onDone?: () => void;
  readonly vehicle: FindVehicleProps;
  readonly onProgress: (progress) => void;
  readonly onError?: (message?: string) => void;
}

export function useVehicleLookupSocket(options: VehicleLookupSocketProps) {
  const { data: socketData } = useSocket([SOCKET_TOPIC.ADDING_NEW_VEHICLE_PROCESS], {
    skip: options?.skip,
  });

  const generateReportSocket = useMemo(() => {
    if (socketData) {
      return socketData[SOCKET_TOPIC.ADDING_NEW_VEHICLE_PROCESS];
    }
  }, [socketData]);

  useEffect(() => {
    if (generateReportSocket?.done) {
      if (!generateReportSocket?.error && options?.onDone) {
        options.onDone();
      }

      if (generateReportSocket?.error) {
        if (options?.onError) {
          options.onError(generateReportSocket.message);
        }
      }
    }

    createAddVehicleEvents(options.vehicle, generateReportSocket);
  }, [generateReportSocket?.done, generateReportSocket?.error, options]);

  useEffect(() => {
    if (options.onProgress) {
      options.onProgress(generateReportSocket?.progress);
    }
  }, [generateReportSocket?.progress]);

  return {
    progress: generateReportSocket?.progress,
  };
}
