import "./assets/add-vehicle-form.scss";

import cx from "classnames";
import { useForm } from "react-hook-form";
import React, { RefObject, useMemo } from "react";
import { ActionButton } from "../ui/ActionButton";
import { FindVehicleFormProps } from "../../api/vehicle/VehicleDTO";
import { DeviceType, useMediaQueryLegacy } from "../../hooks/useMediaQueryLegacy";

interface LayoutProps {
  readonly width: number;
  readonly height: number;
}

interface Props {
  readonly vrm?: string;
  readonly loading: boolean;
  readonly className?: string;
  readonly rootRef?: RefObject<HTMLDivElement>;
  readonly onLayout?: (layout: LayoutProps) => void;
  readonly onSubmit: (values: FindVehicleFormProps) => void;
}

export function AddVrmVehicleForm({ vrm, rootRef, loading, className, onSubmit }: Props) {
  const { register, handleSubmit, reset } = useForm<FindVehicleFormProps>();

  const mediaQuery = useMediaQueryLegacy();

  const showPlaceholder = useMemo(() => mediaQuery.device !== DeviceType.Mobile, [mediaQuery]);

  return (
    <div ref={rootRef} className={cx("container flex-column px-3 add-vehicle-form", className)}>
      <form
        onSubmit={handleSubmit((x) => {
          onSubmit(x);
          reset();
        })}
        className="d-flex px-4 py-3 bg-secondary bg-md-muted flex-column flex-md-row"
      >
        <div className="d-flex align-items-center justify-content-center flex-column mb-3 mb-md-0">
          <span className="fs-4 text-white text-md-primary">Check a vehicle</span>
          <span className="d-inline d-md-none text-center text-white">
            Enter the car registration or paste a URL from your favourite used car site...
          </span>
        </div>
        <div className="d-flex flex-1 mx-md-3 mb-3 mb-md-0 align-items-center align-self-md-center form-vrm-input-container">
          <input
            name="vrm"
            ref={register}
            autoComplete="off"
            defaultValue={vrm}
            className="flex-1 border-0 text-uppercase form-control data-hj-whitelist"
            placeholder={
              showPlaceholder
                ? "Enter the car registration or paste a URL from your favourite used car site..."
                : ""
            }
          />
        </div>
        <div className="d-flex flex-column align-items-center">
          <ActionButton type="submit" loading={loading}>
            Check Vehicle
          </ActionButton>
        </div>
      </form>
    </div>
  );
}
