import styled from "styled-components";

export const Container = styled.div`
  margin: 15px 0 50px;

  .section-title {
    font-weight: 500;
    font-size: 20px;
    margin: 0 0 5px;
  }
  .section-description {
    margin: 0 0 15px;
  }
  .section-vehicle {
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .vehicle {
      img {
        width: 482px;
        height: 362px;
      }
    }
    .vehicle-boxes {
      display: flex;
      flex-direction: column;

      &.top-boxes {
        margin: 0 0 45px;
      }
      &.bottom-boxes {
        top: 55px;
        position: relative;

        .vehicle-boxes-container {
          margin: -20px 0 35px;
        }
      }

      .vehicle-boxes-container {
        display: flex;

        .vehicle-box {
          margin: 0 15px;
          width: 165px;
          color: #30336b;
          position: relative;

          &.is-hidden {
            visibility: hidden;
          }

          .box-title {
            background: #f9ca24;
            text-align: center;
            padding: 10px 30px;
            font-weight: 500;
            font-size: 18px;
          }
          .box-value {
            background: #efefef;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 85px;
            font-size: 28px;
            font-weight: 600;
          }
          .box-appendix {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            .box-appendinx-line {
              border: 1px solid #f9ca24;
              width: 2px;
            }
          }

          &.servicing-box {
            .box-appendix {
              .box-appendinx-line {
                height: 182px;
              }
            }
          }
          &.depreciation-box {
            .box-appendix {
              .box-appendinx-line {
                height: 72px;
              }
            }
          }
          &.mot-box {
            .box-appendix {
              .box-appendinx-line {
                height: 30px;
              }
            }
          }
          &.repairs-box {
            .box-appendix {
              top: -136px;
              .box-appendinx-line {
                height: 82px;
              }
            }
          }
          &.tax-box {
            .box-appendix {
              top: -220px;
              .box-appendinx-line {
                height: 165px;
              }
            }
          }
          &.fuel-box {
            .box-appendix {
              top: -290px;
              .box-appendinx-line {
                height: 220px;
              }
            }
          }
        }
      }
      .total-cost-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 15px;
        border-bottom: 1px solid #e4e4e4;
        padding: 0 0 10px;

        .total-cost-title {
          display: flex;
          align-items: center;

          .total-cost-title-icon {
            margin: 0 10px 0 0;
          }
        }
        .total-cost-value {
          font-size: 26px;
          font-weight: 500;
          color: #22a6b3;
        }
      }
    }
  }

  @media (max-width: 480px) {
    margin: 15px 0 0;

    .section-vehicle {
      height: 700px;

      .vehicle {
        img {
          width: 320px;
          height: auto;
        }
      }
      .vehicle-boxes {
        &.top-boxes {
          margin: 0;
        }
        &.bottom-boxes {
          top: 0;

          .vehicle-boxes-container {
            margin: -20px 0 35px;
          }
        }

        .vehicle-boxes-container {
          .vehicle-box {
            width: 105px;
            margin: 0 7px;

            .box-title {
              font-size: 15px;
              padding: 10px 5px;
            }
            .box-value {
              font-size: 23px;
            }
            .box-appendix {
              .box-icon {
                img {
                  width: 50px;
                }
              }
            }
            &.servicing-box {
              .box-appendix {
                .box-appendinx-line {
                  height: 160px;
                }
              }
            }
            &.depreciation-box {
              .box-appendix {
                .box-appendinx-line {
                  height: 72px;
                }
              }
            }
            &.mot-box {
              .box-appendix {
                .box-appendinx-line {
                  height: 55px;
                }
              }
            }
            &.repairs-box {
              .box-appendix {
                top: -100px;
                .box-appendinx-line {
                  height: 60px;
                }
              }
            }
            &.tax-box {
              .box-appendix {
                top: -170px;
                .box-appendinx-line {
                  height: 130px;
                }
              }
            }
            &.fuel-box {
              .box-appendix {
                top: -210px;
                .box-appendinx-line {
                  height: 170px;
                }
              }
            }
          }
        }
      }
    }
  }
`;
