import React from "react";
import { SvgIcon } from "@material-ui/core";
import { SvgIconProps } from "@material-ui/core/SvgIcon/SvgIcon";

import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends SvgIconProps {
  readonly size?: number;
}

export function DeleteIcon({ size = 36, ...props }: Props) {
  return (
    <SvgIcon
      width="36"
      height="36"
      viewBox="0 0 36 36"
      {...props}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        ...props.style,
      }}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="18" cy="18" r="18" fill={ColorPalette.Danger} />
        <g
          stroke={ColorPalette.White}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        >
          <path d="M7.5 10.5h21M20.25 7.5h-4.5a1.5 1.5 0 0 0-1.5 1.5v1.5h7.5V9a1.5 1.5 0 0 0-1.5-1.5zM15.75 23.25v-7.5M20.25 23.25v-7.5M24.865 27.124A1.5 1.5 0 0 1 23.37 28.5H12.631a1.5 1.5 0 0 1-1.495-1.376L9.75 10.5h16.5l-1.385 16.624z" />
        </g>
      </g>
    </SvgIcon>
  );
}
