import React, { ReactNode } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";

import { APP_STRIPE_PK_KEY } from "../../constants/AppConstants";

interface Props {
  readonly children: ReactNode;
}

export function StripeContainer({ children, ...props }: Props) {
  return (
    <StripeProvider apiKey={APP_STRIPE_PK_KEY!} {...props}>
      <Elements>{children}</Elements>
    </StripeProvider>
  );
}
