import fontRegular from "../MuseoSans/MuseoSans_500.otf";
import fontItalic from "../MuseoSans/MuseoSans_100.otf";
import fontBold from "../MuseoSans/MuseoSans_700.otf";

export const font = {
  family: "MuseoSans",
  fonts: [
    { src: fontRegular },
    { src: fontItalic, fontStyle: "italic" },
    { src: fontBold, fontWeight: 700 },
  ],
};
