export enum ColorPalette {
  Black = "#000000",
  White = "#ffffff",

  Transparent = "rgba(0, 0, 0, 0)",

  Info = "#c7ecee",
  Muted = "#efefef",
  Danger = "#eb4d4b",
  Warning = "#f9ca24",
  Primary = "#30336b",
  Secondary = "#22a6b3",

  Blue = "#0497d8",

  Yellow = "#fef200",

  Success = "#28a745",

  Orange = "#ff9800",

  LightAqua = "#c7ecee",

  Gray = "#6c757d",
  LightGray = "#e0e0e0",
  MiddleGray = "#999999",
}
