import { BaseApi, Options } from "../BaseApi";
import {
  AddVehicleFormProps,
  AuthVehicleProps,
  EditVehicleProps,
  FindVehicleFormProps,
  FindVehicleProps,
  LookupVehicleProps,
  VehicleProps,
} from "./VehicleDTO";

export class VehicleApi extends BaseApi {
  public lookupAuthVehicle(options?: Options): Promise<AuthVehicleProps> {
    return this.get("lookup", options);
  }

  public getShortList(): Promise<VehicleProps[]> {
    return this.get("shortlist/car/list");
  }

  public findVehicle({ vrm }: FindVehicleFormProps): Promise<FindVehicleProps> {
    return this.post("lookup/vehicle", { json: { input: vrm } });
  }

  public lookupVehicle(vrm: string): Promise<LookupVehicleProps> {
    return this.get("lookup/simple/:vrm", { params: { vrm: vrm.replace(/\s/g, "") } });
  }

  public addVehicle(values: AddVehicleFormProps): Promise<FindVehicleProps> {
    return this.post("shortlist/add", { json: values });
  }

  public editVehicle(values: EditVehicleProps): Promise<FindVehicleProps> {
    return this.put("shortlist/edit", { json: values });
  }

  public deleteVehicle(vrm: string): Promise<void> {
    return this.delete("shortlist/:vrm", { params: { vrm } });
  }

  public getVehiclesByVrms(plates: string[]): Promise<VehicleProps[]> {
    return this.post("shortlist/selected", { json: { plates } });
  }
}
