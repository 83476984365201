export const pageLayoutStyles = {
  page: {
    fontFamily: "MuseoSans",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    color: "#2E336B",
  },
  logo: {
    height: 30,
  },
  header: {
    marginTop: 10,
    marginLeft: 20,
    marginRight: 20,
    flexDirection: "row",
    height: "5%",
  },
  main: {
    marginLeft: 20,
    marginRight: 20,
    fontSize: 12,
    flexGrow: 1,
  },
  boldText: {
    fontWeight: 700,
  },
  footer: {
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 20,
    marginRight: 20,
    height: "5%",
    fontSize: 10,
    color: "#000000",
  },
};
