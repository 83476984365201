import "./assets/add-vehicle-button.scss";

import React from "react";

import { PlusIcon } from "../icons/PlusIcon";

interface Props {
  readonly onClick: () => void;
}

export function AddVehicleButton({ onClick }: Props) {
  return (
    <div className="d-flex track">
      <button
        onClick={onClick}
        className="d-flex flex-1 flex-column outline-none bg-light-aqua m-2 align-items-center justify-content-center overflow-hidden add-vehicle-button"
      >
        <PlusIcon size={40} className="mb-2" />
        <span className="text-primary">Show another from your shortlist</span>
      </button>
    </div>
  );
}
