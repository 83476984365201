import React from "react";
import { Svg, Text, View, Link, StyleSheet, Image, Line } from "@react-pdf/renderer";
import { formatFunds } from "../../../../utils/number";
import { PageLayout } from "./PageLayout";
import ReactDOMServer from "react-dom/server";
import Html from "../PdfComponent/Html";

import MoneyBoxIcon from "../assets/moneybox.png";
import RecallIcon from "../assets/recall.png";
import WrenchIcon from "../assets/wrench.png";
import { questionsToAskPageStyle } from "../styles/questionsToAskPageStyle";

const styles = StyleSheet.create(questionsToAskPageStyle);

export const QuestionsToAskPage = ({ report }) => {
  const { questions = [], runningCosts = {}, recallsInfo = {}, predictedIssues = [] } =
    report.questionsAndAnswers || {};

  const hasNoRunningCosts =
    !runningCosts.fuelPerMonth &&
    !runningCosts.deprValuePerMonth &&
    !runningCosts.servicePerMonth &&
    !runningCosts.taxPerMonth;

  const rendererHtml = (index) => {
    const questionWithoutLinks = questions[index].question.replace(/<a [^>]+>[^<]*<\/a>/, "");
    const answerWithoutLinks = questions[index].answer.replace(/<a [^>]+>[^<]*<\/a>/, "");

    const element = (
      <html>
        <body>
          <style>{`
            p, a, li, ul {
              margin: 0;
            }
            @font-face {
            font-family: MuseoSans;
            src: url(./MuseoSans/MuseoSans_500.ttf) format("truetype); 
            } 
            .question-and-answer {
              font-family: MuseoSans;
              font-size: 12px;
              display: flex;
              border: 1px solid #EEEEEE;
            }

            .q {
              color: #22A6B3;
              margin-right: 3px;
              margin-left: 3px;
            }
            .a {
              color: #FF0A00;
              margin-right: 3px;
              margin-left: 3px;
            }
            .question {
              display: flex;
              flex-direction: row;
              background-color: #EFEFEF;
              margin-bottom: 5px;
              padding: 5px;
            }
            .answer {
              display: flex;
              flex-direction: row;
              padding: 5px;
            }
            .textBox {
              width: 95%;
            }
          `}</style>

          <div className="question-and-answer">
            <div className="question">
              <div className="q">Q.</div>
              <div className="textBox" dangerouslySetInnerHTML={{ __html: questionWithoutLinks }} />
            </div>
            <div className="answer">
              <div className="a">A.</div>
              <div className="textBox" dangerouslySetInnerHTML={{ __html: answerWithoutLinks }} />
            </div>
          </div>
        </body>
      </html>
    );

    return ReactDOMServer.renderToStaticMarkup(element);
  };

  return (
    <PageLayout id={report.id}>
      <View>
        <Text style={styles.pageTitle}>QUESTIONS TO ASK</Text>
        <Text style={styles.qaTitle}>
          When viewing a vehicle it is easy to fall in love with it and disregard the questions you should be asking the seller.
        </Text>

        <View style={styles.lineQA}>
          <Svg height="1" width="550">
            <Line x1="0" y1="0" x2="100%" y2="0" strokeWidth={2} stroke="rgb(35,165,180)" />
          </Svg>
        </View>

        <Text style={styles.qaText}>
          Below we list the questions you should ask before viewing the vehicle:
        </Text>

        {report.questionsAndAnswers.questions.map((el, index) => (
          <View key={index} style={styles.qa}>
            <Html>{rendererHtml(index)}</Html>
          </View>
        ))}
      </View>

      <View break={true} wrap={false} style={styles.columnBlock}>
        <View style={styles.column}>
          <View style={styles.columnIconWrapper}>
            <Image style={styles.columnIcon} src={WrenchIcon} />
          </View>

          <Text style={styles.orderText}>
            We have used our machine learning platform to predict what the vehicle is likely to have
            issues with in the future. Some of this may have already been repaired so it is worth asking the seller.
          </Text>

          <Text style={styles.boldText}>Predicted issues</Text>

          {predictedIssues.length === 0 && <Text style={styles.orderText}>No data</Text>}

          {predictedIssues.map((issue) => {
            const cuttentAccent = issue.risk < 60 ? styles.listAccent : styles.listWarningAccent;

            return (
              <Text key={issue.component} style={styles.listEl}>
                <Text style={cuttentAccent}>{issue.risk}%</Text> {issue.component}
              </Text>
            );
          })}
        </View>

        <View style={styles.column}>
          <View style={styles.columnIconWrapper}>
            <Image style={styles.columnIcon} src={RecallIcon} />
          </View>

          {!recallsInfo.hasRecallsMarker ? (
            <Text style={styles.orderText}>
              We have checked this vehicle with the SMMT and there are no safety recalls recorded.
            </Text>
          ) : (
            <View style={{ color: "#EB4D4D" }}>
              <Text style={styles.orderText}>
                The SMMT reports there is an overdue recall on the vehicle as follows:
              </Text>
              <Text style={[styles.orderText, styles.boldText]}>{recallsInfo.vinRecallStatus}</Text>
              <Text style={styles.orderText}>
                Ask for proof that this has been carried out. If not, it should be able to be carried out free of charge by your nearest dealership.
              </Text>
            </View>
          )}
        </View>

        <View style={styles.column}>
          <View style={styles.columnIconWrapper}>
            <Image style={styles.columnIcon} src={MoneyBoxIcon} />
          </View>

          <Text style={styles.orderText}>
            So you can plan ahead, here are some running costs for this {report.vehicle.make}
          </Text>
          <Text style={styles.orderText}> {report.vehicle.model}</Text>

          {(!hasNoRunningCosts && (
            <Text style={styles.orderText}>
              Based on <Text style={styles.boldText}>12k</Text> miles per year, it will cost you:
            </Text>
          )) ||
            null}
          {(hasNoRunningCosts && (
            <Text style={styles.orderText}>
              Unfortunately we don't have running costs for this vehicle at present. We're
              constantly working on our data and hope to have it soon.
            </Text>
          )) ||
            null}
          {(runningCosts.fuelPerMonth && (
            <Text style={styles.listEl}>
              <Text style={styles.listAccent}>£{formatFunds(runningCosts.fuelPerMonth)}</Text> per month in fuel</Text>
          )) ||
            null}
          {(runningCosts.deprValuePerMonth && (
            <Text style={styles.listEl}>
              <Text style={styles.listAccent}>£{formatFunds(runningCosts.deprValuePerMonth)}</Text> per month in depreciation</Text>
          )) ||
            null}
          {(runningCosts.servicePerMonth && (
            <Text style={styles.listEl}>
              <Text style={styles.listAccent}>£{formatFunds(runningCosts.servicePerMonth)}</Text> per month to service</Text>
          )) ||
            null}
          {(runningCosts.taxPerMonth && (
            <Text style={styles.listEl}>
              <Text style={styles.listAccent}>£{formatFunds(runningCosts.taxPerMonth)}</Text> per month to tax</Text>
          )) ||
            null}
          {(runningCosts.insuranceGroup && runningCosts.insuranceGroup.trim() && (
            <Text style={[styles.listEl, { marginTop: 15 }]}>
              Insurance group <Text style={styles.listAccent}>{runningCosts.insuranceGroup}</Text>
            </Text>
          )) ||
            null}
        </View>
      </View>
    </PageLayout>
  );
};
