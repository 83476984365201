import { useParams } from "react-router";
import React, { useMemo, useState } from "react";

import ModalDialog from "../commons/ModalDialog";
import { useResource } from "../hooks/useResource";
import { APP_WP_URL } from "../constants/AppConstants";
import { BackButton } from "../components/ui/BackButton";
import { useReportContext } from "../api/report/ReportContext";
import { AppRouteLayout } from "../components/app/AppRouteLayout";
import { DeviceType, useMediaQueryLegacy } from "../hooks/useMediaQueryLegacy";
import { FutureMots } from "../components/report-mot-data/FutureMots";
import { PreviousMots } from "../components/report-mot-data/PreviousMots";
import { MotInfoItems, MotInfoTabs } from "../components/report-mot-data/MotInfoTabs";

interface Params {
  readonly vrm: string;
}

export function MotDataContainer() {
  const { device } = useMediaQueryLegacy();
  const { vrm } = useParams<Params>();

  const [tab, setTab] = useState(MotInfoItems.Future);
  const [impairInfoShow, setImpairInfoShow] = useState(false);

  const { ReportApi } = useReportContext();

  const motInfoResponse = useResource(() => (vrm ? ReportApi.getMotData(vrm) : undefined), [vrm]);

  const isFutureTab = useMemo(
    () => (device !== DeviceType.Mobile || tab === MotInfoItems.Future) && !motInfoResponse.loading,
    [tab, device, motInfoResponse.loading],
  );
  const isPreviousTab = useMemo(
    () =>
      (device !== DeviceType.Mobile || tab === MotInfoItems.Previous) && !motInfoResponse.loading,
    [device, tab, motInfoResponse.loading],
  );
  const carName = useMemo(() => {
    if (motInfoResponse.data?.carInfo) {
      return `${motInfoResponse.data.carInfo.make} ${motInfoResponse.data.carInfo.model}`;
    }
  }, [motInfoResponse.data]);

  return (
    <AppRouteLayout
      withFooter={false}
      screenTitle="MOT Data"
      contentClassName="container"
      leftComponent={<BackButton />}
      loading={motInfoResponse.loading}
    >
      {carName && <h3 className="mb-3 mb-md-5 text-center text-md-left">{carName}</h3>}

      {!motInfoResponse.loading && (
        <MotInfoTabs className="d-block d-md-none mb-3" value={tab} onChange={setTab} />
      )}

      <div className="d-flex flex-column">
        {isFutureTab && (
          <FutureMots
            carInfo={motInfoResponse.data?.carInfo}
            onImpairInfoClick={() => setImpairInfoShow(true)}
            predicatedIssues={motInfoResponse.data?.motInfo.predictedIssues}
          />
        )}

        {isPreviousTab && <PreviousMots motTests={motInfoResponse.data?.motInfo.motTests} isShortlist={true}/>}
      </div>

      <ModalDialog
        open={impairInfoShow}
        handleClose={() => setImpairInfoShow(false)}
        sourcePath={`${APP_WP_URL}/wp-json/wp/v2/posts/1111`}
      />
    </AppRouteLayout>
  );
}
