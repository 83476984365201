import React, { useMemo } from "react";

import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";
import { UserAccountProps } from "../../api/user/UserDTO";

interface Props {
  readonly onUpgradeClick: () => void;
  readonly account?: UserAccountProps;
}

export function SubscriptionDetailsWithCredits({ account, onUpgradeClick }: Props) {
  const reportCount = useMemo(() => account?.credits?.report || 0, [account?.credits]);
  const lookupCount = useMemo(() => account?.credits?.lookup || 0, [account?.credits]);

  return (
    <div className="flex-1">
      <div className="mb-3 text-center text-md-left">
        You have {reportCount} buyers report and {lookupCount} car check credit(-s) remaining on
        your current plan.
      </div>
      <div className="mb-3 text-center text-md-left">
        To receive unlimited checks and discounted reports you can buy 30 days access below.
      </div>
      <ActionButton
        onClick={onUpgradeClick}
        color={ColorPalette.Danger}
        className="d-none d-md-block"
      >
        Upgrade Account
      </ActionButton>
    </div>
  );
}
