import React, { useMemo } from "react";

import { VehicleProps } from "../../api/vehicle/VehicleDTO";
import { formatGroupNumber } from "../../utils/FormatUtils";
import { ShortListCardDetailsItem } from "./ShortListCardDetailsItem";

import cx from "classnames";

interface Props {
  readonly item: VehicleProps;
  readonly onEstimatedMileageClick: () => void;
}

export function ShortlistCardGeneral({ item, onEstimatedMileageClick }: Props) {
  const hasReport = useMemo(() => Boolean(item.reportId), [item.reportId]);
  const name = useMemo(() => `${item.make} ${item.model}`, [item.make, item.model]);
  const price = useMemo(() => {
    if (item.basicDetails?.listPrice > 0) {
      return formatGroupNumber(item.basicDetails.listPrice);
    }
  }, [item.basicDetails]);
  const marketPrice = useMemo(() => {
    if (item.basicDetails?.marketPrice && hasReport) {
      return formatGroupNumber(item.basicDetails.marketPrice);
    }
  }, [item.basicDetails, hasReport]);

  const mileage = useMemo(() => {
    if (item.basicDetails?.mileage) {
      return formatGroupNumber(item.basicDetails.mileage);
    }

    return "?";
  }, [item.basicDetails]);
  
  return (
    <div className="d-flex flex-column flex-1 mx-2 mx-md-3">
      <img className="w-100" src={item.imageUrl} alt="car_photo" />
      <span className="text-center">{name}</span>
      <span className="text-center">{item.vrm}</span>

      <br/>
      <ShortListCardDetailsItem
            value={mileage}
            className="text-center"
            title={item.basicDetails.isUserMileage ? "Mileage" : "Estimated Mileage"}
            onClick={item.basicDetails.isUserMileage ? undefined : onEstimatedMileageClick}
            titleClassName={cx({ "text-decoration-underline": !item.basicDetails.isUserMileage })}
          />

      <span className="text-center">{price && `Price: £${price}`}</span>
      {marketPrice && (
        <span className="text-center text-danger fs-3 lh-4">Market value: £{marketPrice}</span>
      )}
    </div>
  );
}
