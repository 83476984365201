import classnames from "classnames";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Chip from "@material-ui/core/Chip";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";

import * as styles from "./styles";
import Likelihood from "./Likelihood";
import moment from "../../../utils/moment";
import TabContainer from "../TabContainer";
import { capitalize } from "../../../utils/string";
import withScrollTop from "../../../hoc/withScrollTop";
import * as actions from "../../../redux/actions/report";

export function toOdometerUnit(odometerUnit) {
  if (!odometerUnit) return "";

  switch (odometerUnit.toUpperCase()) {
    case "MI":
      return "Miles";
    case "KM":
      return "Kilometers";
    default:
      return "";
  }
}

export const MOT_STATUS = Object.freeze({
  PASSED: "PASSED",
  FAILED: "FAILED",
});

const MOT_COMMENT_STATUS_CLASS = Object.freeze({
  FAIL: "is-dangerous",
  DANGEROUS: "is-dangerous",
  MAJOR: "is-major",
});

export const MotStatusIcon = Object.freeze({
  [MOT_STATUS.PASSED]: CheckCircleOutlineIcon,
  [MOT_STATUS.FAILED]: HighlightOffIcon,
});

function MOTs({ report, token }) {
  const dispatch = useDispatch();
  const { motTests = [] } = report.mots || {};
  const { vehicle, mots: { predictedIssues } = {} } = report;

  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (!report.mots) {
      dispatch(actions.loadMOTs(report.id, token));
    }
  }, [token]);

  if (!report.mots) {
    return [];
  }

  const motDueDate = moment(vehicle.motDueDate);

  return (
    <styles.Container>
      <div className="d-flex flex-column">
        <div className="d-flex d-md-none flex-column">
          <Tabs
            className="d-block"
            value={currentTab}
            textColor="primary"
            indicatorColor="primary"
            onChange={(_, tab) => setCurrentTab(tab)}
          >
            <Tab label="Future MOTs" value={0} />
            <Tab label="Previous MOTs" value={1} />
          </Tabs>

          <TabContainer active={currentTab} tab={0}>
            <div className="future-mots">
              <div className="mots-title">Future MOTs</div>
              {predictedIssues && predictedIssues.length
                ? [
                    <>
                      <div className="mots-description">The next MOT is due on:</div>,
                      <div
                        className={classnames("mots-due", { "is-dangerous": vehicle.isMotExpired })}
                      >
                        <div className="mot-due-icon">
                          {(vehicle.isMotExpired && <ReportProblemOutlinedIcon width={24} />) || (
                            <CheckCircleOutlineIcon width={24} />
                          )}
                        </div>
                        <div className="mot-due-info">
                          <span>
                            MOT expired {moment(vehicle.motDueDate).fromNow()} ({vehicle.motDueDate}
                            )
                          </span>
                        </div>
                      </div>
                    </>,
                  ]
                : null}
              <Likelihood issues={predictedIssues} />
            </div>
          </TabContainer>

          <TabContainer active={currentTab} tab={1}>
            <div className="previous-mots">
              <div className="mots-title">Previous MOTs</div>
              {motTests && motTests.length ? (
                <div className="mots-description">
                  See the results of previous MOTs. Please pay attention to any items that were
                  marked as dangerous and major as well as advisories.
                </div>
              ) : (
                <div className="mots-description">This vehicle hasn't had an MOT yet.</div>
              )}
              <div
                className={classnames("d-flex flex-column mots-grid", {
                  "has-data": motTests && motTests.length,
                })}
              >
                {(motTests &&
                  motTests.length &&
                  motTests.map((mot, index) => {
                    const isPassed = mot.testResult === MOT_STATUS.PASSED;
                    const odometerValue = mot.odometerValue;
                    const odometerUnit = toOdometerUnit(mot.odometerUnit);
                    const IndicatorIcon = isPassed
                      ? MotStatusIcon[MOT_STATUS.PASSED]
                      : MotStatusIcon[MOT_STATUS.FAILED];

                    return (
                      <div
                        key={index}
                        className={classnames("mot-block", {
                          "is-passed": isPassed,
                          "is-failed": !isPassed,
                        })}
                      >
                        <div className="mot-indicator">
                          <IndicatorIcon />
                        </div>
                        <div className="mot-description">
                          <div className="mot-description-title">
                            {capitalize(mot.testResult)} {mot.completedDate}
                          </div>
                          <div className="mot-mileage">
                            Mileage: {odometerValue} {capitalize(odometerUnit)}
                          </div>
                          <div className="mot-comments">
                            {mot.comments.length ? (
                              <ul className="comments-list">
                                {mot.comments.map((comment, i) => (
                                  <li
                                    key={`${index}-${i}`}
                                    className={classnames(
                                      "mot-comment",
                                      MOT_COMMENT_STATUS_CLASS[comment.type],
                                    )}
                                  >
                                    <span className="mot-comment-text">{comment.text}</span>
                                    <Chip
                                      label={capitalize(comment.type)}
                                      className="mot-chip"
                                      size="small"
                                    />
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <span>No comments</span>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })) ||
                  null}
              </div>
            </div>
          </TabContainer>
        </div>

        <div className="d-none d-md-flex flex-column">
          <div className="future-mots">
            <div className="mots-title">Future MOTs</div>
            {predictedIssues && predictedIssues.length
              ? [
                  <>
                    <div className="mots-description">The next MOT is due on:</div>,
                    <div
                      className={classnames("mots-due", { "is-dangerous": vehicle.isMotExpired })}
                    >
                      <div className="mot-due-icon">
                        {(vehicle.isMotExpired && <ReportProblemOutlinedIcon width={24} />) || (
                          <CheckCircleOutlineIcon width={24} />
                        )}
                      </div>
                      <div className="mot-due-info">
                        {(vehicle.isMotExpired && (
                          <span>
                            MOT expired {moment(vehicle.motDueDate).fromNow()} ({vehicle.motDueDate})
                          </span>
                        )) ||
                          (motDueDate.isValid() ? (
                            <span>
                              <span className="moment-to-now-date">
                                {capitalize(moment(vehicle.motDueDate).toNow(true))}
                              </span>
                              left on current MOT ({vehicle.motDueDate})
                            </span>
                          ) : (
                            <span>MOT due date is not recognized properly.</span>
                          ))}
                      </div>
                    </div>
                  </>,
                ]
              : null}
            <Likelihood issues={predictedIssues} />
          </div>
          <div className="previous-mots">
            <div className="mots-title">Previous MOTs</div>
            {motTests && motTests.length ? (
              <div className="mots-description">
                See the results of previous MOTs. Please pay attention to any items that were marked
                as dangerous and major as well as advisories.
              </div>
            ) : (
              <div className="mots-description">This vehicle hasn't had an MOT yet.</div>
            )}
            <div className={classnames("mots-grid", { "has-data": motTests && motTests.length })}>
              {(motTests &&
                motTests.length &&
                motTests.map((mot, index) => {
                  const isPassed = mot.testResult === MOT_STATUS.PASSED;
                  const odometerValue = mot.odometerValue;
                  const odometerUnit = toOdometerUnit(mot.odometerUnit);
                  const IndicatorIcon = isPassed
                    ? MotStatusIcon[MOT_STATUS.PASSED]
                    : MotStatusIcon[MOT_STATUS.FAILED];

                  return (
                    <div
                      key={index}
                      className={classnames("mot-block", {
                        "is-passed": isPassed,
                        "is-failed": !isPassed,
                      })}
                    >
                      <div className="mot-indicator">
                        <IndicatorIcon />
                      </div>
                      <div className="mot-description">
                        <div className="mot-description-title">
                          {capitalize(mot.testResult)} {mot.completedDate}
                        </div>
                        <div className="mot-mileage">
                          Mileage: {odometerValue} {capitalize(odometerUnit)}
                        </div>
                        <div className="mot-comments">
                          {mot.comments.length ? (
                            <ul className="comments-list">
                              {mot.comments.map((comment, i) => (
                                <li
                                  key={`${index}-${i}`}
                                  className={classnames(
                                    "mot-comment",
                                    MOT_COMMENT_STATUS_CLASS[comment.type],
                                  )}
                                >
                                  <span className="mot-comment-text">{comment.text}</span>
                                  <div>
                                    <Chip
                                      size="small"
                                      className="mot-chip"
                                      label={capitalize(comment.type)}
                                    />
                                  </div>
                                </li>
                              ))}
                            </ul>
                          ) : (
                            <span>No comments</span>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })) ||
                null}
            </div>
          </div>
        </div>
      </div>
    </styles.Container>
  );
}

export default withScrollTop(MOTs);
