/* eslint-disable */
import Cookies from "js-cookie";
import { Auth } from "aws-amplify";
import { update } from "immupdate";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";

import { useQuery } from "../hooks/useQuery";
import { useResource } from "../hooks/useResource";
import { RegisterUserProps } from "../dto/AuthDTO";
import { validToken } from "../utils/ValidateUtils";
import { useUserContext } from "../api/user/UserContext";
import { useAuthContext } from "../api/auth/AuthContext";
import { AuthState, ProductPlans } from "../dto/EnumDTO";
import { useScrollMemory } from "../hooks/useScrollMemory";
import { AuthCustomerValuesProps } from "../api/auth/AuthApi";
import { RegisterForm } from "../components/auth/RegisterForm";
import { useResponseHandler } from "../hooks/useResponseHandler";
import { AppRouteLayout } from "../components/app/AppRouteLayout";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { StripeContainer } from "../components/stripe/StripeContainer";
import {
  authStateSelector,
  changeAuthState,
  clearRegisterState,
  clearTemporaryRegisterVehicle,
  registerUsernameSelector,
  temporaryRegisterVehicleSelector,
  tokenSelector,
} from "../reducers/authReducer";
import { Analytics } from "../utils/Analytics";
import { PRODUCTS } from "../components/form/ProductField";
import { useOptimizeActivate } from "../hooks/useOptimizeActivate";

const analytics = new Analytics();

interface Query {
  readonly reason?: string;
  readonly fromEmail: string;
  readonly product?: ProductPlans;
}

export function RegisterCheckoutContainer() {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<RegisterUserProps>({} as RegisterUserProps);

  useScrollMemory();

  const history = useHistory();
  const query = useQuery<Query>();

  const dispatch = useDispatch();

  const token = useShallowEqualSelector(tokenSelector);
  const authState = useShallowEqualSelector(authStateSelector);
  const username = useShallowEqualSelector(registerUsernameSelector);
  const registerVehicle = useShallowEqualSelector(temporaryRegisterVehicleSelector);

  const { UserApi, setUserData } = useUserContext();
  const { AuthApi, clearRegister } = useAuthContext();

  useEffect(() => {
    if (!validToken(token) && authState === AuthState.Unauthorized && !Cookies.get("qst-answers")) {
      Cookies.set("user_registered", "0", { domain: "carguide.co.uk" });

      clearRegister();
      history.replace("/auth/register");
    } else if (validToken(token) && authState === AuthState.Authorized) {
      if (history.location.pathname !== "/auth/checkout?") {
        return;
      }
      if (Cookies.get("qst-answers") && !token) {
        history.replace("/auth/checkout?product=trial_signup");
      } else {
        history.replace("/shortlist");
      }
    }
  }, [token, history, clearRegister, authState]);

  const userDataResponse = useResource(() => UserApi.getUserData(), []);

  useResponseHandler(userDataResponse, (data, error) => {
    if (data && !error) {
      setUserData({ data });

      dispatch(clearRegisterState());
      dispatch(clearTemporaryRegisterVehicle());
      dispatch(changeAuthState({ authState: AuthState.Authorized }));
      if (history.location.pathname !== "/auth/checkout?") {
        history.replace("/shortlist");
        return;
      }
      if (Cookies.get("qst-answers") && !token) {
        history.replace("/auth/checkout?product=trial_signup");
      } else {
        history.replace("/auth/product");
      }
    }
  });

  useEffect(() => {
    setLoading(true);
    Auth.currentUserInfo()
      .then((x) => {
        if (x) {
          setUser({
            username: x.username,
            name: x.attributes?.name,
            email: x.attributes?.email,
            phoneNumber: x.attributes?.["custom:phone_number"],
          });
        }
        setLoading(false);
      })
      .then(() => setLoading(false));
  }, []);

  useOptimizeActivate();
  return (
    <AppRouteLayout loading={loading} withFooter={false} title="Checkout" contentClassName="mt-5">
      {!loading && Object.keys(user).length !== 0 && (
        <StripeContainer>
          <RegisterForm
            user={user}
            username={username}
            productId={query.product}
            registerReason={query.reason}
            registerVehicle={registerVehicle}
            onSubmit={(values, isTrial) => {
              if (isTrial) {
                return AuthApi.authCustomerTrial(
                  update(values as AuthCustomerValuesProps, {
                    productId: query.product!,
                    signupReason: query.reason,
                    vrm: registerVehicle?.registration,
                  }),
                );
              } else {
                return AuthApi.authCustomer(
                  update(values as AuthCustomerValuesProps, {
                    productId: query.product!,
                    signupReason: query.reason,
                    vrm: registerVehicle?.registration,
                  }),
                );
              }
            }}
            onComplete={() => {
              const product = PRODUCTS.find(({ id }) => id === query.product);

              const price = [product?.price.priceInteger, product?.price.priceFractional]
                .map((x) => x || 0)
                .join(".");

              analytics.facebookEvent({ event: "CompleteRegistration", value: price });

              if (user?.username) {
                analytics.setGoogleVariable("userId", user.username);
              }

              if (user?.email) {
                analytics.setGoogleVariable("userEmail", user.email);
              }

              Cookies.set("user_registered", "1", { domain: "carguide.co.uk" });

              if (!userDataResponse.loading) {
                userDataResponse.refetch();
              }
            }}
          />
        </StripeContainer>
      )}
    </AppRouteLayout>
  );
}
