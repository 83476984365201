import React from "react";
import { Grid } from "@material-ui/core";

import * as styles from "./styles";

export default function CircularIndeterminate(props) {
  return (
    <styles.ProgressBox>
      <Grid container={true} justify="center" alignItems="center" direction="column">
        <styles.Progress />
        {props.text}
      </Grid>
    </styles.ProgressBox>
  );
}
