import "./assets/car-detailed-card.scss";

import React from "react";
import cx from "classnames";
import { uid } from "react-uid";
import PropTypes from "prop-types";

import { CarDetailsTable } from "./CardDetailsTable";

CarDetailedCard.propTypes = {
  hasReport: PropTypes.bool,
  className: PropTypes.string,
  onBuyReportClick: PropTypes.func,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export function CarDetailedCard({ list = [], hasReport, className, onBuyReportClick }) {
  return (
    <div className={cx("flex-1 flex-column car-detailed-card", className)}>
      {list.map(x => (
        <CarDetailsTable
          data={x}
          key={uid(x)}
          hasReport={hasReport}
          onBuyReportClick={onBuyReportClick}
        />
      ))}
    </div>
  );
}
