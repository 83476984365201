import React from "react";
import { noop } from "lodash";

import { useVehicleContext } from "../../api/vehicle/VehicleContext";
import { AddVehicleDialog, AddVehicleDialogProps } from "./AddVehicleDialog";

type Props = Omit<AddVehicleDialogProps, "onSubmit">;

export function AddVehicleDialogWrapper(props: Props) {
  const { VehicleApi } = useVehicleContext();

  if (!props.open) {
    return null;
  }

  return (
    <AddVehicleDialog {...props} onSubmit={(values) => VehicleApi.addVehicle(values).then(noop)} />
  );
}
