import "./styles.scss";

import React from "react";
import cx from "classnames";
import { SvgIcon } from "@material-ui/core";

export function WarningIcon(props) {
  return (
    <SvgIcon
      width="19"
      height="19"
      viewBox="0 0 19 19"
      {...props}
      className={cx("icons", props.className)}
    >
      <g fill="none" fillRule="evenodd" stroke="#EB4D4B" strokeLinecap="round" strokeWidth="1.5">
        <path strokeLinejoin="round" d="M9.5 14.844a.297.297 0 1 0 0 .594.297.297 0 0 0 0-.594" />
        <path d="M9.5 12.469V6.531" />
        <path
          strokeLinejoin="round"
          d="M10.783 1.393a1.43 1.43 0 0 0-2.566 0l-7.5 15.278A1.204 1.204 0 0 0 1.8 18.406H17.2a1.204 1.204 0 0 0 1.082-1.735l-7.5-15.278z"
        />
      </g>
    </SvgIcon>
  );
}
