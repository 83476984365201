import Amplify from "aws-amplify";
import {update} from "immupdate";

import awsExports from "../aws-exports";
import {APP_REDIRECT_SIGN_OUT} from "../constants/AppConstants";

if (awsExports.aws_user_pools_id === "eu-west-2_RJQfZRYss") {
    awsExports.oauth.domain = "auth.carguide.co.uk";
} else if (awsExports.aws_user_pools_id === "eu-west-2_JUFWHTOE6") {
    awsExports.oauth.domain = "auth-staging2.carguide.co.uk";
}

export function createAmplifyConfig(redirectSignIn: string) {
  Amplify.configure(
    update(awsExports, {
      oauth: {
        redirectSignIn,
        scope: awsExports.oauth.scope,
        domain: awsExports.oauth.domain,
        redirectSignOut: APP_REDIRECT_SIGN_OUT!,
        responseType: awsExports.oauth.responseType,
      },
    }),
  );
}
