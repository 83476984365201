import { Box, FormControl, InputLabel, Select, TextField, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Cookies from "js-cookie";

import React, { useState, useCallback, useEffect } from "react";
import style from "./Survey.module.css";

import { AuthVehicleInfo } from "../../../components/auth/AuthVehicleInfo";
import { RegisterContainer } from "../../RegisterContainer";
import { useQuery } from "../../../hooks/useQuery";

const subOpt = {
  "What is your balloon/final payment to buy the car?":
    "What is your balloon/final payment to buy the car?",
  "How many months have you left on your finance agreement?":
    "How many months have you left on your finance agreement?",
  "How much did you pay for the vehicle?": "How much did you pay for the vehicle?",
  "How much do you pay per month?": "How much do you pay per month?",
  "How much deposit did you pay roughly?": "How much deposit did you pay roughly?",
  "How many years did you lease the vehicle over?":
    "How many years did you lease the vehicle over?",
  "How many months are left on your lease agreement?":
    "How many months are left on your lease agreement?",
};

const options = {
  vehicleWithPCPFinance: {
    value: "I bought my vehicle with PCP Finance",
    qst: [
      "What is your balloon/final payment to buy the car?",
      "How many months have you left on your finance agreement?",
      "How much did you pay for the vehicle?",
      "How much do you pay per month?",
      "How much deposit did you pay roughly?",
    ],
  },
  vehicleWithHPFinance: {
    value: "I bought my vehicle with HP Finance",
    qst: [
      "How many months have you left on your finance agreement?",
      "How much did you pay for the vehicle?",
      "How much do you pay per month?",
      "How much deposit did you pay roughly?",
    ],
  },
  vehicleWithBankLoan: {
    value: "I bought my vehicle with a bank loan",
    qst: [
      "How much did you pay for the vehicle?",
      "How much do you pay per month?",
      "How much deposit did you pay roughly?",
    ],
  },
  leaseThisVehicle: {
    value: "I lease this vehicle",
    qst: [
      "How much do you pay per month?",
      "How much deposit did you pay roughly?",
      "How many years did you lease the vehicle over?",
      "How many months are left on your lease agreement?",
    ],
  },
  companyOwnedVehicle: {
    value: "This vehicle was a company owned vehicle",
    qst: [],
  },
  paidCash: {
    value: "I paid cash and bought outright",
    qst: [],
  },
  other: {
    value: "Other",
    qst: [],
  },
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      width: "100%",
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    root: {
      "& > *": {
        margin: theme.spacing(1),
        width: "100%",
      },
    },
    lookup: {
      margin: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    typography: {
      textAlign: "center",
      fontSize: "24px",
    },
    spin: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "510px",
      height: "325px",
    },
  }),
);

interface Query {
  readonly vrm?: string;
  readonly vrm2?: string;
}

export const MainQuestionnaire: React.FC = () => {
  const classes = useStyles();
  const [currentQuestionID, setCurrentQuestionID] = useState<number | null>(null);
  const [carsVrm, setCarsVrm] = useState<any>({});
  const [answersValue, setAnswersValue] = useState({});
  const [isSubmitedForm, setSybmitedForm] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [year, setYear] = useState("");

  const { vrm, vrm2 } = useQuery<Query>();

  const questionsHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentQuestionID(event.target.value as number);
  };

  const yearsHandler = (e: React.ChangeEvent<{ value: unknown }>) => {
    setYear(e.target.value as string);
  };

  const currentYear = new Date().getFullYear();
  const getYears = useCallback(() => {
    return Array(currentYear - (currentYear - 80))
      .fill("")
      .map((v, idx) => currentYear - idx) as Array<number>;
  }, []);

  const answersHandler = (id: number) => (e: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = e.target;
    setAnswersValue((prev) => ({ ...prev, [id]: value }));
  };

  useEffect(() => {
    if (currentQuestionID) {
      const initialAnswersValue = options[currentQuestionID].qst.reduce(
        (acc, cur) => ({
          ...acc,
          [cur]: "",
        }),
        {},
      );
      setAnswersValue(initialAnswersValue);
    }
  }, [currentQuestionID]);

  const onSetCookie = async () => {
    Cookies.set("qst-answers", { answers: answersValue, vrm, vrm2 });
  };

  useEffect(() => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      myVrm: vrm,
      checkVrm: vrm2,
    });

    fetch("https://ho5c2vzbth.execute-api.eu-west-2.amazonaws.com/lookup/simple", {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    })
      .then((response) => response.json())
      .then(setCarsVrm)
      // eslint-disable-next-line no-console
      .catch((error) => console.log("error", error));
  }, []);

  const myCar = carsVrm?.myCar?.dvladata;

  return (
    <>
      <div className={style.questionnaire_conteiner}>
        <div className={style.questionnaire_block}>
          {myCar !== undefined ? (
            <div className={style.lookup_info}>
              <AuthVehicleInfo
                isMyCar={myCar}
                vehicle={{
                  make: myCar?.manufacturer,
                  model: myCar?.model,
                  colour: myCar?.colour?.current,
                  regDate: myCar?.registrationdate?.firstregistration,
                  fuelType: myCar?.fuel,
                  registration: myCar?.registrationdate?.firstregistrationuk,
                }}
                loading={false}
                onBackClick={() => window.history.back()}
              />
              <AuthVehicleInfo
                vehicle={{
                  make: carsVrm?.checkCar?.dvladata?.manufacturer,
                  model: carsVrm?.checkCar?.dvladata?.model,
                  colour: carsVrm?.checkCar?.dvladata?.colour?.current,
                  regDate: carsVrm?.checkCar?.dvladata?.registrationdate?.firstregistration,
                  fuelType: carsVrm?.checkCar?.dvladata?.fuel,
                  registration: carsVrm?.checkCar?.dvladata?.registrationdate?.firstregistrationuk,
                }}
                loading={false}
                onBackClick={() => window.history.back()}
              />
            </div>
          ) : (
            <div className={style.err_found}>
              <span>
                Sorry this doesn’t match our records, please click below to access your limited free
                account
              </span>
            </div>
          )}
          <div className={style.block1}>
            <Typography className={classes.typography}>
              So we can check it's really your car, when did you get it?
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel style={{ backgroundColor: "#fff" }} htmlFor="outlined-age-native-simple">
                Year
              </InputLabel>
              <Select
                native={true}
                onChange={yearsHandler}
                inputProps={{
                  name: "Year",
                  id: "outlined-age-native-simple",
                }}
              >
                <option aria-label="Year" value="" />
                {getYears().map((items) => (
                  <option key={items}>{items}</option>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={style.block2}>
            <Typography>So we can customise your report, which applies to you?</Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple">Choose your answer</InputLabel>
              <Select
                native={true}
                value={currentQuestionID || ""}
                onChange={questionsHandler}
                label="Choose your answer"
                inputProps={{
                  name: "Choose your answer",
                  id: "outlined-age-native-simple",
                }}
              >
                <option aria-label="Choose your answer" value="" />
                {Object.keys(options).map((id) => (
                  <option key={id} value={id}>
                    {options[id].value}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={style.questions_block}>
            {currentQuestionID ? (
              <form action="" method="" className={classes.root} autoComplete="off">
                {options[currentQuestionID].qst.map((id) => (
                  <Box className={style.text_field} key={id}>
                    <InputLabel htmlFor={subOpt[id]}>{subOpt[id]}</InputLabel>
                    <TextField
                      required={true}
                      onChange={answersHandler(id)}
                      error={isSubmitedForm && !answersValue[id]}
                      helperText={
                        isSubmitedForm && !answersValue[id] ? "This field is required" : ""
                      }
                      id={subOpt[id]}
                      variant="outlined"
                    />
                  </Box>
                ))}
              </form>
            ) : null}
          </div>

          <div className={style.block3}>
            <Typography className={classes.typography}>
              How do you want to view your Free Buyers Report (normally £8.99)
            </Typography>
            <div className={style.register}>
              <RegisterContainer
                currentQuestionID={currentQuestionID}
                answersValue={answersValue}
                setSybmitedForm={setSybmitedForm}
                onSetCookieData={onSetCookie}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
