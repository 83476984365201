import React from "react";
import { withRouter } from "react-router-dom";
import PrintOutlinedIcon from "@material-ui/icons/PrintOutlined";
import KeyboardBackspaceOutlinedIcon from "@material-ui/icons/KeyboardBackspaceOutlined";
import * as styles from "./styles";
import { useIntercom } from "../../../hooks/useIntercom";
import { usePageViewEvent } from "../../../hooks/usePageViewEvent";

const Navigation = props => {
  useIntercom();

  usePageViewEvent("CarGuide - Report");

  function handleBackButton() {
    props.history.goBack();
  }

  return (
    <styles.NavigationContainer>
      <div className="navigation-content">
        <div className="navigation-back-button" onClick={handleBackButton}>
          <KeyboardBackspaceOutlinedIcon className="button-icon left-arrow-icon" />
          <span className="button-text">Back</span>
        </div>
        <h1 className="navigation-title">Buyers Report</h1>
        <div className="navigation-print-button">
          <span className="button-text">Download</span>
          <PrintOutlinedIcon className="button-icon print-icon" />
        </div>
      </div>
    </styles.NavigationContainer>
  );
};

export default withRouter(Navigation);
