import "./assets/sorting-info.scss";

import React, { useMemo, useState } from "react";

import { SortingSelectBox } from "./SortingSelectBox";
import { VEHICLE_SORTING_ITEMS } from "../../constants/VehicleConstants";

interface Props {
  readonly onChangeFilter: (value: string) => void;
}

export function SortingInfo({ onChangeFilter }: Props) {
  const [filter, setFilter] = useState("");

  const items = useMemo(
    () =>
      VEHICLE_SORTING_ITEMS.map(({ title, value, category }) => ({
        title,
        value: `${value}-${category}`,
      })),
    [],
  );

  return (
    <div className="d-flex track">
      <div className="d-flex flex-1 flex-column m-2 mx-md-3 sorting-info">
        <div className="d-flex flex-1 flex-column mb-2 justify-content-between">
          <span className="fs-4 sorting-info-title">Your top cars.</span>
          <span className="sorting-info-title">
            Change the sort order to display the most important information first:
          </span>
        </div>

        <SortingSelectBox
          items={items}
          value={filter}
          onChange={(x) => {
            setFilter(x);
            onChangeFilter(x);
          }}
        />
      </div>
    </div>
  );
}
