import { useMemo } from "react";
import { useDispatch } from "react-redux";

import { UserApi } from "./UserApi";
import { useApiBase } from "../ApiContext";
import {
  setUserAccount,
  SetUserAccountMeta,
  setUserData,
  SetUserDataMeta,
} from "../../reducers/userReducer";

export interface UserContextProps {
  readonly UserApi: UserApi;

  readonly setUserData: (meta: SetUserDataMeta) => void;
  readonly setUserAccount: (meta: SetUserAccountMeta) => void;
}

export function useUserContext(): UserContextProps {
  const data = useApiBase();

  const dispatch = useDispatch();

  const api = useMemo(() => new UserApi(data), [data]);

  return {
    UserApi: api,

    setUserData: (meta) => dispatch(setUserData(meta)),
    setUserAccount: (meta) => dispatch(setUserAccount(meta)),
  };
}
