import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import WrenchIcon from "../../../assets/img/reports/wrench.svg";
import MoneyBoxIcon from "../../../assets/img/reports/moneybox.svg";
import { formatFunds } from "../../../utils/number";
import { callWhenPossible } from "../../../utils/callback";
import withScrollTop from "../../../hoc/withScrollTop";
import TAB from "../tab.enum";
import NoData from "../common/NoData";
import ModalDialog from "../../../commons/ModalDialog";
import Recall from "./Recall";
import * as actions from "../../../redux/actions/report";
import * as styles from "./styles";
import { APP_WP_URL } from "../../../constants/AppConstants";

const ISSUE_PREDICTION_THRESHOLD = 60;

function disableDefault(event) {
  event.preventDefault();
}

function disableBackendLinks(wrapperSelector) {
  const wrapper = document.querySelector(wrapperSelector);

  if (wrapper) {
    wrapper.querySelectorAll("a").forEach(reference => {
      reference.addEventListener("click", disableDefault);
    });
  }
}

function QuestionsToAsk({ token, report, handleTabChange }) {
  const dispatch = useDispatch();
  const { questions = [], runningCosts = {}, recallsInfo = {}, predictedIssues = [] } =
    report.questionsAndAnswers || {};

  const openRunningCostSection = event => {
    event.preventDefault();
    handleTabChange(event, TAB.RUNNING_COSTS);
  };

  const openRepairsSection = event => {
    event.preventDefault();
    handleTabChange(event, TAB.REPAIRS);
  };

  const openServiceSchedulesSection = event => {
    event.preventDefault();
    handleTabChange(event, TAB.SERVICE_SCHEDULES);
  };

  const openMotsSection = event => {
    event.preventDefault();
    handleTabChange(event, TAB.MOTS);
  };

  useEffect(() => {
    if (!report.questionsAndAnswers) {
      dispatch(actions.loadQuestionsAndAnswers(report.id, token));
    }

    // the following thing has to burn in Hell, sorry for publishing that :(
    const wrapperSelector = ".questions-and-answers";
    const openTabReference = 'a[data-action-type="open-tab"]';
    const servicesReferenceSelector = `${openTabReference}[data-action-tab-id="serviceSchedules"]`;
    const motsReferenceSelector = `${openTabReference}[data-action-tab-id="mots"]`;
    const repairsReferenceSelector = `${openTabReference}[data-action-tab-id="repairs"]`;
    const DELAY_TIME = 1000; // should be enough...

    callWhenPossible(
      () => !!document.querySelector(wrapperSelector),
      () => {
        // disabling all references coming from backend
        disableBackendLinks(wrapperSelector);

        // bind service schedules tab open action by clicking on relevant reference
        const openServiceTabReference = document.querySelector(servicesReferenceSelector);
        if (openServiceTabReference) {
          openServiceTabReference.addEventListener("click", openServiceSchedulesSection);
        }

        // bind mots tab open action by clicking on relevant reference
        const openMotsTabReference = document.querySelector(motsReferenceSelector);
        if (openMotsTabReference) {
          openMotsTabReference.addEventListener("click", openMotsSection);
        }

        // bind repairs tab open action by clicking on relevant reference
        const openRepairsTabReference = document.querySelector(repairsReferenceSelector);
        if (openRepairsTabReference) {
          openRepairsTabReference.addEventListener("click", openRepairsSection);
        }
      },
      DELAY_TIME,
    );

    // remove event listeners on unmount
    return () => {
      const wrapper = document.querySelector(wrapperSelector);
      if (wrapper) {
        wrapper.querySelectorAll("a").forEach(reference => {
          reference.removeEventListener("click", disableDefault);
        });
      }

      const openServiceTabReference = document.querySelector(servicesReferenceSelector);
      if (openServiceTabReference) {
        openServiceTabReference.removeEventListener("click", openServiceSchedulesSection);
      }

      const openMotsTabReference = document.querySelector(motsReferenceSelector);
      if (openMotsTabReference) {
        openMotsTabReference.removeEventListener("click", openMotsSection);
      }

      const openRepairsTabReference = document.querySelector(repairsReferenceSelector);
      if (openRepairsTabReference) {
        openRepairsTabReference.addEventListener("click", openRepairsSection);
      }
    };
  }, [token]);

  const [isDepreciationModalOpen, setDepreciationModalOpen] = useState(false);
  const toggleDepreciationModal = event => {
    event.preventDefault();
    setDepreciationModalOpen(!isDepreciationModalOpen);
  };

  const [isInsuranceModalOpen, setInsuranceModalOpen] = useState(false);
  const toggleInsuranceModal = event => {
    event.preventDefault();
    setInsuranceModalOpen(!isInsuranceModalOpen);
  };

  if (!report.questionsAndAnswers || !report.vehicle) {
    return [];
  }

  const hasNoRunningCosts =
    !runningCosts.fuelPerMonth &&
    !runningCosts.deprValuePerMonth &&
    !runningCosts.servicePerMonth &&
    !runningCosts.taxPerMonth;

  return (
    <styles.Container>
      <div className="container-title">
        When viewing a vehicle it is easy to fall in love with it and disregard the questions you
        should be asking the seller.
      </div>
      <div className="container-subtitle">
        Below we list the questions you should ask before viewing the vehicle:
      </div>
      <div className="questions-and-answers">
        {(questions.length &&
          questions.map((questionAndAnswer, index) => (
            <div key={index} className="question-and-answer">
              <div className="question-block">
                <div className="qa-icon question-icon">Q.</div>
                <div
                  className="question-content"
                  dangerouslySetInnerHTML={{ __html: questionAndAnswer.question }}
                />
              </div>
              <div className="answer-block">
                <div className="qa-icon answer-icon">A.</div>
                <div
                  className="answer-content"
                  dangerouslySetInnerHTML={{ __html: questionAndAnswer.answer }}
                />
              </div>
            </div>
          ))) ||
          null}
      </div>
      <div className="other-info-section">
        <div className="info-block machine-learning-block">
          <div className="info-block-icon">
            <img src={WrenchIcon} width={80} />
          </div>
          <div className="info-block-description">
            <span className="d-inline-block text-center text-mb-left">
              We’ve used our machine learning platform to predict what the vehicle is likely to have
              issues with in the future. Some of this may have already been repaired so it is worth
              asking the seller.
            </span>
            <div className="info-reference text-center">
              <a href="#" onClick={openRepairsSection}>
                See the full list here.
              </a>
            </div>
            <div className="predicted-issues">
              <div className="predicted-issues-title text-center">Predicted issues</div>
              <div className="predicted-issues-list">
                {predictedIssues && predictedIssues.length ? (
                  predictedIssues.map((predictedIssue, index) => (
                    <div
                      key={index}
                      className={classnames(
                        "predicted-issue d-flex justify-content-center align-items-center",
                        {
                          "is-dangerous": predictedIssue.risk >= ISSUE_PREDICTION_THRESHOLD,
                        },
                      )}
                    >
                      <span className="predicted-issue-percentage">{predictedIssue.risk}%</span>
                      <span className="predicted-issue-description">
                        {predictedIssue.component}
                      </span>
                    </div>
                  ))
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>
        </div>
        <Recall info={recallsInfo} />
        <div className="info-block running-costs-block">
          <div className="info-block-icon">
            <img src={MoneyBoxIcon} width={80} />
          </div>
          <div className="info-block-description text-center">
            <span className="d-inline-block text-center text-mb-left">
              So you can plan ahead, here are some running costs for this {report.vehicle.make}{" "}
            </span>
            {report.vehicle.model}.
            <div className="info-reference text-center">
              <a href="#" onClick={openRunningCostSection}>
                See full list here.
              </a>
            </div>
          </div>
          {(!hasNoRunningCosts && (
            <div className="running-costs-description text-center">
              Based on <strong>12k</strong> miles per year, it will cost you:
            </div>
          )) ||
            null}
          <div className="running-costs-items text-center">
            {(hasNoRunningCosts && (
              <div className="running-cost">
                Unfortunately we don't have running costs for this vehicle at present. We're
                constantly working on our data and hope to have it soon.
              </div>
            )) ||
              null}
            {(runningCosts.fuelPerMonth && (
              <div className="running-cost text-center">
                <span className="running-cost-value">
                  £{formatFunds(runningCosts.fuelPerMonth)}
                </span>{" "}
                per month in fuel
              </div>
            )) ||
              null}
            {(runningCosts.deprValuePerMonth && (
              <div className="running-cost info-reference text-center">
                <span className="running-cost-value">
                  £{formatFunds(runningCosts.deprValuePerMonth)}
                </span>{" "}
                per month in depreciation{" "}
                <a href="#" onClick={toggleDepreciationModal}>
                  What is this?
                </a>
              </div>
            )) ||
              null}
            {(runningCosts.servicePerMonth && (
              <div className="running-cost text-center">
                <span className="running-cost-value">
                  £{formatFunds(runningCosts.servicePerMonth)}
                </span>{" "}
                per month to service
              </div>
            )) ||
              null}
            {(runningCosts.taxPerMonth && (
              <div className="running-cost text-center">
                <span className="running-cost-value">£{formatFunds(runningCosts.taxPerMonth)}</span>{" "}
                per month to tax
              </div>
            )) ||
              null}
            {(runningCosts.insuranceGroup && runningCosts.insuranceGroup.trim() && (
              <div className="running-cost info-reference text-center">
                Insurance group{" "}
                <span className="running-cost-value">{runningCosts.insuranceGroup}</span>{" "}
                <a href="#" onClick={toggleInsuranceModal}>
                  What is this?
                </a>
              </div>
            )) ||
              null}
          </div>
        </div>
      </div>
      <ModalDialog
        sourcePath={`${APP_WP_URL}/wp-json/wp/v2/posts/976`}
        open={isDepreciationModalOpen}
        handleClose={toggleDepreciationModal}
      />
      <ModalDialog
        sourcePath={`${APP_WP_URL}/wp-json/wp/v2/posts/1079`}
        open={isInsuranceModalOpen}
        handleClose={toggleInsuranceModal}
      />
    </styles.Container>
  );
}

export default withScrollTop(QuestionsToAsk);
