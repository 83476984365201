import "./assets/stripe-error.scss";

import React from "react";
import cx from "classnames";

interface Props {
  readonly error?: string;
  readonly className?: string;
}

export function StripeError({ error, className }: Props) {
  return (
    <div
      className={cx(
        "d-flex align-items-center px-2 justify-content-center text-center stripe-error",
        className,
      )}
    >
      {error}
    </div>
  );
}
