import "./assets/likelihood-issue.scss";

import cx from "classnames";
import { capitalize } from "lodash";
import React, { useMemo } from "react";

import { RiskLevel } from "../../dto/EnumDTO";
import { getRiskLevel } from "../../utils/ReportHelpers";
import { MotPredicatedIssueProps } from "../../api/report/ReportDTO";

interface Props {
  readonly issue: MotPredicatedIssueProps;
}

export function LikelihoodIssue({ issue }: Props) {
  const title = useMemo(
    () => `${capitalize(issue.component)} - ${issue.risk}% likelihood of issues`,
    [issue],
  );

  const failureLevel = useMemo(() => getRiskLevel(issue.fail), [issue.fail]);
  const advisoryLevel = useMemo(() => getRiskLevel(issue.advisory), [issue.advisory]);
  const highRisk = useMemo(() => getRiskLevel(issue.risk) === RiskLevel.High, [issue.risk]);

  const description = useMemo(
    () =>
      `There’s a ${advisoryLevel} chance of an advisory note being issued and a ${failureLevel} chance of a failure at the next MOT.`,
    [advisoryLevel, failureLevel],
  );

  return (
    <div className="d-flex flex-column mb-3 likelihood-issue">
      <div
        className={cx("d-flex align-items-center p-2 bg-secondary", {
          "bg-danger": highRisk,
          "bg-secondary": !highRisk,
        })}
      >
        <span className="text-white">{title}</span>
      </div>
      <span className="my-2">{description}</span>
    </div>
  );
}
