import { Dict } from "../dto/AppDTO";
import { Logger, Printable } from "./Logger";
import { tryStringifyJSON } from "./DataUtils";
import { IS_DEV } from "../constants/AppConstants";
import { IntercomActions } from "../constants/AnalyticsConstants";

export interface FacebookEventProps {
  readonly event: string;
  readonly currency?: string;
  readonly value: number | string;
}

export interface GoogleEventProps {
  readonly page?: string;
  readonly title?: string;
  readonly location?: string;
  readonly eventLabel?: string;
  readonly eventAction?: string;
  readonly eventCategory?: string;
}

export interface GoogleOptionsProps {
  readonly nonInteraction?: 1;
}

export interface AnalyticsProps {
  readonly debug?: boolean;
  readonly googleDebug?: boolean;
  readonly taboolaDebug?: boolean;
  readonly facebookDebug?: boolean;
  readonly intercomDebug?: boolean;
  readonly microsoftDebug?: boolean;
}

export class Analytics {
  private readonly debug?: boolean;
  private readonly googleDebug?: boolean;
  private readonly taboolaDebug?: boolean;
  private readonly facebookDebug?: boolean;
  private readonly intercomDebug?: boolean;
  private readonly microsoftDebug?: boolean;

  constructor(props?: AnalyticsProps) {
    this.debug = props?.debug;
    this.googleDebug = props?.googleDebug;
    this.taboolaDebug = props?.taboolaDebug;
    this.facebookDebug = props?.facebookDebug;
    this.intercomDebug = props?.intercomDebug;
    this.microsoftDebug = props?.microsoftDebug;
  }

  private log(namespace: string, message: string, ...args: Printable[]) {
    const logger = new Logger(namespace);

    logger.log(`Sent event: ${message}`, ...args);
  }

  public taboolaEvent(name: string) {
    if (!IS_DEV || this.taboolaDebug || this.debug) {
      // @ts-ignore
      const { _tfa } = window;

      if (_tfa) {
        _tfa.push({ notify: "event", name, id: 1279282, revenue: "" });

        this.log("Taboola", name);
      }
    }
  }

  public facebookPixel(event: string, options = {}) {
    // @ts-ignore
    const { fbq } = window;

    if (fbq) {
      fbq("track", event, options);

      this.log("Facebook", "track", tryStringifyJSON({ event, ...options }));
    }
  }

  public facebookEvent({ currency = "GBP", event, value }: FacebookEventProps) {
    if (!IS_DEV || this.facebookDebug || this.debug) {
      this.facebookPixel(event, { value, currency });
    }
  }

  public googleEvent(hitType: string, event: GoogleEventProps, options?: GoogleOptionsProps) {
    if (!IS_DEV || this.googleDebug || this.debug) {
      // @ts-ignore
      const { ga } = window;

      if (ga) {
        const meta = {
          ...event,
          hitType,
        };

        ga("send", meta, options);

        this.log("Google", "event", tryStringifyJSON(meta));
      }
    }
  }

  public addGoogleVariable(field: string, value: any) {
    if (!IS_DEV || this.googleDebug || this.debug) {
      // @ts-ignore
      const { dataLayer } = window;

      if (dataLayer) {
        const meta = { [field]: value };

        dataLayer.push(meta);

        this.log("Google", "dataLayer", tryStringifyJSON(meta));
      }
    }
  }

  public setGoogleVariable(field: string, value: any) {
    if (!IS_DEV || this.googleDebug || this.debug) {
      // @ts-ignore
      const { ga } = window;

      if (ga) {
        const meta = { [field]: value };

        ga("set", field, value);

        this.log("Google", "dataLayer", tryStringifyJSON(meta));
      }
    }
  }

  public intercomEvent(action: IntercomActions, data: Dict<string | number | undefined> = {}) {
    if (!IS_DEV || this.intercomDebug || this.debug) {
      // @ts-ignore
      const { Intercom } = window;

      if (Intercom) {
        const meta = {
          ...data,
          event_date: Math.round(Date.now() / 1000),
        };

        Intercom("trackEvent", action, meta);

        this.log("Intercom", action, tryStringifyJSON(meta));
      }
    }
  }

  public googleEcommerce(product: { id?: string; price: string; title: string; variant?: string }) {
    if (!IS_DEV || this.googleDebug || this.debug) {
      // @ts-ignore
      const { ga } = window;

      if (ga) {
        ga("require", "ec");

        const addProduct = {
          quantity: "1",
          id: product.id,
          sku: product.id,
          name: product.title,
          price: product.price,
          variant: product.variant,
        };

        const purchaseAction = {
          id: Date.now(),
        };

        ga("ec:addProduct", addProduct);

        ga("ec:setAction", "purchase", purchaseAction);

        this.log("Google", "Ecommerce", tryStringifyJSON({ purchaseAction, addProduct }));
      }
    }
  }

  public microsoftAds(product: { id?: string; price: string; title: string; variant?: string }) {
    if (!IS_DEV || this.microsoftDebug || this.debug) {

      // @ts-ignore
      window.uetq.push('event', 'BingAds', {
        'event_category': 'Purchase',
        'event_label': product.variant,
        'event_value': product.price
      });

      // @ts-ignore
      window.uetq.push('event', 'BingAds', {
        'event_category': 'Purchase',
        'event_label': product.title,
        'event_value': product.price
      });

      const addProduct = {
        quantity: "1",
        id: product.id,
        sku: product.id,
        name: product.title,
        price: product.price,
        variant: product.variant,
      };

      const purchaseAction = {
        id: Date.now(),
      };

      this.log("Microsoft", "Ads", tryStringifyJSON({purchaseAction, addProduct}));
    }
  }

  public webgainsAds(order: { id?: string; price: string; orderReference?: string }) {
    if (!IS_DEV) {
      // @ts-ignore
      const { ITCVRQ } = window;
      const { referrer } = document;

      if (ITCVRQ) {
        const addProduct = {
          value: order.price,
          currency: "GBP",
          language: "UK_en",
          eventId: "1081815",
          orderReference: order.orderReference,
          comment: "",
          multiple: "",
          checksum: "",
          items: "",
          customerId: "",
          voucherId: "",
          location: String(referrer),
        };

        ITCVRQ("set", "trk.programId", "292335");
        ITCVRQ("set", "cvr", addProduct);
        ITCVRQ("conversion");

        this.log("Webgains", "Conversion", tryStringifyJSON({ addProduct }));
      }
    }
  }

}
