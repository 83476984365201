import { Analytics } from "./Analytics";
import { IntercomActions } from "./IntercomHelpers";
import { SocketDataProps } from "../hooks/useSocket";
import { FindVehicleProps } from "../api/vehicle/VehicleDTO";
import { isAutotraderSource, isMotorsSource } from "./VehicleHalpers";
import {
  VEHICLE_LOOKUP_CATEGORY,
  VEHICLE_SCRAPE_CATEGORY,
  VehicleLookupActions,
  VehicleScrapeActions,
} from "../constants/AnalyticsConstants";

const analytics = new Analytics();

export function createVehicleLookupEvents(vehicle: FindVehicleProps) {
  if (isMotorsSource(vehicle.sourceLink)) {
    analytics.googleEvent("event", {
      eventLabel: vehicle.registration,
      eventCategory: VEHICLE_SCRAPE_CATEGORY,
      eventAction: VehicleScrapeActions.MotorsLookup,
    });
  } else if (isAutotraderSource(vehicle.sourceLink)) {
    analytics.googleEvent("event", {
      eventLabel: vehicle.registration,
      eventCategory: VEHICLE_SCRAPE_CATEGORY,
      eventAction: VehicleScrapeActions.AutotraderLookup,
    });
  } else {
    analytics.googleEvent("event", {
      eventLabel: vehicle.registration,
      eventCategory: VEHICLE_LOOKUP_CATEGORY,
      eventAction: VehicleLookupActions.VehicleLookup,
    });
  }

  analytics.googleEvent("event", {
    eventCategory: VEHICLE_LOOKUP_CATEGORY,
    eventLabel: `${vehicle.make}, ${vehicle.model}`,
    eventAction: VehicleLookupActions.VehicleMakeModel,
  });
  analytics.googleEvent("event", {
    eventCategory: VEHICLE_LOOKUP_CATEGORY,
    eventAction: VehicleLookupActions.VehicleMakeModelYear,
    eventLabel: `${vehicle.make}, ${vehicle.model}, ${vehicle.year}`,
  });
}

export function createAddVehicleEvents(
  vehicle: Partial<FindVehicleProps>,
  socket?: SocketDataProps,
) {
  if (socket?.done && !socket?.error) {
    if (vehicle.sourceLink) {
      if (isMotorsSource(vehicle.sourceLink)) {
        analytics.googleEvent("event", {
          eventLabel: vehicle.registration,
          eventCategory: VEHICLE_SCRAPE_CATEGORY,
          eventAction: VehicleScrapeActions.MotorsAddToShortList,
        });
      } else {
        analytics.googleEvent("event", {
          eventLabel: vehicle.registration,
          eventCategory: VEHICLE_SCRAPE_CATEGORY,
          eventAction: VehicleScrapeActions.AutotraderAddToShortList,
        });
      }
    } else {
      analytics.googleEvent("event", {
        eventLabel: vehicle.registration,
        eventCategory: VEHICLE_LOOKUP_CATEGORY,
        eventAction: VehicleLookupActions.VehicleAddToShortlist,
      });
    }
  }

  if (socket?.done && socket?.error) {
    if (vehicle.sourceLink) {
      if (isMotorsSource(vehicle.sourceLink)) {
        analytics.googleEvent("event", {
          eventCategory: VEHICLE_SCRAPE_CATEGORY,
          eventLabel: vehicle.registration,
          eventAction: VehicleScrapeActions.MotorsLookupError,
        });
      } else {
        analytics.googleEvent("event", {
          eventCategory: VEHICLE_SCRAPE_CATEGORY,
          eventLabel: vehicle.registration,
          eventAction: VehicleScrapeActions.AutotraderLookupError,
        });
      }
    } else {
      analytics.googleEvent("event", {
        eventCategory: VEHICLE_LOOKUP_CATEGORY,
        eventLabel: vehicle.registration,
        eventAction: VehicleLookupActions.VehicleLookupError,
      });
    }
  }
}

export function createDeleteVehicleEvent(vrm: string, success = true, message?: string) {
  if (success) {
    analytics.intercomEvent(IntercomActions.VehicleDeletedFromShortlist, {
      vrm,
      status: "success",
    });
  } else {
    analytics.intercomEvent(IntercomActions.VehicleDeletedFromShortlist, {
      vrm,
      message,
      status: "fail",
    });
  }
}
