import React from "react";
import { Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import moment from "../../../../utils/moment";

import Logo from "../assets/logo.png";
import { pageLayoutStyles } from "../styles/pageLayoutStyles";

const styles = StyleSheet.create(pageLayoutStyles);

export const PageLayout = ({ children, id }) => {
  const date = moment().format("DD/MM/YYYY");

  return (
    <Page size="A4" wrap={true} style={styles.page}>
      <View fixed={true} style={styles.header}>
        <Image style={styles.logo} src={Logo} />
      </View>

      <View style={styles.main}>{children}</View>

      <View fixed={true} style={styles.footer}>
        <Text style={styles.boldText}> Contact: support@carguide.co.uk </Text>
        <Text>
          CarGuide.co.uk, a trading name of Impair Ltd. Registered in England and Wales: 12197920,
        </Text>
        <Text>Report generated: {date}, Report ID: {id}</Text>
      </View>
    </Page>
  );
};
