import { withRouter } from "react-router-dom";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Select from "@material-ui/core/Select";
import { isMobile } from "react-device-detect";
import _ from "lodash";
import { compose } from "recompose";

import MOTs from "./MOTs";
import MileageAndRisks from "./MileageAndRisks";
import Navigation from "./Navigation";
import QuestionsToAsk from "./QuestionsToAsk";
import Repairs from "./Repairs";
import RunningCosts from "./RunningCosts";
import ServiceSchedules from "./ServiceSchedules";
import Sidebar from "./Sidebar";
import TabContainer from "./TabContainer";
import TestDrive from "./TestDrive";
import TAB from "./tab.enum";
import * as actions from "../../redux/actions/report";
import * as styles from "./styles";

const sections = [
  { label: "Questions to ask", value: TAB.QUESTIONS_AND_ANSWERS },
  { label: "The test drive", value: TAB.TEST_DRIVE },
  { label: "Service Schedules", value: TAB.SERVICE_SCHEDULES },
  { label: "MOTs", value: TAB.MOTS },
  { label: "Running costs", value: TAB.RUNNING_COSTS },
  { label: "Repairs", value: TAB.REPAIRS },
  { label: "Mileage & risks", value: TAB.MILEAGE_AND_RISKS },
];

const importantItemsSection = { label: "Important Items", value: TAB.IMPORTANT_ITEMS };

if (isMobile) {
  sections.unshift(importantItemsSection);
}

const DEBOUNCE_TIME_ON_RESIZE = 50;
const MOBILE_MAX_WIDTH = 480;

const enhancer = compose(
  withRouter,
  connect(
    ({ report, newAuth }) => ({ report, token: newAuth.token }),
    dispatch => ({
      actions: bindActionCreators(actions, dispatch),
    }),
  ),
);

class ReportPage extends React.Component {
  state = {
    isMobile,
    currentTab: this.determineCurrentTab(isMobile),
    dimensions: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
  };

  determineCurrentTab(isMobile) {
    return isMobile ? TAB.IMPORTANT_ITEMS : TAB.QUESTIONS_AND_ANSWERS;
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    // setting specific report to display at the moment
    this.props.actions.setReport(id);

    // subscribe on window resize to update tabs/sidebar content views
    window.addEventListener("resize", this.debouncedHandleResize);

    // and call it once component did mount to make sure the tabs are correct
    this.debouncedHandleResize();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location && this.history.action !== "POP") {
      window.scrollTo(0, 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.debouncedHandleResize);
  }

  debouncedHandleResize = _.debounce(async () => {
    const newIsMobile = window.innerWidth <= MOBILE_MAX_WIDTH;

    // we have Important Items section represented as a tab section if on mobile device
    // the following code is responsible for switching and showing/hiding that tab
    const importantItemsQueryCallback = section => section.value === TAB.IMPORTANT_ITEMS;
    if (newIsMobile) {
      const importantItems = sections.find(importantItemsQueryCallback);

      if (!importantItems) {
        sections.unshift(importantItemsSection);
      }
    } else {
      _.remove(sections, importantItemsQueryCallback);
    }

    const newState = {
      ...this.state,
      isMobile: newIsMobile,
      dimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    };

    if (newState.currentTab === TAB.IMPORTANT_ITEMS) {
      // determine current tab based on desktop/mobile version
      newState.currentTab = this.determineCurrentTab(newIsMobile);
    }

    this.setState(newState);
  }, DEBOUNCE_TIME_ON_RESIZE);

  handleTabChange = (event, tabIndex) => {
    this.setState({
      ...this.state,
      currentTab: tabIndex,
    });
  };

  handleSelectChange = event => {
    this.setState({
      ...this.state,
      currentTab: event.target.value,
    });
  };

  render() {
    const {
      match: { params },
      report: { entities },
      token,
    } = this.props;
    const { currentTab } = this.state;
    const report = entities[params.id] || null;

    // don't start rendering till current report is loaded into the store
    if (!report) {
      return <>Loading...</>;
    }

    return (
      <styles.ReportPageContainer>
        <Navigation />
        <div className="report-page-content-container">
          <Sidebar report={report} handleTabChange={this.handleTabChange} />
          <div className="report-container">
            <Tabs
              value={currentTab}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {sections.map((section, index) => (
                <Tab key={section.value} label={section.label} value={section.value} />
              ))}
            </Tabs>
            <Select
              native
              className="report-select-navigator"
              value={currentTab}
              onChange={this.handleSelectChange}
            >
              {sections.map((section, index) => (
                <option key={section.value} value={section.value}>
                  {section.label}
                </option>
              ))}
            </Select>
            <TabContainer active={currentTab} tab={TAB.IMPORTANT_ITEMS}>
              <Sidebar isInTab report={report} handleTabChange={this.handleTabChange} />
            </TabContainer>
            <TabContainer active={currentTab} tab={TAB.QUESTIONS_AND_ANSWERS}>
              <QuestionsToAsk
                token={token}
                report={report}
                handleTabChange={this.handleTabChange}
              />
            </TabContainer>
            <TabContainer active={currentTab} tab={TAB.TEST_DRIVE}>
              <TestDrive
                token={token} report={report} handleTabChange={this.handleTabChange} />
            </TabContainer>
            <TabContainer active={currentTab} tab={TAB.SERVICE_SCHEDULES}>
              <ServiceSchedules
                token={token} report={report} />
            </TabContainer>
            <TabContainer active={currentTab} tab={TAB.MOTS}>
              <MOTs
                token={token} report={report} />
            </TabContainer>
            <TabContainer active={currentTab} tab={TAB.RUNNING_COSTS}>
              <RunningCosts
                token={token} report={report} />
            </TabContainer>
            <TabContainer active={currentTab} tab={TAB.REPAIRS}>
              <Repairs
                token={token} report={report} />
            </TabContainer>
            <TabContainer active={currentTab} tab={TAB.MILEAGE_AND_RISKS}>
              <MileageAndRisks
                token={token} report={report} />
            </TabContainer>
          </div>
        </div>
      </styles.ReportPageContainer>
    );
  }
}

export default enhancer(ReportPage);
