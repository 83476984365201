import "./assets/header-vehicle-info.scss";

import React, { useMemo } from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import { ProductPlans } from "../../dto/EnumDTO";
import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";
import { VehicleProps } from "../../api/vehicle/VehicleDTO";
import { StripeContainer } from "../stripe/StripeContainer";
import { ShortListBuyReportButtonWrapper } from "../short-list/ShortListBuyReportButtonWrapper";

interface Props {
  readonly vehicle: VehicleProps;
  readonly onRemoveClick: () => void;
  readonly onBuyReportClick: () => void;
  readonly onViewReportClick: () => void;
  readonly onComplete?: (product: ProductPlans) => void;
}

export function HeaderVehicleInfo({
  vehicle,
  onComplete,
  onRemoveClick,
  onBuyReportClick,
  onViewReportClick,
}: Props) {
  const name = useMemo(() => `${vehicle.make} ${vehicle.model}`, [vehicle]);
  const hasImage = useMemo(() => Boolean(vehicle.imageUrl), [vehicle.imageUrl]);
  const hasReport = useMemo(() => Boolean(vehicle.reportId), [vehicle.reportId]);

  return (
    <div className="d-flex track">
      <div className="flex-1 d-flex flex-column m-2 mx-sm-3 position-relative header-vehicle-info">
        {hasImage && (
          <div className="d-flex justify-content-center flex-1">
            <img
              alt="auto"
              src={vehicle.imageUrl}
              className="align-self-center mb-3 d-none d-md-block header-image"
            />
          </div>
        )}

        <span className="text-center mb-2 header-name">{name}</span>
        <span className="text-center border border-dark rounded mb-2 p-1 mx-3">
          <span className="text-center">{vehicle.vrm}</span>
        </span>

        {hasReport && (
          <ActionButton
            onClick={onViewReportClick}
            className="buy-report-button"
            color={ColorPalette.Secondary}
            id="compare-view-report-trackbutton"
          >
            View Report
          </ActionButton>
        )}

        {!hasReport && (
          <StripeContainer>
            <ShortListBuyReportButtonWrapper
              vrm={vehicle.vrm}
              onComplete={onComplete}
              onClick={onBuyReportClick}
              className="buy-report-button"
              id="compare-buy-report-trackbutton"
            />
          </StripeContainer>
        )}

        <button
          className="border-0 outline-none position-absolute p-0 bg-white remove-button"
          onClick={onRemoveClick}
        >
          <HighlightOffIcon />
        </button>
      </div>
    </div>
  );
}
