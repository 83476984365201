import { WPClient } from "../utils/http";
import { APP_WP_URL } from "../constants/AppConstants";

export const navbarAPI = {
  navbar() {
    return WPClient.get(`${APP_WP_URL}/wp-json/menus/v1/menus/main`)
      .then((response) => response)
      .catch((err) => err.response);
  },
};
