import qs from "qs";
import { DateTime } from "luxon";

import { Dict } from "../dto/AppDTO";

export const SHORT_DATE_FORMAT = "dd.MM.yyyy";

export function formatGroupNumber(value: number | undefined | null): string {
  if (!value) {
    return "";
  }

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function parseSearch<TData = any>(search = ""): TData & Dict<string> {
  return qs.parse(search.replace("?", "")) as TData & Dict<string>;
}

export function formatDate(date, options: any = {}) {
  return date.setLocale(options.locale || "en").toLocaleString(options.format);
}

export function formatSecondsToString(date, format = SHORT_DATE_FORMAT) {
  if (!date) {
    return;
  }

  return DateTime.fromSeconds(date).toFormat(format);
}

export function formatMillisecondsToString(
  date,
  format = SHORT_DATE_FORMAT,
  replaceValue = "",
): string {
  if (!date) {
    return replaceValue;
  }

  const dateTime = DateTime.fromMillis(date);

  return formatDate(dateTime, { format });
}
