import React, { useEffect, useMemo } from "react";

import { useResource } from "../../hooks/useResource";
import { usePurchaseContext } from "../../api/purchase/PurchaseContext";
import { UpgradePlanDialog, UpgradePlanDialogProps } from "./UpgradePlanDialog";
import { PaygSingleReport, PaygUpgrade } from "../../constants/ProductConstants";

export enum UpgradePlanType {
  Renew,
  Single,
  Upgrade,
}

interface Props extends Omit<UpgradePlanDialogProps, "product" | "hasSubscription"> {
  readonly type: UpgradePlanType;
}

export function UpgradePlanDialogWrapper({ type, open, ...props }: Props) {
  const { PurchaseApi } = usePurchaseContext();

  const purchaseOptionsResponse = useResource(() => PurchaseApi.getPurchaseOptions(), [], {
    skip: !open || type === UpgradePlanType.Single || type === UpgradePlanType.Upgrade,
  });

  const planProduct = useMemo(() => {
    const products = purchaseOptionsResponse.data?.products || [];

    if (type === UpgradePlanType.Single) {
      return PaygSingleReport;
    }

    if (type === UpgradePlanType.Upgrade) {
      return PaygUpgrade;
    }

    if (type === UpgradePlanType.Renew && products.length > 0) {
      return products[0];
    }
  }, [type, purchaseOptionsResponse.data?.products]);

  const openDialog = useMemo(() => Boolean(open && planProduct), [open, planProduct]);

  useEffect(() => {
    if (!purchaseOptionsResponse.loading && !planProduct) {
      props.onDismiss();
    }
  }, [planProduct, purchaseOptionsResponse.loading]);

  return <UpgradePlanDialog {...props} open={openDialog} product={planProduct} />;
}
