import "./assets/vehicle-no-report-item.scss";

import cx from "classnames";
import { isString } from "lodash";
import React, { isValidElement, ReactNode } from "react";

interface Props {
  readonly id: string;
  readonly bulletId?: string;
  readonly className?: string;
  readonly title: string | ReactNode;
}

export function VehicleNoReportItem({ id, title, bulletId, className }: Props) {
  return (
    <div
      className={cx(
        "d-flex align-items-center flex-nowrap mb-lg-2 flex-1 vehicle-no-report-item",
        className,
      )}
    >
      <span className="item-bullet" id={bulletId}>
        &#8226;
      </span>

      {isString(title) && (
        <span className="d-inline-flex px-3" id={id}>
          {title}
        </span>
      )}

      {isValidElement(title) && (
        <div className="px-3" id={id}>
          {title}
        </div>
      )}
    </div>
  );
}
