import "./assets/edit-vehicle-form.scss";

import { toFinite } from "lodash";
import React, { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

import { TextField } from "../form/TextField";
import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";
import { createNumberValidator } from "../../utils/ValidateUtils";
import { EditVehicleFormProps } from "../../api/vehicle/VehicleDTO";

interface Props {
  readonly onDismiss: () => void;
  readonly defaultValues?: Partial<EditVehicleFormProps>;
  readonly onSubmit: (values: EditVehicleFormProps) => Promise<void>;
}

export function EditVehicleForm({ onDismiss, defaultValues, onSubmit }: Props) {
  const { errors, control, formState, handleSubmit } = useForm<EditVehicleFormProps>({
    defaultValues,
    mode: "onBlur",
  });

  const formatRules = useCallback((title: string) => {
    const message = `${title} Value should be between 0 and 99999999`;

    return {
      min: {
        message,
        value: 0,
      },
      max: {
        message,
        value: 99999999,
      },
      required: false,
      validate: createNumberValidator(message),
    };
  }, []);

  return (
    <form
      className="d-flex flex-column m-3 edit-vehicle-form"
      onSubmit={handleSubmit((x) =>
        onSubmit({
          mileage: toFinite(x.mileage),
          listPrice: toFinite(x.listPrice),
        }),
      )}
    >
      {/*
          // @ts-ignore */}
      <Controller
        type="number"
        as={TextField}
        name="mileage"
        label="Mileage"
        className="mb-3"
        control={control}
        autoComplete="off"
        error={errors.mileage}
        rules={formatRules("Mileage")}
        defaultValue={defaultValues?.mileage}
      />
      {/*
          // @ts-ignore */}
      <Controller
        label="Price"
        type="number"
        as={TextField}
        name="listPrice"
        className="mb-3"
        control={control}
        autoComplete="off"
        error={errors.listPrice}
        rules={formatRules("Price")}
        defaultValue={defaultValues?.listPrice}
      />

      <div className="d-flex">
        <ActionButton
          fluid={true}
          className="mr-1"
          onClick={onDismiss}
          color={ColorPalette.MiddleGray}
        >
          Cancel
        </ActionButton>
        <ActionButton
          fluid={true}
          type="submit"
          className="bg-danger ml-1"
          color={ColorPalette.Danger}
          loading={formState.isSubmitting}
          disabled={formState.isSubmitting || !formState.isValid}
        >
          Update
        </ActionButton>
      </div>
    </form>
  );
}
