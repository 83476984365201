import qs from "qs";
import Cookies from "js-cookie";
import { isString } from "lodash";
import { DELETE } from "immupdate";
import { Auth, Hub } from "aws-amplify";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useQuery } from "../hooks/useQuery";
import { Analytics } from "../utils/Analytics";
import Circular from "../commons/Circular/Circular";
import { validToken } from "../utils/ValidateUtils";
import { useUserContext } from "../api/user/UserContext";
import { useAuthContext } from "../api/auth/AuthContext";
import { AuthState, ProductPlans } from "../dto/EnumDTO";
import { PRODUCTS } from "../components/form/ProductField";
import { useRegisterSocket } from "../hooks/useRegisterSocket";
import { AppRouteLayout } from "../components/app/AppRouteLayout";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { cognitoUserSelector, setCognitoUser } from "../reducers/userReducer";
import { SocialGroupContainer } from "../components/auth/SocialGroupContainer";
import { EmailAuthType, EmailAuthWrapper } from "../components/auth/EmailAuthWrapper";
import {
  changeAuthState,
  clearRegisterState,
  clearTemporaryRegisterVehicle,
  registerStateSelector,
  setTemporaryRegisterVehicle,
  setToken,
  temporaryRegisterVehicleSelector,
  tokenSelector,
} from "../reducers/authReducer";
import { createAmplifyConfig } from "../utils/AuthUtils";
import { APP_REDIRECT_SIGN_IN } from "../constants/AppConstants";

const analytics = new Analytics();

interface Query {
  readonly code?: string;
  readonly state?: string;

  readonly email?: string;
  readonly emailConfirmCode?: string;
  readonly emailAuthType?: EmailAuthType;
}

export function AuthCheckContainer() {
  const history = useHistory();
  const query = useQuery<Query>();

  const dispatch = useDispatch();

  const token = useShallowEqualSelector(tokenSelector);
  const cognitoUser = useShallowEqualSelector(cognitoUserSelector);
  const registerState = useShallowEqualSelector(registerStateSelector);
  const vehicle = useShallowEqualSelector(temporaryRegisterVehicleSelector);

  const [loading, setLoading] = useState(true);

  const { UserApi } = useUserContext();
  const { AuthApi, clearRegister } = useAuthContext();

  const isTrialProduct = useMemo(() => registerState?.product === ProductPlans.TrialSignUp, [
    registerState,
  ]);

  useEffect(() => {
    if (registerState?.vehicle) {
      dispatch(setTemporaryRegisterVehicle({ vehicle: registerState.vehicle }));
    }
  }, [dispatch, registerState]);

  const failureHandler = useCallback(
    (errorMessage?: string) => {
      Cookies.set("user_registered", "0", { domain: "carguide.co.uk" });

      history.replace(
        [
          "/auth/register",
          qs.stringify({
            errorMessage,
            product: registerState?.product,
            marketing: registerState?.marketing,
            vrm: registerState?.vehicle?.registration,
            reason:
              registerState?.reason && registerState?.reason !== "none"
                ? registerState.reason
                : undefined,
          }),
        ]
          .filter(Boolean)
          .join("?"),
      );

      clearRegister();

      setLoading(false);
    },
    [history, clearRegister, registerState],
  );

  const successHandler = useCallback(
    (user: any) => {
      if (user?.signInUserSession) {
        dispatch(setToken({ token: user?.signInUserSession }));
      }

      if (user?.username) {
        analytics.setGoogleVariable("userId", user.username);
      }

      if (user?.email) {
        analytics.setGoogleVariable("userEmail", user.email);
      }

      Cookies.set("user_registered", "1", { domain: "carguide.co.uk" });

      dispatch(clearRegisterState());
      dispatch(clearTemporaryRegisterVehicle());
      dispatch(changeAuthState({ authState: AuthState.Authorized }));
      analytics.setGoogleVariable("userId", user.username);
      if (Cookies.get("qst-answers")) {
        history.replace("/auth/checkout?product=trial_signup");
      } else {
        history.replace("/shortlist");
      }

      setLoading(false);
    },
    [dispatch, history],
  );

  const userFailureHandler = useCallback(
    (error: any, user: any, fromEmail?: boolean) => {
      if (error.status === 400) {
        if (isTrialProduct) {
          AuthApi.authCustomerTrial(
            {
              hasAcceptedTC: true,
              name: user.attributes.name,
              email: user.attributes.email,
              productId: ProductPlans.TrialSignUp,
              signupReason: registerState?.reason,
              vrm: registerState?.vehicle?.registration,
              hasMarketing: Boolean(registerState?.marketing),
            },
            {
              headers: { Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}` },
            },
          )
            .then(() => {
              if (!registerState?.vehicle?.registration) {
                successHandler(user);
              }

              analytics.facebookEvent({ event: "CompleteRegistration", value: 0 });
            })
            .catch((e) => failureHandler(e.message));
        } else {
          dispatch(setToken({ token: user.signInUserSession }));

          if (Cookies.get("qst-answers")) {
            history.replace("/auth/checkout?product=trial_signup");
          } else if (!registerState?.product && !Cookies.get("qst-asners")) {
            history.replace(
              `/auth/product?${qs.stringify({
                fromEmail: fromEmail ? "true" : DELETE,
                reason:
                  registerState?.reason && registerState?.reason !== "none"
                    ? registerState.reason
                    : DELETE,
              })}`,
            );
          } else {
            history.replace(
              `/auth/checkout?${qs.stringify({
                fromEmail: fromEmail ? "true" : DELETE,
                product: registerState?.product || DELETE,
                reason:
                  registerState?.reason && registerState?.reason !== "none"
                    ? registerState.reason
                    : DELETE,
              })}`,
            );
          }

          setLoading(false);

          dispatch(clearRegisterState());
        }
      } else {
        failureHandler(error.message);
      }
    },
    [history, AuthApi, dispatch, registerState, isTrialProduct, failureHandler, successHandler],
  );

  const userAccountHandler = useCallback(
    (user, fromEmail?: boolean) => {
      UserApi.getUserAccount({
        headers: { Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}` },
      })
        .then(() => {
          successHandler(user);
        })
        .catch((e) => {
          userFailureHandler(e, user, fromEmail);
        });
    },
    [UserApi, successHandler, userFailureHandler],
  );

  const checkUser = useCallback(
    (fromEmail?: boolean) => {
      Auth.currentAuthenticatedUser()
        .then((x) => userAccountHandler(x, fromEmail))
        .catch((e) => failureHandler(isString(e) ? e : e.message));
    },
    [userAccountHandler, failureHandler],
  );

  useEffect(() => {
    const isConfirm = Boolean(
      (query.email || query.emailAuthType) &&
        (query.emailAuthType === EmailAuthType.Confirm ||
          query.emailAuthType === EmailAuthType.ForgotPasswordSubmit),
    );

    if (validToken(token) && !isConfirm && !isTrialProduct) {
      checkUser();
    } else if (isConfirm) {
      setLoading(false);
    }
  }, [token, checkUser, isTrialProduct, query.emailAuthType, query.email, query.emailConfirmCode]);

  const hubListener = useCallback(
    ({ payload: { data, event } }) => {
      let newAuthState = event;

      switch (event) {
        case "signUI":
        case "cognitoHostedUI":
          newAuthState = "signedIn";

          dispatch(setCognitoUser({ user: data }));

          break;

        case "cognitoHostedUI_failure":
        case "parsingUrl_failure":
          failureHandler("Authentication failed");
          break;
      }

      if (newAuthState === "signedIn") {
        checkUser();
      }
    },
    [checkUser, dispatch, failureHandler],
  );

  useEffect(() => {
    Hub.listen("auth", hubListener);

    return () => Hub.remove("auth", hubListener);
  }, [hubListener]);

  const { progress } = useRegisterSocket({
    onError: failureHandler,
    reason: registerState?.reason,
    username: cognitoUser?.username,
    vehicle: registerState?.vehicle,
    onDone: () => successHandler(cognitoUser),
    skip: Boolean(!isTrialProduct || !cognitoUser || !vehicle),
    product: PRODUCTS.find(({ id }) => id === registerState?.product),
  });

  useEffect(() => {
    createAmplifyConfig(APP_REDIRECT_SIGN_IN!);
  }, []);

  return (
    <AppRouteLayout
      title="Check"
      withFooter={false}
      contentClassName="align-items-center justify-content-center"
    >
      {loading && (
        <Circular
          text={progress && progress >= 0 ? "Gathering information on your vehicle..." : ""}
        />
      )}

      {!loading && (
        <SocialGroupContainer progress={progress} className="overflow-hidden">
          <EmailAuthWrapper
            onlySignIn={true}
            progress={progress}
            authEmail={query.email}
            onEmailAuth={(user) => {
              dispatch(setCognitoUser({ user }));
              checkUser(true);
            }}
            emailConfirmCode={query.emailConfirmCode}
            initialType={query.emailAuthType || EmailAuthType.SignIn}
            onCancelClick={() => {
              history.replace("/auth/register");

              dispatch(clearRegisterState());
            }}
          />
        </SocialGroupContainer>
      )}
    </AppRouteLayout>
  );
}
