/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:088d3ab6-9683-4792-8b86-1a0a451458f2",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_RJQfZRYss",
    "aws_user_pools_web_client_id": "6gvusnfndjgl4l7fbe66j8jd1n",
    "oauth": {
        "domain": "carguided465e490-d465e490-prod.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/auth/register-b/checkout/,https://app.carguide.co.uk/auth/register-b/checkout/,https://app.staging.carguide.co.uk/auth/register-b/checkout/,http://localhost:3000/auth/check/,https://app.carguide.co.uk/auth/check/,https://app.staging.carguide.co.uk/auth/check/,https://localhost:3000/auth/check/,https://06c032a28ffb.ngrok.io/auth/check/,https://9b680d1d35c6.ngrok.io/auth/check/,https://5f6f6dd281a3.ngrok.io/auth/check/,https://5f6f6dd281a3.ngrok.io/auth/check/",
        "redirectSignOut": "http://localhost:3000/,https://app.carguide.co.uk/,https://app.staging.carguide.co.uk/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
