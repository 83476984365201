import { PDFDownloadLink } from "@react-pdf/renderer";
import React, { useState } from "react";

export const PDFDownload = ({ children }) => {
  const [download, setDownload] = useState(false);

  const switchDownload = () => {
    setDownload(true);
  };

  const pdfDownloadView = ({ error, loading }) => {
    if (error) {
      return "Loading failed";
    }

    if (loading) {
      return "Document creation...";
    }

    return "Download now!";
  };

  return (
    <div>
      <button style={{ width: "100%", padding: "10px", borderRadius: 5 }} onClick={switchDownload}>
        <img
          style={{ height: "20px", marginRight: "5px" }}
          src="https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg"
        />
        {download ? (
          <PDFDownloadLink document={children} fileName="report.pdf">
            {pdfDownloadView}
          </PDFDownloadLink>
        ) : (
          <span>Create PDF file</span>
        )}
      </button>
    </div>
  );
};
