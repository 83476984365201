export const APP_WP_URL = process.env.WP_URL;
export const APP_DEPLOY = process.env.DEPLOY;
export const APP_API_URL = process.env.API_URL;
export const AMAZON_URL = process.env.AMAZON_URL;
export const APP_HOME_URL = process.env.HOME_URL;
export const APP_SENTRY_DSN = process.env.SENTRY_DSN;
export const APP_LOGOUT_URL = process.env.LOGOUT_URL;
export const APP_BRANCH_NAME = process.env.BRANCH_NAME;
export const APP_WEBSOCKET_URL = process.env.WEBSOCKET_URL;
export const APP_STRIPE_PK_KEY = process.env.STRIPE_PK_KEY;
export const APP_REDIRECT_SIGN_IN = process.env.REDIRECT_SIGN_IN;
export const APP_REDIRECT_SIGN_OUT = process.env.REDIRECT_SIGN_OUT;
export const APP_SALVAGE_IMAGE_URL = process.env.SALVAGE_IMAGE_URL;
export const APP_REDIRECT_SIGN_IN_B = process.env.REDIRECT_SIGN_IN_B;

export const APP_TAG_MANAGER_APP_ID = process.env.TAG_MANAGER_APP_ID;
export const APP_INTERCOM_IDENTITY_KEY = process.env.INTERCOM_IDENTITY_KEY;

export const IS_STAGING = APP_DEPLOY === "staging";
export const IS_PRODUCTION = APP_DEPLOY === "prod";
export const IS_DEV = process.env.NODE_ENV === "development";
