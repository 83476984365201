import "./assets/app-header.scss";

import cx from "classnames";
import Cookies from "js-cookie";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import React, { useMemo, useRef, useState } from "react";

import { AuthState } from "../../dto/EnumDTO";
import { Routes } from "../../constants/Routes";
import LogoImage from "./assets/carguide_logo.svg";
import { AppDropDownItem } from "./AppDropDownItem";
import { AppMobileMenuButton } from "./AppMobileMenuButton";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import AppLogoutConfirmationModal from "./AppLogoutConfirmationModal";

AppHeader.propTypes = {
  data: PropTypes.object,
  onSignOut: PropTypes.func,
  authState: PropTypes.string,
  hasAuthorized: PropTypes.bool,
};

export function AppHeader({ data, onSignOut, hasAuthorized, authState }) {
  const ref = useRef(null);

  const location = useLocation();

  const [openMenu, setOpenMenu] = useState(false);
  const [showSignOutModal, setShowSignOutModal] = useState(false);

  const firstItem = useMemo(() => data && data.items && data.items[0], [data]);
  const secondItem = useMemo(() => data && data.items && data.items[1], [data]);
  const thirdItem = useMemo(() => data && data.items && data.items[2], [data]);
  const fourItem = useMemo(() => data && data.items && data.items[3], [data]);

  const registerRoute = useMemo(() => location.pathname.indexOf("/auth") >= 0, [location.pathname]);
  const registerBRoute = useMemo(() => location.pathname.indexOf(Routes.AuthRegister) === 0, [
    location.pathname,
  ]);
  const showAuthMenu = useMemo(
    () => Boolean(authState === AuthState.Authorized && hasAuthorized && !registerRoute),
    [authState, registerRoute, hasAuthorized],
  );

  useOutsideClick({
    ref,
    active: openMenu,
    onClick: () => setOpenMenu(false),
  });

  return (
    <div className="d-flex flex-column app-header">
      <div ref={ref} className="d-flex flex-1 position-relative header-wrapper">
        <div className="container d-flex align-items-center justify-content-between">
          <Link to="/">
            <img width={200} src={LogoImage} alt="logo" />
          </Link>

          <div className="d-none d-lg-flex">
            {firstItem && (
              <a className="d-flex py-1 px-3 align-items-center header-item" href={firstItem.url}>
                {firstItem.title}
              </a>
            )}

            {secondItem && (
              <AppDropDownItem title={secondItem.title} items={secondItem.child_items} />
            )}

            {thirdItem && <AppDropDownItem title={thirdItem.title} items={thirdItem.child_items} />}

            {fourItem && <AppDropDownItem title={fourItem.title} items={fourItem.child_items} />}

            {showAuthMenu && (
              <>
                <Link
                  to="/shortlist"
                  onClick={() => setOpenMenu(false)}
                  className="d-flex py-1 px-3 align-items-center header-item"
                >
                  My Shortlist
                </Link>

                <AppDropDownItem
                  title="My Account"
                  items={[
                    { title: "My Details", url: "/account", native: true },
                    { title: "Sign Out", onClick: () => setShowSignOutModal(true) },
                  ]}
                />
              </>
            )}

            {Boolean((!showAuthMenu && !registerRoute) || registerBRoute) && (
              <Link
                to="/auth/register"
                className={cx("d-flex py-1 px-3 align-items-center header-item", {
                  "bg-danger br-1 text-white text-decoration-none": registerBRoute,
                })}
              >
                Sign-In
              </Link>
            )}
          </div>

          <div className="d-flex d-lg-none">
            <AppMobileMenuButton value={openMenu} onClick={() => setOpenMenu(x => !x)} />
          </div>
        </div>

        {openMenu && (
          <div
            className="d-block d-lg-none bg-white px-3 w-100 position-absolute overflow-hidden"
            style={{ top: "85px", zIndex: 100, boxShadow: "0 9px 10px -10px grey" }}
          >
            <div
              id="MobileMenuContent"
              className="d-flex flex-column py-4 overflow-auto"
              style={{ borderTop: "1px solid rgba(0,0,0,0.1)" }}
            >
              {firstItem && (
                <a className="d-flex py-1 px-3 align-items-center header-item" href={firstItem.url}>
                  {firstItem.title}
                </a>
              )}

              {secondItem && (
                <AppDropDownItem
                  absolute={false}
                  title={secondItem.title}
                  items={secondItem.child_items}
                />
              )}

              {thirdItem && (
                <AppDropDownItem
                  absolute={false}
                  title={thirdItem.title}
                  items={thirdItem.child_items}
                />
              )}

              {fourItem && (
                <AppDropDownItem
                  absolute={false}
                  title={fourItem.title}
                  items={fourItem.child_items}
                />
              )}

              {showAuthMenu && (
                <>
                  <Link
                    to="/shortlist"
                    onClick={() => setOpenMenu(false)}
                    className="d-flex py-1 px-3 align-items-center header-item"
                  >
                    My Shortlist
                  </Link>

                  <AppDropDownItem
                    absolute={false}
                    title="My Account"
                    onClose={() => setOpenMenu(false)}
                    items={[
                      { title: "My Details", url: "/account", native: true },
                      { title: "Sign Out", onClick: () => setShowSignOutModal(true) },
                    ]}
                  />
                </>
              )}

              {Boolean((!showAuthMenu && !registerRoute) || registerBRoute) && (
                <Link
                  to="/auth/register?emailAuthType=sign-in"
                  className="d-flex py-1 px-3 align-items-center header-item"
                >
                  Sign-In
                </Link>
              )}
            </div>
          </div>
        )}
      </div>

      <AppLogoutConfirmationModal
        open={showSignOutModal}
        onCancel={() => setShowSignOutModal(false)}
        onSubmit={() => {
          Cookies.set("user_registered", "0", { domain: "carguide.co.uk" });

          setShowSignOutModal(false);
          onSignOut();
        }}
      />
    </div>
  );
}
