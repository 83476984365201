import styled from "styled-components";

export const NavigationContainer = styled.div`
  background: #22a6b3;
  height: 70px;
  color: #fff;
  display: flex;
  justify-content: center;

  @media (min-width: 320px) and (max-width: 480px) {
    height: 70px;
  }

  .navigation-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 960px;

    .left-arrow-icon {
      margin: 0 10px 0 0;
    }
    .print-icon {
      margin: 0 0 0 10px;
    }
    .navigation-title {
      font-weight: 500;
      font-size: 26px;
      margin: 0;
    }

    @media (min-width: 1200px) {
      width: 1140px;
    }

    @media (min-width: 320px) and (max-width: 480px) {
      .button-text {
        display: none;
      }
      .left-arrow-icon {
        margin: 0 0 0 20px;
      }
      .print-icon {
        margin: 0 20px 0 0;
      }
      .navigation-title {
        font-size: 22px;
      }
    }

    .navigation-back-button {
      cursor: pointer;
      display: flex;
    }
    .navigation-print-button {
      cursor: pointer;
      display: flex;
      visibility: hidden;
    }
  }
`;
