import styled from "styled-components";

export const Container = styled.div`
  .mileage-title {
    font-size: 20px;
    font-weight: 500;
    margin: 0 0 5px;
  }
  .mileage-description {
    margin: 0 0 15px;
  }
  .mileage-chart {
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.1);
    margin: 0 0 15px;

    .chart-header {
      color: #fff;
      font-weight: 500;
      background: #22a6b3;
      border-radius: 5px 5px 0 0;
      padding: 20px 30px;
      font-size: 18px;
    }
  }
  .mileage-note {
    a {
      color: #30336b;
      text-decoration: underline;
    }
  }

  &.is-dangerous {
    .chart-header {
      background: #eb4d4b;
    }
  }
`;
