import "./assets/auth-vehicle-reason.scss";

import React from "react";
import cx from "classnames";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

interface Props {
  readonly error?: boolean;
  readonly reason?: string;
  readonly onChange: (reason: string) => void;
}

export function AuthVehicleReason({ error, reason, onChange }: Props) {
  return (
    <FormControl
      error={error}
      variant="outlined"
      id="VehicleReasonControl"
      className="mb-4 auth-vehicle-reason"
    >
      <InputLabel
        id="choose"
        className={cx("reason-label", {
          "none-reason-label": Boolean(!reason),
          "px-2 bg-white": Boolean(reason),
        })}
      >
        Is this your vehicle or are you looking at buying it?
      </InputLabel>
      <Select
        value={reason}
        labelId="choose"
        className={cx({ "text-gray": reason === "none" })}
        onChange={({ target }) => onChange(target.value as string)}
      >
        <MenuItem value="none">Pick an option</MenuItem>
        <MenuItem value="I’ve had it for a while">I’ve had it for a while</MenuItem>
        <MenuItem value="I’ve just bought it">I’ve just bought it</MenuItem>
        <MenuItem value="I’m buying it today">I’m buying it today</MenuItem>
        <MenuItem value="I’m buying it this week">I’m buying it this week</MenuItem>
        <MenuItem value="I’m just looking around">I’m just looking around</MenuItem>
      </Select>
    </FormControl>
  );
}
