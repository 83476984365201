import React from "react";
import moment from "../../../../utils/moment";
import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import { ISSUE_PREDICTION_RISK_THRESHOLD } from "../../MOTs/Likelihood/index";
import { MOT_STATUS, toOdometerUnit } from "../../MOTs";
import { getRisklevel } from "../../MOTs/Likelihood/index";
import { capitalize } from "../../../../utils/string";
import { PageLayout } from "./PageLayout";

import checkIcon from "../assets/check-circle.png";
import closeIcon from "../assets/close-circle.png";
import { motsPageStyles } from "../styles/motsPageStyles";

const styles = StyleSheet.create(motsPageStyles);

export const MOTsPage = ({ report }) => {
  const { motTests = [] } = report.mots || {};
  const { vehicle, mots: { predictedIssues } = {} } = report;

  return (
    <PageLayout id={report.id}>
      <View>
        <Text style={styles.pageTitle}>MOTS</Text>
        <Text style={styles.motTitle}> Future MOTs </Text>

        <View>
          {predictedIssues && predictedIssues.length ? (
            <View>
              <Text style={styles.orderText}> The next MOT is due on: </Text>

              <View style={styles.motDateBlock}>
                <Image style={styles.icon} src={checkIcon} />
                <Text>
                  {moment(vehicle.motDueDate).fromNow()} left on current MOT ({vehicle.motDueDate})
                </Text>
              </View>
            </View>
          ) : null}
          <Text style={[styles.orderText, { textAlign: "center" }]}>
            We've used the impair.ai platform to predict the likelihood of issues that may occur on
            this vehicle in the next 12 months.
          </Text>

          <View style={styles.issueBlock}>
            {predictedIssues.map((issue, index) => {
              const isDangerous = issue.risk >= ISSUE_PREDICTION_RISK_THRESHOLD;
              const advisoryLevel = getRisklevel(issue.advisory);
              const nextMOTfailureLevel = getRisklevel(issue.fail);

              const currentBg = isDangerous ? styles.dangerousBg : styles.secondaryBg;

              return (
                <View key={index} style={styles.issueCard}>
                  <View style={[styles.issueCardTitle, currentBg]}>
                    <Text>
                      {capitalize(issue.component)} - {issue.risk}% likelihood of issues
                    </Text>
                  </View>
                  <Text style={styles.issueCardContent}>
                    There’s a {advisoryLevel} chance of an advisory note being issued and a{" "}
                    {nextMOTfailureLevel} chance of a failure at the next MOT.
                  </Text>
                </View>
              );
            })}
          </View>
        </View>

        <View break={true}>
          <Text style={styles.motTitle}>Previous MOTs</Text>
          {motTests && motTests.length ? (
            <Text style={styles.orderText}>
              See the results of previous MOTs. Please pay attention to any items that were marked
              as dangerous and major as well as advisories.
            </Text>
          ) : (
            <Text style={styles.orderText}>This vehicle hasn't had an MOT yet.</Text>
          )}
          <View style={styles.previousMOTsBlock}>
            {(motTests &&
              motTests.length &&
              motTests.map((mot, index) => {
                const isPassed = mot.testResult === MOT_STATUS.PASSED;
                const odometerValue = mot.odometerValue;
                const odometerUnit = toOdometerUnit(mot.odometerUnit);

                return (
                  <View wrap={false} key={index} style={styles.prevMOT}>
                    <View>
                      <Image style={styles.icon} src={isPassed ? checkIcon : closeIcon} />
                    </View>
                    <View>
                      <Text>
                        {capitalize(mot.testResult)} {mot.completedDate}
                      </Text>
                      <Text>
                        Mileage: {odometerValue} {capitalize(odometerUnit)}
                      </Text>
                      <View style={styles.commentBlock}>
                        {mot.comments.length ? (
                          <View>
                            {mot.comments.map((comment, i) => {
                              let currentBg = {};
                              let textColor = {};
                              switch (comment.type) {
                                case "FAIL":
                                case "DANGEROUS":
                                  currentBg = styles.dangerousBg;
                                  textColor = styles.failText;
                                  break;
                                case "MAJOR":
                                  currentBg = styles.warningBg;
                                  break;
                                default:
                                  currentBg = styles.normalBg;
                                  break;
                              }

                              return (
                                <View key={`${index}-${i}`} style={styles.comment}>
                                  <Text style={textColor}> - {comment.text}</Text>
                                  <View>
                                    <Text style={[styles.commentType, currentBg]}>
                                      {capitalize(comment.type)}
                                    </Text>
                                  </View>
                                </View>
                              );
                            })}
                          </View>
                        ) : (
                          <Text>No comments</Text>
                        )}
                      </View>
                    </View>
                  </View>
                );
              })) ||
              null}
          </View>
        </View>
      </View>
    </PageLayout>
  );
};
