import moment from "../utils/moment";

export const SORTING_ORDER = Object.freeze({
  PRICE: "price",
  MILEAGE: "mileage",
  MANUFACTURE_DATE: "dateOfManufacture",
  MPG: "combinedMPG",
  COST_PER_MILE: "costPerMile",
  BOOT_CAPACITY: "bootCapacity",
});

export const VEHICLE_CATEGORY_INDEX = Object.freeze({
  BASIC_DETAILS: "basicDetails",
  COMPONENT_DETAILS: "componentDetails",
  DIMENSIONS: "dimensions",
  ECONOMY: "economy",
  ENGINE_SPECS: "engineSpecs",
  IDENTITY: "identity",
  KEY_DATES: "keyDates",
  COMPONENT_RISKS: "componentRisks",
  EV_DETAILS: "evDetails",
  PROVENANCE: "provenance",
});

export const FIELD_TYPE = Object.freeze({
  BOOLEAN: "boolean",
});

export const SORTING_ITEMS = [
  {
    title: "Price",
    value: SORTING_ORDER.PRICE,
    category: VEHICLE_CATEGORY_INDEX.BASIC_DETAILS,
  },
  {
    title: "Mileage",
    value: SORTING_ORDER.MILEAGE,
    category: VEHICLE_CATEGORY_INDEX.BASIC_DETAILS,
  },
  {
    title: "Date of Manufacture",
    value: SORTING_ORDER.MANUFACTURE_DATE,
    category: VEHICLE_CATEGORY_INDEX.BASIC_DETAILS,
  },
  {
    title: "MPG (Combined)",
    value: SORTING_ORDER.MPG,
    category: VEHICLE_CATEGORY_INDEX.ECONOMY,
  },
  {
    title: "Fuel cost per mile",
    value: SORTING_ORDER.COST_PER_MILE,
    category: VEHICLE_CATEGORY_INDEX.BASIC_DETAILS,
  },
  {
    title: "Boot Capacity",
    value: SORTING_ORDER.BOOT_CAPACITY,
    category: VEHICLE_CATEGORY_INDEX.DIMENSIONS,
  },
];

export const MAX_VEHICLES_IN_COMPARISON = 5;

export const SORTING_CRITERIA = {
  [SORTING_ORDER.MILEAGE]: (first, second) =>
    first[VEHICLE_CATEGORY_INDEX.BASIC_DETAILS].mileage -
    second[VEHICLE_CATEGORY_INDEX.BASIC_DETAILS].mileage,
  [SORTING_ORDER.PRICE]: (first, second) =>
    first[VEHICLE_CATEGORY_INDEX.BASIC_DETAILS].price -
    second[VEHICLE_CATEGORY_INDEX.BASIC_DETAILS].price,
  [SORTING_ORDER.MANUFACTURE_DATE]: (first, second) =>
    moment(second[VEHICLE_CATEGORY_INDEX.BASIC_DETAILS].dateOfManufacture) -
    moment(first[VEHICLE_CATEGORY_INDEX.BASIC_DETAILS].dateOfManufacture),
  [SORTING_ORDER.MPG]: (first, second) =>
    second[VEHICLE_CATEGORY_INDEX.BASIC_DETAILS].combinedMpg -
    first[VEHICLE_CATEGORY_INDEX.BASIC_DETAILS].combinedMpg,
  [SORTING_ORDER.COST_PER_MILE]: (first, second) =>
    first[VEHICLE_CATEGORY_INDEX.BASIC_DETAILS].costPerMile -
    second[VEHICLE_CATEGORY_INDEX.BASIC_DETAILS].costPerMile,
  [SORTING_ORDER.BOOT_CAPACITY]: (first, second) =>
    second[VEHICLE_CATEGORY_INDEX.DIMENSIONS].bootCapacity -
    first[VEHICLE_CATEGORY_INDEX.DIMENSIONS].bootCapacity,
};

export const VEHICLE_CATEGORIES = [
  {
    title: "Provenance",
    dataIndex: [VEHICLE_CATEGORY_INDEX.PROVENANCE],
    hasUpsellBoxes: true,
  },
  {
    title: "Basic Details",
    dataIndex: [VEHICLE_CATEGORY_INDEX.BASIC_DETAILS],
  },
  {
    title: "Component Details",
    dataIndex: [VEHICLE_CATEGORY_INDEX.COMPONENT_DETAILS],
  },
  {
    title: "Dimensions",
    dataIndex: [VEHICLE_CATEGORY_INDEX.DIMENSIONS],
  },
  {
    title: "Economy",
    dataIndex: [VEHICLE_CATEGORY_INDEX.ECONOMY],
  },
  {
    title: "Engine Specs",
    dataIndex: [VEHICLE_CATEGORY_INDEX.ENGINE_SPECS],
  },
  {
    title: "Identity",
    dataIndex: [VEHICLE_CATEGORY_INDEX.IDENTITY],
  },
  {
    title: "Key Dates",
    dataIndex: [VEHICLE_CATEGORY_INDEX.KEY_DATES],
  },
  {
    title: "EV Details",
    dataIndex: [VEHICLE_CATEGORY_INDEX.EV_DETAILS],
  },
];

export const VEHICLE_CATEGORY_FIELDS = {
  [VEHICLE_CATEGORY_INDEX.PROVENANCE]: [
    {
      title: "Outstanding finance",
      dataIndex: "hasOutstandingFinance",
      type: FIELD_TYPE.BOOLEAN,
    },
    {
      title: "Write-off",
      dataIndex: "hasWrittenOffMarker",
      type: FIELD_TYPE.BOOLEAN,
    },
    {
      title: "Stolen",
      dataIndex: "hasStolenMarker",
      type: FIELD_TYPE.BOOLEAN,
    },
    {
      title: "Scrapped",
      dataIndex: "hasScrappedMarker",
      type: FIELD_TYPE.BOOLEAN,
    },
    {
      title: "Imported",
      dataIndex: "hasImportedMarker",
      type: FIELD_TYPE.BOOLEAN,
    },
    {
      title: "Exported",
      dataIndex: "hasExportedMarker",
      type: FIELD_TYPE.BOOLEAN,
    },
    {
      title: "Outstanding recalls",
      dataIndex: "hasOutstandingRecalls",
      type: FIELD_TYPE.BOOLEAN,
    },
    {
      title: "Number of owners",
      dataIndex: "numberOfKeepers",
    },
    {
      title: "Previous colours",
      dataIndex: "hasPreviousColours",
      type: FIELD_TYPE.BOOLEAN,
    },
  ],
  [VEHICLE_CATEGORY_INDEX.BASIC_DETAILS]: [
    {
      title: "Price",
      dataIndex: "listPrice",
      format: (value) => (!value ? "N/A" : value),
    },
    {
      title: "Market Value",
      dataIndex: "marketPrice",
      format: (value, hasReport) => (!value || !hasReport ? "N/A" : value),
    },
    {
      title: "Mileage",
      dataIndex: "mileage",
    },
    {
      title: "Colour",
      dataIndex: "colour",
      format: (value) => value.charAt(0).toUpperCase() + value.substring(1).toLowerCase(),
    },
    {
      title: "Manufacture Date",
      dataIndex: "dateOfManufacture",
    },
    {
      title: "MOT Due",
      dataIndex: "motStatus",
    },
    {
      title: "Body Style",
      dataIndex: "bodyStyle",
    },
    {
      title: "Fuel Type",
      dataIndex: "fuelType",
      format: (value) => value.charAt(0).toUpperCase() + value.substring(1).toLowerCase(),
    },
    {
      title: "Doors",
      dataIndex: "doors",
    },
    {
      title: "CC",
      dataIndex: "cc",
    },
    {
      title: "Seats",
      dataIndex: "seats",
    },
    {
      title: "Transmission Type",
      dataIndex: "transmissionType",
    },
    {
      title: "Drive Type",
      dataIndex: "driveType",
    },
    {
      title: "Fuel cost per mile",
      dataIndex: "costPerMile",
      format: (value) => {
        if (!value) {
          return "";
        }

        return `${Math.round(value)}p`;
      },
    },
    {
      title: "Combined MPG",
      dataIndex: "combinedMpg",
    },
  ],
  [VEHICLE_CATEGORY_INDEX.COMPONENT_DETAILS]: [
    {
      title: "Spare Tyre",
      dataIndex: "spareTyre",
    },
    {
      title: "Front Tyres",
      dataIndex: "frontTyres",
    },
    {
      title: "Rear Tyres",
      dataIndex: "rearTyres",
    },
    {
      title: "Front Brakes",
      dataIndex: "frontBrakes",
    },
    {
      title: "Rear Brakes",
      dataIndex: "rearBrakes",
    },
  ],
  [VEHICLE_CATEGORY_INDEX.DIMENSIONS]: [
    {
      title: "Boot Capacity (litres)",
      dataIndex: "bootCapacity",
      suffix: " litres",
    },
    {
      title: "Tank Size (litres)",
      dataIndex: "tankSize",
    },
    {
      title: "Length (mm)",
      dataIndex: "length",
    },
    {
      title: "Width (mm)",
      dataIndex: "width",
    },
    {
      title: "Height (mm)",
      dataIndex: "height",
    },
    {
      title: "Wheel Base (mm)",
      dataIndex: "wheelbase",
    },
    {
      title: "Gross Weight (kg)",
      dataIndex: "grossVehicleWeight",
    },
    {
      title: "Ground Clearance (mm)",
      dataIndex: "groundClearance",
    },
  ],
  [VEHICLE_CATEGORY_INDEX.ECONOMY]: [
    {
      title: "Combined MPG",
      dataIndex: "combinedMPG",
    },
    {
      title: "Urban Cold MPG",
      dataIndex: "urbanColdMPG",
    },
    {
      title: "Extra Urban MPG",
      dataIndex: "extraUrbanMPG",
    },
    {
      title: "Acceleration MPH",
      dataIndex: "accelerationMPH",
    },
    {
      title: "BHP",
      dataIndex: "bhp",
    },
    {
      title: "Torque LBFT",
      dataIndex: "torqueLBFT",
    },
    {
      title: "Torque NM",
      dataIndex: "torqueNM",
    },
    {
      title: "Torque RPM",
      dataIndex: "torqueRPM",
    },
  ],
  [VEHICLE_CATEGORY_INDEX.ENGINE_SPECS]: [
    {
      title: "Cylinders",
      dataIndex: "cylinders",
    },
    {
      title: "Cylinder Arrangement",
      dataIndex: "cylinderArrangement",
    },
    {
      title: "Valves",
      dataIndex: "valves",
    },
    {
      title: "Fuel Delivery",
      dataIndex: "fuelDelivery",
    },
    {
      title: "Engine Make",
      dataIndex: "engineMake",
    },
    {
      title: "Engine Code",
      dataIndex: "engineCode",
    },
    {
      title: "Oil Capacity",
      dataIndex: "oilCapacity",
    },
  ],
  [VEHICLE_CATEGORY_INDEX.IDENTITY]: [
    {
      title: "VIN",
      dataIndex: "vin",
    },
    {
      title: "Engine Number",
      dataIndex: "engineNumber",
    },
    {
      title: "Country Origin",
      dataIndex: "countryOfOrigin",
      format: (value = []) => {
        if (!value) {
          return "";
        }

        return value
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
          .join(" ");
      },
    },
  ],
  [VEHICLE_CATEGORY_INDEX.KEY_DATES]: [
    {
      title: "Date Of Registration",
      dataIndex: "dateOfRegistration",
    },
    {
      title: "MOT Expiry",
      dataIndex: "motExpiry",
    },
    {
      title: "Manufacture Date",
      dataIndex: "manufactureDate",
    },
    {
      title: "Tax Expiry",
      dataIndex: "taxExpiry",
    },
    {
      title: "Manufacturer Warranty (Mechanical)",
      dataIndex: "warrantyMLExpiry",
    },
    {
      title: "Manufacturer Warranty (Corrosion)",
      dataIndex: "warrantyBYExpiry",
    },
  ],
  [VEHICLE_CATEGORY_INDEX.COMPONENT_RISKS]: [
    {
      title: "Anti Roll Bar",
      dataIndex: "antiRollBar",
    },
    {
      title: "Body",
      dataIndex: "body",
    },
    {
      title: "Brake",
      dataIndex: "brake",
    },
    {
      title: "Brake Light",
      dataIndex: "brakeLight",
    },
    {
      title: "Chassis Rail",
      dataIndex: "chassisRail",
    },
    {
      title: "Clutch",
      dataIndex: "clutch",
    },
    {
      title: "CV Joint",
      dataIndex: "cvJoint",
    },
    {
      title: "Engine",
      dataIndex: "engine",
    },
    {
      title: "Exhaust",
      dataIndex: "exhaust",
    },
    {
      title: "Fog Lamp",
      dataIndex: "fogLamp",
    },
    {
      title: "Fuel Filter",
      dataIndex: "fuelFilter",
    },
    {
      title: "Gear Box",
      dataIndex: "gearbox",
    },
    {
      title: "Hazard Light",
      dataIndex: "hazardLight",
    },
    {
      title: "Head Lamp",
      dataIndex: "headLamp",
    },
    {
      title: "Head Light",
      dataIndex: "headlight",
    },
    {
      title: "Indicator",
      dataIndex: "indicator",
    },
    {
      title: "Mirror",
      dataIndex: "mirror",
    },
    {
      title: "Number Plate",
      dataIndex: "numberPlate",
    },
    {
      title: "Oil",
      dataIndex: "oil",
    },
    {
      title: "Pads",
      dataIndex: "pads",
    },
    {
      title: "Pedal",
      dataIndex: "pedal",
    },
    {
      title: "Reflection",
      dataIndex: "reflection",
    },
    {
      title: "Seat Belt",
      dataIndex: "seatBelt",
    },
    {
      title: "Shock Absorber",
      dataIndex: "shockAbsorber",
    },
    {
      title: "Sill",
      dataIndex: "sill",
    },
    {
      title: "Steering",
      dataIndex: "steering",
    },
    {
      title: "Strut",
      dataIndex: "strut",
    },
    {
      title: "Subframe",
      dataIndex: "subframe",
    },
    {
      title: "Suspension",
      dataIndex: "suspension",
    },
    {
      title: "Track Rod",
      dataIndex: "trackRod",
    },
    {
      title: "Trailing Arm",
      dataIndex: "trailingArm",
    },
    {
      title: "Tyre",
      dataIndex: "tyre",
    },
    {
      title: "Underside",
      dataIndex: "undersideOfVehicle",
    },
    {
      title: "Structure",
      dataIndex: "vehicleStructure",
    },
    {
      title: "Wheel Bearing",
      dataIndex: "wheelBearing",
    },
    {
      title: "Wind Screen",
      dataIndex: "windscreen",
    },
  ],
  [VEHICLE_CATEGORY_INDEX.EV_DETAILS]: [
    {
      title: "Battery Leased",
      dataIndex: "batteryLeased",
    },
    {
      title: "Battery Voltage",
      dataIndex: "batteryVoltage",
    },
    {
      title: "Battery Capacity",
      dataIndex: "batteryCapacity",
    },
    {
      title: "Emission Free Range",
      dataIndex: "emissionFreeRange",
    },
    {
      title: "Miles Per Kwh",
      dataIndex: "milesPerKwh",
    },
  ],
};
