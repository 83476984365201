import { Auth } from "aws-amplify";
import React, { useEffect } from "react";
import { useHistory } from "react-router";

import { useIntercom } from "../hooks/useIntercom";
import Circular from "../commons/Circular/Circular";
import { useAuthContext } from "../api/auth/AuthContext";
import { APP_LOGOUT_URL } from "../constants/AppConstants";
import { usePageViewEvent } from "../hooks/usePageViewEvent";
import { AppRouteLayout } from "../components/app/AppRouteLayout";

export function LogoutContainer() {
  useIntercom();

  usePageViewEvent("CarGuide - Logout");

  const history = useHistory();

  const { clearRegister } = useAuthContext();

  useEffect(() => {
    localStorage.clear();

    Auth.signOut({ global: true }).then(() => (location.href = APP_LOGOUT_URL!));
  }, [history, clearRegister]);

  return (
    <AppRouteLayout withFooter={false} title="Logout">
      <Circular />
    </AppRouteLayout>
  );
}
