import "./assets/compare-sticky-header.scss";

import React, { useMemo } from "react";
import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { countCompareVehiclesSelector } from "../../reducers/vehicleReducer";
import { DeviceType, OrientationType, useMediaQueryLegacy } from "../../hooks/useMediaQueryLegacy";

interface Props {
  readonly vehicleCount: number;
  readonly onCompareClick: () => void;
}

export function CompareStickyHeader({ onCompareClick, vehicleCount }: Props) {
  const mediaQuery = useMediaQueryLegacy();

  const isMobile = useMemo(
    () =>
      mediaQuery.device === DeviceType.Mobile &&
      mediaQuery.orientation === OrientationType.Portrait,
    [mediaQuery],
  );

  const count = useShallowEqualSelector(countCompareVehiclesSelector);
  const vehicleCountText = useMemo(() => {
    if (isMobile) {
      return "two";
    }

    return "four";
  }, [isMobile]);

  const text = useMemo(() => {
    if (vehicleCount === 1 && isMobile) {
      return "Add another vehicle for free";
    }

    return `Choose up to ${vehicleCountText} vehicles and then click "Compare" to see how they stack up.`;
  }, [vehicleCount, vehicleCountText, isMobile]);

  const buttonText = useMemo(() => {
    if (vehicleCount === 1 && isMobile) {
      return "Click here";
    }

    return count > 0 ? `Compare (${count})` : "Compare";
  }, [count, vehicleCount, isMobile]);

  return (
    <div className="d-flex flex-column position-sticky py-4 bg-white compare-sticky-header">
      <div className="container d-flex flex-1 flex-column flex-md-row compare-container">
        <div className="d-flex flex-1 align-items-center mb-2 justify-content-center justify-content-md-start">
          <span className="text-center text-md-left header-sub-title-text">{text}</span>
        </div>

        <div className="d-flex align-items-center flex-column">
          <ActionButton
            className="compare-button"
            color={ColorPalette.Danger}
            onClick={() => onCompareClick()}
            disabled={count === 0 && vehicleCount !== 1}
          >
            {buttonText}
          </ActionButton>
        </div>
      </div>
    </div>
  );
}
