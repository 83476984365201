import { Logger } from "./Logger";
import { Dict } from "../dto/AppDTO";
import { tryStringifyJSON } from "./DataUtils";

export enum IntercomActions {
  VehicleLookup = "Vehicle Lookup",
  VehicleScrape = "Vehicle Scrape",
  ReportPurchased = "Report Purchased",
  AccountUpgraded = "Account Upgraded",
  ComparisonViewed = "Comparison Viewed",
  VehicleAddedToShortlist = "Vehicle Added to shortlist",
  VehicleDeletedFromShortlist = "Vehicle Deleted from shortlist",
}

export function intercomEvent(
  action: IntercomActions,
  meta: Dict<string | number>,
  debug?: boolean,
) {
  // @ts-ignore
  const { Intercom } = window;

  if (Intercom) {
    Intercom("trackEvent", action, meta);

    if (debug) {
      const logger = new Logger("Intercom");

      logger.log(`Sent event: ${action}`, tryStringifyJSON(meta));
    }
  }
}
