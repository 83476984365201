import {useEffect} from "react";
import {useLocation} from "react-router-dom";

import {Analytics} from "../utils/Analytics";

export function usePageViewEvent(title: string) {
  const location = useLocation();

  useEffect(() => {
    const analytics = new Analytics();

    if (location) {
      if (location.pathname == "/auth/register"
          || location.pathname == "/auth/register-b"
          || location.pathname == "/auth/product"
          || location.pathname == "/auth/check"
          || location.pathname == "/auth/register-b/checkout/"
          || location.pathname == "/shortlist"
          || location.pathname == "/account") {

        analytics.setGoogleVariable('referrer', 'https://carguide.co.uk');
      }
    }

    analytics.googleEvent("pageview", {
      title,
      location: location.pathname,
    });
  }, [location.pathname, title]);
}
