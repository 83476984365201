import React from 'react';

export default function NoData(props) {
  return (
    <React.Fragment>
      Unfortunately we don’t currently have this data for this
      vehicle. Please get in touch with the chat icon below.
    </React.Fragment>
  );
}
