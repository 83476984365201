import React from "react";

interface Props {
  readonly title: string;
  readonly value?: string;
}

export function VehicleSalvageItem({ title, value }: Props) {
  return (
    <div className="d-flex flex-column flex-sm-row mb-2">
      <span className="mr-2 font-weight-bold text-primary">{title}:</span>
      <span className=" text-primary">{value}</span>
    </div>
  );
}
