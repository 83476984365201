import "./assets/auth-register-container.scss";

import React, { useMemo } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

import { Routes } from "../../constants/Routes";
import { useQuery } from "../../hooks/useQuery";
import { ProductPlans } from "../../dto/EnumDTO";
import { Analytics } from "../../utils/Analytics";
import { Loader } from "../../components/ui/Loader";
import { useResource } from "../../hooks/useResource";
import { AMAZON_URL } from "../../constants/AppConstants";
import Button from "../../components/CustomButtons/Button";
import { setRegisterVehicle } from "../../reducers/authReducer";
import { useResourceHandler } from "../../hooks/useResourceHandler";
import { useVehicleContext } from "../../api/vehicle/VehicleContext";
import { useOptimizeActivate } from "../../hooks/useOptimizeActivate";
import { PreviousMots } from "../../components/report-mot-data/PreviousMots";
import { RegisterProvenance } from "../../components/auth/RegisterProvenance";
import { CarGeneralInfoCard } from "../../components/car-details/CarGeneralInfoCard";
import { BasicDetailsFirstRow } from "../../components/car-details/BasicDetailsFirstRow";
import { BasicDetailsThirdRow } from "../../components/car-details/BasicDetailsThirdRow";
import { BasicDetailsSecondRow } from "../../components/car-details/BasicDetailsSecondRow";
import { VEHICLE_LOOKUP_CATEGORY, VehicleLookupActions } from "../../constants/AnalyticsConstants";

interface Query {
  readonly vrm: string;
  readonly vin: string;
}

export function AuthRegisterContainer() {
  const history = useHistory();
  const query = useQuery<Query>();

  const dispatch = useDispatch();

  const { VehicleApi } = useVehicleContext();

  const vehicleResource = useResource(
    () =>
      query?.vrm
        ? VehicleApi.lookupAuthVehicle({
            query: { vrm: query.vrm.replace(/\s/g, "") },
            prefixUrl: AMAZON_URL,
          })
        : query?.vin
        ? VehicleApi.lookupAuthVehicle({
            query: { vin: query.vin.replace(/\s/g, "") },
            prefixUrl: AMAZON_URL,
          })
        : undefined,
    [query],
  );

  useResourceHandler(vehicleResource, {
    onSuccess: (data) => {
      const analytics = new Analytics();

      analytics.googleEvent(
        "event",
        {
          eventCategory: VEHICLE_LOOKUP_CATEGORY,
          eventAction: VehicleLookupActions.VehiclePlateChange,
          eventLabel: data?.provenance?.hasPlateChanges ? "yes" : "no",
        },
        { nonInteraction: 1 },
      );

      analytics.googleEvent(
        "event",
        {
          eventCategory: VEHICLE_LOOKUP_CATEGORY,
          eventAction: VehicleLookupActions.VehicleSalvage,
          eventLabel: data?.provenance?.hasSalvageRecords ? "yes" : "no",
        },
        { nonInteraction: 1 },
      );

      analytics.googleEvent(
        "event",
        {
          eventCategory: VEHICLE_LOOKUP_CATEGORY,
          eventAction: VehicleLookupActions.VehicleExported,
          eventLabel: data?.provenance?.hasExportedMarker ? "yes" : "no",
        },
        { nonInteraction: 1 },
      );

      analytics.googleEvent(
        "event",
        {
          eventCategory: VEHICLE_LOOKUP_CATEGORY,
          eventAction: VehicleLookupActions.VehicleColourChange,
          eventLabel: data?.provenance?.hasColourChanges ? "yes" : "no",
        },
        { nonInteraction: 1 },
      );

      analytics.googleEvent(
        "event",
        {
          eventCategory: VEHICLE_LOOKUP_CATEGORY,
          eventLabel: data?.provenance?.likelyFailureItem,
          eventAction: VehicleLookupActions.VehicleFailurePrediction,
        },
        { nonInteraction: 1 },
      );

      analytics.googleEvent("event", {
        eventLabel: data?.basicDetails.vrm,
        eventCategory: VEHICLE_LOOKUP_CATEGORY,
        eventAction: VehicleLookupActions.VehicleLookup,
      });
    },
  });

  const title = useMemo(() => {
    if (vehicleResource.data) {
      const carTitle = [
        vehicleResource.data.basicDetails?.make,
        vehicleResource.data.basicDetails?.model,
      ]
        .filter(Boolean)
        .join(" ");

      return `Basic information we have on this ${carTitle}`;
    }
  }, [vehicleResource.data]);

  const motData = useMemo(() => vehicleResource.data?.motData, [vehicleResource.data]);
  const provenance = useMemo(() => vehicleResource.data?.provenance, [vehicleResource.data]);
  const basicDetails = useMemo(() => vehicleResource.data?.basicDetails, [vehicleResource.data]);
  const keyDates = useMemo(() => vehicleResource.data?.keyDates, [vehicleResource.data]);

  useOptimizeActivate();

  if ((!query?.vrm && !query?.vin) || vehicleResource.error) {
    return (
      <div className="d-flex flex-column flex-grow-1 flex-shrink-1 align-items-center justify-content-center">
        <span>Vehicle not found</span>
        <span>
          Please{" "}
          <span
            className="text-decoration-underline"
            role="button"
            onClick={() => window.history.back()}
          >
            click here to retry
          </span>
        </span>
      </div>
    );
  }

  return (
    <div className="pt-5 position-relative auth-register-container">
      {Boolean(
        !vehicleResource.loading &&
          basicDetails &&
          provenance &&
          !provenance.hasSalvageRecords &&
          motData,
      ) && (
        <div className="d-flex flex-column container">
          <span className="text-primary fl-4 mb-3 font-weight-bold mb-3">{title}</span>
          <div className="d-flex flex-column flex-xl-row mb-lg-1">
            <CarGeneralInfoCard carDetails={basicDetails!} />
            <div className="d-flex mx-lg-1 mx-xl-2 flex-column flex-1">
              <div className="d-flex flex-column flex-shrink-1 flex-grow-1">
                <BasicDetailsFirstRow basicDetails={basicDetails!} />
                <BasicDetailsSecondRow
                  basicDetails={basicDetails!}
                  keyDates={keyDates!}
                  isReveal={true}
                />
                <BasicDetailsThirdRow
                  isReveal={true}
                  provenance={provenance!}
                  basicDetails={basicDetails!}
                  className="align-items-center details-row"
                />
                {/* <BasicDetailsFourthRow
                  isReveal={true}
                  provenance={provenance!}
                  className="align-items-center details-row"
                /> */}
              </div>
              <div className="d-flex flex-column align-items-center">
                <Button
                  // @ts-ignore
                  color="success"
                  id="buyjourney-revealnow-trackbutton"
                  onClick={() => {
                    dispatch(
                      setRegisterVehicle({
                        vehicle: {
                          make: basicDetails?.make!,
                          model: basicDetails?.model!,
                          colour: basicDetails?.colour!,
                          registration: basicDetails?.vrm!,
                          fuelType: basicDetails?.fuelType!,
                        },
                      }),
                    );

                    history.push(Routes.AuthRegisterProduct);
                  }}
                  className="my-3"
                >
                  Buy car check report
                </Button>

                <span className="font-weight-bold">OR</span>

                <Button
                  // @ts-ignore
                  color="danger"
                  className="my-3"
                  id="buyjourney-saveforlater-trackbutton"
                  onClick={() =>
                    history.push(
                      `/auth/register?vrm=${query.vrm}&product=${ProductPlans.TrialSignUp}`,
                    )
                  }
                >
                  Save this car for later
                </Button>
              </div>
            </div>
            <RegisterProvenance
              onViewReportClick={() => {
                dispatch(
                  setRegisterVehicle({
                    vehicle: {
                      make: basicDetails?.make!,
                      model: basicDetails?.model!,
                      colour: basicDetails?.colour!,
                      registration: basicDetails?.vrm!,
                      fuelType: basicDetails?.fuelType!,
                    },
                  }),
                );

                history.push(Routes.AuthRegisterProduct);
              }}
            />
          </div>

          <PreviousMots motTests={motData!} />
        </div>
      )}

      {Boolean(
        !vehicleResource.loading &&
          basicDetails &&
          provenance &&
          provenance.hasSalvageRecords &&
          motData,
      ) && (
        <div className="d-flex flex-column container">
          <span className="text-primary fl-4 mb-3 font-weight-bold mb-3">{title}</span>
          <div className="d-flex flex-column flex-xl-row mb-lg-1">
            <CarGeneralInfoCard carDetails={basicDetails!} />
            <div className="d-flex mx-lg-1 mx-xl-2 flex-column flex-1">
              <div className="d-flex flex-column flex-shrink-1 flex-grow-1">
                <BasicDetailsFirstRow basicDetails={basicDetails!} />
                <BasicDetailsSecondRow
                  basicDetails={basicDetails!}
                  keyDates={keyDates!}
                  isReveal={true}
                />
                <BasicDetailsThirdRow
                  isReveal={true}
                  provenance={provenance!}
                  basicDetails={basicDetails!}
                  className="align-items-center details-row"
                />
              </div>
              <div className="d-flex flex-column align-items-center">
                <span className="font-weight-bold text-center fl-4 text-danger salvage-warning">
                  Warning!
                </span>
                <span className="font-weight-bold text-left salvage-text">
                  This vehicle has previously been sold though a salvage auction and has likely been
                  involved in an insurance claim though accident damage, stolen or flood water
                  damage.
                </span>
                <span className="font-weight-bold text-left salvage-text">
                  Purchase a report below to see more details.
                </span>

                <Button
                  // @ts-ignore
                  color="success"
                  id="buyjourney-revealnow-trackbutton"
                  onClick={() => {
                    dispatch(
                      setRegisterVehicle({
                        vehicle: {
                          make: basicDetails?.make!,
                          model: basicDetails?.model!,
                          colour: basicDetails?.colour!,
                          registration: basicDetails?.vrm!,
                          fuelType: basicDetails?.fuelType!,
                        },
                      }),
                    );

                    history.push(Routes.AuthRegisterProduct);
                  }}
                  className="my-3"
                >
                  Buy for £9.99
                </Button>

                <span className="font-weight-bold">OR</span>

                <Button
                  // @ts-ignore
                  color="danger"
                  className="my-3"
                  id="buyjourney-saveforlater-trackbutton"
                  onClick={() =>
                    history.push(
                      `/auth/register?vrm=${query.vrm}&product=${ProductPlans.TrialSignUp}`,
                    )
                  }
                >
                  Create free account
                </Button>
              </div>
            </div>
            <RegisterProvenance
              onViewReportClick={() => {
                dispatch(
                  setRegisterVehicle({
                    vehicle: {
                      make: basicDetails?.make!,
                      model: basicDetails?.model!,
                      colour: basicDetails?.colour!,
                      registration: basicDetails?.vrm!,
                      fuelType: basicDetails?.fuelType!,
                    },
                  }),
                );

                history.push(Routes.AuthRegisterProduct);
              }}
            />
          </div>

          <PreviousMots motTests={motData!} />
        </div>
      )}

      <Loader loading={vehicleResource.loading} className="mt-4" />
    </div>
  );
}
