import "./assets/stripe-payment-request-button.scss";

import cx from "classnames";
import React, { useEffect, useState } from "react";
import { PaymentRequestButtonElement } from "react-stripe-elements";

import { Loader } from "../ui/Loader";
import Button from "@material-ui/core/Button";

interface Props {
  readonly label: string;
  readonly amount: number;

  readonly onClick?: () => void;
  readonly onPayment?: (ev: any) => void;

  readonly disabled?: boolean;
  readonly className?: string;

  readonly stripe: any | undefined;

  readonly showOr?: boolean;
}

export function StripePaymentRequestButton({
  label,
  stripe,
  showOr = true,
  amount,
  onClick,
  disabled,
  className,
  onPayment,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [
    paymentRequest,
    setPaymentRequest,
  ] = useState<stripe.paymentRequest.StripePaymentRequest | null>(null);

  useEffect(() => {
    if (stripe && onPayment) {
      setLoading(true);

      const pr = stripe.paymentRequest({
        country: "GB",
        currency: "gbp",
        requestPayerName: true,
        requestPayerEmail: true,
        total: { amount, label },
      });

      pr.canMakePayment()
        .then((result) => {
          if (result) {
            setPaymentRequest(pr);
          }

          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [stripe]);

  useEffect(() => {
    if (paymentRequest && onPayment) {
      paymentRequest.on("paymentmethod", (ev) => onPayment(ev));
    }
  }, [paymentRequest]);

  if (loading) {
    return <Loader loading={loading} className="mt-3" />;
  }

  if (!paymentRequest || !onPayment) {
    return null;
  }

  return (
    <div className={cx("d-flex flex-column", className)}>
      <div
        className={cx(
          "d-flex justify-content-center position-relative stripe-payment-request-button",
          { "mb-2": !showOr },
        )}
      >
        <PaymentRequestButtonElement
          className="flex-1"
          paymentRequest={paymentRequest}
          style={{
            paymentRequestButton: {
              type: "buy",
              theme: "dark",
              height: "42px",
            },
          }}
        />

        {disabled && (
          <div className="position-absolute-fill">
            <Button className="text-white placeholder-payment-button" onClick={onClick}>
              Buy with apple/google pay
            </Button>
          </div>
        )}
      </div>

      {showOr && <span className="text-center font-weight-bold my-3">OR</span>}
    </div>
  );
}
