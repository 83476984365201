import React from "react";

export function TrustpilotStarsIcon() {
  return (
    <svg width="128" height="24" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="nonzero" fill="none">
        <path
          fill="#00BC80"
          d="M0 0h24v24H0zM26 0h24v24H26zM52 0h24v24H52zM78 0h24v24H78zM104 0h24v24h-24z"
        />
        <path
          d="M12.5 16.2l3.7-1 1.5 4.8-5.2-3.8zm8.5-6.1h-6.5l-2-6.1-2 6H4L9.3 14l-2 6 5.2-3.7 3.3-2.3L21 10zM37.5 16.2l3.7-1 1.5 4.8-5.2-3.8zm8.5-6.1h-6.5l-2-6.1-2 6H29l5.3 3.9-2 6 5.2-3.7 3.3-2.3L46 10zM64.5 16.2l3.7-1 1.5 4.8-5.2-3.8zm8.5-6.1h-6.5l-2-6.1-2 6H56l5.3 3.9-2 6 5.2-3.7 3.3-2.3L73 10zM90.5 16.2l3.7-1 1.5 4.8-5.2-3.8zm8.5-6.1h-6.5l-2-6.1-2 6H82l5.3 3.9-2 6 5.2-3.7 3.3-2.3L99 10zM116.5 16.2l3.7-1 1.5 4.8-5.2-3.8zm8.5-6.1h-6.5l-2-6.1-2 6H108l5.3 3.9-2 6 5.2-3.7 3.3-2.3L125 10z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
}
