import { uid } from "react-uid";
import React, { useMemo } from "react";

import { MotTest } from "./MotTest";

interface Props {
  readonly motTests?: any;
  readonly isShortlist?: boolean;
}

const MAX_MOTS_ON_PAGE = 2;

export function PreviousMots({ motTests = [], isShortlist }: Props) {
  const emptyTests = useMemo(() => !motTests?.length || motTests?.length === 0, [motTests]);
  const motTestsDemo = motTests.slice(0, MAX_MOTS_ON_PAGE);
  const previousMotTests = isShortlist ? motTests : motTestsDemo;

  return (
    <div className="d-flex flex-column mt-7">
      <h5>Previous MOTs</h5>

      {emptyTests && (
        <span className="text-center text-md-left">This vehicle hasn't had an MOT yet.</span>
      )}

      {!emptyTests && (
        <>
          <span className="mb-3">
            See the results of previous MOTs. Please pay attention to any items that were marked as
            dangerous and major as well as advisories.
          </span>

          <div className="d-flex flex-column flex-md-row flex-md-wrap border-top border-left border-muted mb-3">
            {previousMotTests?.map((test) => (
              <MotTest key={uid(test)} test={test} />
            ))}
          </div>

          {!isShortlist && (
            <span className="mb-3 text-center">
              Want to see the full MOT history? Buy a
              <a href="https://app.carguide.co.uk/auth/register-b/product"> <u>report</u> </a>
              or sign up for
              <a href="https://app.carguide.co.uk/auth/register/?product=trial_signup"> <u>free</u> </a>
            </span>
          )}
        </>
      )}
    </div>
  );
}
