import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import classnames from "classnames";
import Mileage from "./Mileage";
import GoodVehicleIcon from "../../../assets/img/reports/good-vehicle.svg";
import BadVehicleIcon from "../../../assets/img/reports/bad-vehicle.svg";
import OkVehicleIcon from "../../../assets/img/reports/ok-vehicle.svg";
import WarningIcon from "../../../assets/img/reports/warning.svg";
import * as actions from "../../../redux/actions/report";
import withScrollTop from "../../../hoc/withScrollTop";
import {getTaxInfo} from "../common/tax";
import ModalDialog from "../../../commons/ModalDialog";
import TAX from "../tax.enum";
import * as styles from "./styles";
import {APP_WP_URL} from "../../../constants/AppConstants";
import TAXI from "../../../utils/taxi.enum";

export const writtenCategories = {
  A: "Scrap only",
  B: "Break for parts",
  C: "Structurally damaged but repairable",
  D: "Not structurally damaged, repairable",
  S: "Structurally damaged but repairable",
  N: "Not structurally damaged, repairable",
};

function Warning(props) {
  return (
    <div className="warning">
      <div className="warning-content">
        <div className="warning-icon">
          <img src={WarningIcon} width={60} />
        </div>
        <div className="warning-title">Warning!</div>
        <div className="warning-description">{props.description}</div>
      </div>
    </div>
  );
}

function MileageAndRisks({ report, token }) {
  const dispatch = useDispatch();
  const mileageAndRisks = report.mileageAndRisks;
  const { recallsInfo = {} } = report.questionsAndAnswers || {};

  const risks = [
    {
      status: vehicle => !vehicle.hasOutstandingFinance,
      successTitle: () => "Checked: Finance",
      failureTitle: () => "Outstanding finance",
      successDescription: vehicle => `
        We have checked the vehicle against finance registers and there is
        no record of the vehicle having outstanding finance.
      `,
      failureDescription: vehicle => {
        return (
          <div>
            <div className="failure-description with-bottom-spacing">
              We have checked the vehicle against finance registers and there is a record of the
              vehicle having {vehicle.financeRecords.length} outstanding finance agreement(-s).
              Please contact the seller.
            </div>
            <div className="list-info-container">
              {vehicle.financeRecords.map(agreement => (
                <div
                  key={agreement.agreementNumber}
                  className={classnames("list-info-item", {
                    "has-multiple": vehicle.financeRecords.length > 1,
                  })}
                >
                  <div className="info-detail">Agreement Type: {agreement.agreementType}</div>
                  <div className="info-detail">Agreement Number: {agreement.agreementNumber}</div>
                  <div className="info-detail">
                    Agreement Term: {agreement.agreementTerm} months
                  </div>
                  <div className="info-detail">Start Date: {agreement.agreementDate}</div>
                  <div className="info-detail">Finance Company: {agreement.financeCompany}</div>
                  <div className="info-detail">Contact Number: {agreement.contactNumber}</div>
                  <div className="info-detail">Transacaction Date: {agreement.transactionDate}</div>
                </div>
              ))}
            </div>
          </div>
        );
      },
    },
    {
      status: vehicle => !vehicle.hasStolenMarker,
      show: vehicle => Boolean(!vehicle.hasStolenMarker),
      successTitle: () => "Checked: Not stolen",
      failureTitle: () => "Checked: Stolen",
      successDescription: () =>
        "We have checked the vehicle against stolen registers and it is not currently marked as stolen.",
      failureDescription: () => "Not currently marked as stolen.",
    },
    {
      status: vehicle => !vehicle.hasWrittenOffMarker,
      successTitle: () => "Checked: Not written off",
      failureTitle: () => "Checked: Written off",
      successDescription: () => `
        We have checked the vehicle against various registers
        and there is no record of the vehicle being written off.
      `,
      failureDescription: () => (
        <span>
          Warning, we have identified that this vehicle has been marked as{" "}
          <span className="font-weight-bold">written off</span>.
        </span>
      ),
      additional: vehicle => [
        {
          field: "Date",
          value: vehicle.miaftrConditionDate,
        },
        {
          field: "Category",
          value: vehicle.miaftrDamage,
          desc: vehicle.miaftrDamage ? (
            <span className="d-inline-flex align-items-start">
              <span className="font-weight-bold mr-2">Cat {vehicle.miaftrDamage}</span>
              {writtenCategories[vehicle.miaftrDamage]}
            </span>
          ) : (
            <span />
          ),
        },
      ],
      description: "If you believe this is an error, you can contact the DVLA on 0300 790 6802",
    },
    {
      status: vehicle => !vehicle.hasOutstandingRecalls,
      successTitle: () => "Checked: Recalls",
      failureTitle: () => "Outstanding recall",
      successDescription: () => (
        <div className="success-description">
          We have checked this vehicle with the{" "}
          <a href="#" className="modal-button" onClick={toggleSMMTModal}>
            SMMT
          </a>{" "}
          and there are no safety recalls recorded.
        </div>
      ),
      failureDescription: (_, { recallsInfo }) => (
        <div className="failure-description">
          The{" "}
          <a href="#" onClick={toggleSMMTModal} className="modal-button">
            SMMT
          </a>{" "}
          reports there is an overdue recall on the vehicle. as follows:
          <div className="info-block with-spacing-around">{recallsInfo.vinRecallStatus}</div>
          Ask for proof that this has been carried out. If not, it should be able to be carried out
          free of charge by your nearest dealership.
        </div>
      ),
    },
    {
      status: vehicle => vehicle.taxStatusId === TAX.TAXED,
      successTitle: () => "Checked: Taxed",
      failureTitle: vehicle => getTaxInfo(vehicle.taxStatusId, toggleSORNModal),
      successDescription: () => `We have checked this vehicle against DVLA and it is taxed.`,
      failureDescription: vehicle => {
        if (vehicle.taxStatusId === TAX.SORN) {
          return (
            <div className="failure-description">
              <div className="failure-description with-bottom-spacing">
                This vehicle is not taxed for on road use.
              </div>
              You must tax this vehicle before taking it for a test drive on public roads unless it
              is on dealer trade-plates or you are attending a pre-booked MOT.
            </div>
          );
        }

        return (
          <div className="failure-description">
            <div className="failure-description with-bottom-spacing">
              We have checked this vehicle against DVLA and it is untaxed.
            </div>
            You must tax this vehicle before taking it for a test drive on public roads unless it is
            on dealer trade-plates or you are attending a pre-booked MOT.
          </div>
        );
      },
    },
    {
      status: vehicle => !vehicle.hasImportedMarker,
      successTitle: () => "Checked: Not imported",
      failureTitle: () => "Checked: Imported",
      successDescription: () => `
        We have no record of this vehicle being imported into the country.
      `,
      failureDescription: () => `
        We have a record of this vehicle being imported into the country.
      `,
    },
    {
      status: vehicle => !vehicle.hasExportedMarker,
      successTitle: () => "Checked: Not exported",
      failureTitle: () => "Checked: Exported",
      successDescription: () => `
        We have checked the vehicle against the DVLA and there is no
        record of the vehicle being exported.
      `,
      failureDescription: () => `
        We have checked the vehicle against the DVLA and there is a
        record of the vehicle being exported.
      `,
    },
    {
      status: vehicle => !vehicle.hasColourChanges,
      successTitle: () => "Checked: Colour",
      failureTitle: () => "Colour changes detected",
      successDescription: () => `
        We have no record of the vehicles’ colour being changed.
      `,
      failureDescription: () => `
        We have a record of the vehicles’ colour being changed.
      `,
    },
    {
      status: vehicle => !vehicle.hasVrmChanges,
      checkWarning: vehicle => vehicle.hasVrmChanges,
      successTitle: () => "Checked: Registration",
      failureTitle: () => "Registration plate changes detected",
      successDescription: () => `
       We have no record of registration plate changes on this vehicle.
      `,
      failureDescription: vehicle => (
        <div className="failure-description">
          Below are the registration plates and the dates they were taken off this vehicle:
          {(vehicle.vrmChangeHistory && vehicle.vrmChangeHistory.length && (
            <div className="info-block">
              {vehicle.vrmChangeHistory.map((changeItem, index) => (
                <div key={index}>
                  {changeItem.date}: {changeItem.vrm}
                </div>
              ))}
            </div>
          )) ||
            null}
        </div>
      ),
      note: `
        Note that a vehicle may have had a recent plate change but this will
        need to be confirmed with the DVLA (for example, if the seller has
        recently taken their private plate off the vehicle).
      `,
    },
    {
      status: vehicle => (vehicle.lastKeeperDate || vehicle.previousKeeperDate),
      successTitle: () => "Vehicle owners",
      failureTitle: () => "Vehicle owners",
      successDescription: vehicle => (
        <div>
          <div>
            Current owner acquired vehicle on:{" "}
            <p>{vehicle.lastKeeperDate ? vehicle.lastKeeperDate : "N/A"}</p>
          </div>
          <div>
            Length of ownership:
            <p>{vehicle.lengthOfOwnership ? vehicle.lengthOfOwnership : "N/A"}</p>
          </div>
          <div>
            Previous owner acquired vehicle on:{" "}
            <p>{vehicle.previousKeeperDate ? vehicle.previousKeeperDate : "N/A"}</p>
          </div>
        </div>
      ),
      failureDescription: () => `Not Available`,
    },
    {
      status: vehicle => !vehicle.hasSalvageRecords,
      checkWarning: vehicle => vehicle.hasSalvageRecords,
      successTitle: () => "Checked: No Salvage Record",
      failureTitle: () => "Salvage Record found",
      successDescription: () => `
        There is no record of this vehicle on our salvage register.
      `,
      failureDescription: () => {
        return (
            <div className="failure-description">
              There is a record of this vehicle on our salvage register.
            </div>
        );
      },
    },
    {
      status: vehicle => vehicle.taxiStatusId === TAXI.NONE,
      checkWarning: vehicle => vehicle.taxiStatusId > TAXI.NONE,
      successTitle: () => "Checked: No Taxi Record",
      failureTitle: vehicle => vehicle.taxiStatusId === TAXI.POSSIBLE_TAXI ? "Possible Taxi" : "Taxi Record found",
      successDescription: () => `
        There is no record of this vehicle on our taxi register.
      `,
      failureDescription: () => {
        return (
            <div className="failure-description">
              There is a record of this vehicle on our taxi register.
            </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (!mileageAndRisks) {
      dispatch(actions.loadMileageAndRisks(report.id, token));
    }
  }, [token]);

  const [isSORNModalOpen, setSORNModalOpen] = useState(false);
  const toggleSORNModal = event => {
    event.preventDefault();
    setSORNModalOpen(!isSORNModalOpen);
  };

  const [isSMMTModalOpen, setSMMTModalOpen] = useState(false);
  const toggleSMMTModal = event => {
    event.preventDefault();
    setSMMTModalOpen(!isSMMTModalOpen);
  };

  if (!mileageAndRisks) {
    return [];
  }

  return (
    <styles.Container>
      <div className="container-title">
        We have checked the vehicle for high-risk items. Anything you need to watch out for will be
        highlighted in red.
      </div>

      {report.vehicle && report.vehicle.hasStolenMarker ? (
        <Warning
          description={
            <div className="d-flex flex-column">
              <span>This car has been reported as stolen.</span>
              <span>Date {report.vehicle.stolenDate}</span>
            </div>
          }
        />
      ) : null}

      {report.vehicle && report.vehicle.hasScrappedMarker ? (
        <Warning
          description={
            "This vehicle is marked as scrapped. Please read our advice here on buying a scrapped vehicle."
          }
        />
      ) : null}

      <div className="risks">
        {risks.map((risk, index) => {
          const additionalInfo = { recallsInfo };
          const show = !risk.show || risk.show(report.vehicle);
          const status = risk.status(report.vehicle, additionalInfo);
          const hasWarning = risk.checkWarning && risk.checkWarning(report.vehicle);

          if (!show) {
            return null;
          }

          return (
            <div
              key={index}
              className={classnames("risk mb-3 mr-2", {
                "risk-ok": status,
                "risk-attention": !status,
                "risk-warning": hasWarning,
              })}
            >
              <div className="risk-icon">
                {status ? (
                  <img src={GoodVehicleIcon} width={60} />
                ) : hasWarning ? (
                  <img src={OkVehicleIcon} width={60} />
                ) : (
                  <img src={BadVehicleIcon} width={60} />
                )}
              </div>
              <div className="risk-title">
                {status
                  ? risk.successTitle(report.vehicle, additionalInfo)
                  : risk.failureTitle(report.vehicle, additionalInfo)}
              </div>
              <div className="risk-description">
                {status
                  ? risk.successDescription(report.vehicle, additionalInfo)
                  : risk.failureDescription(report.vehicle, additionalInfo)}
              </div>

              {!status && risk.additional && (
                <div
                  className="d-flex flex-column"
                  style={{
                    margin: "0 -30px",
                  }}
                >
                  {risk.additional(report.vehicle, additionalInfo).map(({ field, value, desc }) => (
                    <div
                      className="d-flex flex-column"
                      style={{ padding: "0 21px", borderTop: "1px solid" }}
                    >
                      <div className="d-flex py-3 align-items-center">
                        <span className="mr-2 font-weight-bold">{field}:</span>
                        <span>{value}</span>
                      </div>

                      {desc}
                    </div>
                  ))}
                </div>
              )}
              {risk.note && <div className="risk-note">{risk.note}</div>}

              {!status && risk.description && (
                <div
                  className="bg-danger d-flex"
                  style={{ margin: "0 -30px -30px", padding: "18px 21px" }}
                >
                  <span className="font-weight-bold text-white text-left">{risk.description}</span>
                </div>
              )}
            </div>
          );
        })}
      </div>

      <Mileage data={mileageAndRisks} hasMileageAnomaly={report.vehicle.hasMileageAnomaly} />

      <ModalDialog
        sourcePath={`${APP_WP_URL}/wp-json/wp/v2/posts/1160`}
        open={isSORNModalOpen}
        handleClose={toggleSORNModal}
      />

      <ModalDialog
        sourcePath={`${APP_WP_URL}/wp-json/wp/v2/posts/1114`}
        open={isSMMTModalOpen}
        handleClose={toggleSMMTModal}
      />
    </styles.Container>
  );
}

export default withScrollTop(MileageAndRisks);
