import * as axios from "axios";
import { APP_API_URL } from "../constants/AppConstants";

export const WPClient = axios.create();

export const instance = axios.create({
  baseURL: APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default instance;
