import "./styles.scss"

import React from "react";
import cx from "classnames";
import { SvgIcon } from "@material-ui/core";

export function GasIcon(props) {
  return <SvgIcon width="35px" height="35px" viewBox="0 0 33 35" {...props} className={cx("icons gas-icon", props.className)}>
    <defs>
      <path id="a" d="M0 0h33v35H0z"/>
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b" fill="#fff">
        <use href="#a"/>
      </mask>
      <path fill="#000" d="M2.93 28.44h19.23V4.925a3.722 3.722 0 0 0-1.106-2.643 3.761 3.761 0 0 0-2.657-1.1H6.694a3.764 3.764 0 0 0-2.658 1.1A3.722 3.722 0 0 0 2.93 4.925V28.44zm0 2.703h19.23v-1.52H2.93v1.52zm15.662-15.246a2.302 2.302 0 0 0 2.301-2.29V5.687a2.303 2.303 0 0 0-2.301-2.29H6.498a2.3 2.3 0 0 0-2.3 2.29v7.922a2.299 2.299 0 0 0 2.301 2.289h12.093zM6.498 14.715h12.094a1.112 1.112 0 0 0 1.112-1.107V5.686a1.111 1.111 0 0 0-1.112-1.107H6.498a1.113 1.113 0 0 0-1.113 1.107v7.922a1.114 1.114 0 0 0 1.113 1.107zm16.851 17.61H1.19v1.493h22.713v-1.493h-.553zm6.416-18.132h1.023a1.024 1.024 0 0 0 1.023-1.017v-2.523a1.025 1.025 0 0 0-1.023-1.017h-1.023v4.557zm-17.083 5.976l-1.575 2.825a1.778 1.778 0 0 0-.243.904c0 .5.204.952.533 1.28.329.326.782.528 1.285.528.502 0 .956-.202 1.286-.529a1.802 1.802 0 0 0 .29-2.185l-1.576-2.823zm0-2.428l-2.602 4.663a2.97 2.97 0 0 0 .476 3.608 3.004 3.004 0 0 0 2.126.876c.83 0 1.581-.335 2.126-.876a2.973 2.973 0 0 0 .48-3.599l-2.606-4.672zM6.694 0h11.703c1.362 0 2.6.554 3.498 1.446a4.9 4.9 0 0 1 1.454 3.48v7.205c.514.114.976.374 1.337.733.495.492.802 1.172.802 1.92v10.314a1.544 1.544 0 0 0 3.089 0V6.188l1.594-1.542.827.844-1.233 1.193v1.77h1.023a2.213 2.213 0 0 1 2.212 2.2v2.523a2.214 2.214 0 0 1-2.212 2.199h-1.023v9.723c0 1.495-1.23 2.718-2.732 2.718-1.503 0-2.733-1.223-2.733-2.718V14.784c0-.422-.175-.806-.455-1.085a1.564 1.564 0 0 0-.496-.332v17.776h.572a1.168 1.168 0 0 1 1.169 1.162V35H0v-2.695a1.166 1.166 0 0 1 1.169-1.162h.572V4.925c0-1.355.557-2.587 1.454-3.479A4.95 4.95 0 0 1 6.694 0z" mask="url(#b)"/>
    </g>
  </SvgIcon>
}
