import {ProductPlans} from "../dto/EnumDTO";

export const SubsSingleReport = {
  amount: 499,
  priceWhole: 4,
  isActive: true,
  type: "credits",
  category: "subs",
  isUpgrade: false,
  reportCredits: 1,
  lookupCredits: 0,
  priceFractional: 99,
  id: ProductPlans.SubsSingleReport,
  title: "Buyers Report - 30-day Plan",
  subtitle: "with subscriber discount",
};

export const PaygSingleReport = {
  amount: 599,
  priceWhole: 5,
  isActive: true,
  type: "credits",
  category: "payg",
  isUpgrade: false,
  reportCredits: 1,
  lookupCredits: 0,
  priceFractional: 99,
  id: ProductPlans.PaygSingleReport,
  title: "Buyers Report - PAYG Plan",
  subtitle: "with subscriber discount",
};

export const PaygUpgrade = {
  amount: 1499,
  priceWhole: 14,
  isActive: true,
  isUpgrade: true,
  category: "payg",
  reportCredits: 2,
  lookupCredits: 2,
  priceFractional: 99,
  type: "subscription",
  id: ProductPlans.Upgrade,
  subtitle: "Unlimited vehicle checks",
  title: "Buyers Report - 30-day Plan Renewal",
};
