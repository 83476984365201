import "./assets/sorting-select-box.scss";

import React from "react";
import { uid } from "react-uid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

interface Item {
  readonly value: any;
  readonly title: string;
}

interface Props {
  readonly value: any;
  readonly items: Item[];
  readonly onChange: (value: any) => void;
}

export function SortingSelectBox({ items = [], value, onChange }: Props) {
  return (
    <FormControl variant="outlined" className="sorting-select-box">
      <InputLabel htmlFor="outlined-sort-order-select">Sort order</InputLabel>
      <Select
        value={value}
        labelWidth={74}
        autoWidth={true}
        className="sorting-select"
        onChange={({ target }) => onChange(target.value)}
        inputProps={{
          name: "sort",
          id: "outlined-sort-order-select",
        }}
      >
        <MenuItem value="">None</MenuItem>

        {items.map((item: Item) => (
          <MenuItem key={uid(item)} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
