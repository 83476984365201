import React from "react";

import { hasSubscriptionSelector } from "../../reducers/userReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { SelectPlanDialog, SelectPlanDialogProps } from "./SelectPlanDialog";

export function SelectPlanDialogWrapper(props: Omit<SelectPlanDialogProps, "hasSubscription">) {
  const hasSubscription = useShallowEqualSelector(hasSubscriptionSelector);

  return <SelectPlanDialog {...props} hasSubscription={hasSubscription} />;
}
