import React from "react";

import { Dialog } from "../ui/Dialog";
import { AppError } from "../../utils/AppError";
import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";

export interface DeleteVehicleDialogProps {
  readonly open: boolean;
  readonly title: string;
  readonly error?: AppError;
  readonly loading: boolean;
  readonly onDismiss: () => void;
  readonly onDeletePress: () => void;
}

export function DeleteVehicleDialog({
  open,
  title,
  error,
  loading,
  onDismiss,
  onDeletePress,
}: DeleteVehicleDialogProps) {
  return (
    <Dialog
      open={open}
      title={title}
      onClose={onDismiss}
      disableEscapeKeyDown={loading}
      disableBackdropClick={loading}
      actions={
        <div className="d-flex justify-content-between flex-column-reverse flex-sm-row">
          <ActionButton
            disabled={loading}
            className="mr-sm-1"
            onClick={onDismiss}
            color={ColorPalette.MiddleGray}
          >
            Cancel
          </ActionButton>
          <ActionButton
            loading={loading}
            onClick={onDeletePress}
            color={ColorPalette.Danger}
            className="mb-2 mb-sm-0 ml-sm-1"
          >
            Delete
          </ActionButton>
        </div>
      }
    >
      {error && <span className="mb-2 text-center text-danger">{error?.message}</span>}
      <span className="text-center text-sm-left">
        Are you sure you want to delete this vehicle from your shortlist?
      </span>
    </Dialog>
  );
}
