import "./assets/car-buy-credits-card.scss";

import React from "react";
import cx from "classnames";

import { ProductProps } from "../../dto/ProductDTO";

export interface CarBuyCreditsCardProps {
  readonly className?: string;
  readonly product?: ProductProps;
  readonly onComplete: () => void;
}

export function CarBuyCreditsCard({ className = "flex-1" }: CarBuyCreditsCardProps) {
  return (
    <div
      className={cx(
        "d-flex ml-lg-1 ml-xl-2 mt-2 flex-column mt-3 mt-lg-2 car-buy-credits-card",
        className,
      )}
    />
  );
}
