import React, { useEffect } from "react";

import { LoaderProgress } from "../ui/LoaderProgress";
import { EmailConfirmFormProps } from "../../dto/AuthDTO";

interface Props {
  readonly authEmail?: string;
  readonly confirmCode?: string;
  readonly onSubmit: (values: EmailConfirmFormProps) => void;
}

export function EmailConfirmSignUp({ onSubmit, authEmail = "", confirmCode = "" }: Props) {
  useEffect(() => {
    onSubmit({ email: authEmail, code: confirmCode });
  }, []);

  return (
    <div className="d-flex flex-column align-items-center">
      <LoaderProgress className="mb-5" isAbsolute={false} />

      <span className="text-center text-primary">Just a moment. Verification is in process</span>
    </div>
  );
}
