import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  error: {
    backgroundColor: "#eb4d4b",
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
}));
