import "./assets/user-details-form.scss";

import React from "react";
import { Controller, useForm } from "react-hook-form";

import { TextField } from "../form/TextField";
import { ActionButton } from "../ui/ActionButton";
import { CheckboxLegacy } from "../ui/CheckboxLegacy";
import { ColorPalette } from "../../theme/ColorPalette";
import { APP_WP_URL } from "../../constants/AppConstants";
import { UserDetailsFormProps } from "../../api/user/UserDTO";
import { createEmailValidator, createStringValidator } from "../../utils/ValidateUtils";

interface Props {
  readonly loading?: boolean;
  readonly defaultValues?: Partial<UserDetailsFormProps>;
  readonly onSubmit: (values: UserDetailsFormProps) => Promise<void>;
}

export function UserDetailsForm({ loading, defaultValues, onSubmit }: Props) {
  const { errors, control, formState, handleSubmit } = useForm<UserDetailsFormProps>({
    defaultValues,
    mode: "onBlur",
  });

  return (
    <form className="d-flex flex-column mb-7 user-details-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-column my-3 input-group">
        {/*
          // @ts-ignore */}
        <Controller
          name="name"
          label="Name"
          as={TextField}
          className="mb-3"
          control={control}
          error={errors.name}
          rules={{ validate: createStringValidator("Enter Name") }}
        />
        {/*
          // @ts-ignore */}
        <Controller
          name="email"
          label="Email"
          as={TextField}
          className="mb-3"
          control={control}
          error={errors.email}
          rules={{
            required: "Enter Email Address",
            validate: createEmailValidator("Enter Valid Email Address"),
          }}
        />
        {/*
          // @ts-ignore */}
        <Controller
          as={TextField}
          className="mb-3"
          control={control}
          name="phoneNumber"
          label="Mobile Number"
        />
      </div>
      <h3>Notification settings</h3>
      <span className="mb-3">
        From time to time, we’d like to send you marketing information on our products and services
        we think you’ll be interested in - to help you get the most out of your subscription.
      </span>
      {/*
          // @ts-ignore */}
      <Controller
        label="Email"
        name="viaEmail"
        className="mb-0"
        control={control}
        as={CheckboxLegacy}
      />
      {/*
          // @ts-ignore */}
      <Controller
        label="SMS"
        name="viaSms"
        className="mb-0"
        control={control}
        as={CheckboxLegacy}
      />
      {/*
          // @ts-ignore */}
      <Controller
        name="viaPush"
        className="mb-3"
        control={control}
        as={CheckboxLegacy}
        label="Push notifications"
      />

      <span className="mb-4">
        You can stop these messages at any time by clicking on the 'unsubscribe' link in any email
        or by contacting our customer support team. However, we'll still need to send you important
        service messages from time to time such as changes to your shortlist. We treat your personal
        data with care. To find out more, read our{" "}
        <a
          target="_blank"
          rel="noreferrer noopener"
          className="text-decoration-underline"
          href={`${APP_WP_URL}/privacy-policy`}
        >
          privacy policy
        </a>
        .
      </span>
      <ActionButton
        type="submit"
        className="submit-button"
        color={ColorPalette.Danger}
        disabled={formState.isSubmitting || !formState.isValid || loading}
      >
        Save
      </ActionButton>
    </form>
  );
}
