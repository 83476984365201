import { BaseApi } from "../BaseApi";
import { ReportMotDataProps } from "./ReportDTO";

export class ReportApi extends BaseApi {
  public generateReport(vrm: string): Promise<void> {
    return this.post("report/:vrm", { params: { vrm } });
  }

  public getMotData(vrm: string): Promise<ReportMotDataProps> {
    return this.get("cars/:vrm/mot_info", { params: { vrm } });
  }
}
