import { useDispatch } from "react-redux";
import React, { useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";

import ModalDialog from "../commons/ModalDialog";
import { useResource } from "../hooks/useResource";
import Circular from "../commons/Circular/Circular";
import { APP_WP_URL } from "../constants/AppConstants";
import { BackButton } from "../components/ui/BackButton";
import { useUserContext } from "../api/user/UserContext";
import { useResponseHandler } from "../hooks/useResponseHandler";
import { CarDetails } from "../components/car-details/CarDetails";
import { AppRouteLayout } from "../components/app/AppRouteLayout";
import { useVehicleContext } from "../api/vehicle/VehicleContext";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { UseCreditDialogWrapper } from "../components/ui/UseCreditDialogWrapper";
import { setVehicleList, vehicleItemSelector } from "../reducers/vehicleReducer";
import {
  hasCreditsSelector,
  hasSubscriptionSelector,
  setUserAccount,
  userSubscriptionSelector,
} from "../reducers/userReducer";
import {
  UpgradePlanDialogWrapper,
  UpgradePlanType,
} from "../components/ui/UpgradePlanDialogWrapper";
import { SelectPlanDialogWrapper } from "../components/ui/SelectPlanDialogWrapper";
import { ProductPlans } from "../dto/EnumDTO";
import { useGeneratingReportSocket } from "../hooks/useGeneratingReportSocket";
import { CircularProgress } from "@material-ui/core";
import { Dialog } from "../components/ui/Dialog";
import {Auth} from "aws-amplify";

export function SummaryContainer() {
  const { vrm } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  const hasCredits = useShallowEqualSelector(hasCreditsSelector);
  const carDetails = useShallowEqualSelector(vehicleItemSelector(vrm));
  const hasSubscription = useShallowEqualSelector(hasSubscriptionSelector);
  const userSubscription = useShallowEqualSelector(userSubscriptionSelector);

  const [buyReport, setBuyReport] = useState<any>(null);
  const [showProgress, setShowProgress] = useState(false);
  const [showMoreModal, setShowMoreModal] = useState(false);
  const [upgradeType, setUpgradeType] = useState(UpgradePlanType.Single);
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
  const [showUseCreditDialog, setShowUseCreditDialog] = useState(false);
  const [showSelectPlanDialog, setShowSelectPlanDialog] = useState(false);

  const { UserApi } = useUserContext();
  const { VehicleApi } = useVehicleContext();

  const userAccountResponse = useResource(() => UserApi.getUserAccount(), []);
  const vehicleListResponse = useResource(() => VehicleApi.getShortList(), []);
  const subscriptionStatusResponse = useResource(() => UserApi.getSubscriptionStatus(), []);

  useResponseHandler(vehicleListResponse, (data, error) => {
    if (data && !error) {
      dispatch(setVehicleList({ list: data }));
    }
  });

  useResponseHandler(userAccountResponse, (data, error) => {
    if (data && !error) {
      dispatch(setUserAccount({ account: data }));
    }
  });

  const reportVehicleTitle = useMemo(
    () => [carDetails?.make, carDetails?.model].filter(Boolean).join(" "),
    [carDetails],
  );

  useGeneratingReportSocket({
    isApple: true,
    vrm: buyReport?.vrm,
    paymentType: "purchase",
    product: buyReport?.product,
    skip: !buyReport,
    onDone: () => {
      setBuyReport(null);
      setShowProgress(false);

      vehicleListResponse.refetch();
      userAccountResponse.refetch();
    },
    onError: () => {
      setBuyReport(null);
      setShowProgress(false);
    },
  });

  const [isTrade, setIsTrade] = useState(false);
  useMemo(() => {
    Auth.currentAuthenticatedUser().then((d) => {
      if ("attributes" in d && "custom:tags" in d["attributes"]) {
        const tags_attr = d["attributes"]["custom:tags"];
        try {
          if (tags_attr) setIsTrade(JSON.parse(tags_attr).some((t) => "TRADE" === t));
        } catch (e) {
          setIsTrade(false);
        }
      }
    });
  }, [isTrade]);

  return (
    <AppRouteLayout
      withFooter={false}
      screenTitle="Vehicle Summary"
      leftComponent={<BackButton onClick={() => history.goBack()} />}
    >
      {Boolean(!vehicleListResponse.loading && !carDetails) && (
        <span className="align-self-center">Vehicle Not Found</span>
      )}

      {Boolean(vehicleListResponse.loading && !carDetails) && <Circular />}

      {carDetails && (
        <CarDetails
          isTrade={isTrade}
          onComplete={(product) => {
            setShowProgress(true);
            setBuyReport({
              vrm: carDetails.vrm,
              product: {
                id: product,
                priceFractional: 99,
                priceWhole: product === ProductPlans.SubsSingleReport ? 4 : 5,
              },
            });
          }}
          carDetails={carDetails}
          subscriptionStatus={userSubscription?.status}
          onMoreReportClick={() => setShowMoreModal(true)}
          onBuyCreditsCompleteClick={() => {
            userAccountResponse.refetch();
            subscriptionStatusResponse.refetch();
          }}
          onViewReportClick={() => history.push(`/car-report/${carDetails.reportId}`)}
          onBuyReportClick={() => {
            if (hasSubscription && !hasCredits) {
              setShowUpgradeDialog(true);
              setUpgradeType(UpgradePlanType.Renew);
            }

            if (!hasSubscription && !hasCredits) {
              setShowSelectPlanDialog(true);
            }

            if (hasCredits) {
              setShowUseCreditDialog(true);
            }
          }}
        />
      )}

      <SelectPlanDialogWrapper
        open={showSelectPlanDialog}
        onDismiss={() => setShowSelectPlanDialog(false)}
        onUpgradeClick={() => {
          setShowUpgradeDialog(true);
          setShowSelectPlanDialog(false);
          setUpgradeType(UpgradePlanType.Upgrade);
        }}
        onBuySingleClick={() => {
          setShowUpgradeDialog(true);
          setShowSelectPlanDialog(false);
          setUpgradeType(UpgradePlanType.Single);
        }}
      />

      <UpgradePlanDialogWrapper
        vrm={vrm!}
        type={upgradeType}
        open={showUpgradeDialog}
        onComplete={() => {
          setShowUpgradeDialog(false);

          vehicleListResponse.refetch();
        }}
        onDismiss={() => setShowUpgradeDialog(false)}
      />

      <UseCreditDialogWrapper
        vrm={vrm}
        open={showUseCreditDialog}
        title={reportVehicleTitle}
        onDismiss={() => setShowUseCreditDialog(false)}
        onComplete={() => {
          setShowUseCreditDialog(false);

          vehicleListResponse.refetch();
        }}
      />

      <ModalDialog
        open={showMoreModal}
        handleClose={() => setShowMoreModal(false)}
        sourcePath={`${APP_WP_URL}/wp-json/wp/v2/posts/1357`}
      />

      <Dialog contentClassName="p-3" open={showProgress}>
        <CircularProgress />
      </Dialog>
    </AppRouteLayout>
  );
}
