import "./assets/add-vehicle-info-item.scss";

import React from "react";

interface Props {
  readonly label: string;
  readonly value?: string;
}

export function AddVehicleInfoItem({ label, value = "" }: Props) {
  return (
    <div className="d-flex flex-column mb-3 add-vehicle-info-item">
      <span className="info-item-label">{label}</span>
      <span className="info-item-value">{value}</span>
    </div>
  );
}
