import React from "react";
import Dialog from "@material-ui/core/Dialog";

import * as styles from "./app-logout-confirmation-modal";

export default function AppLogoutConfirmationModal(props) {
  return (
    <Dialog open={props.open} onClose={props.onCancel}>
      <styles.ModalContainer>
        <div className="modal-title">Please confirm your action</div>
        <div className="modal-text">Are you sure you want to log out from your account?</div>
        <div className="modal-actions">
          <styles.CancelButton onClick={props.onCancel}>Cancel</styles.CancelButton>
          <styles.SubmitButton type="button" color="danger" onClick={props.onSubmit}>
            Log Out
          </styles.SubmitButton>
        </div>
      </styles.ModalContainer>
    </Dialog>
  );
}
