import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import React, { useCallback, useEffect, useMemo } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { AuthState } from "../dto/EnumDTO";
import ReportPage from "../views/ReportPage";
import { Routes } from "../constants/Routes";
import { MainContainer } from "./MainContainer";
import { validToken } from "../utils/ValidateUtils";
import { LogoutContainer } from "./LogoutContainer";
import ErrorPage from "../views/ErrorPage/ErrorPage";
import { AuthContainer } from "./auth/AuthContainer";
import { SummaryContainer } from "./SummaryContainer";
import { AccountContainer } from "./AccountContainer";
import { MotDataContainer } from "./MotDataContainer";
import { AppLayout } from "../components/app/AppLayout";
import { RegisterContainer } from "./RegisterContainer";
import { AuthCheckContainer } from "./AuthCheckContainer";
import { ShortListContainer } from "./ShortListContainer";
import { ComparisonContainer } from "./ComparisonContainer";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { RegisterProductContainer } from "./RegisterProductContainer";
import { RegisterCheckoutContainer } from "./RegisterCheckoutContainer";
import { MainQuestionnaire } from "./auth/SurveyPage/SurveyPage";
import {
  authStateSelector,
  changeAuthState,
  setToken,
  tokenSelector,
} from "../reducers/authReducer";

const RootContainer = () => {
  const location = useLocation();

  const dispatch = useDispatch();

  const token = useShallowEqualSelector(tokenSelector);
  const authState = useShallowEqualSelector(authStateSelector);

  const isValidToken = useMemo(() => validToken(token), [token, location.pathname]);

  const authAuthorized = useMemo(() => authState === AuthState.Authorized, [authState]);

  const checkUser = useCallback(() => {
    if (!isValidToken) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          dispatch(setToken({ token: user.signInUserSession }));
        })
        .catch(() => dispatch(changeAuthState({ authState: AuthState.Unauthorized })));
    }
  }, [isValidToken, dispatch]);

  useEffect(() => {
    if (!authAuthorized || isValidToken) {
      return;
    }

    checkUser();

    const interval = setInterval(checkUser, 60 * 1000);

    return () => clearInterval(interval);
  }, [checkUser, authAuthorized, isValidToken]);

  return (
    <AppLayout>
      <Switch>
        <Route path="/" exact={true} component={MainContainer} />

        <Route path="/auth/check" component={AuthCheckContainer} />
        <Route path="/auth/register" component={RegisterContainer} />
        <Route path="/auth/product" component={RegisterProductContainer} />
        <Route path="/auth/checkout" component={RegisterCheckoutContainer} />
        <Route path="/auth/register-c" component={MainQuestionnaire} />

        <Route path={Routes.AuthRegister} component={AuthContainer} />

        <Route exact={true} path="/auth/logout" component={LogoutContainer} />

        {Boolean(!authAuthorized || !isValidToken) && <Redirect to="/auth/register" from="/" />}

        <Route path="/shortlist/:vrm/mot" component={MotDataContainer} />
        <Route path="/shortlist/:vrm/summary" component={SummaryContainer} />
        <Route path="/shortlist" component={ShortListContainer} />

        <Route path="/car-report/:id/:tab?" component={ReportPage} />

        <Route path="/account" component={AccountContainer} />

        <Route path="/comparison" component={ComparisonContainer} />

        <Route component={ErrorPage} />
      </Switch>
    </AppLayout>
  );
};

export default RootContainer;
