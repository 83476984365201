import cx from "classnames";
import React, { useMemo } from "react";

import MotorsLogoImage from "./assets/motors.png";
import AutoTraderLogoImage from "./assets/autotrader.png";
import { VehicleProps } from "../../api/vehicle/VehicleDTO";

interface Props {
  readonly item: VehicleProps;
  readonly className?: string;
}

export function ShortListCardSourceLink({ item, className }: Props) {
  const isMotors = useMemo(() => item.providerId === 1, [item.providerId]);
  const isAutoTrader = useMemo(() => item.providerId === 2, [item.providerId]);

  if (!isAutoTrader && !isMotors) {
    return null;
  }

  return (
    <div
      className={cx(
        "pr-3 pr-md-2 pl-2 pl-md-3 align-items-center justify-content-cente cursor-pointerr",
        className,
      )}
    >
      <a href={item.sourceLink} rel="noopener noreferrer" target="_blank">
        {isMotors && <img width={40} alt="provider" src={MotorsLogoImage} />}
        {isAutoTrader && <img width={40} alt="provider" src={AutoTraderLogoImage} />}
      </a>
    </div>
  );
}
