export const PROVIDED_MANUALLY = 0;
export const PROVIDED_BY_MOTORS = 1;
export const PROVIDED_BY_AUTOTRADER = 2;

export const MOTORS_WEBSITE_URL = "motors.co.uk";
export const AUTOTRADER_WEBSITE_URL = "autotrader.co.uk";

export const PROVENANCE_LIST_ITEMS = [
  "hasOutstandingFinance",
  "hasWrittenOffMarker",
  "hasStolenMarker",
  "hasScrappedMarker",
  "hasOutstandingRecalls",
  "numberOfKeepers",
  "hasExportedMarker",
  "hasImportedMarker",
  "hasPreviousColours",
  "taxiStatusId",
];

export const FREE_PROVENANCE_LIST_ITEMS = [
  "hasExportedMarker",
  "hasImportedMarker",
  "hasPreviousColours",
  "taxiStatusId",
];

export const VEHICLE_CATEGORIES: string[] = [
  "economy",
  "identity",
  "keyDates",
  "evDetails",
  "provenance",
  "dimensions",
  "engineSpecs",
  "basicDetails",
  "componentRisks",
  "componentDetails",
];

export const EXCLUDED_CATEGORIES: string[] = ["componentRisks", "identity"];

export const EXCLUDED_FIELDS: string[] = ["hasVrmChanges"];

export const COMPARE_COLUMN_CATEGORIES: string[] = [
  "provenance",
  "basicDetails",
  "engineSpecs",
  "dimensions",
  "componentDetails",
  "economy",
  "identity",
  "keyDates",
  "componentRisks",
  "evDetails",
];

export const FIRST_COLUMNS_CATEGORIES: string[] = [
  "modelDetails",
  "economy",
  "identity",
  "keyDates",
  "componentDetails",
  "componentRisks",
  "evDetails",
];
export const SECOND_COLUMNS_CATEGORIES: string[] = [
  "provenance",
  "basicDetails",
  "engineSpecs",
  "dimensions",
];
export const MOBILE_COLUMNS_CATEGORIES: string[] = [
  "provenance",
  "basicDetails",
  "engineSpecs",
  "dimensions",
  "componentDetails",
  "economy",
  "identity",
  "keyDates",
  "componentRisks",
  "evDetails",
];

export const VEHICLE_SORTING_ITEMS = [
  {
    title: "Price",
    value: "listPrice",
    category: "basicDetails",
  },
  {
    title: "Mileage",
    value: "mileage",
    category: "basicDetails",
  },
  {
    title: "Date of Manufacture",
    value: "dateOfManufacture",
    category: "basicDetails",
  },
  {
    title: "MPG (Combined)",
    value: "combinedMpg",
    category: "economy",
  },
  {
    title: "Fuel cost per mile",
    value: "costPerMile",
    category: "basicDetails",
  },
  {
    title: "Boot Capacity",
    value: "bootCapacity",
    category: "dimensions",
  },
];

export const UPSEL_ROW_COUNT = 5;
export const COMPARISON_MAX_COUNT = 4;
export const COMPARISON_MOBILE_MAX_COUNT = 2;
