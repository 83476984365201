import "./assets/car-details.scss";

import cx from "classnames";
import React, {useMemo} from "react";

import {CarDetailedCard} from "./CarDetailedCard";
import {CarBuyReportCard} from "./CarBuyReportCard";
import {CarGeneralInfoCard} from "./CarGeneralInfoCard";
import {VehicleProps} from "../../api/vehicle/VehicleDTO";
import {CarBasicDetailsCard} from "./CarBasicDetailsCard";
import {getSummaryColumns} from "../../utils/VehicleHalpers";
import {ProductPlans, SubscriptionStatus} from "../../dto/EnumDTO";
import {CarBuyCreditsCardWrapper} from "./CarBuyCreditsCardWrapper";

interface Props {
  readonly carDetails: VehicleProps;
  readonly subscriptionStatus?: SubscriptionStatus;

  readonly onBuyReportClick: () => void;
  readonly onBuyCreditsCompleteClick: () => void;

  readonly onMoreReportClick: () => void;
  readonly onViewReportClick: () => void;

  readonly onComplete?: (product: ProductPlans) => void;
  readonly isTrade?: boolean;
}

export function CarDetails({
  onComplete,
  carDetails,
  onBuyReportClick,
  onViewReportClick,
  onMoreReportClick,
  subscriptionStatus,
  onBuyCreditsCompleteClick,
  isTrade,
}: Props) {
  const reportId = useMemo(() => carDetails.reportId, [carDetails]);
  const hasReport = useMemo(() => Boolean(reportId), [reportId]);
  const hasSubscription = useMemo(() => subscriptionStatus === SubscriptionStatus.MonthlyAccess, [
    subscriptionStatus,
  ]);
  const { firstColumn = [], secondColumn = [], mobileColumn = [] } = useMemo(
    () => getSummaryColumns(carDetails),
    [carDetails],
  );

  return (
    <div className="container d-flex flex-column flex-lg-row car-details">
      <div
        className={cx("d-flex flex-column mb-4 car-details-content", {
          "flex-lg-2": hasReport && !hasSubscription,
          "flex-lg-1": !hasReport || hasSubscription,
        })}
      >
        <span className="text-primary fl-4 mb-3 font-weight-bold car-name-title">{`${carDetails.make} ${carDetails.model}`}</span>

        <div className="d-flex flex-column flex-lg-row mb-lg-1">
          <CarGeneralInfoCard carDetails={carDetails} />
          <CarBasicDetailsCard
            provenance={carDetails.provenance}
            basicDetails={carDetails.basicDetails}
            keyDates={carDetails.keyDates}
          />
          {(!hasReport || hasSubscription) && (
            <CarBuyReportCard
              vrm={carDetails.vrm}
              hasReport={hasReport}
              onComplete={onComplete}
              onBuyClick={onBuyReportClick}
              onMoreClick={onMoreReportClick}
              onViewReportClick={onViewReportClick}
              isTrade={isTrade}
            />
          )}

          {hasReport && !hasSubscription && (
            <CarBuyReportCard
              vrm={carDetails.vrm}
              hasReport={hasReport}
              onComplete={onComplete}
              onBuyClick={onBuyReportClick}
              onMoreClick={onMoreReportClick}
              className="d-flex d-lg-none mt-5"
              onViewReportClick={onViewReportClick}
              isTrade={isTrade}
            />
          )}
        </div>

        <div className="d-flex flex-column flex-lg-row mt-lg-1">
          <CarDetailedCard
            list={mobileColumn}
            hasReport={hasReport}
            onBuyReportClick={onBuyReportClick}
            className="d-flex d-lg-none pr-lg-1 pr-xl-2"
          />
          {firstColumn.length > 0 && (
            <CarDetailedCard
              list={firstColumn}
              hasReport={hasReport}
              onBuyReportClick={onBuyReportClick}
              className="d-none d-lg-flex pr-lg-1 pr-xl-2"
            />
          )}
          {secondColumn.length > 0 && (
            <CarDetailedCard
              list={secondColumn}
              hasReport={hasReport}
              onBuyReportClick={onBuyReportClick}
              className={cx("d-none d-lg-flex pl-lg-1 pl-xl-2", {
                "pr-lg-1 pr-xl-2": !hasSubscription,
              })}
            />
          )}

          {(firstColumn.length === 0 || secondColumn.length === 0) && (
            <div
              className={cx(
                "flex-1 flex-column car-detailed-card d-none d-lg-flex pl-lg-1 pl-xl-2",
                {
                  "pr-lg-1 pr-xl-2": !hasSubscription,
                },
              )}
            />
          )}
          {!hasReport && !hasSubscription && (
            <CarBuyCreditsCardWrapper onComplete={onBuyCreditsCompleteClick} />
          )}

          {hasSubscription && <div className="d-flex flex-column flex-1 mb-7 mb-lg-0 pt-lg-5" />}
        </div>
      </div>

      {hasReport && !hasSubscription && (
        <div className="d-flex flex-column flex-1 mb-7 mb-lg-0 pt-lg-5">
          <CarBuyReportCard
            vrm={carDetails.vrm}
            hasReport={hasReport}
            onComplete={onComplete}
            onBuyClick={onBuyReportClick}
            onMoreClick={onMoreReportClick}
            className="d-none d-lg-flex mb-5"
            onViewReportClick={onViewReportClick}
            isTrade={isTrade}
          />

          <CarBuyCreditsCardWrapper className="flex-0" onComplete={onBuyCreditsCompleteClick} />
        </div>
      )}
    </div>
  );
}
