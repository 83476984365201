import React from "react";
import { useDispatch } from "react-redux";

import { useQuery } from "../../hooks/useQuery";
import { AuthVehicleInfo } from "./AuthVehicleInfo";
import { useResource } from "../../hooks/useResource";
import { setRegisterState } from "../../reducers/authReducer";
import { useVehicleContext } from "../../api/vehicle/VehicleContext";
import { useResponseHandler } from "../../hooks/useResponseHandler";

interface Query {
  readonly vrm?: string;
}

export function AuthVehicleInfoWrapper() {
  const dispatch = useDispatch();

  const { VehicleApi } = useVehicleContext();

  const { vrm } = useQuery<Query>();

  const vehicleResponse = useResource(() => (vrm ? VehicleApi.lookupVehicle(vrm!) : undefined), [
    vrm,
  ]);

  useResponseHandler(vehicleResponse, (data, error) => {
    if (data && !error) {
      dispatch(setRegisterState({ state: { vehicle: data } }));
    }
  });

  if (!vrm) {
    return null;
  }

  return (
    <AuthVehicleInfo
      error={vehicleResponse.error}
      vehicle={vehicleResponse.data}
      loading={vehicleResponse.loading}
      onBackClick={() => window.history.back()}
    />
  );
}
