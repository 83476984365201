export const detailsPageStyles = {
  pageTitle: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
    color: "#22A6B3",
    marginBottom: 10,
  },
  orderText: {
    fontSize: 12,
  },
  boldText: {
    fontWeight: 700,
  },
  listAccent: {
    fontSize: 14,
    color: "#EFEFEFEF",
  },
  qa: {
    fontSize: 12,
  },
  detailsHeader: {
    display: "flex",
    flexDirection: "row",
  },
  mainImg: {
    height: 200,
  },
  mainInfo: {
    marginLeft: 50,
    marginTop: 50,
    fontSize: 16,
  },
  tableBlock: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  table: {
    width: 265,
    margin: 5,
  },
  tableTitle: {
    marginBottom: 15,
    fontWeight: 700,
    color: "#22A6B3",
  },
  tableBody: {
    display: "flex",
    flexDirection: "column",
  },
  tableLine: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 1,
  },
  bgGray: {
    backgroundColor: "#EFEFEFEF",
  },
};
