import "./assets/short-list-card-details.scss";

import cx from "classnames";
import React, { useMemo } from "react";

import NewLinkIcon from "./assets/new-link.png";
import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";
import { VehicleProps } from "../../api/vehicle/VehicleDTO";
import { formatGroupNumber } from "../../utils/FormatUtils";
import { ShortListCardDetailsItem } from "./ShortListCardDetailsItem";
import { getTaxiInfo, hasTaxiMarker } from "../../views/ReportPage/common/taxi";

interface Props {
  readonly item: VehicleProps;
  readonly onMotClick: () => void;
  readonly onSummaryClick: () => void;
  readonly onSalvageRecordsClick: () => void;
  readonly onEstimatedMileageClick: () => void;
  readonly onSalvageRecordsTitleClick: () => void;
  readonly isTrade?: boolean;
}

export function ShortListCardDetails({
  item,
  onMotClick,
  onSummaryClick,
  onSalvageRecordsClick,
  onEstimatedMileageClick,
  onSalvageRecordsTitleClick,
  isTrade,
}: Props) {
  const mileage = useMemo(() => {
    if (item.basicDetails?.mileage) {
      return formatGroupNumber(item.basicDetails.mileage);
    }

    return "?";
  }, [item.basicDetails]);
  const costPerMile = useMemo(() => {
    if (item.economy?.costPerMile) {
      return `${Math.round(item.economy.costPerMile)}p`;
    }

    return "?";
  }, [item.economy]);

  const residualValue = useMemo(() => {
    if (item.economy?.residualValue) {
      // return `£${Math.round(item.economy.residualValue)}`;
      return `£${formatGroupNumber(item.economy.residualValue)}`;
    }

    return "?";
  }, [item.economy]);

  const onFinanceOffer = () => {
    window.open(
      "https://carguide.carmoney.co.uk/car-finance-calculator?utm_source=carguide&amp;utm_medium=panel_button&amp;utm_campaign=shortlist",
      "_blank",
    );
  };

  return (
    <div className="d-flex flex-column flex-lg-row bg-muted p-2 p-lg-3 mr-2 mr-md-3 mx-md-3 mx-lg-0 mb-2 short-list-card-details">
      <div className="d-flex flex-1 flex-column flex-lg-row mb-2 mb-lg-0 flex-wrap flex-lg-nowrap">
        <div className="d-flex flex-lg-1">
          {/* <ShortListCardDetailsItem
            value={mileage}
            className="pr-1"
            title={item.basicDetails.isUserMileage ? "Mileage" : "Estimated Mileage"}
            onClick={item.basicDetails.isUserMileage ? undefined : onEstimatedMileageClick}
            titleClassName={cx({ "text-decoration-underline": !item.basicDetails.isUserMileage })}
          /> */}
        </div>
        <div className="d-flex flex-lg-1">
          {isTrade && (
              <ShortListCardDetailsItem
                  className="px-1"
                  value={residualValue}
                  title="Residual value"
              />
          )}
          {!isTrade && (
              <ShortListCardDetailsItem
                  className="px-1"
                  value={costPerMile}
                  title="Fuel cost per mile"
              />
          )}
          <ShortListCardDetailsItem
            className="px-1"
            title="V5c Last Issued"
            value={item.keyDates?.lastKeeperChangeDate || "N/A"}
          />
          <ShortListCardDetailsItem
            title="MOT Due"
            className="px-1"
            value={item.basicDetails?.motStatus}
          />
        </div>
        <div className="d-flex flex-lg-1">
          <ShortListCardDetailsItem
            className="px-1"
            title="Plate Change"
            value={item.provenance.hasVrmChanges ? "Yes" : "No"}
            valuesClassName={cx({ "text-danger": item.provenance.hasVrmChanges })}
          />
          <ShortListCardDetailsItem
            className="px-1"
            value={hasTaxiMarker(item.provenance.taxiStatusId) ? "Yes" : "No"}
            title={getTaxiInfo(item.provenance.taxiStatusId)}
            valuesClassName={cx({ "text-danger": item.provenance.taxiStatusId > 0 }, "taxi-title")}
          />
          <ShortListCardDetailsItem
            className="px-1"
            value={item.provenance.hasSalvageRecords ? "Found" : "Not Found"}
            valuesClassName={cx({ "text-danger": item.provenance.hasSalvageRecords })}
            title={
              item.provenance.hasSalvageRecords ? (
                <div className="d-flex flex-column align-items-center">
                  <button
                    onClick={onSalvageRecordsTitleClick}
                    className="d-inline-flex text-primary cursor-pointer text-decoration-underline flex-column align-items-center bg-none border-0 bg-transparent m-0 p-0 outline-none"
                  >
                    <span className="text-center">Unrecorded write-off</span>
                  </button>
                  <button
                    onClick={onSalvageRecordsClick}
                    className="d-inline-flex text-primary flex-column align-items-center bg-none border-0 bg-transparent m-0 p-0 outline-none"
                  >
                    <img alt="salvage" width={16} src={NewLinkIcon} className="mt-2 text-primary" />
                  </button>
                </div>
              ) : (
                "Unrecorded write-off"
              )
            }
          />
        </div>
      </div>
      <div className="d-flex flex-column pl-1">
        <ActionButton className="mb-2" onClick={onSummaryClick} color={ColorPalette.Secondary}>
          View Summary
        </ActionButton>

        <ActionButton className="mb-2" color={ColorPalette.Secondary} onClick={onMotClick}>
          View MOT Data
        </ActionButton>

        {!isTrade && (
          <ActionButton
            id="carmoney-shortlist-button-trackbutton"
            className="mb-2"
            color={ColorPalette.Orange}
            onClick={onFinanceOffer}
          >
            View Car Finance Offer
          </ActionButton>
        )}
        {!isTrade && (
          <div className="mb-2">No Deposit with low APRs from 5.9%, saving £1,155 on average!</div>
        )}
      </div>
    </div>
  );
}
