import {isNumber} from "lodash";
import React, {useMemo} from "react";

import {
    AuthVehicleBasicDetailsProps,
    AuthVehicleKeyDatesProps,
    VehicleBasicDetailsProps,
    VehicleKeyDataProps,
} from "../../api/vehicle/VehicleDTO";
import {BasicDetailsRow} from "./BasicDetailsRow";
import {formatGroupNumber, formatSecondsToString} from "../../utils/FormatUtils";

interface Props {
  readonly isReveal?: boolean;
  readonly className?: string;
  readonly basicDetails: VehicleBasicDetailsProps | AuthVehicleBasicDetailsProps;
  readonly keyDates: VehicleKeyDataProps | AuthVehicleKeyDatesProps;
}

export function BasicDetailsSecondRow({ className, isReveal, basicDetails, keyDates }: Props) {

  const dateOfManufacture = useMemo(() => {
    if (isNumber(basicDetails.manufactureDate)) {
      return formatSecondsToString(basicDetails.manufactureDate);
    }

    return keyDates.manufactureDate;
  }, [basicDetails.manufactureDate]);

  return (
    <BasicDetailsRow className={className}>
      <div className="d-flex flex-grow-1 mx-1 mx-lg-2 flex-shrink-1 flex-column align-items-center justify-content-end flex-1 card-cell">
        <span className="font-weight-bold text-center fl-3">{dateOfManufacture}</span>
        <span className="mt-1 text-center font-weight-light card-cell-title-small">
          Date of manufacture
        </span>
      </div>
      <div className="d-flex flex-grow-1 mx-1 mx-lg-2 flex-shrink-1 flex-column align-items-center justify-content-end flex-1 card-cell">
        <span className="font-weight-bold text-center fs-4 lh-3">{basicDetails.doors || 0}</span>
        <span className="mt-1 text-center font-weight-light card-cell-title-small">Doors</span>
      </div>
      <div className="d-flex flex-grow-1 mx-1 mx-lg-2 flex-shrink-1 flex-column align-items-center justify-content-end flex-1 card-cell">
        <span className="font-weight-bold text-center fl-3">
          {formatGroupNumber(basicDetails.mileage)}
        </span>
        <span className="mt-1 text-center font-weight-light card-cell-title-small">
          {basicDetails.isUserMileage && !isReveal ? "Mileage" : "Estimated Mileage"}
        </span>
      </div>
    </BasicDetailsRow>
  );
}
