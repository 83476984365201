import styled from "styled-components";
import Button from "../CustomButtons/Button";

export const ModalContainer = styled.div`
  .modal-title {
    background: #22a6b3;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    padding: 15px 10px;
  }
  .modal-text {
    margin: 15px 10px;
  }
  .modal-actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    padding: 0 10px;
  }
`;

export const CancelButton = styled(Button)({});
export const SubmitButton = styled(Button)({
  margin: "5px 0 5px 10px",
});
