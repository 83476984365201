import React from "react";

import { GasIcon } from "../icons/GasIcon";
import { BodyIcon } from "../icons/BodyIcon";
import { BasicDetailsRow } from "./BasicDetailsRow";

import {
  AuthVehicleBasicDetailsProps,
  VehicleBasicDetailsProps,
} from "../../api/vehicle/VehicleDTO";
import { PaintIcon } from "../icons/PaintIcon";

interface Props {
  readonly className?: string;
  readonly basicDetails: VehicleBasicDetailsProps | AuthVehicleBasicDetailsProps;
}

export function BasicDetailsFirstRow({ basicDetails, className }: Props) {
  return (
    <BasicDetailsRow className={className}>
      <div className="d-flex flex-grow-1 mx-1 mx-lg-2 flex-shrink-1 flex-column align-items-center justify-content-end flex-1 card-cell">
        <PaintIcon />
        <span className="mt-1 text-center font-weight-light card-cell-title-small">
          {basicDetails.colour}
        </span>
      </div>
      <div className="d-flex flex-grow-1 mx-1 mx-lg-2 flex-shrink-1 flex-column align-items-center justify-content-end flex-1 card-cell">
        <GasIcon />
        <span className="mt-1 text-center font-weight-light card-cell-title-small">
          {basicDetails.fuelType}
        </span>
      </div>
      <div className="d-flex flex-grow-1 mx-1 mx-lg-2 flex-shrink-1 flex-column align-items-center justify-content-end flex-1 card-cell">
        <BodyIcon />
        <span className="mt-1 text-center font-weight-light card-cell-title-small">
          {basicDetails.bodyStyle}
        </span>
      </div>
    </BasicDetailsRow>
  );
}
