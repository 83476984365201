import "./assets/action-button.scss";

import React from "react";
import cx from "classnames";
import { Button as MUIButton, ButtonProps, CircularProgress } from "@material-ui/core";

import { ColorPalette } from "../../theme/ColorPalette";

interface Props extends Omit<ButtonProps, "color"> {
  readonly fluid?: boolean;
  readonly loading?: boolean;
  readonly color?:
    | ColorPalette.Danger
    | ColorPalette.Primary
    | ColorPalette.Success
    | ColorPalette.Secondary
    | ColorPalette.MiddleGray
    | ColorPalette.Blue
    | ColorPalette.Yellow
    | ColorPalette.Orange;
  readonly circularColor?: ColorPalette;
  readonly onClick?: (args: any) => void;
}

export function ActionButton({
  color = ColorPalette.Primary,
  fluid,
  loading,
  children,
  disabled,
  className,
  circularColor = ColorPalette.White,
  ...props
}: Props) {
  return (
    <MUIButton
      {...props}
      disabled={disabled || loading}
      className={cx(
        "outline-none action-button",
        {
          "flex-1": fluid,
          "fixed-size": !fluid,
          "bg-blue": color === ColorPalette.Blue,
          "bg-danger": color === ColorPalette.Danger,
          "bg-yellow": color === ColorPalette.Yellow,
          "bg-primary": color === ColorPalette.Primary,
          "bg-success": color === ColorPalette.Success,
          "bg-secondary": color === ColorPalette.Secondary,
          "bg-orange": color === ColorPalette.Orange,
          "cancel-button": color === ColorPalette.MiddleGray,
          disabled: disabled || loading,
        },
        className,
      )}
    >
      {loading ? (
        <CircularProgress
          size={18}
          className="circular-progress"
          style={{ color: circularColor }}
        />
      ) : (
        children
      )}
    </MUIButton>
  );
}
