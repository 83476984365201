import "./assets/car-general-info-card.scss";

import React from "react";
import PropTypes from "prop-types";

import MotorsLogo from "../../assets/img/motors.png";
import AutoTraderLogo from "../../assets/img/autotrader.png";

CarGeneralInfoCard.propTypes = {
  carDetails: PropTypes.object.isRequired,
};

export function CarGeneralInfoCard({ carDetails }) {
  return (
    <div className="d-flex mr-lg-1 mr-xl-2 flex-column justify-content-between flex-1 car-general-info-card">
      <div className="d-flex flex-1 flex-column justify-content-center">
        <img className="w-100" src={carDetails.imageUrl} alt="car_photo" />
      </div>

      <div className="d-flex justify-content-center my-3 br-1 align-items-center position-relative">
        <div className="d-flex align-items-center justify-content-center car-wrm-container">
          <span className="font-weight-bold fl-4">{carDetails.vrm}</span>
        </div>

        {carDetails.providerId === 1 && (
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={carDetails.sourceLink || "#"}
            className="position-absolute trade-logo"
          >
            <img alt="motors" src={MotorsLogo} />
          </a>
        )}

        {carDetails.providerId === 2 && (
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={carDetails.sourceLink || "#"}
            className="position-absolute trade-logo"
          >
            <img width={32} alt="motors" src={AutoTraderLogo} />
          </a>
        )}
      </div>
    </div>
  );
}
