import styled from "styled-components";

export const Container = styled.div`
  margin: 15px 0 0;

  .section-title {
    font-weight: 500;
    font-size: 20px;
    margin: 0 0 5px;
  }
  .section-description {
    margin: 0 0 15px;
  }

  .mot-repairs {
    .mot-repairs-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2vw;
      margin: 0 0 20px;

      .mot-repair {
        display: flex;
        border-bottom: 1px solid #e0dfdf85;
        padding: 7px 0;

        .mot-repair-icon {
          color: #f9ca24;
          margin: 0 10px 0 0;
        }
        .mot-repair-content {
          .mot-repair-title {
            font-weight: 500;
          }
        }
      }
    }
  }
  .wear-and-tear {
    margin: 0 0 25px;

    .wear-and-tear-repairs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 2vw;

      .repairs-icon {
        text-align: center;
        margin: 0 0 10px;
      }
      .repairs-title {
        text-align: center;
        font-weight: 500;
        padding: 0 45px;
      }
      .repairs-estimates {
        .no-repairs {
          text-align: center;
          margin: 20px 0 0;
          color: grey;
        }
        .estimate-item {
          display: flex;
          padding: 20px 0;
          border-bottom: 1px solid #e0dfdf85;

          .estimate-icon {
            color: #22a6b3;
            margin: 0 10px 0 0;
          }
          .estimate-content {
            .estimate-description {
            }
            .estimate-price {
              color: #22a6b3;

              .price-value {
                font-size: 20px;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }
`;
