import React from "react";
import cx from "classnames";

import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props {
  readonly color?: any;
  readonly title?: string;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly onClick: () => void;
}

export function MotorwayButton({
  onClick,
  disabled,
  className,
  title = "Compare prices",
  color = ColorPalette.Blue,
}: Props) {
  return (
    <ActionButton
      color={color}
      onClick={onClick}
      disabled={disabled}
      className={cx("d-flex justify-content-center align-items-center", className)}
    >
      <span className="lh-3">{title}</span>
    </ActionButton>
  );
}
