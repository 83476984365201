import React, { useEffect, useMemo, useState } from "react";

import { Dialog } from "./Dialog";
import { Analytics } from "../../utils/Analytics";
import { ProductProps } from "../../dto/ProductDTO";
import { StripeContainer } from "../stripe/StripeContainer";
import { useUpgradePaymentSocket } from "../../hooks/useUpgradePaymentSocket";
import { StripeCheckoutFormWrapper } from "../stripe/StripeCheckoutFormWrapper";
import { useGeneratingReportSocket } from "../../hooks/useGeneratingReportSocket";
import { StripeUpgradeAccountFormWrapper } from "../stripe/StripeUpgradeAccountFormWrapper";
import { BUY_PRODUCT_CATEGORY, BuyProductActions } from "../../constants/AnalyticsConstants";

const analytics = new Analytics();

export interface UpgradePlanDialogProps {
  readonly vrm?: string;
  readonly open: boolean;
  readonly isCredit?: boolean;
  readonly onDismiss: () => void;
  readonly product?: ProductProps;
  readonly onComplete?: (key?: string) => void;
}

export function UpgradePlanDialog({
  vrm,
  open,
  product,
  isCredit,
  onDismiss,
  onComplete,
}: UpgradePlanDialogProps) {
  const [isApple, setIsApple] = useState(false);
  const [skipSocket, setSkipSocket] = useState(true);
  const [startProcess, setStartProcess] = useState<number | undefined>();
  const [generationLoader, setGenerationLoader] = useState(false);

  const isUpgrade = useMemo(() => product?.id === "payg_upgrade", [product?.id]);
  const price = useMemo(
    () => (product ? `${product?.priceWhole}.${product?.priceFractional}` : 0),
    [product?.priceWhole, product?.priceFractional],
  );

  const { progress } = useGeneratingReportSocket({
    vrm,
    isApple,
    product,
    isUpgrade,
    paymentType: "purchase",
    skip: skipSocket || Boolean(!vrm && !isCredit),
    onDone: (key) => {
      setSkipSocket(true);

      if (onComplete && open) {
        onComplete(key);
      }

      setStartProcess(undefined);
      setGenerationLoader(false);
    },
    onError: () => {
      setStartProcess(undefined);
      setGenerationLoader(false);
    },
  });

  useUpgradePaymentSocket({
    product,
    skip: skipSocket || !isUpgrade || Boolean(vrm || isCredit),
    onDone: () => {
      setSkipSocket(true);

      if (onComplete && open) {
        onComplete();
      }

      setStartProcess(undefined);
      setGenerationLoader(false);
    },
    onError: () => {
      setStartProcess(undefined);
      setGenerationLoader(false);
    },
  });

  useEffect(() => {
    if (open && product) {
      analytics.googleEvent("event", {
        eventLabel: product.type,
        eventCategory: BUY_PRODUCT_CATEGORY,
        eventAction: BuyProductActions.BuyProductView,
      });
    }
  }, [open, product]);

  return (
    <Dialog
      open={open}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      progress={progress || startProcess}
      title="You're out of buyers report credits!"
      titleClassName="text-center justify-content-center justify-content-sm-start text-sm-left"
    >
      <span className="my-2 font-weight-medium" style={{ fontSize: "20px" }}>
        {isUpgrade ? "Upgrade your account" : `Buy single report £${price}`}
      </span>
      <span className="my-2">
        {isUpgrade ? "To upgrade your account, please input your card details below." : undefined}
      </span>

      <StripeContainer>
        {vrm || isCredit ? (
          <StripeCheckoutFormWrapper
            vrm={vrm}
            product={product}
            amountLabel="Buy single report"
            onDismiss={() => {
              setSkipSocket(true);
              onDismiss();
            }}
            submitTitle={`Buy for £${price}`}
            generationLoader={generationLoader}
            onError={() => {
              setStartProcess(undefined);
              setGenerationLoader(false);
            }}
            onComplete={(x = false) => {
              setSkipSocket(false);
              setStartProcess(0);
              setGenerationLoader(true);
              setIsApple(x);
            }}
          />
        ) : (
          <StripeUpgradeAccountFormWrapper
            product={product}
            amountLabel="Upgrade your account"
            onError={() => {
              setStartProcess(undefined);
              setGenerationLoader(false);
            }}
            onComplete={(x = false) => {
              setSkipSocket(false);
              setStartProcess(0);
              setGenerationLoader(true);
              setIsApple(x);
            }}
            submitTitle={`Buy for £${price}`}
            generationLoader={generationLoader}
            onDismiss={() => {
              setSkipSocket(true);
              onDismiss();
            }}
          />
        )}
      </StripeContainer>
    </Dialog>
  );
}
