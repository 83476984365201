import * as types from "../types/auth";

const handlers = {
  [types.INITIALIZE]: (state, { payload: context }) => {
    return {
      ...state,
      context,
    };
  },
  [types.TERMINATE]: (state) => {
    return {
      ...state,
      context: null,
    };
  },
};

const initialState = {
  context: null,
};

export default function Reducer(state = initialState, payload) {
  const handler = handlers[payload.type];
  return (handler && handler(state, payload)) || state;
}
