export const repairsPageStyles = {
  pageTitle: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
    color: "#22A6B3",
    marginBottom: 10,
  },
  orderText: {
    fontSize: 12,
    marginBottom: 10,
  },
  boldText: {
    fontWeight: 700,
  },
  motRepairCard: {
    width: 240,
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  },
  repairsTitle: {
    fontSize: 14,
    marginBottom: 10,
    fontWeight: 700,
  },
  repairsContent: {
    width: 220,
  },
  icon: {
    width: 15,
    height: 15,
    marginRight: 10,
  },
  motRepairsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  estimateWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "flex-start",
  },
  estimateIcon: {
    width: 40,
    height: 40,
    marginBottom: 15,
  },
  estimateCard: {
    width: 260,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  estimateCardContent: {
    width: 230,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  estimateCardContentPrice: {
    color: "#22A6B3",
    marginBottom: 15,
  },
  estimateCardContentPriceValue: {
    fontSize: 14,
  },
};
