import React from "react";
import cx from "classnames";
import { IconButton } from "@material-ui/core";

import { EditIcon } from "../icons/EditIcon";
import { DeleteIcon } from "../icons/DeleteIcon";
import { ShortListCompareSwitchWrapper } from "./ShortListCompareSwitchWrapper";

interface Props {
  readonly vrm: string;
  readonly className?: string;
  readonly onEditClick: () => void;
  readonly onDeleteClick: () => void;
}

export function ShortListCardControl({ vrm, className, onEditClick, onDeleteClick }: Props) {
  return (
    <div className={cx("d-flex flex-1 flex-xl-0 flex-xl-column", className)}>
      <div className="d-flex flex-1 flex-xl-column align-self-center">
        <IconButton onClick={onDeleteClick} className="mb-1">
          <DeleteIcon />
        </IconButton>

        <IconButton onClick={onEditClick}>
          <EditIcon />
        </IconButton>
      </div>
      <div className="d-flex align-items-center justify-content-center pt-2">
        <ShortListCompareSwitchWrapper vrm={vrm} />
      </div>
    </div>
  );
}
