import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import CustomerReducer from "../reducers-legacy/customerReducer";
import Error from "../reducers-legacy/errorReducer";
import VehicleInfoReducer from "../reducers-legacy/vehicleInfoReducer";
import report from "./reducers/report";
import auth from "./reducers/auth";
import ui from "./reducers/ui";
import comparison from "./reducers/comparison";
import shortlist from "./reducers/shortlist";
import { vehicleReducer, vehicleReducerPersistConfig } from "../reducers/vehicleReducer";
import { userReducer } from "../reducers/userReducer";
import { authReducer, authReducerPersistConfig } from "../reducers/authReducer";
import { persistReducer } from "redux-persist";

import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  report,
  auth,
  ui,
  newAuth: persistReducer(
    {
      ...authReducerPersistConfig,
      key: "auth",
      storage,
    },
    authReducer,
  ),
  vehicle: persistReducer(
    {
      ...vehicleReducerPersistConfig,
      key: "vehicle",
      storage,
    },
    vehicleReducer,
  ),
  comparison,
  shortlist,
  loginPage: CustomerReducer,
  vehicleInfoPage: VehicleInfoReducer,
  errorHandle: Error,
  form: formReducer,
  user: userReducer,
});

export default rootReducer;
