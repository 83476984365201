import styled from "styled-components";

export const Container = styled.div`
  width: 262px;
  min-width: 262px;
  margin: 0 25px 0 0;

  @media (min-width: 320px) and (max-width: 480px) {
    width: auto;
    min-width: auto;
    margin: 0;
  }

  .general-details {
    background: #fff;
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.1);

    .general-details-content {
      padding: 15px;

      @media (min-width: 320px) and (max-width: 480px) {
        display: flex;
      }

      .general-details-info {
        margin: 0 0 10px;

        .info-detail {
          margin: 0 0 3px;

          &.model-title {
            font-weight: 500;
          }
        }
      }
      .general-details-price {
        font-weight: 500;
        font-size: 24px;
        margin: 0 0 10px;
      }
      .general-details-score {
        display: none;

        .score-details {
          .score-details-value {
            font-size: 20px;
            font-weight: 500;
            color: #22a6b3;
          }
        }
      }
    }
  }
  .important-items {
    background: #efefef;

    @media (min-width: 320px) and (max-width: 480px) {
      display: none;
    }

    .important-items-content-container {
      padding: 15px;

      .important-items-title {
        font-size: 18px;
        font-weight: 500;
        margin: 0 0 10px;
      }
      .important-items-we-can-confirm {
        font-size: 20px;
        margin: 0 0 10px;
      }
      .important-items-list {
        margin: 0 0 10px;

        .important-item {
          display: flex;
          margin: 0 0 10px;

          .inline-info {
            display: flex;

            a {
              margin: 0 0 0 5px;
            }
          }

          .important-item-icon {
            margin: 0 10px 0 0;

            &.item-ok {
              color: #22a6b3;
            }
            &.item-attention {
              color: #eb4d4b;
            }
          }
          .important-item-text {
            &.item-attention {
              color: #eb4d4b;

              * {
                color: #eb4d4b;
              }
            }
          }
        }
      }
      .important-items-see-full-list {
        a {
          color: #30336b;
          text-decoration: underline;
          font-weight: 500;
        }
      }
    }
  }

  &.is-in-tab {
    .important-items {
      display: block;
    }
    .general-details {
      display: none;
    }
  }
`;

export const VehiclePicture = styled.div`
  background-image: url(${(props) => props.src});
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 172px;
  margin: 0 0 10px;

  @media (min-width: 320px) and (max-width: 480px) {
    height: 125px;
    margin: 0 15px 0 5px;
    width: 150px;
  }
`;
