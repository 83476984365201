import React, {useMemo} from "react";
import cx from "classnames";
import {FormControl} from "@material-ui/core";

import {ProductPlans} from "../../dto/EnumDTO";
import {ColorPalette} from "../../theme/ColorPalette";
import {AuthProductProps} from "../../api/auth/AuthDTO";
import {AuthProductItem} from "../product/AuthProductItem";

export const PRODUCTS: AuthProductProps[] = [
  {
    title: "Free Account",
    buttonId: "trial-button",
    id: ProductPlans.TrialSignUp,
    button: "Create free account",
    color: ColorPalette.Success,
    subtitle: "Try our platform, no card details needed",
    detailsTitle: "All this data for free:",
    specialDetails: ["1 x Free Car Check:"],
    price: {
      currency: "£",
      priceInteger: 0,
      priceHint: "Nothing to pay",
    },
    details: [
      "1 x Free Car Check:",
      "Full MOT History",
      "Imported / Exported",
      "Ex-Salvage Register Check",
      "MOT Failure Prediction",
      "Plate & Colour Changes",
      "Date V5c Last Issued",
      "Detailed Technical Data",
    ],
  },
  {
    title: "Single Report",
    buttonId: "buyjourney-buy-payg-trackbutton",
    id: ProductPlans.PaygSignUp,
    color: ColorPalette.Secondary,
    button: "Buy Single Report",
    subtitle: "Good if you've found your dream car!",
    specialDetails: ["1 x Buyer's Report which includes:", "Shortlist & Compare 2 Cars"],
    price: {
      currency: "£",
      priceInteger: 9,
      priceFractional: 99,
      priceHint: "Additional Reports only £5.99",
    },
    detailsTitle: "Everything with a free account plus:",
    details: [
      "Shortlist & Compare 2 Cars",
      "1 x Buyer's Report which includes:",
      "Outstanding Finance Check",
      "Insurance Write-Off",
      "Ex-Salvage Register Check",
      "Stolen or Scrapped",
      "Safety Recalls",
      "Upcoming Repairs & Costs",
      "Service Schedules & Costs",
    ],
    description:
        "If you have more cars to check, you can save money by purchasing 30 days access instead.",
  },
  {
    title: "30 Days Access",
    buttonId: "buyjourney-buy-30days-trackbutton",
    id: ProductPlans.SubsSignUp,
    color: ColorPalette.Warning,
    button: "Buy 30 Days Access",
    subtitle: "Unlimited car checks",
    specialDetails: ["2 x Buyer's Reports which include:", "Shortlist & Compare as many Cars as you like!"],
    description: "If you don't need 30 days access you can purchase a single report instead.",
    price: {
      currency: "£",
      priceInteger: 14,
      priceFractional: 99,
      priceHint: "Additional Reports only £4.99",
    },
    detailsTitle: "Everything with a free account plus:",
    details: [
      "Shortlist & Compare as many Cars as you like!",
      "2 x Buyer's Reports which include:",
      "Outstanding Finance Check",
      "Insurance Write-Off",
      "Ex-Salvage Register Check",
      "Stolen or Scrapped",
      "Safety Recalls",
      "Upcoming Repairs & Costs",
      "Service Schedules & Costs",
    ],
  },
];

interface Props {
  readonly className?: string;
  readonly withTrial?: boolean;
  readonly onChange: (product: AuthProductProps) => void;
}

export function ProductField({ onChange, className, withTrial = true }: Props) {
  const list = useMemo(() => {
    if (!withTrial) {
      return PRODUCTS.filter(({ id }) => id !== ProductPlans.TrialSignUp);
    }

    return PRODUCTS;
  }, [withTrial]);

  return (
    <FormControl className={cx("form-control border-0 px-0 mb-5 h-auto", className)}>
      <div className="d-flex flex-column flex-md-row mb-2 mb-md-0">
        {list.map((product, idx, arr) => {
          const first = idx === 0;
          const last = idx + 1 === arr.length;

          return (
            <AuthProductItem
              key={product.id}
              product={product}
              onChange={() => onChange(product)}
              className={cx("mx-0", {
                "ml-md-2": last,
                "mr-md-2": first,
                "mx-md-2": !first,
              })}
            />
          );
        })}
      </div>

      <span className="d-flex text-primary text-center mx-2 font-weight-bolder h3">
        Disclaimer: Please note for vehicles over 15 years old, low-volume car manufacturers or
        specialist vehicles the data we provide may be limited
      </span>
    </FormControl>
  );
}
