import "./assets/select.scss";

import React from "react";
import cx from "classnames";
import { uid } from "react-uid";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MUISelect,
} from "@material-ui/core";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";

interface ListItemProps {
  readonly label: string;
  readonly value: string;
}

export interface SelectProps {
  readonly label?: string;

  readonly id?: string;
  readonly error?: boolean;
  readonly required?: boolean;
  readonly helperText?: string;

  readonly value: any;
  readonly onChange: SelectInputProps["onChange"];

  readonly className?: string;
  readonly labelClassName?: string;
  readonly wrapperClassName?: string;

  readonly disabled?: boolean;

  readonly items: ListItemProps[];
}

export function Select({
  id,
  label,
  error,
  required,
  items = [],
  helperText,
  labelClassName,
  wrapperClassName,
  ...selectProps
}: SelectProps) {
  return (
    <FormControl
      id={id}
      error={error}
      variant="outlined"
      className={cx("select-ui", wrapperClassName)}
    >
      {Boolean(label) && (
        <InputLabel
          required={required}
          id={id}
          className={cx(
            "reason-label",
            {
              "none-reason-label": Boolean(!selectProps.value),
              "px-2 bg-white": Boolean(selectProps.value),
            },
            labelClassName,
          )}
        >
          {label}
        </InputLabel>
      )}
      <MUISelect {...selectProps} labelId={id}>
        {items.map((item) => (
          <MenuItem key={uid(item)} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </MUISelect>
      {Boolean(helperText) && <FormHelperText className="mx-0">{helperText}</FormHelperText>}
    </FormControl>
  );
}
