import { update } from "immupdate";
import { difference, isArray, isBoolean, toFinite, toInteger } from "lodash";

import { Dict } from "../dto/AppDTO";
import { parseCamelCase } from "./StringUrils";
import { formatGroupNumber } from "./FormatUtils";
import {
  AddVehicleFormProps,
  FindVehicleProps,
  VehicleCompareItemProps,
  VehicleProps,
  VehicleTableDetailsListProps,
  VehicleTableDetailsProps,
} from "../api/vehicle/VehicleDTO";
import {
  AUTOTRADER_WEBSITE_URL,
  COMPARE_COLUMN_CATEGORIES,
  EXCLUDED_CATEGORIES,
  EXCLUDED_FIELDS,
  FIRST_COLUMNS_CATEGORIES,
  MOBILE_COLUMNS_CATEGORIES,
  MOTORS_WEBSITE_URL,
  PROVENANCE_LIST_ITEMS,
  PROVIDED_BY_AUTOTRADER,
  PROVIDED_BY_MOTORS,
  PROVIDED_MANUALLY,
  SECOND_COLUMNS_CATEGORIES,
} from "../constants/VehicleConstants";
import { DateTime } from "luxon";
import { Analytics } from "./Analytics";
import { MOTORWAY_CATEGORY, MotorwayActions } from "../constants/AnalyticsConstants";

export function isExcludedBlock(index = "") {
  return EXCLUDED_CATEGORIES.includes(index);
}

export function isExcludedField(index = "") {
  return EXCLUDED_FIELDS.includes(index);
}

export function formatHeaderTitle(index = "", fallbackTitle = "") {
  switch (index.trim()) {
    case "evDetails":
      return "EV Details";

    default:
      return parseCamelCase(index) || fallbackTitle;
  }
}

export function formatVehicleInfoTitle(index = "", fallbackTitle = "", vehicle: VehicleProps) {
  switch (index.trim()) {
    case "mileage":
      return vehicle?.basicDetails.isUserMileage ? "Mileage" : "Estimated Mileage";

    case "listPrice":
      return "Price";

    case "marketPrice":
      return "Market Value";

    case "bootCapacity":
      return "Boot Capacity (litres)";

    case "tankSize":
      return "Tank Size (litres)";

    case "hasWrittenOffMarker":
      return "Write-off";

    case "hasStolenMarker":
      return "Stolen";

    case "hasOutstandingFinance":
      return "Outstanding finance";

    case "hasOutstandingRecalls":
      return "Outstanding recalls";

    case "hasScrappedMarker":
      return "Scrapped";

    case "hasImportedMarker":
      return "Imported";

    case "hasExportedMarker":
      return "Exported";

    case "numberOfKeepers":
      return "Number of owners";

    case "hasPreviousColours":
      return "Previous colours";

    case "dateOfManufacture":
      return "Manufacture Date";

    case "motStatus":
      return "MOT Due";

    case "costPerMile":
      return "Fuel cost per mile";

    case "combinedMpg":
      return "Combined MPG";

    case "length":
      return "Length (mm)";

    case "width":
      return "Width (mm)";

    case "height":
      return "Height (mm)";

    case "wheelbase":
      return "Wheel Base (mm)";

    case "grossVehicleWeight":
      return "Gross Weight (kg)";

    case "groundClearance":
      return "Ground Clearance (mm)";

    case "urbanColdMpg":
      return "Urban Cold MPG";

    case "extraUrbanMpg":
      return "Extra Urban MPG";

    case "accelerationMph":
      return "Acceleration 0-62 (secs)";

    case "bhp":
      return "BHP";

    case "torqueLbft":
      return "Torque LBFT";

    case "torqueNm":
      return "Torque NM";

    case "torqueRpm":
      return "Torque RPM";

    case "vin":
      return "VIN";

    case "countryOfOrigin":
      return "Country Origin";

    case "motExpiry":
      return "MOT Expiry";

    case "warrantyMlExpiry":
      return "Manufacturer Warranty (Mechanical)";

    case "warrantyByExpiry":
      return "Manufacturer Warranty (Corrosion)";

    case "warrantyPtExpiry":
      return "Manufacturer Warranty (Paintwork)";

    case "warrantyRyExpiry":
      return "Manufacturer Warranty (Recovery/Breakdown)";

    case "cvJoint":
      return "CV Joint";

    case "undersideOfVehicle":
      return "Underside";

    case "vehicleStructure":
      return "Structure";

    case "safetyNcapRating":
      return "Safety NCAP Rating";

    case "euroEmission":
      return "Euro Status/Emission";

    case "lastV5CIssueDate":
      return "Last V5C Issue Date";

    case "roadTaxCost6M":
      return "Road Tax 6 months cost";

    case "roadTaxCost12M":
      return "Road Tax 12 months cost";

    case "taxiStatusId":
      return "Possible Taxi";

    case "p11DPrice":
      return "P11d Price";

    case "introductionDate":
      return "Date of Introduction";

    case "endOfProductionDate":
      return "End of Production";

    case "batteryKw":
      return "KW";

    case "milesPerKwh":
      return "Energy Consumption";

    case "maximumSpeed":
      return "Maximum Speed (mph)";

    default:
      return parseCamelCase(index) || fallbackTitle;
  }
}

export function formatVehicleInfoValueWithDelimiters(value: any) {
  const number = isBoolean(value) ? NaN : toInteger(value);

  if (!isNaN(number) && number > 0) {
    return formatGroupNumber(number);
  }

  return value;
}

export function formatVehicleInfoValue(index: string, value: any, hasReport = false) {
  switch (index) {
    case "engineNumber":
      return value;

    case "listPrice":
      return !value ? "N/A" : `£${formatVehicleInfoValueWithDelimiters(value)}`;

    case "marketPrice":
      return !value || !hasReport ? "N/A" : `£${formatVehicleInfoValueWithDelimiters(value)}`;

    case "costPerMile":
      return value ? `${formatVehicleInfoValueWithDelimiters(Math.round(value))}p` : null;

    case "costToFill":
      return value ? `£${formatVehicleInfoValueWithDelimiters(Math.round(value))}` : null;

    case "roadTaxCost6M":
      return !value ? "N/A" : `£${formatVehicleInfoValueWithDelimiters(value)}`;

    case "roadTaxCost12M":
      return !value ? "N/A" : `£${formatVehicleInfoValueWithDelimiters(value)}`;

    case "p11DPrice":
      return !value ? "N/A" : `£${formatVehicleInfoValueWithDelimiters(value)}`;

    case "taxiStatusId":
      switch (value) {
        case 0:
          return "No";
        case 1:
        case 2:
          return "Yes";
      }
      return "N/A";
    case "batteryRange":
      return value ? `${value} miles` : null;

    case "milesPerKwh":
      return value ? `${value} miles per kWh` : null;

    default:
      return formatVehicleInfoValueWithDelimiters(value);
  }
}

export function sortTableItems(
  index: string,
  list: VehicleTableDetailsListProps[],
): VehicleTableDetailsListProps[] {
  switch (index) {
    case "provenance":
      const oldItems = list.reduce(
        (acc, item) =>
          update(acc, {
            [item.index]: item,
          }),
        {} as Dict<VehicleTableDetailsListProps>,
      );

      const items = PROVENANCE_LIST_ITEMS.reduce(
        (acc, item) =>
          update(acc, {
            [item]: oldItems[item],
          }),
        {},
      );

      return Object.values(items);

    default:
      return list;
  }
}

function formatInfoList(
  list: string[],
  vehicle: VehicleProps,
  removeEmpty = true,
): VehicleTableDetailsProps[] {
  const hasReport = Boolean(vehicle.reportId);

  return list.reduce((acc: VehicleTableDetailsProps[], item) => {
    const x = acc.slice();

    // @ts-ignore
    const category = vehicle[item];
    const valueList = Object.keys(category);
    const isProvenance = item === "provenance";

    let isNotEmpty = false;

    const mappedList = valueList
      .map((index) => {
        const value = category[index];

        isNotEmpty = isNotEmpty || Boolean(value);

        return {
          index,
          title: formatVehicleInfoTitle(index, "", vehicle),
          value: formatVehicleInfoValue(index, category[index], hasReport),
        };
      })
      .filter(({ index }) => !EXCLUDED_FIELDS.includes(index));

    if (removeEmpty && !isNotEmpty && !isProvenance) {
      return x;
    }

    const value = {
      index: item,
      title: formatHeaderTitle(item),
      hasUpsellBoxes: isProvenance,
      list: sortTableItems(item, mappedList),
    };

    x.push(value);

    return x;
  }, []);
}

export function formatComparisonVehicles(
  vehicles: VehicleProps[] = [],
  sortIndex?: string,
): VehicleCompareItemProps[][] {
  const compareList = difference(COMPARE_COLUMN_CATEGORIES, EXCLUDED_CATEGORIES);

  const result: VehicleCompareItemProps[][] = [];

  compareList.forEach((compareCategory) => {
    vehicles.forEach((vehicle, idx) => {
      const item = formatInfoList(compareList, vehicle, false);
      const infoList = item.find(({ index }) => index === compareCategory);

      if (infoList) {
        if (idx === 0) {
          if (!isArray(result[0])) {
            result[0] = [];
          }

          result[0].push({
            vehicle,
            empty: true,
            vrm: vehicle.vrm,
            values: infoList.list,
            index: compareCategory,
            title: formatVehicleInfoTitle(compareCategory, "", vehicle),
          });
        }

        if (!isArray(result[idx + 1])) {
          result[idx + 1] = [];
        }

        result[idx + 1].push({
          vehicle,
          title: "",
          vrm: vehicle.vrm,
          values: infoList.list,
          index: compareCategory,
          empty: infoList.list.every(({ value }) => !value),
          upsel: Boolean(compareCategory === "provenance" && !vehicle.reportId),
        });
      }
    });
  });

  if (sortIndex) {
    const [valueIndex, categoryIndex] = sortIndex.split("-");

    const [firstColumn, ...restResult] = result;

    return [
      firstColumn,
      ...restResult.sort((arrayA, arrayB) => {
        const categoryA = arrayA.find(({ index }) => index === categoryIndex);
        const categoryB = arrayB.find(({ index }) => index === categoryIndex);

        if (categoryA && categoryB) {
          const valueA = categoryA.values.find(({ index }) => index === valueIndex);
          const valueB = categoryB.values.find(({ index }) => index === valueIndex);

          if (valueA && valueB) {
            if (valueIndex === "dateOfManufacture") {
              const isoDateA = valueA.value.split(".").reverse().join("-");
              const isoDateB = valueB.value.split(".").reverse().join("-");

              const dateA = DateTime.fromISO(isoDateA);
              const dateB = DateTime.fromISO(isoDateB);

              if (dateA < dateB) {
                return 1;
              }

              if (dateA > dateB) {
                return -1;
              }

              return 0;
            }

            const formattedValueA = Number(valueA.value?.replace(/(p|£)/g, "").replace(",", "."));
            const formattedValueB = Number(valueB.value?.replace(/(p|£)/g, "").replace(",", "."));

            return formattedValueB - formattedValueA;
          }

          if (valueA && !valueB) {
            return -1;
          }

          if (!valueA && valueB) {
            return 1;
          }
        }

        if (categoryA && !categoryB) {
          return -1;
        }

        if (!categoryA && categoryB) {
          return 1;
        }

        return 0;
      }),
    ];
  }

  return result;
}

export function getSummaryColumns(vehicle?: VehicleProps) {
  if (!vehicle) {
    return {};
  }

  const firstList = difference(FIRST_COLUMNS_CATEGORIES, EXCLUDED_CATEGORIES);
  const secondList = difference(SECOND_COLUMNS_CATEGORIES, EXCLUDED_CATEGORIES);
  const mobileList = difference(MOBILE_COLUMNS_CATEGORIES, EXCLUDED_CATEGORIES);

  return {
    firstColumn: formatInfoList(firstList, vehicle),
    secondColumn: formatInfoList(secondList, vehicle),
    mobileColumn: formatInfoList(mobileList, vehicle),
  };
}

export function isMotorsSource(link?: string): boolean {
  return Boolean(link && link.indexOf(MOTORS_WEBSITE_URL) >= 0);
}

export function isAutotraderSource(link?: string): boolean {
  return Boolean(link && link.indexOf(AUTOTRADER_WEBSITE_URL) >= 0);
}

export function formatAddVehicleValues(
  { mileage, listPrice }: AddVehicleFormProps,
  { sourceLink = "", registration, mileage: initialMileage }: FindVehicleProps,
) {
  return {
    mileage,
    listPrice,
    sourceLink,
    vrm: registration,
    isUserMileage: toFinite(initialMileage) !== toFinite(mileage),
    providerId: isMotorsSource(sourceLink)
      ? PROVIDED_BY_MOTORS
      : isAutotraderSource(sourceLink)
      ? PROVIDED_BY_AUTOTRADER
      : PROVIDED_MANUALLY,
  };
}

export function openMotorway(
  vrm: string,
  action: MotorwayActions = MotorwayActions.ShortlistValuation,
) {
  const analytics = new Analytics();

  analytics.googleEvent("event", {
    eventLabel: vrm,
    eventAction: action,
    eventCategory: MOTORWAY_CATEGORY,
  });

  window.open(
    `https://motorway.co.uk/${vrm}/?utm_source=carguide&utm_medium=affiliate&utm_term=carguide&utm_campaign=carguide_widget_shortlist`,
    "_newtab",
  );
}
