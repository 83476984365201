import React from "react";
import {Image, Line, StyleSheet, Svg, Text, View} from "@react-pdf/renderer";
import {PageLayout} from "./PageLayout";
import ReactDOMServer from "react-dom/server";
import Html from "../PdfComponent/Html";
import TAX from "../../tax.enum";

import GoodVehicleIcon from "../assets/good-vehicle.png";
import BadVehicleIcon from "../assets/bad-vehicle.png";
import OkVehicleIcon from "../assets/ok-vehicle.png";
import {mileageAndRisksPageStyles} from "../styles/mileageAndRisksPageStyles";
import {getTaxInfo} from "../../common/tax";
import TAXI from "../../../../utils/taxi.enum";

const styles = StyleSheet.create(mileageAndRisksPageStyles);

export const MileageAndRisksPage = ({ report }) => {
  const mileageAndRisks = report.mileageAndRisks;
  const { recallsInfo = {} } = report.questionsAndAnswers || {};

  const risks = [
    {
      status: (vehicle) => !vehicle.hasOutstandingFinance,
      successTitle: () => "Checked: Finance",
      failureTitle: () => "Outstanding finance",
      successDescription: (vehicle) =>
        ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12 }}>
            We have checked the vehicle against finance registers and there is no record of the vehicle having outstanding finance.
          </div>
        ),
      failureDescription: (vehicle) => {
        return ReactDOMServer.renderToStaticMarkup(
          <div>
            <div style={{ fontSize: 12, marginBottom: 10 }}>
              We have checked the vehicle against finance registers and there is a record of the
              vehicle having {vehicle.financeRecords.length} outstanding finance agreement(-s).
              Please contact the seller.
            </div>
            <div style={{ fontSize: 12 }}>
              {vehicle.financeRecords.map((agreement) => (
                <div key={agreement.agreementNumber}>
                  <div style={{ fontSize: 12 }}>
                    <b>Agreement Type:</b> {agreement.agreementType.trim()}
                  </div>
                  <div style={{ fontSize: 12 }}>
                    <div style={{ fontWeight: 700 }}>Agreement Number:</div> {agreement.agreementNumber}
                  </div>
                  <div style={{ fontSize: 12 }}>
                    <b>Agreement Term:</b> {agreement.agreementTerm} months
                  </div>
                  <div style={{ fontSize: 12 }}>
                    <b>Start Date:</b> {agreement.agreementDate}
                  </div>
                  <div style={{ fontSize: 12 }}>
                    <div style={{ fontWeight: 700 }}>Finance Company:</div> {agreement.financeCompany}
                  </div>
                  <div style={{ fontSize: 12 }}>
                    <b>Contact Number:</b> {agreement.contactNumber}
                  </div>
                  <div style={{ fontSize: 12 }}>
                    <b>Transacaction Date:</b> {agreement.transactionDate}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      },
    },
    {
      status: (vehicle) => !vehicle.hasStolenMarker,
      show: (vehicle) => Boolean(!vehicle.hasStolenMarker),
      successTitle: () => "Checked: Not stolen",
      failureTitle: () => "Checked: Stolen",
      successDescription: () =>
        ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12 }}>
            "We have checked the vehicle against stolen registers and it is not currently marked as stolen."
          </div>
        ),
      failureDescription: () => ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12 }}>Not currently marked as stolen.</div>
        ),
    },
    {
      status: (vehicle) => !vehicle.hasWrittenOffMarker,
      successTitle: () => "Checked: Not written off",
      failureTitle: () => "Checked: Written off",
      successDescription: () =>
        ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12 }}>
            We have checked the vehicle against various registers and there is no record of the vehicle being written off.
          </div>
        ),
      failureDescription: () =>
        ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12 }}>
            Warning, we have identified that this vehicle has been marked as written off!
          </div>
        ),
      additional: (vehicle) => [
        {
          field: "Date",
          value: vehicle.miaftrConditionDate,
        },
        {
          field: "Category",
          value: vehicle.miaftrDamage,
        },
      ],
      description: "If you believe this is an error, you can contact the DVLA on 0300 790 6802",
    },
    {
      status: (vehicle) => !vehicle.hasOutstandingRecalls,
      successTitle: () => "Checked: Recalls",
      failureTitle: () => "Outstanding recall",
      successDescription: () =>
        ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12 }}>
            We have checked this vehicle with the SMMT and there are no safety recalls recorded.
          </div>
        ),
      failureDescription: (_, { recallsInfo }) =>
        ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12 }}>
            The SMMT reports there is an overdue recall on the vehicle. as follows:
            <div style={{ fontWeight: 700, margin: "5px auto", textAlign: "center" }}>
              {recallsInfo.vinRecallStatus}
            </div>
            Ask for proof that this has been carried out. If not, it should be able to be carried outfree of charge by your nearest dealership.
          </div>
        ),
    },
    {
      status: (vehicle) => vehicle.taxStatusId === TAX.TAXED,
      successTitle: () => "Checked: Taxed",
      failureTitle: (vehicle) => getTaxInfo(vehicle.taxStatusId),
      successDescription: () =>
        ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12 }}>
            We have checked this vehicle against DVLA and it is taxed.
          </div>
        ),
      failureDescription: (vehicle) => {
        if (vehicle.taxStatusId === TAX.SORN) {
          return ReactDOMServer.renderToStaticMarkup(
            <div style={{ fontSize: 12 }}>
              <div style={{ fontSize: 12, marginBottom: 10, textAlign: "center" }}>
                This vehicle is not taxed for on road use.
              </div>
              You must tax this vehicle before taking it for a test drive on public roads unless it
              is on dealer trade-plates or you are attending a pre-booked MOT.
            </div>
          );
        }

        return ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12 }}>
            <div style={{ fontSize: 12 }}>
              We have checked this vehicle against DVLA and it is untaxed.
            </div>
            You must tax this vehicle before taking it for a test drive on public roads unless it is
            on dealer trade-plates or you are attending a pre-booked MOT.
          </div>
        );
      },
    },
    {
      status: (vehicle) => !vehicle.hasImportedMarker,
      successTitle: () => "Checked: Not imported",
      failureTitle: () => "Checked: Imported",
      successDescription: () =>
        ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12 }}>
            We have no record of this vehicle being imported into the country.
          </div>
        ),
      failureDescription: () =>
        ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12 }}>
            We have a record of this vehicle being imported into the country.
          </div>
        ),
    },
    {
      status: (vehicle) => !vehicle.hasExportedMarker,
      successTitle: () => "Checked: Not exported",
      failureTitle: () => "Checked: Exported",
      successDescription: () =>
        ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12 }}>
            We have checked the vehicle against the DVLA and there is no record of the vehicle being exported.
          </div>
        ),
      failureDescription: () =>
        ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12 }}>
            We have checked the vehicle against the DVLA and there is a record of the vehicle being exported.
          </div>
        ),
    },
    {
      status: (vehicle) => !vehicle.hasColourChanges,
      successTitle: () => "Checked: Colour",
      failureTitle: () => "Colour changes detected",
      successDescription: () =>
        ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12 }}>
            We have no record of the vehicles’ colour being changed.
          </div>
        ),
      failureDescription: () =>
        ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12 }}>
            We have a record of the vehicles’ colour being changed.
          </div>
        ),
    },
    {
      status: (vehicle) => !vehicle.hasVrmChanges,
      checkWarning: (vehicle) => vehicle.hasVrmChanges,
      successTitle: () => "Checked: Registration",
      failureTitle: () => "Registration plate changes detected",
      successDescription: () =>
        ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12 }}>
            We have no record of registration plate changes on this vehicle.
          </div>
        ),
      failureDescription: (vehicle) =>
        ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: 12, textAlign: "center" }}>
            <div>
              Below are the registration plates and the dates they were taken off this vehicle:
            </div>
            {(vehicle.vrmChangeHistory && vehicle.vrmChangeHistory.length && (
              <div style={{ fontWeight: 700, display: "flex", alignItems: "center" }}>
                {vehicle.vrmChangeHistory.map((changeItem, index) => (
                  <div key={index}>
                    {changeItem.date}: {changeItem.vrm}
                  </div>
                ))}
              </div>
            )) ||
              null}
          </div>,
        ),
      note: `
        Note that a vehicle may have had a recent plate change but this will
        need to be confirmed with the DVLA (for example, if the seller has
        recently taken their private plate off the vehicle).
      `,
    },
    {
      status: (vehicle) => vehicle.lastKeeperDate || vehicle.previousKeeperDate,
      successTitle: () => "Vehicle owners",
      failureTitle: () => "Vehicle owners",
      successDescription: (vehicle) =>
        ReactDOMServer.renderToStaticMarkup(
          <div style={{ fontSize: "12px", display: "flex", alignItems: "center" }}>
            <div>Current owner acquired vehicle on: </div>
            <div>{vehicle.lastKeeperDate ? vehicle.lastKeeperDate : "N/A"}</div>
            <div>Length of ownership:</div>
            <div>{vehicle.lengthOfOwnership ? vehicle.lengthOfOwnership : "N/A"}</div>
            <div>Previous owner acquired vehicle on: </div>
            <div>{vehicle.previousKeeperDate ? vehicle.previousKeeperDate : "N/A"}</div>
          </div>
        ),
      failureDescription: () =>
        ReactDOMServer.renderToStaticMarkup(<div style={{ fontSize: 12 }}>Not Available</div>),
    },
    {
      status: vehicle => !vehicle.hasSalvageRecords,
      checkWarning: vehicle => vehicle.hasSalvageRecords,
      successTitle: () => "Checked: No Salvage Record",
      failureTitle: () => "Salvage Record found",
      successDescription: () =>
        ReactDOMServer.renderToStaticMarkup(
            <div style={{ fontSize: 12 }}>
                There is no record of this vehicle on our salvage register.
            </div>
        ),
      failureDescription: () =>
        ReactDOMServer.renderToStaticMarkup(
            <div style={{ fontSize: 12 }}>
                There is a record of this vehicle on our salvage register.
            </div>
        ),
    },
    {
      status: vehicle => vehicle.taxiStatusId === TAXI.NONE,
      checkWarning: vehicle => vehicle.taxiStatusId > TAXI.NONE,
      successTitle: () => "Checked: No Taxi Record",
      failureTitle: vehicle => vehicle.taxiStatusId === TAXI.POSSIBLE_TAXI ? "Possible Taxi" : "Taxi Record found",
      successDescription: () =>
        ReactDOMServer.renderToStaticMarkup(
            <div style={{ fontSize: 12 }}>
                There is no record of this vehicle on our taxi register.
            </div>
        ),
      failureDescription: () =>
        ReactDOMServer.renderToStaticMarkup(
            <div style={{ fontSize: 12 }}>
                There is a record of this vehicle on our taxi register.
            </div>
        ),
    },
  ];

  return (
    <PageLayout id={report.id}>
      <Text style={styles.pageTitle}>MILEAGE AND RISKS</Text>

      <Text style={styles.orderText}>
        We have checked the vehicle for high-risk items. Anything you need to watch out for will be
        highlighted in red.
      </Text>

      {report.vehicle && report.vehicle.hasStolenMarker ? (
        <View style={styles.warning}>
          <Text style={styles.warningTitle}>Warning!</Text>
          <Text>This car has been reported as stolen.</Text>
          <Text>Date {report.vehicle.stolenDate}</Text>
        </View>
      ) : null}

      {report.vehicle && report.vehicle.hasScrappedMarker ? (
        <View style={styles.warning}>
          <Text style={styles.warningTitle}>Warning!</Text>
          <Text>
            This vehicle is marked as scrapped. Please read our advice here on buying a scrapped vehicle.
          </Text>
        </View>
      ) : null}

      <View style={styles.risks}>
        {risks.map((risk, index) => {
          const additionalInfo = { recallsInfo };
          const show = !risk.show || risk.show(report.vehicle);
          const status = risk.status(report.vehicle, additionalInfo);
          const hasWarning = risk.checkWarning && risk.checkWarning(report.vehicle);

          if (!show) {
            return null;
          }

          const currentRisk = status ? (
            styles.riskOk
          ) : hasWarning ? (
            styles.riskWarning
          ) : (
            styles.riskAttention
          );


          return (
            <View key={index} wrap={false} style={[styles.risk, currentRisk]}>
              <View>
                {status ? (
                  <Image style={styles.icon} src={GoodVehicleIcon} />
                ) : hasWarning ? (
                  <Image style={styles.icon} src={OkVehicleIcon} />
                ) : (
                  <Image style={styles.icon} src={BadVehicleIcon} />
                )}
              </View>
              <Text style={styles.riskTitle}>
                {status
                  ? risk.successTitle(report.vehicle, additionalInfo)
                  : risk.failureTitle(report.vehicle, additionalInfo)}
              </Text>
              <View style={styles.riskDescription}>
                {status ? (
                  <Html>{risk.successDescription(report.vehicle, additionalInfo)}</Html> 
                ) : (
                  <Html>{risk.failureDescription(report.vehicle, additionalInfo)}</Html>
                )}
              </View>

              {!status && risk.additional && (
                <View style={{ display: "flex", flexDirection: "column" }}>
                  {risk.additional(report.vehicle, additionalInfo).map(({ field, value }) => (
                    <View key={field} style={{ padding: "5px", borderTop: "1px solid red" }}>
                      <Text>
                        <Text style={{ fontWeight: 700 }}>{field}:</Text> {value}
                      </Text>
                    </View>
                  ))}
                </View>
              )}
              {risk.note && <Text style={{ fontStyle: "italic" }}>{risk.note}</Text>}

              {!status && risk.description && (
                <View style={styles.descriptionWarning}>
                  <Text>{risk.description}</Text>
                </View>
              )}
            </View>
          );
        })}
      </View>

      <View break={true}>
        <Text style={styles.orderTitle}>MOT Mileage</Text>
        <Text style={styles.orderText}>
          Below are the odometer (speedo) readings taken at each MOT test over the life of the
          vehicle. A vehicle typically does 12,000 miles per annum and although this can vary we
          have plotted a line to show where the latest mileage should approximately be.
        </Text>

        <View style={styles.mileageInfo}>
          <Text>Current Mileage</Text>
          <Text>Vehicles in the UK typically do 12,000 miles per annum.</Text>
        </View>

        {mileageAndRisks.chartReadyData?.length > 0 ? (
          <View>
            <View style={styles.mileageTable}>
              <View style={styles.mileageTableHeader}>
                <Text style={styles.mileageTableCol1}>Year</Text>
                <Text style={styles.mileageTableCol2}>Expected Mileage</Text>
                <Text style={styles.mileageTableCol3}>Real Mileage</Text>
              </View>
              <View style={styles.lineMileageTable}>
                <Svg height="1" width="520">
                  <Line x1="0" y1="0" x2="100%" y2="0" strokeWidth={2} stroke="rgb(35,165,180)" />
                </Svg>
              </View>
              <View style={styles.mileageTableBody}>
                {mileageAndRisks.chartReadyData.map((entry, index) => {
                  const currentStyle =
                    index % 2 ? styles.mileageTableLine : [styles.mileageTableLine, styles.bgGray];

                  return (
                    <View key={entry.year} style={currentStyle}>
                      <Text style={styles.mileageTableCol1}>{entry.year}</Text>
                      <Text style={styles.mileageTableCol2}>{entry.expectedMileage.toFixed()}</Text>
                      <Text style={styles.mileageTableCol3}>{entry.mileage ? entry.mileage.toFixed() : `N/A`}</Text>
                    </View>
                  );
                })}
              </View>
            </View>
          </View>
        ) : (
          <Text style={styles.orderText}>No data.</Text>
        )}

        <Text style={styles.orderText}>
          If any odometer readings have been recorded in kilometres, we have automatically converted
          these to miles. If you suspect an issue with the mileage you can contact the DVSA.
        </Text>
      </View>
    </PageLayout>
  );
};
