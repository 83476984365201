import "./assets/add-vehicle-dialog.scss";

import React, { useEffect, useState } from "react";

import { Dialog } from "../ui/Dialog";
import { ActionButton } from "../ui/ActionButton";
import { AddVehicleForm } from "./AddVehicleForm";
import { ColorPalette } from "../../theme/ColorPalette";
import { AddVehicleInfoItem } from "./AddVehicleInfoItem";
import { formatAddVehicleValues } from "../../utils/VehicleHalpers";
import { createVehicleLookupEvents } from "../../utils/AnalyticsHelpers";
import { useVehicleLookupSocket } from "../../hooks/useVehicleLookupSocket";
import { AddVehicleProps, FindVehicleProps } from "../../api/vehicle/VehicleDTO";

export interface AddVehicleDialogProps {
  readonly open: boolean;
  readonly onDismiss: () => void;
  readonly vehicle?: FindVehicleProps;
  readonly onSubmit: (values: AddVehicleProps) => Promise<void>;
}

export function AddVehicleDialog({ open, vehicle, onDismiss, onSubmit }: AddVehicleDialogProps) {
  const [error, setError] = useState<string | undefined>();
  const [progress, setProgress] = useState<number | undefined>();

  useEffect(() => {
    if (open && vehicle) {
      createVehicleLookupEvents(vehicle);
    }
  }, [open, vehicle]);

  useVehicleLookupSocket({
    vehicle: vehicle!,
    onProgress: setProgress,
    skip: Boolean(!vehicle || !open),
    onDone: () => {
      setProgress(undefined);
      onDismiss();
    },
    onError: (message) => {
      setError(message);
      setProgress(undefined);
    },
  });

  return (
    <Dialog
      open={open}
      progress={progress}
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      paperClassName="dialog-paper"
      className="add-vehicle-dialog"
      title="Is this the correct vehicle?"
      actions={
        !vehicle && (
          <div className="d-flex justify-content-end">
            <ActionButton onClick={onDismiss} color={ColorPalette.Danger}>
              Ok
            </ActionButton>
          </div>
        )
      }
    >
      {!vehicle && <span className="text-center my-3">Vehicle no found!</span>}

      {vehicle && (
        <>
          <div className="d-flex flex-column mx-3 mt-3">
            {error && <span className="text-center text-danger mb-2">{error}</span>}

            <AddVehicleInfoItem value={`${vehicle?.make} ${vehicle?.model}`} label="Make & Model" />
            <AddVehicleInfoItem value={vehicle?.colour} label="Colour" />
            <AddVehicleInfoItem value={vehicle?.fuelType} label="Fuel Type" />
            <AddVehicleInfoItem value={vehicle?.regDate} label="Date of Registration" />
          </div>

          <AddVehicleForm
            onDismiss={onDismiss}
            initialValues={vehicle}
            processing={progress! >= 0}
            onSubmit={(values) =>
              onSubmit(formatAddVehicleValues(values, vehicle)).then(() => setProgress(0))
            }
          />
        </>
      )}
    </Dialog>
  );
}
