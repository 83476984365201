import "./assets/subscription-features-list.scss";

import React from "react";
import cx from "classnames";

import {SubscriptionFeaturesItem} from "./SubscriptionFeaturesItem";

interface Props {
  readonly className?: string;
}

export function SubscriptionFeaturesList({ className }: Props) {
  return (
    <div className={cx("d-fex flex-column subscription-features-list", className)}>
      <div className="text-secondary font-weight-medium mb-2 text-center text-md-left features-header">
        30 Days Access Features
      </div>
      <div className="d-flex flex-column align-items-center align-items-md-start">
        <SubscriptionFeaturesItem title="Unlimited Car Checks" />
        <SubscriptionFeaturesItem title="2 Included Buyers Reports" />
        <SubscriptionFeaturesItem title="Additional Reports only £4.99" />
        <SubscriptionFeaturesItem title="No Ongoing Commitment!" />
      </div>
    </div>
  );
}
