import React from "react";
import { Route } from "react-router";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { StylesProvider } from "@material-ui/core/styles";
import { PersistGate } from "redux-persist/integration/react";

import { GlobalStyle } from "../styles";
import RootContainer from "./RootContainer";
import { ApiProvider } from "../api/ApiContext";
import store, { persistor } from "../redux/store";
import Circular from "../commons/Circular/Circular";
import { ErrorBoundary } from "../components/app/ErrorBoundary";

export function AppContainer() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={<Circular />} persistor={persistor}>
          <StylesProvider injectFirst={true}>
            <ErrorBoundary>
              <ApiProvider>
                <BrowserRouter>
                  <Route component={RootContainer} path="/" />
                </BrowserRouter>
              </ApiProvider>
            </ErrorBoundary>
          </StylesProvider>
        </PersistGate>
      </Provider>
      <GlobalStyle />
    </>
  );
}
