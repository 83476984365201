import cx from "classnames";
import React, {useMemo} from "react";

import {BasicDetailsRow} from "./BasicDetailsRow";
import {
    AuthVehicleBasicDetailsProps,
    AuthVehicleProvenanceProps,
    VehicleBasicDetailsProps,
    VehicleProvenanceProps,
} from "../../api/vehicle/VehicleDTO";

interface Props {
  readonly isReveal?: boolean;
  readonly className?: string;
  readonly provenance: VehicleProvenanceProps | AuthVehicleProvenanceProps;
  readonly basicDetails: VehicleBasicDetailsProps | AuthVehicleBasicDetailsProps;
}

export function BasicDetailsThirdRow({ isReveal, className, basicDetails, provenance }: Props) {

  const failureItem = useMemo(() => {
    if (provenance.likelyFailureValue && provenance.likelyFailureItem) {
      return provenance.likelyFailureValue + '% ' + provenance.likelyFailureItem;
    }

    return 'N/A';
  }, [provenance]);

  return (
    <BasicDetailsRow className={className}>
      <div className="d-flex flex-grow-1 mx-1 mx-lg-2 flex-shrink-1 flex-column align-items-center justify-content-end flex-1 card-cell">
        <span className="mt-1 text-center font-weight-light card-cell-title-small">Exported?</span>
        <span
          className={cx("font-weight-bold text-center fl-4", {
            "text-danger": provenance.hasExportedMarker || provenance.isExported,
            "text-secondary": !provenance.hasExportedMarker && !provenance.isExported,
          })}
        >
          {provenance.hasExportedMarker || provenance.isExported ? "??" : "??"}
        </span>
      </div>
      {!isReveal && (
        <div className="d-flex flex-grow-1 mx-1 mx-lg-0 mx-xl-2 flex-shrink-1 flex-column align-items-center justify-content-end flex-1 card-cell">
          <span className="mt-1 text-center font-weight-light card-cell-title-small">
            Most likely Failure Item
          </span>
          <span className="mt-1 text-center font-weight-light card-cell-title-small">
            <b>{failureItem}</b>
          </span>
        </div>
      )}
      {isReveal && (
        <div className="d-flex flex-grow-1 mx-1 mx-lg-0 mx-xl-2 flex-shrink-1 flex-column align-items-center justify-content-end flex-1 card-cell">
          <span className="mt-1 text-center font-weight-light card-cell-title-small">
            Salvage Register Match
          </span>
          <span className={cx("font-weight-bold text-center fl-4", {
            "text-danger": provenance.hasSalvageRecords,
            "text-secondary": !provenance.hasSalvageRecords,
          })}>
            {provenance.hasSalvageRecords ? "??" : "??"}
          </span>
        </div>  
      )}
      <div className="d-flex flex-grow-1 mx-1 mx-lg-2 flex-shrink-1 flex-column align-items-center justify-content-end flex-1 card-cell">
        <span className="mt-1 text-center font-weight-light card-cell-title-small">Possible Taxi?</span>
        <span
          className={cx("font-weight-bold text-center fl-4", {
            "text-danger": provenance.taxiStatusId > 0,
            "text-secondary": provenance.taxiStatusId < 1,
          })}
        >
          {provenance.taxiStatusId > 0 ? "??" : "??"}
        </span>
      </div>
    </BasicDetailsRow>
  );
}
