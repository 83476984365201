import { useEffect } from "react";

import { Resource } from "./useResource";
import { AppError } from "../utils/AppError";

/**
 * Use useResourceHandler instead
 * @deprecated
 */
export function useResponseHandler<TData = any>(
  { data, loading, error, resetError }: Resource<TData>,
  onComplete?: (data?: TData, error?: AppError) => void,
) {
  useEffect(() => {
    if (loading) {
      resetError();
    }

    if (onComplete && !loading) {
      onComplete(data, error);
    }
  }, [error, data, loading]);
}
