import { camelCase, isArray, isPlainObject } from "lodash";

export interface AppErrorProps<T = any> extends Error {
  data: T;
  status: number;
}

export interface FormErrorProps {
  readonly field: string;
  readonly message: string;
}

export class AppError implements AppErrorProps {
  public name: string;
  public status: number;
  public message: string;

  data: FormErrorProps[] | Response;

  constructor({ data, name, status, message }: AppErrorProps) {
    const dataName = data && isPlainObject(data) ? data.name : name;
    const dataMessage = data && isPlainObject(data) ? data.message : "";

    this.data = data;
    this.name = dataName;
    this.status = status;
    this.message = message || dataMessage;
  }

  public getFormErrors(): any[] {
    if (isArray(this.data)) {
      return this.data.map(({ field, message }) => ({
        errorText: message,
        field: camelCase(field),
      }));
    }

    return [];
  }
}
