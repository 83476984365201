import { deepUpdate, DELETE, update } from "immupdate";

import {
  UserAccountProps,
  UserCreditsProps,
  UserDataProps,
  UserSubscriptionProps,
} from "../api/user/UserDTO";
import { createReducer, createRootReducer, PerformAction } from "../utils/ReducerUtils";
import { SubscriptionStatus } from "../dto/EnumDTO";
import { Action } from "redux";

export interface SetUserDataMeta {
  readonly data: UserDataProps;
}

export interface SetUserAccountMeta {
  readonly account: UserAccountProps;
}

export interface SetCognitoUserMeta {
  readonly user: any;
}

enum ReducerActions {
  SetUserData = "User/SetUserData",
  SetUserAccountData = "User/SetUserAccountData",

  SetCognitoUser = "User/SetCognitoUser",
  ClearCognitoUser = "User/ClearCognitoUser",
}

export interface UserReducerState {
  readonly cognitoUser?: any;
  readonly userData?: UserDataProps;
  readonly credits?: UserCreditsProps;
  readonly subscription?: UserSubscriptionProps;
}

function getState(): UserReducerState {
  return {};
}

export const userReducer = createRootReducer(
  getState(),

  createReducer([ReducerActions.SetUserData], (state, { meta }) =>
    deepUpdate(state).at("userData").set(meta.data),
  ),

  createReducer([ReducerActions.SetUserAccountData], (state, { meta }) =>
    update(state, {
      credits: meta.account.credits,
      subscription: meta.account.subscription,
    }),
  ),

  createReducer([ReducerActions.SetCognitoUser], (state, { meta }) =>
    update(state, { cognitoUser: meta.user }),
  ),

  createReducer([ReducerActions.ClearCognitoUser], (state) =>
    update(state, { cognitoUser: DELETE }),
  ),
);

// ==================
// Selectors
// ==================

export function userDataSelector({ user }: any): UserDataProps | undefined {
  return user.userData;
}

export function userAccountSelector({ user }: any): UserAccountProps {
  return {
    credits: user.credits,
    customer: user.userData,
    subscription: user.subscription,
  };
}

export function userSubscriptionSelector({ user }: any): UserSubscriptionProps | undefined {
  return user.subscription;
}

export function hasSubscriptionSelector({ user }: any): boolean {
  return user.subscription?.status === SubscriptionStatus.MonthlyAccess;
}

export function hasCreditsSelector({ user }: any): boolean {
  return Boolean(user.credits?.report && user.credits?.report > 0);
}

export function subscriptionExpiredSelector({ user }: any): boolean {
  return user.subscription?.status === SubscriptionStatus.SubscriptionExpired;
}

export const userHasEmailSelector = ({ user }: any): boolean => Boolean(user.userData?.email);

export const cognitoUserSelector = ({ user }: any): any | undefined => user.cognitoUser;

// ==================
// Actions
// ==================

export function setUserData(meta: SetUserDataMeta): PerformAction<SetUserDataMeta> {
  return { meta, type: ReducerActions.SetUserData };
}

export function setUserAccount(meta: SetUserAccountMeta): PerformAction<SetUserAccountMeta> {
  return { meta, type: ReducerActions.SetUserAccountData };
}

export function setCognitoUser(meta: SetCognitoUserMeta): PerformAction<SetCognitoUserMeta> {
  return { meta, type: ReducerActions.SetCognitoUser };
}
export function clearCognitoUser(): Action {
  return { type: ReducerActions.ClearCognitoUser };
}
