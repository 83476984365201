import { APP_TAG_MANAGER_APP_ID, IS_PRODUCTION } from "../constants/AppConstants";
import {
  LIVE_GOOGLE_OPTIMIZE_ID,
  STAGING_GOOGLE_OPTIMIZE_ID,
} from "../constants/AnalyticsConstants";

export function initTagManager() {
  const { body, head } = document;

  if (head) {
    const scriptElement = document.createElement("script");

    const optimizeId = IS_PRODUCTION ? LIVE_GOOGLE_OPTIMIZE_ID : STAGING_GOOGLE_OPTIMIZE_ID;

    scriptElement.innerHTML = `
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
          "gtm.start": new Date().getTime(),
          event: "gtm.js",
        });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "${APP_TAG_MANAGER_APP_ID}");
      
      window.dataLayer = window.dataLayer || [];
      
      dataLayer.push({ 'optimize_id': '${optimizeId}'});
    `;

    const antiFlickStyle = document.createElement("style");

    antiFlickStyle.innerHTML = ".async-hide { opacity: 0 !important}";

    const antiFlickerScript = document.createElement("script");

    antiFlickerScript.innerHTML = `
        (function(a,s,y,n,c,h,i,d,e){
          s.className+=' '+y;h.start=1*new Date;
          h.end=i=function(){
            s.className=s.className.replace(RegExp(' ?'+y),'')};
            (a[n]=a[n]||[]).hide=h;setTimeout(function(){
              i();h.end=null
            },c);
            h.timeout=c;
        })(window,document.documentElement,'async-hide','dataLayer',4000,{optimizeId:true});
    `;

    head.appendChild(scriptElement);
    head.appendChild(antiFlickStyle);
    head.appendChild(antiFlickerScript);
  }

  if (body) {
    const noScriptElement = document.createElement("noscript");

    noScriptElement.innerText = `
      <iframe height="0" width="0" style="display:none;visibility:hidden" src="https://www.googletagmanager.com/ns.html?id=${APP_TAG_MANAGER_APP_ID}"></iframe>
    `;

    body.appendChild(noScriptElement);
  }
}
