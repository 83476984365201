import "./assets/short-list-card.scss";

import React from "react";

import { VehicleProps } from "../../api/vehicle/VehicleDTO";
import { ShortListCardReport } from "./ShortListCardReport";
import { ShortListCardDetails } from "./ShortListCardDetails";
import { ShortListCardControl } from "./ShortListCardControl";
import { ShortlistCardGeneral } from "./ShortlistCardGeneral";
import { ShortListCardSourceLink } from "./ShortListCardSourceLink";
import { ProductPlans } from "../../dto/EnumDTO";

interface Props {
  readonly bullet1?: string;
  readonly item: VehicleProps;
  readonly onMotClick: () => void;
  readonly onMorePress: () => void;
  readonly onEditClick: () => void;
  readonly onDeleteClick: () => void;
  readonly onSummaryClick: () => void;
  readonly onBuyReportClick: () => void;
  readonly onViewReportClick: () => void;
  readonly onSalvageRecordsClick: () => void;
  readonly onEstimatedMileageClick: () => void;
  readonly onSalvageRecordsTitleClick: () => void;
  readonly onComplete?: (product: ProductPlans) => void;
  readonly isTrade?: boolean;
}

export function ShortListCard({
  item,
  bullet1,
  onComplete,
  onMotClick,
  onEditClick,
  onMorePress,
  onDeleteClick,
  onSummaryClick,
  onBuyReportClick,
  onViewReportClick,
  onSalvageRecordsClick,
  onEstimatedMileageClick,
  onSalvageRecordsTitleClick,
  isTrade,
}: Props) {
  return (
    <div className="d-flex flex-column mb-4 pt-2 pt-md-3 pb-0 pb-xl-3 short-list-card">
      <div className="d-flex flex-column flex-lg-row">
        <div className="d-flex mb-lg-0 flex-column flex-sm-row image-wrapper">
          <div className="d-flex flex-column flex-1 mb-2 mb-sm-0">
            <ShortlistCardGeneral item={item} onEstimatedMileageClick={onEstimatedMileageClick} />

            <ShortListCardSourceLink item={item} className="d-none d-xl-flex" />
          </div>

          <div className="d-flex flex-1 d-lg-none flex-column">
            <ShortListCardDetails
              item={item}
              onMotClick={onMotClick}
              onSummaryClick={onSummaryClick}
              onSalvageRecordsClick={onSalvageRecordsClick}
              onEstimatedMileageClick={onEstimatedMileageClick}
              onSalvageRecordsTitleClick={onSalvageRecordsTitleClick}
              isTrade={isTrade}
            />
          </div>
        </div>

        <div className="d-flex flex-1 flex-column pr-0 pr-lg-3 pr-xl-0">
          <div className="d-none d-lg-flex">
            <ShortListCardDetails
              item={item}
              onMotClick={onMotClick}
              onSummaryClick={onSummaryClick}
              onSalvageRecordsClick={onSalvageRecordsClick}
              onEstimatedMileageClick={onEstimatedMileageClick}
              onSalvageRecordsTitleClick={onSalvageRecordsTitleClick}
              isTrade={isTrade}
            />
          </div>

          <ShortListCardReport
            item={item}
            bullet1={bullet1}
            onComplete={onComplete}
            onMorePress={onMorePress}
            onBuyReportClick={onBuyReportClick}
            onViewReportClick={onViewReportClick}
            isTrade={isTrade}
          />
        </div>

        <div className="d-none d-xl-flex">
          <ShortListCardControl
            vrm={item.vrm}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
          />
        </div>
      </div>

      <div className="d-flex d-xl-none">
        <ShortListCardSourceLink className="d-flex" item={item} />

        <ShortListCardControl
          vrm={item.vrm}
          className="ml-md-2"
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
        />
      </div>
    </div>
  );
}
