import "./assets/loader-progress.scss";

import React, { useMemo } from "react";
import cx from "classnames";
import { CircularProgress } from "@material-ui/core";

interface Props {
  readonly size?: number;
  readonly className?: string;
  readonly isAbsolute?: boolean;
}

export function LoaderProgress({ size = 40, className, isAbsolute = true }: Props) {
  const borderRadius = useMemo(() => size / 2, [size]);
  const circularSize = useMemo(() => size - 16, [size]);

  return (
    <div className={cx("d-flex loader-progress", { "position-absolute": isAbsolute }, className)}>
      <div
        className="p-2 bg-muted circular-progress"
        style={{
          width: size,
          height: size,
          borderRadius,
        }}
      >
        <CircularProgress size={circularSize} />
      </div>
    </div>
  );
}
