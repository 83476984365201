import React from "react";

import TAXI from "../../../utils/taxi.enum";

export function hasTaxiMarker(status) {
  switch (status) {
    case TAXI.TAXI:
    case TAXI.POSSIBLE_TAXI:
      return true;
    default:
      return false;
  }
}

export function getTaxiInfo(status) {
  switch (status) {
    case TAXI.TAXI:
      return "Taxi record found";
    case TAXI.POSSIBLE_TAXI:
      return "Possible Taxi";
    default:
      return "Taxi record";
  }
}

export function getTaxiInfoForSideBar(status) {
  switch (status) {
    case TAXI.TAXI:
      return "This vehicle was registered as taxi";
    case TAXI.POSSIBLE_TAXI:
      return "MOT records suggest this could be an ex-taxi";
    default:
      return "No taxi record found";
  }
}
