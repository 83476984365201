import "./assets/auth-container.scss";

import React from "react";
import { Route, Switch, useLocation } from "react-router";

import { Routes } from "../../constants/Routes";
import { AuthRegisterContainer } from "./AuthRegisterContainer";
import { AppRouteLayout } from "../../components/app/AppRouteLayout";
import { AuthRegisterProductContainer } from "./AuthRegisterProductContainer";
import { AuthRegisterCheckoutContainer } from "./AuthRegisterCheckoutContainer";

export function AuthContainer() {
  const location = useLocation();

  return (
    <AppRouteLayout withFooter={false} contentClassName="flex-1" title="Registration">
      <Switch location={location}>
        <Route path={Routes.AuthRegisterProduct} component={AuthRegisterProductContainer} />
        <Route path={Routes.AuthRegisterCheckout} component={AuthRegisterCheckoutContainer} />
        <Route exact={true} path={Routes.AuthRegister} component={AuthRegisterContainer} />
      </Switch>
    </AppRouteLayout>
  );
}
