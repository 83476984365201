import React, { useState } from "react";
import classnames from "classnames";
import { capitalize } from "../../../../utils/string";
import ModalDialog from "../../../../commons/ModalDialog";
import * as styles from "./styles";
import { APP_WP_URL } from "../../../../constants/AppConstants";

export const ISSUE_PREDICTION_RISK_THRESHOLD = 60;

export const ISSUE_PREDICTION_THRESHOLD = Object.freeze({
  LOW: {
    value: 29,
    title: "low",
  },
  MEDIUM: {
    value: 59,
    title: "medium",
  },
  HIGH: {
    title: "high",
  },
});

export const getRisklevel = risk => {
  let riskLevel = ISSUE_PREDICTION_THRESHOLD.HIGH.title;

  if (risk < ISSUE_PREDICTION_THRESHOLD.LOW.value) {
    riskLevel = ISSUE_PREDICTION_THRESHOLD.LOW.title;
  } else if (risk < ISSUE_PREDICTION_THRESHOLD.MEDIUM.value) {
    riskLevel = ISSUE_PREDICTION_THRESHOLD.MEDIUM.title;
  }

  return riskLevel;
};

export default function Likelihood({ issues }) {
  const [isImpairModalOpen, setImpairModalOpen] = useState(false);
  const toggleImpairModal = event => {
    event.preventDefault();
    setImpairModalOpen(!isImpairModalOpen);
  };

  if (!issues || !issues.length) {
    return (
      <styles.Container>
        <div className="no-data-message">
          Unfortunately impair.ai doesn't have MOT prediction data on this vehicle yet.
        </div>
      </styles.Container>
    );
  }

  return (
    <styles.Container>
      <div className="issues-likelihood-description">
        <span className="d-inline-block text-center text-mb-left">
          We’ve used the impair.ai platform to predict the likelihood of issues that may occur on
          this vehicle in the next 12 months. For more information{" "}
          <a href="#" onClick={toggleImpairModal}>
            click here
          </a>
        </span>
        .
      </div>
      <div className="d-flex flex-column d-md-grid issues-likelihood-grid">
        {issues.map((issue, index) => {
          const isDangerous = issue.risk >= ISSUE_PREDICTION_RISK_THRESHOLD;
          const advisoryLevel = getRisklevel(issue.advisory);
          const nextMOTfailureLevel = getRisklevel(issue.fail);

          return (
            <div
              key={index}
              className={classnames("issue-likelihood", { "is-dangerous": isDangerous })}
            >
              <div className="likelihood-title">
                {capitalize(issue.component)} - {issue.risk}% likelihood of issues
              </div>
              <div className="likelihood-content">
                There’s a {advisoryLevel} chance of an advisory note being issued and a{" "}
                {nextMOTfailureLevel} chance of a failure at the next MOT.
              </div>
            </div>
          );
        })}
      </div>
      <ModalDialog
        sourcePath={`${APP_WP_URL}/wp-json/wp/v2/posts/1111`}
        open={isImpairModalOpen}
        handleClose={toggleImpairModal}
      />
    </styles.Container>
  );
}
