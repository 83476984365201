import React, { useEffect } from "react";
import classnames from "classnames";
import { useDispatch } from "react-redux";
import WrenchIcon from "../../../assets/img/reports/wrench.svg";
import MoneyBoxIcon from "../../../assets/img/reports/moneybox.svg";
import TaxIcon from "../../../assets/img/reports/tax.svg";
import ServiceIcon from "../../../assets/img/reports/service.svg";
import DepreciationIcon from "../../../assets/img/reports/depreciation.svg";
import FuelIcon from "../../../assets/img/reports/fuel.svg";
import MOTIcon from "../../../assets/img/reports/mot.svg";
import VehiclePicture from "../../../assets/img/reports/vehicle.svg";
import withScrollTop from "../../../hoc/withScrollTop";
import { formatFunds } from "../../../utils/number";
import * as actions from "../../../redux/actions/report";
import * as styles from "./styles";

function getTopBoxes(context) {
  return [
    {
      title: "Servicing",
      className: "servicing-box",
      value: context.servicePerMonth,
      icon: ServiceIcon,
    },
    {
      title: "Depreciation",
      className: "depreciation-box",
      value: context.deprValuePerMonth,
      icon: DepreciationIcon,
    },
    {
      title: "MOT",
      className: "mot-box",
      value: context.motPerMonth,
      icon: MOTIcon,
    },
  ];
}

function getBottomBoxes(context) {
  return [
    {
      title: "Repairs",
      className: "repairs-box",
      value: context.repairsPerMonth,
      icon: WrenchIcon,
    },
    {
      icon: TaxIcon,
      title: "Tax",
      className: "tax-box",
      value: context.taxPerMonth,
      description: "This includes the 5% surcharge for paying monthly/6-monthly",
    },
    {
      title: "Fuel",
      className: "fuel-box",
      value: context.fuelPerMonth,
      icon: FuelIcon,
    },
  ];
}

function RunningCosts({ report, token }) {
  const dispatch = useDispatch();
  const displayInfo = report.runningCosts || {};

  useEffect(() => {
    if (!report.runningCosts) {
      dispatch(actions.loadRunningCosts(report.id, token));
    }
  }, [token]);

  if (!report.runningCosts) {
    return [];
  }

  const topBoxes = getTopBoxes(displayInfo);
  const bottomBoxes = getBottomBoxes(displayInfo);

  return (
    <styles.Container>
      <div className="section-title">Costs per month</div>
      <div className="section-description">
        Based on average mileage of 12k miles per year and fuel costs at £
        {formatFunds(displayInfo.fuelCostPerLitre)} per litre.
      </div>
      <div className="section-vehicle">
        <div className="vehicle-boxes top-boxes">
          <div className="vehicle-boxes-container">
            {topBoxes.map((box, index) => (
              <div
                key={index}
                className={classnames("vehicle-box", box.className, { "is-hidden": !box.value })}
              >
                <div className="box-title">{box.title}</div>
                <div className="box-value">£{formatFunds(box.value)}</div>
                <div className="box-appendix">
                  <div className="box-appendinx-line"></div>
                  <div className="box-icon">
                    <img src={box.icon} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="vehicle">
          <img src={VehiclePicture} />
        </div>
        <div className="vehicle-boxes bottom-boxes">
          <div className="vehicle-boxes-container">
            {bottomBoxes.map((box, index) => (
              <div
                key={index}
                className={classnames("vehicle-box", box.className, { "is-hidden": !box.value })}
              >
                <div className="box-appendix">
                  <div className="box-icon">
                    <img src={box.icon} width={70} />
                  </div>
                  <div className="box-appendinx-line"></div>
                </div>
                <div className="box-title">{box.title}</div>
                <div className="d-flex flex-column box-value">
                  <span>£{formatFunds(box.value)}</span>
                  <span className="mx-2 fs-2 text-center">{box.description}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="total-cost-section">
            <div className="total-cost-title">
              <div className="total-cost-title-icon">
                <img src={MoneyBoxIcon} width={40} />
              </div>
              <div className="total-cost-title-text">Total cost per month</div>
            </div>
            <div className="total-cost-value">£{formatFunds(displayInfo.totalPerMonth)}</div>
          </div>
        </div>
      </div>
    </styles.Container>
  );
}

export default withScrollTop(RunningCosts);
