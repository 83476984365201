import "./assets/edit-vehicle-form.scss";

import React from "react";
import { toFinite } from "lodash";
import { number, object } from "yup";
import { Form, Formik } from "formik";

import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";
import { FormikTextField } from "../form/FormikTextField";
import { AddVehicleFormProps } from "../../api/vehicle/VehicleDTO";

const validationSchema = object({
  mileage: number()
    .nullable(true)
    .min(0, "Mileage Value should be between 0 and 99999999")
    .max(99999999, "Mileage Value should be between 0 and 99999999"),
  listPrice: number()
    .nullable(true)
    .min(0, "Mileage Value should be between 0 and 99999999")
    .max(99999999, "Price Value should be between 0 and 99999999"),
});

interface Props {
  readonly processing: boolean;

  readonly onDismiss: () => void;

  readonly initialValues: AddVehicleFormProps;
  readonly onSubmit: (values: AddVehicleFormProps) => Promise<void>;
}

export function AddVehicleForm({ onDismiss, processing, initialValues, onSubmit }: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(x) =>
        onSubmit({
          mileage: toFinite(x.mileage),
          listPrice: toFinite(x.listPrice),
        })
      }
    >
      {({ handleSubmit, isSubmitting, isValid, values }) => {
        const loading = isSubmitting || processing;

        const priceTitle = values.listPrice ? "Price (click to edit)" : "Price (click to add)";
        const mileageTitle =
          values.mileage === initialValues.mileage
            ? "Estimated mileage (click to edit)"
            : "Mileage";

        return (
          <Form
            className="d-flex flex-column mt-0 mb-3 mx-3 edit-vehicle-form"
            onSubmit={handleSubmit}
          >
            <FormikTextField
              label={mileageTitle}
              name="mileage"
              className="mb-3"
              type="number"
              autoComplete="off"
            />
            <FormikTextField
              label={priceTitle}
              name="listPrice"
              className="mb-4"
              type="number"
              autoComplete="off"
            />

            <div className="d-flex flex-column-reverse flex-sm-row actions-container">
              <ActionButton
                fluid={true}
                disabled={loading}
                className="mr-sm-1"
                onClick={onDismiss}
                color={ColorPalette.MiddleGray}
              >
                No
              </ActionButton>
              <ActionButton
                fluid={true}
                type="submit"
                loading={loading}
                disabled={!isValid}
                color={ColorPalette.Success}
                className="ml-sm-1 mb-2 mb-sm-0"
              >
                Yes
              </ActionButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
