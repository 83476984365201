import { vehicleAPI } from "../api-legacy/vehicleAPI";
import { catchError, setError } from "./errorReducer";
import { APP_LOGOUT_URL } from "../constants/AppConstants";

const SET_STATUS = "SET_STATUS";
const VEHICLE_INFO = "VEHICLE_INFO";
const TOGGLE_IS_FETCHING = "TOGGLE_IS_FETCHING";
const ADD_CAR = "ADD_CAR";
const URL_IMAGE = "URL_IMAGE";
const SET_COOKIE = "SET_COOKIE";
const SET_LIST_CARS = "SET_LIST_CARS";
const SET_LOCAL_STORAGE = "SET_LOCAL_STORAGE";
const SET_INSPECT = "SET_INSPECT";
const SET_CHANGE_URL = "SET_CHANGE_URL";
const CATCH_ERROR = "CATCH_ERROR";
const SET_CAN_REDIRECT = "SET_CAN_REDIRECT";
const GET_VEHICLE_FOR_MODAL = "GET_VEHICLE_FOR_MODAL";
const REPORT_LIST = "REPORT_LIST";
const CHECK_CAR_PROFILE = "CHECK_CAR_PROFILE";
const CHECK_DELETE_REPORT = "CHECK_DELETE_REPORT";
const IDS_VEHICLE_INFO_LIFE = "IDS_VEHICLE_INFO_LIFE";
const FOR_MODAL_FETCHING = "FOR_MODAL_FETCHING";

export const initialState = {
  vrm: null,
  status: 0,
  isFetching: false,
  changeURL: "",
  listCars: [],
  inspect: null,
  errors: [{ title: "", detail: "" }],
  canRedirect: false,
  reportList: null,
  isExist: false,
  isDeleted: false,
  ids: null,
  forModalFetching: false,
};

const VehicleInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case VEHICLE_INFO: {
      return {
        ...state,
        vrm: action.vrm,
      };
    }
    case SET_STATUS: {
      return {
        ...state,
        status: action.status,
      };
    }
    case TOGGLE_IS_FETCHING: {
      return {
        ...state,
        isFetching: action.isFetching,
      };
    }
    case ADD_CAR: {
      return {
        ...state,
        addCar: action.addCar,
      };
    }
    case URL_IMAGE: {
      return {
        ...state,
        urlImage: action.urlImage,
      };
    }
    case SET_COOKIE: {
      return {
        ...state,
        cookie: action.cookie,
      };
    }
    case SET_LOCAL_STORAGE: {
      return {
        ...state,
        localStorage: action.localStorage,
      };
    }
    case SET_LIST_CARS: {
      return {
        ...state,
        listCars: action.listCars,
      };
    }
    case SET_INSPECT: {
      return {
        ...state,
        inspect: action.inspect,
      };
    }
    case SET_CHANGE_URL: {
      return {
        ...state,
        changeURL: action.changeURL,
      };
    }
    case CATCH_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }
    case SET_CAN_REDIRECT: {
      return {
        ...state,
        canRedirect: action.canRedirect,
      };
    }

    case GET_VEHICLE_FOR_MODAL: {
      return {
        ...state,
        carForModal: action.carForModal,
      };
    }
    case REPORT_LIST: {
      return {
        ...state,
        reportList: action.reportList,
      };
    }
    case CHECK_CAR_PROFILE: {
      return {
        ...state,
        isExist: action.isExist,
      };
    }
    case CHECK_DELETE_REPORT: {
      return {
        ...state,
        isDeleted: action.isDeleted,
      };
    }
    case IDS_VEHICLE_INFO_LIFE: {
      return {
        ...state,
        ids: action.ids,
      };
    }
    case FOR_MODAL_FETCHING: {
      return {
        ...state,
        forModalFetching: action.forModalFetching,
      };
    }
    default:
      return state;
  }
};

export const checkVehicleExistsThunk = (vrm) => {
  return (dispatch) => {
    dispatch(toggleIsFetching(true));
    vehicleAPI.checkVehicleExists(vrm).then((response) => {
      dispatch(toggleIsFetching(false));
      if (response.status == 200) {
        dispatch(setCarRedirect(true));
      } else {
        dispatch(setCarRedirect(false));
        dispatch(catchError(response.data));
      }
    });
  };
};

export const checkProfileCarExistsThunk = (vrm) => {
  return (dispatch) => {
    dispatch(setCarRedirect(false));

    dispatch(checkExistsProfileCar(false));
    vehicleAPI.checkProfileCarExists(vrm).then((response) => {
      if (response.data.exists === true) {
        dispatch(checkExistsProfileCar(true));
        dispatch(setCarRedirect(false));
      } else if (response.data.exists === false) {
        dispatch(checkExistsProfileCar(false));
        dispatch(setCarRedirect(true));
      }
      if (response.status == 401) {
        window.location.assign(APP_LOGOUT_URL);
      }
    });
  };
};

export const deleteVRMThunk = (vrm) => (dispatch) => {
  return vehicleAPI.deleteCar(vrm).then((response) => {
    if (response.status == 200) {
      dispatch(setStatus(response.status));
      dispatch(carListThunk());
    } else {
      dispatch(setError(response.data));
    }
  });
};

export const addCarThunk = (vrm, mileage, phoneNumber, providerId, description, listPrice) => (
  dispatch,
) => {
  vehicleAPI
    .addCar(vrm, mileage, phoneNumber, providerId, description, listPrice)
    .then((response) => {
      if (response.status >= 400) {
        dispatch(setError(response.data));
      }

      return response;
    });
};
export const lookupCarThunk = (vrm) => (dispatch) => {
  dispatch(setCarRedirect(false));
  dispatch(setForModalFetching(true));
  dispatch(setIdsVehicleInfoLife(null));
  dispatch(setError(null));
  dispatch(setStatus(0));
  vehicleAPI.lookupVehicle(vrm).then((response) => {
    dispatch(setForModalFetching(false));
    if (response.status == 200) {
      dispatch(setCarForModal(response.data));
    } else {
      dispatch(setStatus(response.status));
      dispatch(setError(response.data));
    }
    if (response.status == 401) {
      window.location.assign(APP_LOGOUT_URL);
    }
  });
};
export const lookupVehicleThunk = (input) => (dispatch) => {
  dispatch(setCarRedirect(false));
  dispatch(setForModalFetching(true));
  dispatch(setIdsVehicleInfoLife(null));
  dispatch(setError(null));
  dispatch(setStatus(0));
  vehicleAPI.lookupVehicle(input).then((response) => {
    dispatch(setForModalFetching(false));
    if (response.status == 200) {
      dispatch(setCarForModal(response.data));
    } else {
      dispatch(setStatus(response.status));
      dispatch(setError(response.data));
    }
    if (response.status == 401) {
      window.location.assign(APP_LOGOUT_URL);
    }
  });
};
export const scrapVehicleThunk = (url) => (dispatch) => {
  dispatch(setCarRedirect(false));
  dispatch(setCarForModal(null));
  dispatch(setForModalFetching(true));
  dispatch(setError(null));
  dispatch(setStatus(0));
  vehicleAPI.scrapCar(url).then((response) => {
    dispatch(setForModalFetching(false));
    if (response.status == 200) {
      dispatch(setIdsVehicleInfoLife(response.data));
    } else {
      dispatch(setStatus(response.status));
      dispatch(setError(response.data));
    }
  });
};

export const carListThunk = () => (dispatch) => {
  dispatch(toggleIsFetching(true));
  vehicleAPI.showListCar().then((response) => {
    dispatch(toggleIsFetching(false));

    // return if response is empty (error occured)
    if (!response) {
      return;
    }

    if (response.status == 200) {
      dispatch(setStatus(200));
      dispatch(setListCars(response.data));
    } else {
      dispatch(setError(response.data));
      if (response.status == 401) {
        window.location.assign(APP_LOGOUT_URL);
      }
    }
  });
};
export const getCarReportThunk = (id) => (dispatch) => {
  dispatch(toggleIsFetching(true));
  vehicleAPI.report(id).then((response) => {
    if (response.status == 200) {
      dispatch(toggleIsFetching(false));
      dispatch(setStatus(200));
      dispatch(setInspect(response.data));
      dispatch(getVehicleInfo(id));
    } else {
      dispatch(toggleIsFetching(false));
      dispatch(setError(response.data));
    }
    if (response.status == 401) {
      window.location.assign(APP_LOGOUT_URL);
    }
  });
};
export const editCarThunk = (editingCar) => (dispatch) => {
  dispatch(toggleIsFetching(true));
  return vehicleAPI.editCar(editingCar).then((response) => {
    dispatch(toggleIsFetching(false));
    if (response.status === 200) {
      dispatch(carListThunk());
    } else {
      dispatch(setError(response.data));
    }
  });
};

const setForModalFetching = (forModalFetching) => ({ type: FOR_MODAL_FETCHING, forModalFetching });
const checkExistsProfileCar = (isExist) => ({ type: CHECK_CAR_PROFILE, isExist });
export const setCarForModal = (carForModal) => ({ type: GET_VEHICLE_FOR_MODAL, carForModal });
const setListCars = (listCars) => ({ type: SET_LIST_CARS, listCars });
const setInspect = (inspect) => ({ type: SET_INSPECT, inspect });
const getVehicleInfo = (vrm) => ({ type: VEHICLE_INFO, vrm });
const setIdsVehicleInfoLife = (ids) => ({ type: IDS_VEHICLE_INFO_LIFE, ids });
export const toggleIsFetching = (isFetching) => ({ type: TOGGLE_IS_FETCHING, isFetching });
const setStatus = (status) => ({ type: SET_STATUS, status });
const setCarRedirect = (canRedirect) => ({ type: SET_CAN_REDIRECT, canRedirect });
export default VehicleInfoReducer;
