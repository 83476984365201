import instance from "../utils/http";

const reportAPI = {
  info(id, token = {}) {
    const { accessToken = {} } = token;

    return instance.get(`/reports/${id}/car_info`, {
      headers: { Authorization: `Bearer ${accessToken.jwtToken}` },
    });
  },
  MOTs(id, token = {}) {
    const { accessToken = {} } = token;

    return instance.get(`/reports/${id}/mot_info`, {
      headers: { Authorization: `Bearer ${accessToken.jwtToken}` },
    });
  },
  serviceSchedules(id, token = {}) {
    const { accessToken = {} } = token;

    return instance.get(`/reports/${id}/service_schedules`, {
      headers: { Authorization: `Bearer ${accessToken.jwtToken}` },
    });
  },
  runningCosts(id, token = {}) {
    const { accessToken = {} } = token;

    return instance.get(`/reports/${id}/running_costs`, {
      headers: { Authorization: `Bearer ${accessToken.jwtToken}` },
    });
  },
  mileageAndRisks(id, token = {}) {
    const { accessToken = {} } = token;

    return instance.get(`/reports/${id}/mileage_and_risks`, {
      headers: { Authorization: `Bearer ${accessToken.jwtToken}` },
    });
  },
  questionsAndAnswers(id, token = {}) {
    const { accessToken = {} } = token;

    return instance.get(`/reports/${id}/questions_to_ask`, {
      headers: { Authorization: `Bearer ${accessToken.jwtToken}` },
    });
  },
  repairs(id, token = {}) {
    const { accessToken = {} } = token;

    return instance.get(`/reports/${id}/repairs`, {
      headers: { Authorization: `Bearer ${accessToken.jwtToken}` },
    });
  },
};

export default reportAPI;
