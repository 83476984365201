import cx from "classnames";
import React, { ReactNode } from "react";
import MUICheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CheckboxProps as MUICheckboxProps } from "@material-ui/core/Checkbox/Checkbox";

export interface CheckboxProps extends MUICheckboxProps {
  readonly id?: string;
  readonly error?: boolean;
  readonly label?: ReactNode;
  readonly className?: string;
  readonly checkboxClassName?: string;
}

export function Checkbox({
  id,
  label,
  error,
  className,
  checkboxClassName,
  ...props
}: CheckboxProps) {
  return (
    <FormControlLabel
      id={id}
      label={label}
      className={cx(className, { "text-danger": error })}
      control={<MUICheckbox {...props} className={checkboxClassName} />}
    />
  );
}
