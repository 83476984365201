const TAB = Object.freeze({
  IMPORTANT_ITEMS: "importantItems",
  QUESTIONS_AND_ANSWERS: "questionsAndAnswers",
  TEST_DRIVE: "testDrive",
  SERVICE_SCHEDULES: "serviceSchedules",
  MOTS: "mots",
  RUNNING_COSTS: "runningCosts",
  REPAIRS: "repairs",
  MILEAGE_AND_RISKS: "mileageAndRisks",
});

export default TAB;
