import styled from "styled-components";

export const Container = styled.div`
  margin: 20px 0 0;

  .container-title {
    font-size: 20px;
    margin: 0 0 15px;
    border-bottom: 1px solid #22a6b336;
    padding: 0 0 15px;
  }
  .warning {
    background: #eb4d4b;
    color: #fff;
    padding: 30px;
    margin: 0 0 20px;
    border-radius: 5px;

    .warning-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      .warning-title {
        font-weight: 600;
        font-size: 24px;
        margin: 10px 0 10px;
      }
      .warning-description {
        font-weight: 500;
      }
    }
  }
  .risks {
    margin: 0 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2vw;

    .risk {
      background: #c7ecee;
      border-radius: 5px;
      padding: 25px 30px 30px;
      text-align: center;

      .inline-info {
        display: flex;
        justify-content: center;

        a {
          margin: 0 0 0 5px;
        }
      }
      .risk-icon {
        margin: 0 0 15px;
      }
      .risk-title {
        font-size: 20px;
        font-weight: 700;
        margin: 0 0 10px;
      }
      .risk-description {
        margin: 0 0 10px;

        .modal-button {
          text-decoration: underline;
          font-weight: 500;
          color: #30336b;
        }
        .list-info-container {
          margin: 10px 0 -40px;

          .list-info-item {
            margin: 0 -30px;

            &.has-multiple {
              border-top: 1.5px solid #eb4d4b;
            }

            .info-detail {
              border-top: 1px solid #eb4d4b;
              padding: 10px 25px;
              text-align: left;
            }
          }
        }
        .failure-description {
          &.with-bottom-spacing {
            margin: 0 0 10px;
          }
          .info-block {
            font-weight: bold;

            &.with-spacing-around {
              margin: 5px 0 10px;
            }
          }
        }
      }
      .risk-note {
        font-style: italic;
      }

      &.risk-attention {
        color: #eb4d4b;
        background: rgba(235, 77, 75, 0.2);
        border: 2px solid #eb4d4b;

        * {
          color: #eb4d4b;
        }

        &.risk-warning {
          color: #3c4858;
          background: rgba(249, 202, 37, 0.2);
          border: 2px solid #f9ca25;

          * {
            color: #3c4858;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .risks {
      grid-template-columns: 1fr;
    }
  }
`;
