import "./assets/vehicle-list-table.scss";

import { uid } from "react-uid";
import { isBoolean } from "lodash";
import React, { useMemo } from "react";

import { VehicleListCell } from "./VehicleListCell";
import { TableBuyReport } from "../report/TableBuyReport";
import { FREE_PROVENANCE_LIST_ITEMS } from "../../constants/VehicleConstants";
import { VehicleCompareItemValuesProps } from "../../api/vehicle/VehicleDTO";

interface Props {
  readonly id: string;
  readonly title?: string;
  readonly upsel?: boolean;
  readonly firstColumn: boolean;
  readonly onBuyReportClick: () => void;
  readonly values: VehicleCompareItemValuesProps[];
}

export function VehicleListTable({
  id,
  upsel,
  title,
  firstColumn,
  onBuyReportClick,
  values,
}: Props) {
  const upselRowCount = useMemo(() => values.length - FREE_PROVENANCE_LIST_ITEMS.length, [values]);

  const list = useMemo(() => {
    if (upsel) {
      return values.slice(upselRowCount);
    }

    return values;
  }, [upsel, values, upselRowCount]);

  const height = useMemo(() => `${upselRowCount * 34}px`, [upselRowCount]);

  return (
    <div className="d-flex flex-column mb-5 vehicle-list-table">
      <span className="text-secondary px-2 vehicle-list-table-title">{title}</span>

      <div id={`category-${id}`} className="d-flex flex-column">
        {upsel && (
          <TableBuyReport
            style={{ height }}
            onClick={onBuyReportClick}
            className="list-table-report"
          />
        )}

        {list.map((value, idx) => (
          <VehicleListCell
            id={value.index}
            key={uid(value)}
            firstColumn={firstColumn}
            odd={upsel && firstColumn ? idx % 2 !== 0 : idx % 2 === 0}
            title={
              title
                ? value.title
                : isBoolean(value.value)
                ? value.value
                  ? "Yes"
                  : "No"
                : value.value
            }
          />
        ))}
      </div>
    </div>
  );
}
