import React from "react";
import MUISnackbar, { SnackbarProps } from "@material-ui/core/Snackbar";

export function Snackbar(props: SnackbarProps) {
  return (
    <MUISnackbar
      {...props}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    />
  );
}
