import "./assets/stripe-checkout-form.scss";

import cx from "classnames";
import { CircularProgress } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CardElement, ReactStripeElements } from "react-stripe-elements";

import { StripeError } from "./StripeError";
import Button from "../CustomButtons/Button";
// @ts-ignore
import StripeImage from "./assets/stripe.png";
import { isValidEmail } from "../../utils/ValidateUtils";
import { StripePaymentRequestButton } from "./StripePaymentRequestButton";

interface CardChangeProps {
  readonly empty?: boolean;
  readonly message?: string;
  readonly complete?: boolean;
}

export interface StripeCheckoutFormProps {
  readonly title?: string;
  readonly amount: number;
  readonly loading?: boolean;
  readonly className?: string;
  readonly amountLabel: string;
  readonly withTitle?: boolean;
  readonly submitTitle?: string;
  readonly stripeError?: string;
  readonly paymentRequest?: any;
  readonly onDismiss?: () => void;
  readonly userHasEmail?: boolean;
  readonly showDisclaimer?: boolean;
  readonly onPayment?: (ev: any) => void;
  readonly stripeErrorClassName?: string;
  readonly onSubmit?: (email: string) => void;
  readonly actionsContainerClassName?: string;
  readonly onChange?: (meta: CardChangeProps) => void;
  readonly stripe?: ReactStripeElements.StripeProps | null;
}

export function StripeCheckoutForm({
  title = "Enter card details",
  amount,
  stripe,
  loading,
  onChange,
  onSubmit,
  withTitle = true,
  onDismiss,
  className,
  submitTitle,
  amountLabel,
  stripeError,
  userHasEmail,
  onPayment,
  showDisclaimer = true,
  stripeErrorClassName,
  actionsContainerClassName,
}: StripeCheckoutFormProps) {
  const [email, setEmail] = useState("");
  const [empty, setEmpty] = useState(true);
  const [error, setError] = useState<string | undefined>();
  const [complete, setComplete] = useState(false);

  const validEmail = useMemo(() => userHasEmail || isValidEmail(email), [email, userHasEmail]);

  const changeHandler = useCallback((element: ReactStripeElements.ElementChangeResponse) => {
    setEmpty(Boolean(element.empty));
    setError(element.error?.message);
    setComplete(Boolean(element.complete));

    if (onChange) {
      onChange({
        empty: Boolean(element.empty),
        message: element.error?.message,
        complete: Boolean(element.complete),
      });
    }
  }, []);

  useEffect(() => {
    if (stripeError) {
      setError(stripeError);
    }
  }, [stripeError]);

  return (
    <div className={cx("d-flex flex-column stripe-checkout-form", className)}>
      <StripePaymentRequestButton
        amount={amount}
        stripe={stripe}
        label={amountLabel}
        onPayment={onPayment}
      />

      {withTitle && <span className="mb-1">{title}</span>}

      <div className={cx("d-flex flex-column", { "mb-3": Boolean(error) })}>
        <CardElement
          onChange={changeHandler}
          className="d-flex align-items-center bg-white pl-2 w-100 card-element"
        />

        {!userHasEmail && (
          <input
            type="email"
            value={email}
            placeholder="Email (Required)"
            className="mt-2 px-2 font-weight-normal outline-none email-input"
            onChange={({ target }) => setEmail(target.value)}
          />
        )}
      </div>

      {error && <StripeError error={error} className={stripeErrorClassName} />}

      {Boolean(onDismiss || onSubmit) && (
        <div
          className={cx(
            "d-flex my-3 flex-column-reverse flex-sm-row actions-container",
            actionsContainerClassName,
            {
              "justify-content-end": Boolean(!onDismiss),
              "justify-content-between": Boolean(onDismiss),
            },
          )}
        >
          {onDismiss && (
            <Button
              // @ts-ignore
              disabled={loading}
              onClick={onDismiss}
              className="dismiss-button d-none d-sm-block"
            >
              Cancel
            </Button>
          )}

          {onDismiss && (
            <Button
              // @ts-ignore
              disabled={loading}
              onClick={onDismiss}
              style={{ width: "100px", height: "25px" }}
              className="dismiss-button d-sm-none align-self-end"
            >
              Cancel
            </Button>
          )}

          {onSubmit && (
            <Button
              // @ts-ignore
              color="success"
              disabled={!complete || empty || loading || !validEmail}
              onClick={() => {
                onSubmit(email);

                setError(undefined);
              }}
            >
              {submitTitle}
              {loading && <CircularProgress size={18} className="circular-progress" />}
            </Button>
          )}
        </div>
      )}

      {showDisclaimer && (
        <div className="d-flex stripe-disclaimer">
          <div className="d-flex flex-1 mr-2 align-items-center ">
            <span className="font-weight-medium stripe-description">
              Please note we do not store your card details, they are processed securely by{" "}
              <a href="https://stripe.com" target="_blank" rel="noopener noreferrer">
                Stripe
              </a>
            </span>
          </div>
          <img src={StripeImage} alt="stripe" width={100} height={25} />
        </div>
      )}
    </div>
  );
}
