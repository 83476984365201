import "./assets/dialog.scss";

import cx from "classnames";
import { isUndefined } from "lodash";
import React, { ReactNode, useMemo } from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { DialogProps } from "@material-ui/core/Dialog/Dialog";
import { Dialog as MUIDialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

interface Props extends DialogProps {
  readonly title?: string;
  readonly progress?: number;
  readonly children: ReactNode;
  readonly actions?: ReactNode;

  readonly titleClassName?: string;
  readonly paperClassName?: string;
  readonly actionsClassName?: string;
  readonly contentClassName?: string;
}

export function Dialog({
  title,
  actions,
  progress,
  children,
  className,
  paperClassName,
  titleClassName,
  actionsClassName,
  contentClassName,
  ...props
}: Props) {
  const showProgress = useMemo(() => !isUndefined(progress), [progress]);

  return (
    <MUIDialog
      scroll="body"
      className={cx("main-dialog", className)}
      classes={{ paper: cx("MuiDialog-Paper", paperClassName) }}
      {...props}
    >
      {Boolean(title) && (
        <DialogTitle
          className={cx(
            "bg-secondary text-white px-3 d-flex align-items-center main-dialog-title",
            titleClassName,
          )}
        >
          {title}
        </DialogTitle>
      )}

      <DialogContent
        className={cx(
          "d-flex flex-column px-2 px-md-3 position-relative w-100 main-dialog-content",
          contentClassName,
        )}
      >
        {showProgress && (
          <LinearProgress
            value={progress}
            color="secondary"
            variant="determinate"
            className="position-absolute linear-progress"
          />
        )}

        {children}
      </DialogContent>

      {actions && (
        <DialogActions
          className={cx("d-flex flex-column align-items-stretch px-2 px-md-3", actionsClassName)}
        >
          {actions}
        </DialogActions>
      )}
    </MUIDialog>
  );
}
