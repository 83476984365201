import React, { useState } from "react";

import { Dialog } from "./Dialog";
import { ActionButton } from "./ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";
import { useGeneratingReportSocket } from "../../hooks/useGeneratingReportSocket";

export interface UseCreditDialogProps {
  readonly vrm?: string;
  readonly open: boolean;
  readonly title: string;
  readonly onDismiss: () => void;
  readonly onComplete?: (key?: string) => void;
  readonly onUseCreditClick: () => Promise<void>;
}

export function UseCreditDialog({
  vrm,
  open,
  title,
  onDismiss,
  onComplete,
  onUseCreditClick,
}: UseCreditDialogProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const { progress } = useGeneratingReportSocket({
    vrm,
    skip: !loading,
    paymentType: "credit",
    onError: (message) => {
      setLoading(false);
      setError(message || "Generating error");
    },
    onDone: (key) => {
      setLoading(false);

      if (onComplete && open) {
        onComplete(key);
      }
    },
  });

  return (
    <Dialog
      open={open}
      title={title}
      progress={progress}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      titleClassName="text-center justify-content-center justify-content-sm-start text-sm-left"
      actions={
        <div className="d-flex justify-content-between flex-column-reverse flex-sm-row">
          <ActionButton
            disabled={loading}
            className="mr-sm-1"
            onClick={() => onDismiss()}
            color={ColorPalette.MiddleGray}
          >
            Cancel
          </ActionButton>

          <ActionButton
            loading={loading}
            color={ColorPalette.Success}
            className="mb-2 mb-sm-0 ml-sm-1"
            onClick={() => {
              setLoading(true);

              onUseCreditClick().catch((e) => {
                setError(e.message);
                setLoading(false);
              });
            }}
          >
            View For 1 Credit
          </ActionButton>
        </div>
      }
    >
      {Boolean(error) && <span className="text-center text-danger mb-2">{error}</span>}

      <span className="mb-3 text-center text-sm-left">
        Are you sure you want to use 1 credit on this report?
      </span>
    </Dialog>
  );
}
