import { useEffect } from "react";
import sha256 from "crypto-js/hmac-sha256";

import { toSnakeCase } from "../utils/CaseUtils";
import { SubscriptionStatus } from "../dto/EnumDTO";
import { userAccountSelector } from "../reducers/userReducer";
import { useShallowEqualSelector } from "./useShallowSelector";
import { APP_INTERCOM_IDENTITY_KEY } from "../constants/AppConstants";

export function useIntercom() {
  const account = useShallowEqualSelector(userAccountSelector);

  useEffect(() => {
    // @ts-ignore
    const { Intercom, intercomSettings = {} } = window;

    if (account.customer && Intercom) {
      // @ts-ignore
      window.intercomSettings = toSnakeCase({
        ...intercomSettings,

        name: account.customer?.name,
        email: account.customer?.email,
        userId: account.customer?.username,

        lookupCredits: account.credits?.lookup,
        reportCredits: account.credits?.report,
        notificationViaSms: account.customer?.marketing.viaSms,
        notificationViaPush: account.customer?.marketing.viaPush,
        notificationViaEmail: account.customer?.marketing.viaEmail,
        hasSubscription: account.subscription?.status === SubscriptionStatus.MonthlyAccess,
      });

      if (account.customer?.username) {
        Intercom(
          "boot",
          toSnakeCase({
            ...intercomSettings,
            userHash: sha256(account.customer?.username, APP_INTERCOM_IDENTITY_KEY).toString(),
          }),
        );
      }
    }
  }, [account]);
}
