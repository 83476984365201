import "./assets/short-list-card-details-item.scss";

import cx from "classnames";
import { isString } from "lodash";
import React, { isValidElement, ReactNode } from "react";

interface Props {
  readonly onClick?: () => void;

  readonly value: string | number;
  readonly title: string | ReactNode;

  readonly className?: string;
  readonly titleClassName?: string;
  readonly valuesClassName?: string;
}

export function ShortListCardDetailsItem({
  title,
  value,
  onClick,
  className,
  titleClassName,
  valuesClassName,
}: Props) {
  return (
    <div className={cx("d-flex flex-column flex-1 short-list-card-details-item", className)}>
      <span className={cx("text-center font-weight-bold detail-value", valuesClassName)}>
        {value}
      </span>

      {isValidElement(title) && (
        <div
          onClick={onClick}
          // @ts-ignore
          role={onClick ? "button" : undefined}
          className={cx("d-flex flex-column detail-title", {
            "cursor-pointer item-button": Boolean(onClick),
          })}
        >
          {title}
        </div>
      )}

      {isString(title) && (
        <span
          onClick={onClick}
          // @ts-ignore
          role={onClick ? "button" : undefined}
          className={cx(
            "text-center detail-title",
            { "cursor-pointer": Boolean(onClick) },
            titleClassName,
          )}
        >
          {title}
        </span>
      )}
    </div>
  );
}
