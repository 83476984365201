import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import classnames from "classnames";
import VehiclePicture from "../../../assets/img/carGuideImages/car.png";
import moment from "../../../utils/moment";
import ModalDialog from "../../../commons/ModalDialog";
import {getTaxInfo} from "../common/tax";
import {getTaxiInfoForSideBar} from "../common/taxi";
import TAB from "../tab.enum";
import TAX from "../tax.enum";
import TAXI from "../../../utils/taxi.enum";
import {format, formatFunds} from "../../../utils/number";
import * as actions from "../../../redux/actions/report";
import * as styles from "./styles";
import {useShallowEqualSelector} from "../../../hooks/useShallowSelector";
import {tokenSelector} from "../../../reducers/authReducer";
import {APP_WP_URL} from "../../../constants/AppConstants";
import {PDFDownload} from "./../PDFDocument/";
import {PDFReport} from "../PDFDocument/PDFReport";
import {setVehicleList, vehicleItemSelector} from "../../../reducers/vehicleReducer";
import {useResource} from "../../../hooks/useResource";
import {useResponseHandler} from "../../../hooks/useResponseHandler";
import {useVehicleContext} from "../../../api/vehicle/VehicleContext";

export default function Sidebar({ report, isInTab, handleTabChange }) {
  const dispatch = useDispatch();
  const importantItems = [
    {
      status: vehicle => !vehicle.isMotExpired,
      successContent: vehicle =>
        `MOT Expires on: ${vehicle.motDueDate}`,
      failureContent: vehicle => `MOT expired on: ${vehicle.motDueDate}`,
    },
    {
      status: vehicle => vehicle.taxStatusId === TAX.TAXED,
      successContent: vehicle => `Road Tax Expires on: ${vehicle.taxDueDate}`,
      failureContent: vehicle => getTaxInfo(vehicle.taxStatusId, toggleSORNModal),
    },
    {
      status: vehicle => !vehicle.hasWrittenOffMarker,
      successContent: () => `The vehicle is not marked as an insurance write-off`,
      failureContent: () => `The vehicle is marked as an insurance write-off`,
    },
    {
      status: vehicle => !vehicle.hasOutstandingFinance,
      successContent: () => `The vehicle does not have outstanding finance`,
      failureContent: () => `The vehicle has outstanding finance`,
    },
    {
      status: vehicle => Boolean(!vehicle.hasSalvageRecords),
      successContent: () => `Salvage Record not found`,
      failureContent: () => `Salvage Record found`,
    },
    {
      status: vehicle => !vehicle.hasStolenMarker,
      successContent: () => `The vehicle is not currently marked as stolen`,
      failureContent: () => `The vehicle is currently marked as stolen`,
    },
    {
      status: vehicle => !vehicle.hasMileageAnomaly,
      successContent: () => `MOT Mileage anomaly has not been detected`,
      failureContent: () => `MOT Mileage anomaly detected`,
    },
    {
      status: vehicle => vehicle.taxiStatusId === TAXI.NONE,
      successContent: vehicle => getTaxiInfoForSideBar(vehicle.taxiStatusId),
      failureContent: vehicle => getTaxiInfoForSideBar(vehicle.taxiStatusId),
    },
  ];

  const token = useShallowEqualSelector(tokenSelector);

  const vrm = report.vehicle?.vrm;
  const carDetails = useShallowEqualSelector(vehicleItemSelector(vrm));

  const { VehicleApi } = useVehicleContext();
  const vehicleListResponse = useResource(() => VehicleApi.getShortList(), []);

  useResponseHandler(vehicleListResponse, (data, error) => {
    if (data && !error) {
      dispatch(setVehicleList({ list: data }));
    }
  });

  useEffect(() => {
    if (!report.vehicle) {
      dispatch(actions.loadVehicle(report.id, token));
    }
    if (!report.mileageAndRisks) {
      dispatch(actions.loadMileageAndRisks(report.id, token));
    }
    if (!report.mots) {
      dispatch(actions.loadMOTs(report.id, token));
    }
    if (!report.questionsAndAnswers) {
      dispatch(actions.loadQuestionsAndAnswers(report.id, token));
    }
    if (!report.services) {
      dispatch(actions.loadServices(report.id, token));
    }
    if (!report.repairs) {
      dispatch(actions.loadRepairs(report.id, token));
    }
    if (!report.runningCosts) {
      dispatch(actions.loadRunningCosts(report.id, token));
    }
  }, [token]);

  const [isSORNModalOpen, setSORNModalOpen] = useState(false);
  const toggleSORNModal = event => {
    event.preventDefault();
    setSORNModalOpen(!isSORNModalOpen);
  };

  if (!report.vehicle) {
    return [];
  }

  const openMileageAndRisksSection = event => {
    event.preventDefault();
    handleTabChange(event, TAB.MILEAGE_AND_RISKS);
  };

  const date = moment().format("DD/MM/YYYY");
  const { vehicle } = report;
  const registrationDate = vehicle.regDate;
  return (
    <styles.Container className={classnames({ "is-in-tab": isInTab })}>
      <div className="general-details">
        <div className="general-details-content">
          <styles.VehiclePicture src={vehicle.imageUrl || VehiclePicture} />
          <div className="general-details-info-items">
            <div className="general-details-info">
              <div className="model-title info-detail">
                {vehicle.make} {vehicle.model}
              </div>
              <div className="info-detail">Registration: {vehicle.vrm}</div>
              <div className="model-colour info-detail">Colour: {vehicle.colour}</div>
              <div className="model-registration info-detail">Registered: {registrationDate}</div>
              {vehicle.mileage ? (
                <div className="info-detail">{vehicle.isUserMileage ? "Mileage" : "Estimated Mileage"}: {format(vehicle.mileage)}</div>
              ) : null}
              {vehicle.listPrice ? (
                <div className="info-detail">Price: £{formatFunds(vehicle.listPrice)}</div>
              ) : null}
              {vehicle.marketPrice ? (
                <div className="info-detail">Market value: £{formatFunds(vehicle.marketPrice)}</div>
              ) : null}
              <div className="model-registration info-detail">Report generated: {date}</div>
            </div>
            <div className="general-details-price"></div>
            <div className="general-details-score">
              <div className="score-details">
                <div className="score-details-title">CarGuide score:</div>
                <div className="score-details-value">80/100</div>
              </div>
              <div className="score-icon"></div>
            </div>
          </div>
          <div>
            <PDFDownload>
              <PDFReport report={report} carDetails={carDetails} />
            </PDFDownload>
          </div>
        </div>
      </div>
      <div className="important-items">
        <div className="important-items-content-container">
          <div className="important-items-title">Important items</div>
          <div className="important-items-we-can-confirm">We can confirm the following:</div>
          <div className="important-items-list">
            {importantItems.map((item, index) => {
              const status = item.status(vehicle);

              return (
                <div key={index} className="important-item">
                  <div
                    className={classnames("important-item-icon", {
                      "item-ok": status,
                      "item-attention": !status,
                    })}
                  >
                    {status ? <CheckCircleOutlineRoundedIcon /> : <ReportProblemOutlinedIcon />}
                  </div>
                  <div className={classnames("important-item-text", { "item-attention": !status })}>
                    {status ? item.successContent(vehicle) : item.failureContent(vehicle)}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="important-items-see-full-list">
            <a href="#" onClick={openMileageAndRisksSection}>
              See full list here
            </a>
          </div>
        </div>
      </div>
      <ModalDialog
        open={isSORNModalOpen}
        handleClose={toggleSORNModal}
        sourcePath={`${APP_WP_URL}/wp-json/wp/v2/posts/1160`}
      />
    </styles.Container>
  );
}
