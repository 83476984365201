import React from 'react';
import * as styles from './styles';

export default function TabContainer({ children, active, tab }) {
  return (
    <styles.TabContainer>
      {
        tab === active && <React.Fragment>{ children }</React.Fragment> 
      }
    </styles.TabContainer>
  );
}
