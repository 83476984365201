import { toLower } from "lodash";
import { Auth } from "aws-amplify";
import { DELETE, update } from "immupdate";
import React, { useCallback, useMemo, useState } from "react";

import { Dialog } from "../ui/Dialog";
import { ActionButton } from "../ui/ActionButton";
import { ErrorSnackbar } from "../ui/ErrorSnackbar";
import { EmailSignUpForm } from "./EmailSignUpForm";
import { EmailSignInForm } from "./EmailSignInForm";
import { ColorPalette } from "../../theme/ColorPalette";
import { EmailConfirmSignUp } from "./EmailConfirmSignUp";
import { useLocationHelpers } from "../../hooks/useLocationHelpers";
import { EmailForgotPasswordForm } from "./EmailForgotPasswordForm";
import { EmailForgotPasswordSubmitForm } from "./EmailForgotPasswordSubmitForm";

export enum EmailAuthType {
  SignUp = "sign-up",
  SignIn = "sign-in",
  Confirm = "confirm",
  ForgotPassword = "forgot-password",
  ForgotPasswordSubmit = "forgot-password-submit",
}

interface Props {
  readonly progress?: number;
  readonly authEmail?: string;
  readonly onlySignIn?: boolean;
  readonly onCancelClick: () => void;
  readonly emailConfirmCode?: string;
  readonly initialType?: EmailAuthType;
  readonly onEmailAuth: (user: any) => void;
  readonly onConfirmAuth?: (user?: any, name?: string, userSub?: string, email?: string) => void;
}

export function EmailAuthWrapper({
  progress,
  onlySignIn,
  onEmailAuth,
  initialType = EmailAuthType.SignUp,
  onConfirmAuth,
  onCancelClick,
  emailConfirmCode,
  authEmail: registerEmail,
}: Props) {
  const [dialog, setDialog] = useState({ title: "", message: "", open: false });

  const [error, setError] = useState<Error | undefined>();
  const [authType, setAuthType] = useState<EmailAuthType>(initialType);
  const [forgotEmail, setForgotEmail] = useState<string | undefined>(
    initialType === EmailAuthType.ForgotPasswordSubmit ? registerEmail : undefined,
  );
  const [authEmail, setAuthEmail] = useState<string | undefined>(
    initialType === EmailAuthType.Confirm ? registerEmail : undefined,
  );

  const isSignUp = useMemo(() => authType === EmailAuthType.SignUp, [authType]);
  const isSignIn = useMemo(() => authType === EmailAuthType.SignIn, [authType]);
  const isConfirm = useMemo(() => authType === EmailAuthType.Confirm, [authType]);
  const isForgotPassword = useMemo(() => authType === EmailAuthType.ForgotPassword, [authType]);
  const isForgotPasswordSubmit = useMemo(() => authType === EmailAuthType.ForgotPasswordSubmit, [
    authType,
  ]);

  const locationHelpers = useLocationHelpers();

  const signInHandle = useCallback(
    ({ email, password }) =>
      Auth.signIn(toLower(email), password)
        .then((user) => {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            Auth.completeNewPassword(user, password, {
              email,
            })
              .then(() => onEmailAuth(user))
              .catch(setError);
          } else {
            onEmailAuth(user);
          }
        })
        .catch((x) => {
          if (x?.code === "UserNotConfirmedException") {
            setAuthEmail(email);
            setAuthType(EmailAuthType.Confirm);
          }
          setError(x);
        }),
    [onEmailAuth],
  );

  return (
    <div className="d-flex flex-column">
      {isSignUp && (
        <EmailSignUpForm
          onCancelClick={onCancelClick}
          onSignInClick={() => setAuthType(EmailAuthType.SignIn)}
          onVerifyClick={() => setAuthType(EmailAuthType.Confirm)}
          onForgotClick={() => setAuthType(EmailAuthType.ForgotPassword)}
          onSubmit={({ email, password, phone, lastName, firstName }) => {
            const name = [firstName, lastName].filter(Boolean).join(" ");

            return Auth.signUp({
              password,
              username: toLower(email),
              attributes: {
                name,
                email: toLower(email),
                "custom:phone_number": phone,
              },
            })
              .then(({ user, userSub }) => {
                setDialog((x) =>
                  update(x, {
                    title: "Sign Up",
                    message:
                      "Registration was successful. Please check your email to verify your email address",
                    open: true,
                  }),
                );

                if (onConfirmAuth) {
                  onConfirmAuth(user, name, userSub, email);
                }

                setAuthType(EmailAuthType.SignIn);
              })
              .catch(setError);
          }}
        />
      )}
      {isSignIn && (
        <EmailSignInForm
          progress={progress}
          onSubmit={signInHandle}
          onForgotClick={() => setAuthType(EmailAuthType.ForgotPassword)}
          onCancelClick={() => {
            if (onlySignIn) {
              onCancelClick();
            } else {
              setAuthType(EmailAuthType.SignUp);
            }
          }}
        />
      )}
      {isConfirm && (
        <EmailConfirmSignUp
          authEmail={authEmail}
          confirmCode={emailConfirmCode}
          onSubmit={({ email, code }) => {
            locationHelpers.replaceQuery({ emailConfirmCode: DELETE, emailAuthType: DELETE });

            Auth.confirmSignUp(email, code)
              .then(() => {
                setDialog((x) =>
                  update(x, {
                    title: "Sign Up",
                    message:
                      "Thank you for verifying your email address. Please sign in to your account now.",
                    open: true,
                  }),
                );
                setAuthEmail(undefined);
                setAuthType(EmailAuthType.SignIn);
              })
              .catch(() => {
                setAuthEmail(undefined);
                setAuthType(EmailAuthType.SignIn);
              });
          }}
        />
      )}
      {isForgotPassword && (
        <EmailForgotPasswordForm
          onSubmit={({ email }) =>
            Auth.forgotPassword(toLower(email))
              .then(() => {
                setForgotEmail(email);
                setDialog((x) =>
                  update(x, {
                    title: "Forgot Password",
                    message: "Please check your email for further instructions.",
                    open: true,
                  }),
                );

                if (onConfirmAuth) {
                  onConfirmAuth();
                }
              })
              .catch(setError)
          }
          onCancelClick={() => setAuthType(EmailAuthType.SignUp)}
        />
      )}
      {isForgotPasswordSubmit && (
        <EmailForgotPasswordSubmitForm
          forgotEmail={forgotEmail}
          onSubmit={async ({ email, password }) => {
            locationHelpers.replaceQuery({ emailConfirmCode: DELETE, emailAuthType: DELETE });

            try {
              if (!emailConfirmCode) {
                throw new Error("Wrong confirm code");
              }

              await Auth.forgotPasswordSubmit(email, emailConfirmCode, password);
              await signInHandle({ email, password });
            } catch (e) {
              setError(e);
            }
          }}
          onCancelClick={() => {
            setForgotEmail(undefined);

            locationHelpers.replaceQuery({ emailConfirmCode: DELETE, emailAuthType: DELETE });

            if (onlySignIn) {
              onCancelClick();
            } else {
              setAuthType(EmailAuthType.SignUp);
            }
          }}
        />
      )}

      <ErrorSnackbar error={error} onClose={() => setError(undefined)} />

      <Dialog
        open={dialog.open}
        title={dialog.title}
        actions={
          <div className="align-self-center">
            <ActionButton
              color={ColorPalette.Primary}
              onClick={() => {
                setDialog((x) =>
                  update(x, {
                    title: "",
                    open: false,
                    message: "",
                  }),
                );
              }}
            >
              Ok
            </ActionButton>
          </div>
        }
      >
        {dialog.message}
      </Dialog>
    </div>
  );
}
