import { useMemo } from "react";

import { ReportApi } from "./ReportApi";
import { useApiBase } from "../ApiContext";

export interface ReportContextProps {
  readonly ReportApi: ReportApi;
}

export function useReportContext(): ReportContextProps {
  const { host, token } = useApiBase();

  const api = useMemo(() => new ReportApi({ host, token }), [host, token]);

  return {
    ReportApi: api,
  };
}
