import { IS_DEV } from "../constants/AppConstants";

export function createEcommerce(
  product: {
    id: string;
    price: string;
    title: string;
    variant?: string;
  },
  debug?: boolean,
) {
  if (!debug && IS_DEV) {
    return;
  }

  // @ts-ignore
  const { ga } = window;

  ga("require", "ec");

  ga("ec:addProduct", {
    quantity: "1",
    id: product.id,
    sku: product.id,
    name: product.title,
    price: product.price,
    variant: product.variant,
  });

  ga("ec:setAction", "purchase", {
    id: Date.now(),
  });
}

export function createEvent(
  event: { label: string; action: string; category: string },
  debug?: boolean,
) {
  if (!debug && IS_DEV) {
    return;
  }

  // @ts-ignore
  const { ga } = window;

  ga("send", {
    hitType: "event",
    eventLabel: event.label,
    eventAction: event.action,
    eventCategory: event.category,
  });
}
