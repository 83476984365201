export const serviceSchedulesPageStyles = {
  pageTitle: {
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
    color: "#22A6B3",
    marginBottom: 10,
  },
  boldText: {
    fontWeight: 700,
  },
  serviceTitle: {
    fontSize: 14,
    marginBottom: 10,
    fontWeight: 700,
  },
  orderText: {
    marginBottom: 10,
  },
  serviceTable: {
    width: 270,
    marginBottom: 10,
  },
  serviceTableTitle: {
    height: 30,
    backgroundColor: "#EB4D4B",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    padding: 5,
    fontWeight: 700,
  },
  serviceTableItem: {
    height: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 5,
  },
  previousBlock: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  link: {
    textDecoration: "underline",
    fontStyle: "italic",
    fontSize: 12,
  },
};
