import classnames from "classnames";
import ErrorIcon from "@material-ui/icons/Error";
import Snackbar from "@material-ui/core/Snackbar";
import React, { useEffect, useState } from "react";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import { useStyles } from "./styles";

function ErrorModal({ error, onClose }) {
  const classes = useStyles();

  const [state, setState] = useState({
    isOpen: false,
    message: null,
  });

  useEffect(() => {
    setState({
      isOpen: Boolean(error),
      message: error && (error.detail || error.message),
    });
  }, [error]);

  return (
    <Snackbar
      onClose={onClose}
      open={state.isOpen}
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <SnackbarContent
        className={classes.error}
        message={
          <span className={classes.message}>
            <ErrorIcon className={classnames(classes.icon, classes.iconVariant)} />
            {state.message}
          </span>
        }
      />
    </Snackbar>
  );
}

export default ErrorModal;
