export const LIVE_GOOGLE_OPTIMIZE_ID = "GTM-M2WLCHM";
export const STAGING_GOOGLE_OPTIMIZE_ID = "OPT-PBGKDD7";

export const LIVE_GOOGLE_ANALYTICS_TRACING_ID = "UA-148409511-1";
export const STAGING_GOOGLE_ANALYTICS_TRACING_ID = "UA-148409511-2";

export const GOOGLE_ANALYTICS_SCRIPT_URL = "https://www.google-analytics.com/analytics.js";
export const DEBUG_GOOGLE_ANALYTICS_SCRIPT_URL =
  "https://www.google-analytics.com/analytics_debug.js";

export const VEHICLE_CATEGORY = "Vehicle";
export const MOTORWAY_CATEGORY = "Motorway";
export const BUY_PRODUCT_CATEGORY = "Buy product";
export const REGISTER_USER_CATEGORY = "Register user";
export const VEHICLE_LOOKUP_CATEGORY = "Vehicle lookup";
export const VEHICLE_SCRAPE_CATEGORY = "Vehicle scrape";

export enum RegisterActions {
  Register = "register",
}

export enum VehicleLookupActions {
  VehicleLookup = "vehicle lookup",
  VehicleCancel = "vehicle 'cancel'",
  VehicleSalvage = "vehicle salvage",
  VehicleExported = "vehicle exported",
  VehicleMakeModel = "vehicle make model",
  VehiclePlateChange = "vehicle plate change",
  VehicleLookupError = "vehicle lookup error",
  VehicleColourChange = "vehicle colour change",
  VehicleMakeModelYear = "vehicle make model year",
  VehicleLookupNotFound = "vehicle lookup not found",
  VehicleAddToShortlist = "vehicle add to shortlist",
  VehicleFailurePrediction = "vehicle failure prediction",
}

export enum MotorwayActions {
  ShortlistValuation = "Shortlist Valuation",
  ShortlistBannerValuation = "Shortlist Banner Valuation",
}

export enum BuyProductActions {
  BuyProductView = "buy product view",
  BuyProductError = "buy product error",
  BuyProductPopup = "buy product popup",
  BuyProductSuccess = "buy product success",
  BuyProductCardFail = "buy product card fail",
  BuyProductStripeCheckoutComplete = "stripe checkout complete",
}

export enum VehicleScrapeActions {
  MotorsLookup = "motors lookup",
  MotorsCancel = "motors 'cancel'",
  AutotraderLookup = "autotrader lookup",
  AutotraderCancel = "autotrader 'cancel'",
  MotorsLookupError = "motors lookup error",
  MotorsLookupNotFound = "motors lookup not found",
  MotorsAddToShortList = "motors add to shortlist",
  AutotraderLookupError = "autotrader lookup error",
  AutotraderLookupNotFound = "autotrader lookup not found",
  AutotraderAddToShortList = "autotrader add to shortlist",
}

export enum VehicleActions {
  VehicleSave = "vehicle save",
  VehicleCancel = "vehicle cancel",
  VehicleDelete = "vehicle delete",
}

export enum IntercomActions {
  Authorization = "Authorization",
  VehicleLookup = "Vehicle Lookup",
  VehicleScrape = "Vehicle Scrape",
  ReportPurchased = "Report Purchased",
  AccountUpgraded = "Account Upgraded",
  ComparisonViewed = "Comparison Viewed",
  VehicleAddedToShortlist = "Vehicle Added to shortlist",
  VehicleDeletedFromShortlist = "Vehicle Deleted from shortlist",
}
