import "./styles.scss"

import React from "react";
import cx from "classnames";
import { SvgIcon } from "@material-ui/core";

export function BodyIcon(props) {
  return <SvgIcon width="39" height="21" viewBox="0 0 39 21" {...props}  className={cx("icons body-icon", props.className)}>
    <g fill="none" fillRule="evenodd" stroke="#000" strokeWidth="1.5">
      <path d="M34.938 16.962c0 2.23-1.819 4.038-4.063 4.038-2.244 0-4.063-1.808-4.063-4.038 0-2.231 1.819-4.039 4.063-4.039 2.244 0 4.063 1.808 4.063 4.039zM12.188 16.962c0 2.23-1.819 4.038-4.063 4.038-2.244 0-4.063-1.808-4.063-4.038 0-2.231 1.819-4.039 4.063-4.039 2.244 0 4.063 1.808 4.063 4.039z"/>
      <path d="M12.188 16.962h14.624m8.125 0h2.438c.861 0 1.625-.76 1.625-1.616V10.5c0-1.543-4.875-3.23-8.938-4.038-1.364-.272-4.875-4.847-4.875-4.847C24.375.808 23.563 0 21.938 0H10.67C8.06 0 7.412 6.462 4.063 6.462H2.438C1.071 6.462 0 7.092 0 8.885v6.71c0 .855.764 1.367 1.625 1.367h2.438"/>
      <path strokeLinecap="round" strokeLinejoin="round" d="M3.25 6.462h26.813"/>
      <path d="M17.063 0v6.462"/>
    </g>
  </SvgIcon>
}
