import React, { useMemo } from "react";

import { useResource } from "../../hooks/useResource";
import { usePurchaseContext } from "../../api/purchase/PurchaseContext";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { CarBuyCreditsCard, CarBuyCreditsCardProps } from "./CarBuyCreditsCard";
import { hasSubscriptionSelector, subscriptionExpiredSelector } from "../../reducers/userReducer";

export function CarBuyCreditsCardWrapper(props: CarBuyCreditsCardProps) {
  const hasSubscription = useShallowEqualSelector(hasSubscriptionSelector);
  const subscriptionExpired = useShallowEqualSelector(subscriptionExpiredSelector);

  const { PurchaseApi } = usePurchaseContext();

  const purchaseOptionsResponse = useResource(() => PurchaseApi.getPurchaseOptions(), []);

  const products = useMemo(() => purchaseOptionsResponse.data?.products || [], [
    purchaseOptionsResponse,
  ]);

  const planProduct = useMemo(() => products.find(({ id }) => id === "payg_upgrade"), [
    products,
    hasSubscription,
    subscriptionExpired,
  ]);

  return <CarBuyCreditsCard {...props} product={planProduct} />;
}
