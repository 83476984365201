export const CLEAN_ERROR = "SHORTLIST/CLEAN_ERROR";
export const SET_ERROR = "SHORTLIST/SET_ERROR";
export const LOAD_VEHICLES_PENDING = "SHORTLIST/LOAD_VEHICLES_PENDING";
export const LOAD_VEHICLES_FULFILLED = "SHORTLIST/LOAD_VEHICLES_FULFILLED";
export const LOAD_VEHICLES_REJECTED = "SHORTLIST/LOAD_VEHICLES_REJECTED";
export const FIND_VEHICLE_PENDING = "SHORTLIST/FIND_VEHICLE_PENDING";
export const FIND_VEHICLE_FULFILLED = "SHORTLIST/FIND_VEHICLE_FULFILLED";
export const FIND_VEHICLE_REJECTED = "SHORTLIST/FIND_VEHICLE_REJECTED";
export const ADD_VEHICLE_PENDING = "SHORTLIST/ADD_VEHICLE_PENDING";
export const ADD_VEHICLE_FULFILLED = "SHORTLIST/ADD_VEHICLE_FULFILLED";
export const ADD_VEHICLE_REJECTED = "SHORTLIST/ADD_VEHICLE_REJECTED";
