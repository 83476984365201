import styled from "styled-components";

export const Container = styled.div`
  .no-data-message {
    margin: 0 0 30px;
  }
  .issues-likelihood-description {
    margin: 0 0 20px;

    a {
      font-weight: 500;
      color: #30336b;
      text-decoration: underline;
    }
  }
  .issues-likelihood-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2vw;
    margin: 0 0 20px;

    .issue-likelihood {
      .likelihood-title {
        padding: 10px;
        font-weight: 500;
        background: #22a6b3;
        color: #fff;
      }
      .likelihood-content {
        padding: 10px 0;
      }

      &.is-dangerous {
        .likelihood-title {
          background: #eb4d4b;
        }
      }
    }
  }
`;
