import "./assets/app-route-layout.scss";

import cx from "classnames";
import { Helmet } from "react-helmet";
import React, { ReactNode, useMemo } from "react";

import Footer from "../../commons/Footer";
import { useIntercom } from "../../hooks/useIntercom";
import { LoaderProgress } from "../ui/LoaderProgress";
import { useScrollMemory } from "../../hooks/useScrollMemory";
import { usePageViewEvent } from "../../hooks/usePageViewEvent";
import { ScreenHeader, ScreenHeaderProps } from "./ScreenHeader";

interface Props extends Omit<ScreenHeaderProps, "title"> {
  readonly loading?: boolean;

  readonly title?: string;
  readonly screenTitle?: string;

  readonly children?: ReactNode;

  readonly headerClassName?: string;
  readonly contentClassName?: string;

  readonly withFooter?: boolean;
}

export function AppRouteLayout({
  title,
  loading,
  children,
  withFooter = true,
  screenTitle,
  headerClassName,
  contentClassName,
  ...screenHeaderProps
}: Props) {
  useIntercom();

  useScrollMemory();

  const documentTitle = useMemo(
    () => ["Car Guide", title || screenTitle].filter(Boolean).join(" "),
    [title, screenTitle],
  );

  usePageViewEvent(documentTitle);

  return (
    <div className="d-flex flex-column flex-1 app-route-layout">
      {documentTitle && (
        <Helmet>
          <title>{documentTitle}</title>
          <meta
            name="description"
            content="Car check with a difference - we check a car’s history and show its future."
          />
        </Helmet>
      )}

      {screenTitle && (
        <ScreenHeader {...screenHeaderProps} className={headerClassName} title={screenTitle} />
      )}

      <div
        className={cx(
          "d-flex flex-column flex-1",
          { "position-relative": loading },
          contentClassName,
        )}
      >
        {children}

        {loading && (
          <div className="position-absolute loader-container">
            <LoaderProgress className="justify-content-center position-sticky loader-process" />
          </div>
        )}
      </div>

      {withFooter && <Footer />}
    </div>
  );
}
