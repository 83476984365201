import "./assets/car-buy-report-card.scss";

import React from "react";
import cx from "classnames";

import Button from "../CustomButtons/Button";
import { ProductPlans } from "../../dto/EnumDTO";
import { StripeContainer } from "../stripe/StripeContainer";
import { VehicleNoReportItem } from "../short-list/VehicleNoReportItem";
import { ShortListBuyReportButtonWrapper } from "../short-list/ShortListBuyReportButtonWrapper";

interface Props {
  readonly vrm: string;
  readonly className?: string;
  readonly hasReport: boolean;
  readonly onBuyClick: () => void;
  readonly onMoreClick: () => void;
  readonly onViewReportClick: () => void;
  readonly onComplete?: (product: ProductPlans) => void;
  readonly isTrade?: boolean;
}

export function CarBuyReportCard({
  vrm,
  className = "d-flex",
  hasReport,
  onBuyClick,
  onComplete,
  onMoreClick,
  onViewReportClick,
  isTrade,
}: Props) {
  if (hasReport) {
    return (
      <div id="summary-content-box" className={cx("flex-column mb-3 mt-2 mt-lg-0", className)}>
        <Button
          // @ts-ignore
          onClick={onViewReportClick}
          id="summary-view-report-trackbutton"
          className="bg-secondary ml-lg-1 ml-xl-2"
        >
          View report
        </Button>
      </div>
    );
  }

  return (
    <div className="d-flex mt-2 mt-lg-0 ml-lg-1 ml-xl-2 flex-column mb-lg-4 flex-1 car-buy-report-card">
      {!isTrade && (
        <span
          id="report-card-title"
          className="font-weight-bold font-italic my-3 mx-4 text-center report-card-title"
        >
          Buy like a Pro and negotiate a better price using our personalised buyer's report.
        </span>
      )}

      <div className="mx-4">
        <div className="d-flex my-2">
          <VehicleNoReportItem id="bullet_1" title="Outstanding finance & recalls" />
          <VehicleNoReportItem id="bullet_2" title="Predict MOT failure items" />
        </div>

        <div className="d-flex justify-content-between mt-2 mb-2">
          <VehicleNoReportItem id="bullet_3" title="Comprehensive mileage history" />
          <VehicleNoReportItem id="bullet_4" title="Due Service & Repairs costs" />
        </div>

        <div className="d-flex justify-content-between my-2">
          <VehicleNoReportItem id="bullet_5" title="CarGuide True Ownership Cost" />
          <VehicleNoReportItem
            id="bullet_6"
            title={
              <span>
                Scrapped & Stolen,{" "}
                <a
                  href="#"
                  className="text-decoration-underline"
                  onClick={(event) => {
                    event.preventDefault();
                    onMoreClick();
                  }}
                >
                  see more...
                </a>
              </span>
            }
          />
        </div>
      </div>

      <div className="d-flex flex-column mt-4 mx-4 mb-3">
        <StripeContainer>
          <ShortListBuyReportButtonWrapper
            vrm={vrm}
            onClick={onBuyClick}
            onComplete={onComplete}
            id="summary-buy-report-trackbutton"
            className="text-white report-buy-button"
          />
        </StripeContainer>
      </div>
    </div>
  );
}
