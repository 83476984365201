import React from "react";
import {Image, Line, Link, StyleSheet, Svg, Text, View} from "@react-pdf/renderer";
import {formatFunds} from "../../../../utils/number";
import {theTestDrivePageStyles} from "../styles/theTestDrivePageStyles";
import {PageLayout} from "./PageLayout";

import Vehicle from "../assets/good-vehicle.png";
import FrontCover from "../../../../assets/img/reports/front-photo-cover.png";
import BackCover from "../../../../assets/img/reports/back-photo-cover.png";

const styles = StyleSheet.create(theTestDrivePageStyles);

export const TheTestDrivePage = ({ report }) => {
  const { vehicle = {}, mileageAndRisks } = report;

  const dataLength =
    mileageAndRisks &&
    mileageAndRisks.chartReadyData &&
    mileageAndRisks.chartReadyData.map(({ data }) => data).filter(Boolean).length;

  return (
    <PageLayout id={report.id}>
      <View wrap={true}>
        <Text style={styles.pageTitle}>THE TEST DRIVE</Text>
        <Text style={styles.testTitle}>
          When it’s time for a test drive, here are some important things to look out for:
        </Text>

        <View style={styles.testContainer}>
          <View style={styles.containerNumber}>
            <Text style={styles.containerNumberText}>1</Text>
          </View>
          <Text style={styles.containerTitle}>Start the car from cold</Text>
          <Text style={styles.cardText}>
            When arranging the test drive ask the seller to ensure the vehicle is cold.
          </Text>
          <Text style={styles.additionalCardText}>
            Starting the car from cold will help you detect any potential issues. If the car doesn’t
            start from cold it may need a new battery or alternator could be required.
            Alternatively, there could be a problem with the starter motor.
          </Text>
        </View>

        <View style={styles.testContainer}>
          <View style={styles.containerNumber}>
            <Text style={styles.containerNumberText}>2</Text>
          </View>
          <Text style={styles.containerTitle}>Check the V5C (Log Book)</Text>
          <Text style={styles.cardText}>
            We do not recommend you buy a vehicle without inspecting the log book. If the seller
            says I’ve applied for it, wait for it to be in their possession before parting with your
            hard earned cash!
          </Text>
          <Text style={styles.cardText}>Check the log book matches the following:</Text>
          <View style={styles.logBookContent}>
            <View style={styles.logBookContentCard}>
              <View style={styles.logBookContentCardTitle}>
                <Text>Front cover</Text>
              </View>
              <View style={styles.flexWrapper}>
                <Image style={styles.coverImg} src={FrontCover} />
              </View>
              <View style={styles.textWrapper}>
                <Text style={styles.logBookContentText}>
                  [Z] Number of owners: {vehicle.numberOfOwners}
                </Text>
                <Text style={styles.logBookContentText}>
                  [Z.2] Acquired vehicle on: {vehicle.lastKeeperDate}
                </Text>
              </View>
            </View>
            <View style={styles.logBookContentCard}>
              <View style={styles.logBookContentCardTitle}>
                <Text>2nd page</Text>
              </View>
              <View style={styles.flexWrapper}>
                <Image style={styles.coverImg} src={BackCover} />
              </View>
              <View style={styles.textWrapper}>
                <Text style={styles.logBookContentText}>[B] Date of first registration: {vehicle.regDate}</Text>
                <Text style={styles.logBookContentText}>[D.1] Make: {vehicle.make}</Text>
                <Text style={styles.logBookContentText}>[D.3] Model: {vehicle.model}</Text>
                <Text style={styles.logBookContentText}>[E] VIN: {vehicle.vin}</Text>
                <Text style={styles.additionalInformation}>To reduce the risk of vehicle cloning we don’t display the full VIN number.</Text>
                <Text style={styles.logBookContentText}>[P.5] Engine Number: {vehicle.engineNumber}</Text>
                <Text style={styles.logBookContentText}>[R] Colour: {vehicle.colour}</Text>
                <Text style={styles.logBookContentText}>V5C issue date: {vehicle.v5cIssueDate}</Text>
              </View>
            </View>
          </View>
        </View>

        <View break={true} style={styles.containerWrapper}>
          <View style={styles.innerTestContainer}>
            <View style={styles.containerNumber}>
              <Text style={styles.containerNumberText}>3</Text>
            </View>
            <Text style={styles.containerTitle}>Proof of Repairs</Text>
            <Text style={styles.cardText}>
              If the seller said they have invoices for the repaired items, ask to see them. They
              should have the following:
            </Text>
            {(report.repairs &&
              report.repairs.proofOfRepairs &&
              report.repairs.proofOfRepairs.map((repair, index) => (
                <Text key={index}>
                  {repair.year} - {repair.details}
                </Text>
              ))) || <Text style={styles.cardText}>No Data</Text>}
          </View>

          <View style={styles.innerTestContainer}>
            <View style={styles.containerNumber}>
              <Text style={styles.containerNumberText}>4</Text>
            </View>
            <Text style={styles.containerTitle}>Proof of Service</Text>
            {!report.services ||
            (!report.services.servicesPrev && !report.services.servicesDue) ||
            (!report.services.servicesPrev.length && !report.services.servicesDue.length) ? (
              <Text style={styles.cardText}>
                Our data providers don’t hold service schedule information for this vehicle. This
                could be due to a number of reasons, for example, the age of the vehicle.
              </Text>
            ) : (
              <View style={styles.flexWrapper}>
                <Text style={styles.cardText}>
                  Ask to see the service history book and make sure the full list of services have
                  been done:
                </Text>
                <Text style={styles.containerTitle}>Previous Services:</Text>
                <View>
                  {(report.services &&
                    report.services.servicesPrev &&
                    report.services.servicesPrev.length &&
                    report.services.servicesPrev
                      .slice(0, 5)
                      .map((service, index) => (
                      <Text key={index}>
                        {service.title} - £{formatFunds(service.totalCost)}
                      </Text>
                    ))) || <Text style={styles.cardText}>No Data</Text>}
                </View>
                <Text style={styles.containerTitle}>Services due:</Text>
                <View>
                  {(report.services &&
                    report.services.servicesDue &&
                    report.services.servicesDue.length &&
                    report.services.servicesDue.map((service, index) => (
                      <Text key={index} className="info-item">
                        {service.title} - £{formatFunds(service.totalCost)}
                      </Text>
                    ))) || <Text style={styles.cardText}>No Data</Text>}
                </View>
              </View>
            )}
          </View>
        </View>

        <View style={styles.infoBlock}>
          <Image style={styles.vehicleIcon} src={Vehicle} />
          <Text style={styles.infoBlockTitle}>CarGuide top tip</Text>
          <Text style={styles.cardText}>
            If the previous service has not been carried out then you should be able to negotiate a
            lower price. If the next service is due imminently you should also be able to negotiate
            a lower price.
          </Text>
        </View>

        <View wrap={false} style={styles.containerWrapper}>
          <View style={styles.innerTestContainer}>
            <View style={styles.containerNumber}>
              <Text style={styles.containerNumberText}>5</Text>
            </View>
            <Text style={styles.containerTitle}>Mileage History</Text>
            {vehicle.hasMileageAnomaly ? (
              <Text style={styles.cardText}>
                Here you can show the chart to the seller and ask them to explain the mileage
                anomaly.
              </Text>
            ) : (
              dataLength > 1 && (
                <Text style={styles.cardText}>There was no mileage anomaly detected.</Text>
              )
            )}
            {mileageAndRisks.chartReadyData?.length > 0 ? (
              <View style={styles.mileageTable}>
                <View style={styles.mileageTableHeader}>
                  <Text style={styles.mileageTableCol1}>Year</Text>
                  <Text style={styles.mileageTableCol2}>Expected Mileage</Text>
                  <Text style={styles.mileageTableCol3}>Real Mileage</Text>
                </View>
                <View style={styles.lineMileageTable}>
                  <Svg height="1" width="210">
                    <Line x1="0" y1="0" x2="100%" y2="0" strokeWidth={2} stroke="rgb(35,165,180)" />
                  </Svg>
                </View>
                <View style={styles.mileageTableBody}>
                  {mileageAndRisks.chartReadyData.map((entry, index) => {
                    const currentStyle = (index % 2) ? styles.mileageTableLine : [styles.mileageTableLine, styles.bgGray];

                    return (
                      <View key={entry.year} style={currentStyle}>
                        <Text style={styles.mileageTableCol1}>{entry.year}</Text>
                        <Text style={styles.mileageTableCol2}>{entry.expectedMileage.toFixed()}</Text>
                        <Text style={styles.mileageTableCol3}>{entry.mileage ? entry.mileage.toFixed() : `N/A`}</Text>
                      </View>
                    );
                  })}
                </View>
              </View>
            ) : (
              <Text style={styles.cardText}>No data.</Text>
            )}
          </View>

          <View style={styles.innerTestContainer}>
            <View style={styles.containerNumber}>
              <Text style={styles.containerNumberText}>6</Text>
            </View>
            <Text style={styles.containerTitle}>
              Knocks or unusual noise could indicate worn suspension components.
            </Text>
            <Text style={styles.cardText}>The engine should start first time.</Text>
            <Text style={styles.cardText}>
              Check dashboard lights for any red or amber warning lights.
            </Text>
            <Text style={styles.cardText}>
              When you have finished the test drive, let the car stand for 1 minute then try to
              restart the ignition - some 'hot start' issues can be costly to fix
            </Text>
            <Text style={styles.cardText}>
              Read our test drive guide for more details
              <Link
                src="https://www.carguide.co.uk/used-car-test-drive-checklist"
                style={styles.link}
              >
                {" "} here
              </Link>
            </Text>
          </View>
        </View>
      </View>
    </PageLayout>
  );
};
