import "./assets/vehicle-salvage-dialog.scss";

import React from "react";

import { Dialog } from "../ui/Dialog";
import { ActionButton } from "../ui/ActionButton";

interface Props {
  readonly open: boolean;
  readonly onClose: () => void;
}

export function VehicleSalvageInfoDialog({ open, onClose }: Props) {
  if (!open) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Salvage Record"
      className="vehicle-salvage-dialog"
      actions={
        <div className="d-flex flex-column flex-sm-row justify-content-end">
          <ActionButton onClick={onClose}>OK</ActionButton>
        </div>
      }
    >
      <span>To see MIAFTR recorded write-off status, please purchase a report.</span>
    </Dialog>
  );
}
