import { toFinite } from "lodash";
import React, { useMemo } from "react";
import { useFormikContext } from "formik";

import { ProductPlans } from "../../dto/EnumDTO";
import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";
import { FormikStripeCardField } from "../form/FormikStripeCardField";
import { AuthProductProps, AuthRegisterFormProps } from "../../api/auth/AuthDTO";
import { StripePaymentRequestButton } from "../stripe/StripePaymentRequestButton";

interface Props {
  readonly stripe?: any;
  readonly progress?: number;
  readonly cardError: boolean;
  readonly product?: AuthProductProps;
  readonly onPayment?: (ev: any) => void;
  readonly onSubmit: (MouseEventHandler) => void;
}

export function PaymentForm({ product, cardError, stripe, onPayment, progress, onSubmit }: Props) {
  const { isSubmitting } = useFormikContext<AuthRegisterFormProps>();

  const amount = useMemo(() => {
    const price = [product?.price?.priceInteger, product?.price?.priceFractional]
      .filter(Boolean)
      .join("");

    return toFinite(price);
  }, [product]);

  const buttonId = useMemo(() => {
    if (cardError) {
      if (product?.id === ProductPlans.PaygSignUp) {
        return "buyjourney-checkout-payg-trackbutton";
      }

      if (product?.id === ProductPlans.SubsSignUp) {
        return "buyjourney-checkout-30days-trackbutton";
      }
    }

    if (product?.id === ProductPlans.PaygSignUp) {
      return "buyjourney-buyfor-payg-trackbuttonn";
    }

    if (product?.id === ProductPlans.SubsSignUp) {
      return "buyjourney-buyfor-30days-trackbutton";
    }
  }, [product, cardError]);

  return (
    <>
      {Boolean(amount) && (
        <StripePaymentRequestButton
          amount={amount}
          stripe={stripe}
          className="mt-3"
          onPayment={onPayment}
          label={product?.title || ""}
        />
      )}

      <FormikStripeCardField name="stripe" wrapperClassName="mb-3" />

      <ActionButton
        id={buttonId}
        className="mb-3"
        onClick={onSubmit}
        color={ColorPalette.Success}
        loading={Boolean(isSubmitting || (progress && progress >= 0))}
      >
        Buy with payment card
      </ActionButton>

      <img
        alt="stripe"
        className="w-100"
        src="https://cdn.mailcleaner.net/wp-content/uploads/logo_credit_cards_stripe_english.png"
      />
    </>
  );
}
