import { useEffect, useMemo } from "react";

import { useSocket } from "./useSocket";
import { Analytics } from "../utils/Analytics";
import { ProductProps } from "../dto/ProductDTO";
import { SOCKET_TOPIC } from "../constants/socket";
import { useShallowEqualSelector } from "./useShallowSelector";
import { hasSubscriptionSelector } from "../reducers/userReducer";

const analytics = new Analytics();

export interface UpgradePaymentSocketProps {
  readonly skip?: boolean;
  readonly onDone?: () => void;
  readonly product?: ProductProps;
  readonly onError?: (message?: string) => void;
}

export function useUpgradePaymentSocket(options?: UpgradePaymentSocketProps) {
  const hasSubscription = useShallowEqualSelector(hasSubscriptionSelector);

  const { data: socketData } = useSocket([SOCKET_TOPIC.UPGRADE_PAYMENT], {
    skip: options?.skip,
  });

  const generateReportSocket = useMemo(() => {
    if (socketData) {
      return socketData[SOCKET_TOPIC.UPGRADE_PAYMENT];
    }
  }, [socketData]);

  useEffect(() => {
    if (options?.skip) {
      return;
    }

    if (generateReportSocket?.done) {
      if (!generateReportSocket?.error && options?.onDone) {
        options.onDone();
      }

      if (generateReportSocket?.error) {
        if (options?.onError) {
          options.onError(generateReportSocket.message);
        }
      }
    }

    if (options?.product) {
      if (generateReportSocket?.done && !generateReportSocket?.error) {
        analytics.facebookEvent({
          event: "Purchase",
          value: Number(`${options.product.priceWhole}.${options.product.priceFractional}`),
        });

        analytics.googleEcommerce({
          id: options.product.id,
          title: "Buyers Report",
          variant: hasSubscription ? "30-day Plan" : "PAYG Plan",
          price: `${options.product.priceWhole}.${options.product.priceFractional}`,
        });

        analytics.microsoftAds({
          id: options.product.id,
          title: "Buyers Report",
          variant: hasSubscription ? "30-day Plan" : "PAYG Plan",
          price: `${options.product.priceWhole}.${options.product.priceFractional}`,
        });
      }
    }
  }, [generateReportSocket?.done, generateReportSocket?.error, options?.product]);

  return {
    progress: generateReportSocket?.progress,
  };
}
