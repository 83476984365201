import React, { ReactNode } from "react";
import MUICheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { FieldProps } from "../../dto/FormDTO";

interface Props extends FieldProps<boolean> {
  readonly id?: string;
  readonly label?: ReactNode;
  readonly checked?: boolean;
  readonly className?: string;
  readonly color?: "primary" | "secondary" | "default";
}

export function CheckboxLegacy({
  id,
  name,
  value,
  label,
  color,
  checked,
  onChange,
  className,
}: Props) {
  return (
    <FormControlLabel
      id={id}
      label={label}
      className={className}
      control={
        <MUICheckbox
          name={name}
          color={color}
          checked={Boolean(value || checked)}
          onChange={(_event, checked) => onChange(checked)}
        />
      }
    />
  );
}
