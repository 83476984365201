import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "./utils/SyncScroll";

import "./theme/scss/bootstrap.scss";

import React from "react";
import ReactDOM from "react-dom";
import { initTagManager } from "./utils/TagManager";
import { AppContainer } from "./containers/AppContainer";

initTagManager();

ReactDOM.render(<AppContainer />, document.getElementById("root"));

if (module.hot) {
  module.hot.accept();
}
