import React from "react";
import cx from "classnames";

import {BasicDetailsRow} from "./BasicDetailsRow";
import {
    AuthVehicleKeyDatesProps,
    AuthVehicleProvenanceProps,
    VehicleKeyDataProps,
    VehicleProvenanceProps
} from "../../api/vehicle/VehicleDTO";

interface Props {
  readonly isReveal?: boolean;
  readonly className?: string;
  readonly provenance: VehicleProvenanceProps | AuthVehicleProvenanceProps;
  readonly keyDates: VehicleKeyDataProps | AuthVehicleKeyDatesProps;
}

export function BasicDetailsFourthRow({ isReveal, className, provenance, keyDates }: Props) {

  return (
    <BasicDetailsRow className={className}>
      <div className="d-flex flex-grow-1 mx-1 mx-lg-2 flex-shrink-1 flex-column align-items-center justify-content-end flex-1 card-cell">
        <span className="mt-1 text-center font-weight-light card-cell-title-small">
          Plate Change?
        </span>
        <span
          className={cx("font-weight-bold text-center text-secondary fl-4", {
            "text-danger": provenance.hasVrmChanges || provenance.hasPlateChanges,
            "text-secondary": !provenance.hasVrmChanges && !provenance.hasPlateChanges,
          })}
        >
          {provenance.hasVrmChanges || provenance.hasPlateChanges ? "YES" : "NO"}
        </span>
      </div>
      <div className="d-flex flex-grow-1 mx-1 mx-lg-2 flex-shrink-1 flex-column align-items-center justify-content-end flex-1 card-cell">
        <span className="mt-1 text-center font-weight-light card-cell-title-small">
          Colour Change?
        </span>
        <span
          className={cx("font-weight-bold text-center fl-4", {
            "text-danger": provenance.hasPreviousColours || provenance.hasColourChanges,
            "text-secondary": !provenance.hasPreviousColours && !provenance.hasColourChanges,
          })}
        >
          {provenance.hasPreviousColours || provenance.hasColourChanges ? "YES" : "NO"}
        </span>
      </div>
      
      <div className="d-flex mb-n1 flex-grow-1 mx-1 mx-lg-2 flex-shrink-1 flex-column align-items-center justify-content-end flex-1 card-cell">
        <span className="font-weight-bold text-center fl-3">{keyDates.lastV5CIssueDate}</span>
        <span className="mt-1 text-center font-weight-light card-cell-title-small">
          V5c Last Issued
        </span>
      </div>      
    </BasicDetailsRow>
  );
}
