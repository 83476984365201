import * as types from "../types/report";
import reportAPI from "../../api-legacy/reportAPI";

export const setReport = (reportId) => (dispatch) => {
  dispatch({
    type: types.SET_REPORT,
    payload: reportId,
  });
};

export const loadVehicle = (reportId, token) => async (dispatch) => {
  const { data: vehicle } = await reportAPI.info(reportId, token);

  dispatch({
    type: types.SET_VEHICLE,
    payload: {
      reportId,
      vehicle,
    },
  });
};

export const loadMileageAndRisks = (reportId, token) => async (dispatch) => {
  const { data: mileageAndRisks } = await reportAPI.mileageAndRisks(reportId, token);

  dispatch({
    type: types.SET_MILEAGE_AND_RISKS,
    payload: {
      reportId,
      mileageAndRisks,
    },
  });
};

export const loadMOTs = (reportId, token) => async (dispatch) => {
  const { data: mots } = await reportAPI.MOTs(reportId, token);

  dispatch({
    type: types.SET_MOTS,
    payload: {
      reportId,
      mots,
    },
  });
};

export const loadQuestionsAndAnswers = (reportId, token) => async (dispatch) => {
  const { data: questionsAndAnswers } = await reportAPI.questionsAndAnswers(reportId, token);

  dispatch({
    type: types.SET_QUESTIONS_AND_ANSWERS,
    payload: {
      reportId,
      questionsAndAnswers,
    },
  });
};

export const loadServices = (reportId, token) => async (dispatch) => {
  const { data: services } = await reportAPI.serviceSchedules(reportId, token);

  dispatch({
    type: types.SET_SERVICES,
    payload: {
      reportId,
      services,
    },
  });
};

export const loadRepairs = (reportId, token) => async (dispatch) => {
  const { data: repairs } = await reportAPI.repairs(reportId, token);

  dispatch({
    type: types.SET_REPAIRS,
    payload: {
      reportId,
      repairs,
    },
  });
};

export const loadRunningCosts = (reportId, token) => async (dispatch) => {
  const { data: runningCosts } = await reportAPI.runningCosts(reportId, token);

  dispatch({
    type: types.SET_RUNNNING_COSTS,
    payload: {
      reportId,
      runningCosts,
    },
  });
};
