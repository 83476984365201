import {BaseApi} from "../BaseApi";
import {PaymentIntentProps} from "./StripeDTO";

interface CreatePaymentIntentValues {
  readonly vrm: string;
  readonly email?: string;
  readonly productId: string;
}

interface UpgradeAccountValues {
  readonly email?: string;
}

export class StripeApi extends BaseApi {
  public createPaymentIntent({
    vrm,
    email,
    productId,
  }: CreatePaymentIntentValues): Promise<PaymentIntentProps> {
    return this.post("stripe/intent/buy_report", { json: { vrm, email, productId } });
  }

  public upgradeAccount({ email }: UpgradeAccountValues) {
    return this.post("stripe/intent/upgrade_account", { json: { email } });
  }
}
