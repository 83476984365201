import "./styles.scss"

import React from "react";
import cx from "classnames";
import { SvgIcon } from "@material-ui/core";

export function PaintIcon(props) {
  return <SvgIcon width="32" height="28" viewBox="0 0 31 27" {...props} className={cx("icons paint-icon", props.className)}>
    <g fill="none" fillRule="evenodd">
      <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M18.212 4.973S31 3.846 31 16.429c0 8.3-2.881 12.825-2.881 5.52 0-6.754-2.48-6.897-2.48-6.897"/>
      <path fill="#000" d="M15.5 12.15a1.35 1.35 0 0 1 0-2.7 1.35 1.35 0 1 1 0 2.7"/>
      <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M15.5 10.8c-5.408 0-12.804-1.196-12.804-5.604C2.696 2.47 6.56.74 12.47.676"/>
      <path stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M13.247 0L.631 12.636a2.16 2.16 0 0 0 0 3.05c.84.843 9.823 9.84 10.664 10.682a2.15 2.15 0 0 0 3.046 0l12.615-12.636L13.247 0z"/>
    </g>
  </SvgIcon>
}
