import { useEffect, useMemo } from "react";

import { useSocket } from "./useSocket";
import { ProductPlans } from "../dto/EnumDTO";
import { Analytics } from "../utils/Analytics";
import { SOCKET_TOPIC } from "../constants/socket";
import { AuthProductProps } from "../api/auth/AuthDTO";
import { LookupVehicleProps } from "../api/vehicle/VehicleDTO";
import { createAddVehicleEvents } from "../utils/AnalyticsHelpers";

const analytics = new Analytics();

export interface RegisterSocketProps {
  readonly skip?: boolean;
  readonly reason?: string;
  readonly isApple?: boolean;
  readonly username?: string;
  readonly onDone?: () => void;
  readonly product?: AuthProductProps;
  readonly vehicle?: LookupVehicleProps;
  readonly onError?: (message?: string) => void;
  readonly onProcess?: (progress: number) => void;
}

export function useRegisterSocket(options?: RegisterSocketProps) {
  const { data: socketData } = useSocket([SOCKET_TOPIC.PURCHASE_PRODUCT], {
    skip: options?.skip,
    username: options?.username,
  });

  const registerReportSocket = useMemo(() => {
    if (socketData) {
      return socketData[SOCKET_TOPIC.PURCHASE_PRODUCT];
    }
  }, [socketData]);

  useEffect(() => {
    if (registerReportSocket?.done) {
      if (!registerReportSocket?.error && options?.onDone) {
        options.onDone();
      }

      if (registerReportSocket?.error) {
        if (options?.onError) {
          options.onError(registerReportSocket.message);
        }
      }

      if (options?.product && options.product?.id !== ProductPlans.TrialSignUp) {
        if (registerReportSocket?.done && !registerReportSocket?.error) {
          analytics.facebookEvent({
            event: "Purchase",
            value: Number(
              `${options.product.price.priceInteger}.${options.product.price.priceFractional}`,
            ),
          });

          analytics.taboolaEvent(
            options.product.id === ProductPlans.PaygSignUp
              ? "make_purchase_payg"
              : "make_purchase_30_day",
          );

          analytics.googleEcommerce({
            id: options.product.id,
            variant: options?.isApple ? "Initial (apple/google pay)" : "Initial",
            title: options.product.id === ProductPlans.PaygSignUp ? "PAYG Plan" : "30-day Plan",
            price: `${options.product.price.priceInteger}.${options.product.price.priceFractional}`,
          });

          analytics.microsoftAds({
            id: options.product.id,
            variant: options?.isApple ? "Initial (apple/google pay)" : "Initial",
            title: options.product.id === ProductPlans.PaygSignUp ? "PAYG Plan" : "30-day Plan",
            price: `${options.product.price.priceInteger}.${options.product.price.priceFractional}`,
          });
        }
      }
    }
  }, [
    options?.isApple,
    options?.product,
    registerReportSocket?.done,
    registerReportSocket?.error,
    registerReportSocket?.message,
  ]);

  useEffect(() => {
    if (options?.vehicle && !options?.skip) {
      createAddVehicleEvents(options.vehicle, registerReportSocket);
    }
  }, [
    options?.skip,
    options?.vehicle,
    registerReportSocket?.done,
    registerReportSocket?.error,
    registerReportSocket?.message,
  ]);

  useEffect(() => {
    if (options?.onProcess) {
      options.onProcess(registerReportSocket?.progress || 0);
    }
  }, [registerReportSocket?.progress]);

  return {
    done: registerReportSocket?.done,
    progress: registerReportSocket?.progress,
  };
}
