export enum SubscriptionStatus {
  NoSubscription,
  MonthlyAccess,
  SubscriptionExpired,
  TrialAccess,
}

export enum AuthState {
  Checkout = "checkout",
  Authorized = "authorized",
  Processing = "processing",
  Unauthorized = "unauthorized",
  SelectProduct = "select-product",
}

export enum ProductPlans {
  Upgrade = "payg_upgrade",
  SubsSignUp = "subs_signup",
  PaygSignUp = "payg_signup",
  TrialSignUp = "trial_signup",
  SubsSingleReport = "subs_single_report",
  PaygSingleReport = "payg_single_report",
}

export enum RiskLevel {
  Low = "low",
  High = "high",
  Medium = "medium",
}

export enum MotTestStatus {
  Passed = "PASSED",
  Failed = "FAILED",
}

export enum MotTestCommentType {
  Prs = "PRS",
  User = "USER",
  Fail = "FAIL",
  Major = "MAJOR",
  Entered = "ENTERED",
  Advisory = "ADVISORY",
  Dangerous = "DANGEROUS",
}
