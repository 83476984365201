import "./assets/screen-header.scss";

import cx from "classnames";
import React, { ReactNode } from "react";

export interface ScreenHeaderProps {
  readonly title?: string;

  readonly buttonWidth?: number;

  readonly leftComponent?: ReactNode;
  readonly rightComponent?: ReactNode;

  readonly className?: string;
  readonly leftComponentsClassName?: string;
  readonly rightComponentsClassName?: string;
}

export function ScreenHeader({
  title,
  className = "mb-4",
  buttonWidth = 70,
  leftComponent,
  rightComponent,
  leftComponentsClassName,
  rightComponentsClassName,
}: ScreenHeaderProps) {
  return (
    <div className={cx("d-flex bg-secondary screen-header", className)}>
      <div className="container d-flex flex-shrink-1 flex-grow-1">
        <div
          style={{ width: buttonWidth }}
          className={cx("d-flex justify-content-start align-items-center", leftComponentsClassName)}
        >
          {leftComponent}
        </div>

        <div className="d-flex flex-1 align-items-center justify-content-center">
          <span className="fl-4 text-white font-weight-bold text-center screen-header-title">
            {title}
          </span>
        </div>

        <div
          style={{ width: buttonWidth }}
          className={cx("d-flex justify-content-end align-items-center", rightComponentsClassName)}
        >
          {rightComponent}
        </div>
      </div>
    </div>
  );
}
