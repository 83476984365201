import "./assets/register-provenance-details.scss";

import React from "react";
import Button from "../CustomButtons/Button";

interface Props {
  readonly onViewReportClick: () => void;
}

export function RegisterProvenanceDetails({ onViewReportClick }: Props) {
  return (
    <div className="position-relative register-provenance-details">
      <div className="position-absolute-fill d-flex flex-column user-select-none">
        <span className="py-1 font-weight-bold lh-4 blured-item">lorem ipsum</span>
        <span className="py-1 font-weight-bold lh-4 blured-item">lorem</span>
        <span className="py-1 font-weight-bold lh-4 blured-item">lorem</span>
        <span className="py-1 font-weight-bold lh-4 blured-item">lorem ipsum</span>
        <span className="py-1 font-weight-bold lh-4 blured-item">lorem</span>
        <span className="py-1 font-weight-bold lh-4 blured-item">lorem ipsum</span>
        <span className="py-1 font-weight-bold lh-4 blured-item">lorem ipsum</span>
        <span className="py-1 font-weight-bold lh-4 blured-item">lorem</span>
        <span className="py-1 font-weight-bold lh-4 blured-item">lorem ipsum</span>
        <span className="py-1 font-weight-bold lh-4 blured-item">lorem</span>
      </div>
      <div className="d-flex flex-column">
        <span className="fs-4 mx-2 text-center text-black font-weight-medium mb-4 mt-2">
          Want the full details?
        </span>
        <Button
          // @ts-ignore
          color="success"
          onClick={onViewReportClick}
          id="buyjourney-viewreport-trackbutton"
          className="align-self-center mb-4 mx-2"
        >
          View full report
        </Button>
        <span className="text-center text-black font-weight-medium mx-2">
          We offer more data than anyone else
        </span>
      </div>
    </div>
  );
}
