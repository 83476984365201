import "./table-buy-report.scss";

import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import ReportIcon from "../../assets/img/comparison/report.svg";

TableBuyReport.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export function TableBuyReport({ id, onClick, style, className }) {
  return (
    <div id={id} style={style} className={cx("table-buy-report", className)}>
      <div className="report-content">
        <img className="report-icon" src={ReportIcon} width={32} alt="report" />
        <div className="text-center report-description">Want the full details?</div>
        <div className="text-center report-action" onClick={onClick}>
          Buy Report
        </div>
      </div>
    </div>
  );
}
