import React, { useState } from "react";
import { injectStripe, ReactStripeElements } from "react-stripe-elements";

import { ProductProps } from "../../dto/ProductDTO";
import { IS_DEV } from "../../constants/AppConstants";
import { createEvent } from "../../utils/GoogleAnalytiscHelpers";
import { useStripeContext } from "../../api/stripe/StripeContext";
import { userHasEmailSelector } from "../../reducers/userReducer";
import { useShallowEqualSelector } from "../../hooks/useShallowSelector";
import { StripeCheckoutForm, StripeCheckoutFormProps } from "./StripeCheckoutForm";
import { BUY_PRODUCT_CATEGORY, BuyProductActions } from "../../constants/AnalyticsConstants";

interface Props
  extends Omit<StripeCheckoutFormProps, "onSubmit" | "loading" | "userHasEmail" | "amount"> {
  readonly vrm?: string;
  readonly onError: () => void;
  readonly product?: ProductProps;
  readonly generationLoader: boolean;
  readonly onComplete: (isApple?: boolean) => void;
  readonly elements?: stripe.elements.Elements | null;
  readonly stripe?: ReactStripeElements.StripeProps | null;
}

export const StripeCheckoutFormWrapper = injectStripe(
  ({ vrm, elements, stripe, onError, onComplete, generationLoader, product, ...props }: Props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | undefined>();

    const userHasEmail = useShallowEqualSelector(userHasEmailSelector);

    const { StripeApi } = useStripeContext();

    return (
      <StripeCheckoutForm
        {...props}
        stripe={stripe}
        stripeError={error}
        amount={product?.amount!}
        userHasEmail={userHasEmail}
        loading={loading || generationLoader}
        onPayment={(ev) => {
          StripeApi.createPaymentIntent({
            vrm: vrm!,
            productId: product?.id!,
          }).then(({ clientSecret }) => {
            if (stripe) {
              stripe
                .confirmCardPayment(
                  clientSecret,
                  { payment_method: ev.paymentMethod.id },
                  { handleActions: false },
                )
                .then(({ error }) => {
                  if (error) {
                    ev.complete("fail");

                    alert("Confirm: " + JSON.stringify(error));
                  } else {
                    ev.complete("success");

                    onComplete(true);

                    stripe.confirmCardPayment(clientSecret);
                  }
                });
            }
          });
        }}
        onSubmit={(email) => {
          setLoading(true);
          setError(undefined);

          StripeApi.createPaymentIntent({
            vrm: vrm!,
            productId: product?.id!,
            email: userHasEmail ? undefined : email,
          })
            .then(({ clientSecret }) => {
              setLoading(false);

              onComplete();

              if (clientSecret && stripe) {
                const card = elements?.getElement("card");

                if (card) {
                  stripe
                    .confirmCardPayment(clientSecret, {
                      payment_method: { card },
                      receipt_email: userHasEmail ? undefined : email,
                    })
                    .then((response) => {
                      if (response?.error) {
                        onError();

                        if (!IS_DEV && product) {
                          createEvent({
                            label: product.type,
                            category: BUY_PRODUCT_CATEGORY,
                            action: BuyProductActions.BuyProductCardFail,
                          });
                        }

                        setError(response.error.message);
                      }
                    });
                }
              }
            })
            .catch(() => setLoading(false));
        }}
      />
    );
  },
);
