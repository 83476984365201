import React from "react";
import cx from "classnames";

import { LoaderProgress } from "./LoaderProgress";

interface Props {
  readonly loading: boolean;
  readonly className?: string;
}

export function Loader({ loading, className }: Props) {
  if (!loading) {
    return null;
  }

  return (
    <div className={cx("position-absolute-fill loader-container", className)}>
      <LoaderProgress className="justify-content-center position-sticky loader-process" />
    </div>
  );
}
