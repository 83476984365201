import "./assets/error-snackbar.scss";

import ErrorIcon from "@material-ui/icons/Error";
import React, { useEffect, useState } from "react";
import { SnackbarContent } from "@material-ui/core";

import { Snackbar } from "./Snackbar";

interface Props {
  readonly error: any;
  readonly onClose?: () => void;
}

export function ErrorSnackbar({ error, onClose }: Props) {
  const [state, setState] = useState({
    isOpen: false,
    message: null,
  });

  useEffect(() => {
    setState({
      isOpen: Boolean(error),
      message: error && (error.detail || error.message),
    });
  }, [error]);

  return (
    <Snackbar onClose={onClose} open={state.isOpen} className="error-snackbar">
      <SnackbarContent
        className="bg-danger"
        message={
          <span className="d-flex align-items-center">
            <ErrorIcon className="mr-3 snackbar-error-icon" />
            {state.message}
          </span>
        }
      />
    </Snackbar>
  );
}
