import { Auth, Hub } from "aws-amplify";
import React, { useCallback, useEffect } from "react";

import { AuthSocialButton, SocialType } from "./AuthSocialButton";

interface Props {
  readonly onError: (reason: any) => void;
  readonly onSuccess: (data: any) => void;
}

export function SocialRegisterButtonsWrapper({ onError, onSuccess }: Props) {
  const hubListener = useCallback(({ payload: { data, event } }) => {
    let authData;

    switch (event) {
      case "signUI":
      case "cognitoHostedUI":
        authData = data;

        break;

      case "cognitoHostedUI_failure":
      case "parsingUrl_failure":
        onError("Authentication failure");

        break;
    }

    if (authData) {
      onSuccess(authData);
    }
  }, []);

  useEffect(() => {
    Hub.listen("auth", hubListener);

    return () => Hub.remove("auth", hubListener);
  }, [hubListener]);

  return (
    <div className="d-flex flex-column">
      <AuthSocialButton
        className="mb-2"
        id="preauth-google-button"
        // @ts-ignore
        onClick={() => Auth.federatedSignIn({ provider: "Google" }).catch(onError)}
      />
      <AuthSocialButton
        className="mb-2"
        type={SocialType.Facebook}
        id="preauth-facebook-button"
        // @ts-ignore
        onClick={() => Auth.federatedSignIn({ provider: "Facebook" }).catch(onError)}
      />
    </div>
  );
}
