import React from "react";

import {RegisterProvenanceItem} from "./RegisterProvenanceItem";
import {RegisterProvenanceDetails} from "./RegisterProvenanceDetails";

interface Props {
  readonly onViewReportClick: () => void;
}

export function RegisterProvenance({ onViewReportClick }: Props) {
  return (
    <div className="d-flex mt-2 mt-lg-0 ml-lg-1 ml-xl-2 flex-column mb-lg-4 flex-1">
      <h5>Provenance</h5>
      <table className="table table-borderless">
        <tbody>
          <RegisterProvenanceItem title="Outstanding finance:">
            <td className="font-weight-light bg-white table-data" rowSpan={10}>
              <RegisterProvenanceDetails onViewReportClick={onViewReportClick} />
            </td>
          </RegisterProvenanceItem>
          <RegisterProvenanceItem title="Service & Repairs:" />
          <RegisterProvenanceItem title="Future issues:" />
          <RegisterProvenanceItem title="Insurance Write-off:" />
          <RegisterProvenanceItem title="Currently marked as Stolen:" />
          <RegisterProvenanceItem title="Scrapped:" />
          <RegisterProvenanceItem title="Number of owners:" />
          <RegisterProvenanceItem title="Exported/Imported:" />
          <RegisterProvenanceItem title="Outstanding recalls:" />
          <RegisterProvenanceItem title="Mileage anomaly:" />
          <RegisterProvenanceItem title="Colour Changes:" />
          <RegisterProvenanceItem title="Taxi Data:" />
        </tbody>
      </table>
    </div>
  );
}
