import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { formatFunds } from "../../../../utils/number";
import { serviceSchedulesPageStyles } from "../styles/serviceSchedulesPageStyles";
import { PageLayout } from "./PageLayout";

const styles = StyleSheet.create(serviceSchedulesPageStyles);

export const ServiceSchedulesPage = ({ report }) => {
  const services = report.services;

  return (
    <PageLayout id={report.id}>
      <Text style={styles.pageTitle}>SERVICE SCHEDULES</Text>

      {services.servicesDue.length === 0 && services.servicesPrev.length === 0 ? (
        <Text style={styles.orderText}>
          Our data providers don’t hold service schedule information for this vehicle. This could be due to a number of reasons, for example, the age of the vehicle.
        </Text>
      ) : (
        <View>
          <Text style={styles.serviceTitle}> Services due </Text>
          <Text style={styles.orderText}>
            Based on the manufacturer's service schedules, the below may be needed on this vehicle:
          </Text>

          {services &&
            services.servicesDue &&
            services.servicesDue.map((service, index) => (
              <View key={index} style={styles.serviceTable}>
                <View style={styles.serviceTableTitle}>
                  <Text>{service.title}</Text>
                </View>
                <View style={styles.serviceTableItem}>
                  <Text>Estimated Labour Time</Text>
                  <Text>{service.labourTime} hours</Text>
                </View>
                <View style={styles.serviceTableItem}>
                  <Text>Estimated Labour Cost</Text>
                  <Text>£{formatFunds(service.labourCost)}</Text>
                </View>
                <View style={styles.serviceTableItem}>
                  <Text>Estimated Parts Cost</Text>
                  <Text>£{formatFunds(service.partsCost)}</Text>
                </View>
                <View style={styles.serviceTableItem}>
                  <Text>Estimated Price</Text>
                  <Text>£{formatFunds(service.totalCost)}</Text>
                </View>
              </View>
            ))}

          <Text style={styles.serviceTitle}> Previous Services </Text>
          <Text style={styles.orderText}>
            Based on manufacturer's schedules, the service book should look like this:
          </Text>

          <View style={styles.previousBlock}>
            {services &&
              services.servicesPrev &&
              services.servicesPrev.map((service, index) => (
                <View wrap={false} key={index} style={styles.serviceTable}>
                  <View style={[styles.serviceTableTitle, { backgroundColor: "#22A6B3" }]}>
                    <Text>{service.title}</Text>
                  </View>
                  <View style={styles.serviceTableItem}>
                    <Text>Estimated Labour Time</Text>
                    <Text>{service.labourTime} hours</Text>
                  </View>
                  <View style={styles.serviceTableItem}>
                    <Text>Estimated Labour Cost</Text>
                    <Text>£{formatFunds(service.labourCost)}</Text>
                  </View>
                  <View style={styles.serviceTableItem}>
                    <Text>Estimated Parts Cost</Text>
                    <Text>£{formatFunds(service.partsCost)}</Text>
                  </View>
                  <View style={styles.serviceTableItem}>
                    <Text>Estimated Price</Text>
                    <Text>£{formatFunds(service.totalCost)}</Text>
                  </View>
                </View>
              ))}
          </View>
        </View>
      )}
    </PageLayout>
  );
};
