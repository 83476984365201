import "./styles.scss";

import React from "react";
import cx from "classnames";
import { SvgIcon } from "@material-ui/core";

export function CheckRoundIcon(props) {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
      className={cx("icons check-round-icon", props.className)}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="#22A6B3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      >
        <path d="M6 13.223L8.45 16.7a1.049 1.049 0 0 0 1.707.051L18 6.828" />
        <circle cx="12" cy="11.999" r="11.25" />
      </g>
    </SvgIcon>
  );
}
