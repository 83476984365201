import StompJS from "stompjs";
import { forEach } from "lodash";
import SockJS from "sockjs-client";
import { update } from "immupdate";
import { useCallback, useEffect, useMemo, useState } from "react";

import { Dict } from "../dto/AppDTO";
import { APP_WEBSOCKET_URL } from "../constants/AppConstants";
import { useShallowEqualSelector } from "./useShallowSelector";
import { registerUsernameSelector } from "../reducers/authReducer";

export interface SocketDataProps {
  readonly key?: string;
  readonly done: boolean;
  readonly error: boolean;
  readonly message?: string;
  readonly progress: number;
}

export function useSocket(topics: string[], options?: { skip?: boolean; username?: string }) {
  const [isConnected, setConnected] = useState(false);
  const [data, setData] = useState<Dict<SocketDataProps>>({});

  const username = useShallowEqualSelector(registerUsernameSelector);

  const id = useMemo(() => options?.username || username, [options?.username, username]);

  const skipSocket = useMemo(() => Boolean(!id || options?.skip), [id, options?.skip]);

  const sock = useMemo(() => {
    if (!skipSocket) {
      return StompJS.over(new SockJS(APP_WEBSOCKET_URL!));
    }
  }, [skipSocket]);

  const createEventHandler = useCallback((topic) => `/user/${id}${topic}`, [id]);

  useEffect(() => {
    if (skipSocket) {
      return;
    }

    let connect = false;

    if (!isConnected && sock) {
      sock.heartbeat.outgoing = 1000;
      sock.heartbeat.incoming = 1000;

      sock.connect({}, () => {
        connect = true;
        setConnected(true);
      });
    }

    return () => {
      if (connect && sock) {
        sock.disconnect(() => {
          connect = false;
          setConnected(false);
        });
      }
    };
  }, [sock, skipSocket]);

  useEffect(() => {
    if (!topics || topics.length === 0 || skipSocket) {
      return;
    }

    const subscriptions: Dict<StompJS.Subscription> = {};

    if (isConnected && sock) {
      topics.forEach((topic) => {
        // @ts-ignore
        if (subscriptions[topic]) {
          return;
        }

        const event = createEventHandler(topic);

        const subscription = sock.subscribe(event, ({ body }) => {
          if (body) {
            setData((x) =>
              update(x, {
                [topic]: JSON.parse(body),
              }),
            );
          }
        });

        if (subscription) {
          // @ts-ignore
          subscriptions[topic] = subscription;
        }
      });
    }

    return () => {
      forEach(subscriptions, (subscription, key) => {
        if (subscription) {
          subscription.unsubscribe();

          // @ts-ignore
          subscriptions[key] = undefined;
        }
      });
    };
  }, [isConnected, sock, skipSocket]);

  return { data, isConnected };
}
