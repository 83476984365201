import React from "react";
import { Form, Formik } from "formik";

import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";
import { FormikTextField } from "../form/FormikTextField";
import { EmailForgotPasswordFormProps } from "../../dto/AuthDTO";

interface Props {
  readonly onCancelClick: () => void;
  readonly onSubmit: (values: EmailForgotPasswordFormProps) => void;
}

export function EmailForgotPasswordForm({ onSubmit, onCancelClick }: Props) {
  return (
    <Formik onSubmit={onSubmit} initialValues={{ email: "" }}>
      {({ handleSubmit, isSubmitting }) => (
        <Form className="d-flex flex-column" onSubmit={handleSubmit}>
          <h3 className="text-center">Forgot Password</h3>
          <FormikTextField
            name="email"
            label="Email"
            className="mb-4"
            inputProps={{
              autoCorrect: "off",
              autoCapitalize: "off",
            }}
          />
          <ActionButton
            type="submit"
            className="mb-3"
            loading={isSubmitting}
            color={ColorPalette.Success}
          >
            Forgot Password
          </ActionButton>
          <ActionButton onClick={onCancelClick} color={ColorPalette.Primary}>
            Cancel
          </ActionButton>
        </Form>
      )}
    </Formik>
  );
}
