import styled from "styled-components";

export const Container = styled.div`
  margin: 15px 0 0;

  .mots-title {
    font-weight: 500;
    font-size: 20px;
    margin: 0 0 5px;
  }
  .mots-description {
    margin: 0 0 15px;
  }
  .future-mots {
    .mots-due {
      display: flex;
      padding: 18px;
      align-items: center;
      border: 1px solid #22a6b3;
      margin: 0 0 15px;

      .mot-due-icon {
        display: flex;
        margin: 0 10px 0 0;

        svg {
          color: #22a6b3;
        }
      }
      .mot-due-info {
        font-weight: 500;
        color: #30336b;

        .moment-to-now-date {
          margin: 0 5px 0 0;
        }
      }

      &.is-dangerous {
        border: 1px solid #eb4d4b;

        .mot-due-icon {
          svg {
            color: #eb4d4b;
          }
        }
      }
    }
  }
  .previous-mots {
    .mots-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0px;

      &.has-data {
        border-top: 1px solid #dcdcdc73;
        border-left: 1px solid #dcdcdc73;
      }
      .mot-block {
        display: flex;
        border-bottom: 1px solid #dcdcdc73;
        border-right: 1px solid #dcdcdc73;
        padding: 15px;

        &.is-passed {
          .mot-indicator {
            svg {
              color: #22a6b3;
            }
          }
        }
        &.is-failed {
          .mot-indicator {
            svg {
              color: #eb4d4b;
            }
          }
        }

        .mot-indicator {
          margin: 0 10px 0 0;
        }
        .mot-description {
          .mot-description-title {
            font-weight: 500;
            margin: 1px 0 5px 0;
          }
          .mot-mileage {
            font-weight: 500;
            margin: 1px 0 5px 0;
          }
          .mot-comments {
            .comments-list {
              padding: 0 0 0 18px;

              .mot-comment {
                margin: 0 0 5px;

                .mot-comment-text {
                  margin: 0 7px 0 0;
                }
                .mot-chip {
                  background-color: #e0e0e0;
                }

                &.is-dangerous {
                  color: #eb4d4b;

                  .mot-chip {
                    background-color: #eb4d4b;
                    color: #fff;
                  }
                }
                &.is-major {
                  .mot-chip {
                    background-color: #ff9800;
                    color: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    .previous-mots {
      .mots-grid {
        .mot-block {
          padding: 0;

          .mot-indicator {
            display: none;
          }
          .mot-description {
            margin: 5px 10px;
          }

          &.is-passed {
            .mot-description-title {
              color: #22a6b3;
            }
          }
          &.is-failed {
            .mot-description-title {
              color: #eb4d4b;
            }
          }
        }
      }
    }
  }
`;
