import { useEffect, useMemo } from "react";

import { Resource } from "./useResource";
import { AppError } from "../utils/AppError";

interface OptionsProps<TData> {
  readonly onSuccess?: (data: TData) => void;
  readonly onFail?: (error: AppError) => void;
}

export function useResourceHandler<TData = any>(
  resource: Resource<TData>,
  options: OptionsProps<TData> = {},
) {
  const error = useMemo(() => {
    if (!resource.loading && resource.error) {
      return resource.error;
    }
  }, [resource.loading, resource.error]);

  const data = useMemo(() => {
    if (!resource.loading && !resource.error && resource.data) {
      return resource.data;
    }
  }, [resource]);

  useEffect(() => {
    if (error && options?.onFail) {
      options.onFail(error);
    }
  }, [error]);

  useEffect(() => {
    if (!resource.loading && data && options?.onSuccess) {
      options?.onSuccess(data);
    }
  }, [data, resource.loading]);
}
