import qs from "qs";
import Cookies from "js-cookie";
import { Auth } from "aws-amplify";
import { DELETE } from "immupdate";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useQuery } from "../hooks/useQuery";
import { Analytics } from "../utils/Analytics";
import Circular from "../commons/Circular/Circular";
import { validToken } from "../utils/ValidateUtils";
import { useUserContext } from "../api/user/UserContext";
import { setCognitoUser } from "../reducers/userReducer";
import { useAuthContext } from "../api/auth/AuthContext";
import { AuthState, ProductPlans } from "../dto/EnumDTO";
import { useScrollMemory } from "../hooks/useScrollMemory";
import { useLocationHelpers } from "../hooks/useLocationHelpers";
import { AppRouteLayout } from "../components/app/AppRouteLayout";
import { AuthSocialGroup } from "../components/auth/AuthSocialGroup";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import {
  authStateSelector,
  changeAuthState,
  clearRegisterState,
  registerStateSelector,
  setRegisterState,
  setToken,
  tokenSelector,
} from "../reducers/authReducer";
import { createAmplifyConfig } from "../utils/AuthUtils";
import { APP_REDIRECT_SIGN_IN } from "../constants/AppConstants";
import { EmailAuthType } from "../components/auth/EmailAuthWrapper";

const analytics = new Analytics();

interface Query {
  readonly vrm?: string;
  readonly product?: ProductPlans;

  readonly errorMessage?: string;

  readonly reason?: string;
  readonly marketing?: string;

  readonly emailAuthType?: EmailAuthType;
}

interface Props {
  readonly onSetCookieData?: () => void;
  readonly setSybmitedForm: (val: boolean) => void;
  readonly answersValue: object;
  readonly currentQuestionID?: number | null;
}

export function RegisterContainer({
  onSetCookieData,
  setSybmitedForm,
  answersValue,
  currentQuestionID,
}: Props) {
  useScrollMemory();

  const query = useQuery<Query>();

  const history = useHistory();
  const { replaceQuery } = useLocationHelpers();

  const dispatch = useDispatch();

  const token = useShallowEqualSelector(tokenSelector);
  const authState = useShallowEqualSelector(authStateSelector);
  const registerState = useShallowEqualSelector(registerStateSelector);

  const [loading, setLoading] = useState(true);
  const [reasonError, setReasonError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(query.errorMessage);
  const [reason, setReason] = useState(query.reason || "none");

  const { UserApi } = useUserContext();
  const { AuthApi } = useAuthContext();
  const { clearRegister } = useAuthContext();

  const isTrialProduct = useMemo(() => registerState?.product === ProductPlans.TrialSignUp, [
    registerState,
  ]);

  const scrollToReason = useCallback(() => {
    const element = document.querySelector("#VehicleReasonControl");

    if (element) {
      window.scrollTo({
        // @ts-ignore
        top: element.offsetTop,
      });
    }
  }, []);

  const clearStateHandler = useCallback(() => {
    Cookies.set("user_registered", "0", { domain: "carguide.co.uk" });

    clearRegister();
  }, [clearRegister]);

  const registerStateHandler = useCallback(
    (marketing?: boolean) => {
      dispatch(
        setRegisterState({
          state: {
            reason,
            product: query.product,
            marketing: Boolean(marketing),
          },
        }),
      );
    },
    [reason, query.product, dispatch],
  );

  const failureHandler = useCallback(
    (message: string) => {
      clearStateHandler();

      setLoading(false);
      setErrorMessage(message);
    },
    [clearStateHandler, setErrorMessage],
  );

  const successHandler = useCallback(
    (user: any) => {
      dispatch(setToken({ token: user?.signInUserSession }));

      if (user?.username) {
        analytics.setGoogleVariable("userId", user.username);
      }

      Cookies.set("user_registered", "1", { domain: "carguide.co.uk" });

      dispatch(setCognitoUser({ user }));
      dispatch(changeAuthState({ authState: AuthState.Authorized }));

      analytics.setGoogleVariable("userId", user.username);
      if (Cookies.get("qst-answers") && !token) {
        history.replace("/auth/checkout?product=trial_signup");
      } else {
        history.replace("/shortlist");
      }

      setLoading(false);
    },
    [dispatch, history],
  );

  const userFailureHandler = useCallback(
    (error: any, user: any, fromEmail?: boolean) => {
      if (error.status === 400) {
        if (isTrialProduct && !Cookies.get("qst-answers")) {
          AuthApi.authCustomerTrial(
            {
              hasAcceptedTC: true,
              name: user.attributes.name,
              email: user.attributes.email,
              productId: ProductPlans.TrialSignUp,
              signupReason: registerState?.reason,
              vrm: registerState?.vehicle?.registration,
              hasMarketing: Boolean(registerState?.marketing),
            },
            {
              headers: { Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}` },
            },
          )
            .then(() => {
              successHandler(user);

              analytics.facebookEvent({ event: "CompleteRegistration", value: 0 });
            })
            .catch((e) => failureHandler(e.message));
        } else {
          dispatch(setToken({ token: user.signInUserSession }));

          if (!registerState?.product && !Cookies.get("qst-answers")) {
            history.replace(
              `/auth/product?${qs.stringify({
                fromEmail: fromEmail ? "true" : DELETE,
                reason:
                  registerState?.reason && registerState?.reason !== "none"
                    ? registerState.reason
                    : DELETE,
              })}`,
            );
          } else if (Cookies.get("qst-answers") && !token) {
            history.replace("/auth/checkout?product=trial_signup");
          } else {
            history.replace(
              `/auth/checkout?${qs.stringify({
                fromEmail: fromEmail ? "true" : DELETE,
                product: registerState?.product || DELETE,
                reason:
                  registerState?.reason && registerState?.reason !== "none"
                    ? registerState.reason
                    : DELETE,
              })}`,
            );
          }

          setLoading(false);

          dispatch(clearRegisterState());
        }
      } else {
        failureHandler(error.message);
      }
    },
    [history, AuthApi, dispatch, registerState, isTrialProduct, failureHandler, successHandler],
  );

  const userAccountHandler = useCallback(
    (user, fromEmail?: boolean) =>
      UserApi.getUserAccount({
        headers: { Authorization: `Bearer ${user.signInUserSession.accessToken.jwtToken}` },
      })
        .then(() => {
          successHandler(user);
        })
        .catch((e) => {
          userFailureHandler(e, user, fromEmail);
        }),
    [UserApi, successHandler, failureHandler],
  );

  const checkUser = useCallback(
    (fromEmail?: boolean) => {
      Auth.currentAuthenticatedUser()
        .then((x) => userAccountHandler(x, fromEmail))
        .catch((e) => failureHandler(e.message));
    },
    [userAccountHandler, failureHandler],
  );

  useEffect(() => {
    if (validToken(token) && authState === AuthState.Authorized) {
      checkUser();
    } else {
      setLoading(false);
    }
  }, [token, checkUser, authState]);

  useEffect(() => {
    createAmplifyConfig(APP_REDIRECT_SIGN_IN!);
  }, []);

  return (
    <AppRouteLayout withFooter={false} title="Registration">
      <div
        id="preauth-sign-in-panel"
        className="d-flex flex-1 position-relative align-items-center justify-content-center"
      >
        {!loading && (
          <>
            <AuthSocialGroup
              reason={reason}
              reasonError={reasonError}
              productId={query.product}
              hasVrm={Boolean(query.vrm)}
              errorMessage={errorMessage}
              emailAuthType={query.emailAuthType}
              marketing={query.marketing === "true"}
              onEmailAuth={async () => {
                if (answersValue) {
                  if (Object.values(answersValue).indexOf("") !== -1) {
                    setSybmitedForm(true);
                    return;
                  }
                }

                if (
                  !currentQuestionID &&
                  history.location.pathname !== "/auth/register/" &&
                  history.location.pathname !== "/auth/register"
                ) {
                  alert("Please fill out the form above");
                  return;
                }
                //Checking for a page /auth/register-c
                if (onSetCookieData && currentQuestionID) {
                  await onSetCookieData();
                  setErrorMessage(undefined);
                  replaceQuery({ errorMessage: DELETE });

                  checkUser(true);
                  return;
                }

                if (query.vrm && (!reason || reason === "none")) {
                  setReasonError(true);
                  scrollToReason();
                } else {
                  setErrorMessage(undefined);
                  replaceQuery({ errorMessage: DELETE });

                  checkUser(true);
                }
              }}
              onReasonChange={(x) => {
                setReasonError(x === "none");

                setErrorMessage(undefined);
                replaceQuery({ errorMessage: DELETE });

                setReason(x);
              }}
              onConfirmAuth={(_, __, userSub, email) => {
                if (query.vrm && (!reason || reason === "none")) {
                  setReasonError(true);
                  scrollToReason();
                } else {
                  setErrorMessage(undefined);
                  replaceQuery({ errorMessage: DELETE });

                  analytics.setGoogleVariable("userId", userSub);
                  analytics.setGoogleVariable("userEmail", email);

                  registerStateHandler();
                }
              }}
              onSocialPress={async (provider, marketing) => {
                if (answersValue) {
                  if (Object.values(answersValue).indexOf("") !== -1) {
                    setSybmitedForm(true);
                    return;
                  }
                }

                if (
                  !currentQuestionID &&
                  history.location.pathname !== "/auth/register/" &&
                  history.location.pathname !== "/auth/register"
                ) {
                  alert("Please fill out the form above");
                  return;
                }

                // Checking for a page /auth/register-c
                if (onSetCookieData && currentQuestionID) {
                  await onSetCookieData();
                  setErrorMessage(undefined);
                  replaceQuery({ errorMessage: DELETE });
                  registerStateHandler(marketing);
                  Auth.federatedSignIn({ provider }).catch(failureHandler);
                  return;
                }

                if (query.vrm && (!reason || reason === "none") && !query.product) {
                  setReasonError(true);
                  scrollToReason();
                } else {
                  setErrorMessage(undefined);
                  replaceQuery({ errorMessage: DELETE });
                  registerStateHandler(marketing);
                  Auth.federatedSignIn({ provider }).catch(failureHandler);
                }
              }}
            />
          </>
        )}

        {loading && <Circular />}
      </div>
    </AppRouteLayout>
  );
}
