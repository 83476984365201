import "./assets/social-group-container.scss";

import cx from "classnames";
import React, { ReactNode } from "react";
import { LinearProgress } from "@material-ui/core";

interface Props {
  readonly progress?: number;
  readonly className?: string;
  readonly children: ReactNode;
  readonly isFreeTrial?: boolean;
  readonly errorMessage?: string;
  readonly progressClassName?: string;
}

export function SocialGroupContainer({
  progress,
  children,
  className,
  isFreeTrial,
  errorMessage,
  progressClassName,
}: Props) {
  return (
    <div
      className={cx(
        "d-flex flex-column my-3 my-md-5 position-relative social-group-container",
        {
          "trial-container": isFreeTrial,
        },
        className,
      )}
    >
      {Boolean(progress && progress >= 0) && (
        <LinearProgress
          value={progress}
          color="secondary"
          variant="determinate"
          className={cx("position-absolute linear-progress", progressClassName)}
        />
      )}

      {children}

      {Boolean(errorMessage) && (
        <span className="text-center text-danger mt-5">{errorMessage}</span>
      )}
    </div>
  );
}
