import "./assets/vehicle-list-cell.scss";

import React from "react";
import cx from "classnames";

interface Props {
  readonly id: string;
  readonly odd: boolean;
  readonly title: string;
  readonly firstColumn: boolean;
}

export function VehicleListCell({ id, odd, title, firstColumn }: Props) {
  return (
    <div
      id={`cell-${id}`}
      className={cx("d-flex align-items-center px-2 vehicle-list-cell", {
        "bg-muted": odd,
        "justify-content-center": !firstColumn,
      })}
    >
      <span className="cell-text">{title}</span>
    </div>
  );
}
