import React from "react";

import { useReportContext } from "../../api/report/ReportContext";
import { UseCreditDialog, UseCreditDialogProps } from "./UseCreditDialog";

export function UseCreditDialogWrapper({
  vrm,
  ...props
}: Omit<UseCreditDialogProps, "loading" | "onUseCreditClick">) {
  const { ReportApi } = useReportContext();

  return <UseCreditDialog {...props} onUseCreditClick={() => ReportApi.generateReport(vrm!)} />;
}
