import React from "react";

import { ActionButton } from "../ui/ActionButton";
import { ColorPalette } from "../../theme/ColorPalette";

interface Props {
  readonly onUpgradeClick: () => void;
}

export function SubscriptionDetailsWithoutCredits({ onUpgradeClick }: Props) {
  return (
    <div className="flex-1">
      <div className="mb-3 text-center text-md-left">
        Unfortunately you've used all available buyers report and car check credits on your current
        plan.
      </div>
      <div className="mb-3 text-center text-md-left">
        To receive unlimited checks and discounted reports you can buy 30 days access below.
      </div>
      <ActionButton
        className="d-none d-md-block"
        color={ColorPalette.Danger}
        onClick={onUpgradeClick}
      >
        Upgrade Account
      </ActionButton>
    </div>
  );
}
