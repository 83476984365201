import "./assets/short-list-container.scss";

import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";

import { AppError } from "../utils/AppError";
import ModalDialog from "../commons/ModalDialog";
import { useResource } from "../hooks/useResource";
import { ColorPalette } from "../theme/ColorPalette";
import { APP_WP_URL } from "../constants/AppConstants";
import { useUserContext } from "../api/user/UserContext";
import ErrorModal from "../views/ShortlistPage/ErrorModal";
import { useResponseHandler } from "../hooks/useResponseHandler";
import { AppRouteLayout } from "../components/app/AppRouteLayout";
import { useVehicleContext } from "../api/vehicle/VehicleContext";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { ShortListCard } from "../components/short-list/ShortListCard";
import { MotorwayWidget } from "../components/motorway/MotorwayWidget";
import { IntercomActions, intercomEvent } from "../utils/IntercomHelpers";
import { ShortListHeader } from "../components/short-list/ShortListHeader";
import { EditVehicleDialog } from "../components/vehicle/EditVehicleDialog";
import { AddVrmVehicleForm } from "../components/vehicle/AddVrmVehicleForm";
import { UseCreditDialogWrapper } from "../components/ui/UseCreditDialogWrapper";
import { VehicleSalvageDialog } from "../components/vehicle/VehicleSalvageDialog";
import { CompareStickyHeader } from "../components/short-list/CompareStickyHeader";
import { AddVrmVehicleMobileForm } from "../components/vehicle/AddVrmVehicleMobileForm";
import { AddVehicleDialogWrapper } from "../components/vehicle/AddVehicleDialogWrapper";
import { DeleteVehicleDialogWrapper } from "../components/vehicle/DeleteVehicleDialogWrapper";
import { FindVehicleProps, VehicleProps, VehicleSalvageProps } from "../api/vehicle/VehicleDTO";
import { EstimatedMileageInfoDialog } from "../components/short-list/EstimatedMileageInfoDialog";
import {
  changeSelectedVehicle,
  setVehicleList,
  vehicleListSelector,
} from "../reducers/vehicleReducer";
import {
  hasCreditsSelector,
  hasSubscriptionSelector,
  setUserAccount,
  setUserData,
} from "../reducers/userReducer";
import {
  UpgradePlanDialogWrapper,
  UpgradePlanType,
} from "../components/ui/UpgradePlanDialogWrapper";
import { Dialog } from "../components/ui/Dialog";
import { useOptimizeExperiment } from "../hooks/useOptimizeExperement";
import { useGeneratingReportSocket } from "../hooks/useGeneratingReportSocket";
import { SelectPlanDialogWrapper } from "../components/ui/SelectPlanDialogWrapper";
import { VehicleSalvageInfoDialog } from "../components/vehicle/VehicleSalvageInfoDialog";
import { ProductPlans } from "../dto/EnumDTO";
import { Auth } from "aws-amplify";

export function ShortListContainer() {
  const [showProgress, setShowProgress] = useState(false);
  const [buyReport, setBuyReport] = useState<any>(null);
  const [salvageInfo, setSalvageInfo] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [error, setError] = useState<AppError | undefined>();
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [salvage, setSalvage] = useState<VehicleSalvageProps | undefined>();
  const [editVehicle, setEditVehicle] = useState<VehicleProps | undefined>();
  const [showAddVehicleForm, setShowAddVehicleForm] = useState(true);
  const [findVehicle, setFindVehicle] = useState<FindVehicleProps | undefined>();
  const [reportVehicle, setReportVehicle] = useState<VehicleProps | undefined>();
  const [deleteVehicle, setDeleteVehicle] = useState<VehicleProps | undefined>();
  const [showAddVehicleModal, setShowAddVehicleModal] = useState(false);
  const [showRenewPlanDialog, setShowRenewPlanDialog] = useState(false);
  const [showBuyReportDialog, setShowBuyReportDialog] = useState(false);
  const [showUseCreditDialog, setShowUseCreditDialog] = useState(false);
  const [showSelectPlanDialog, setShowSelectPlanDialog] = useState(false);
  const [findVehicleVrmLoading, setFindVehicleVrmLoading] = useState(false);
  const [showUpgradePlanDialog, setShowUpgradePlanDialog] = useState(false);
  const [findVrmAfterUpgrade, setFindVrmAfterUpgrade] = useState<string | undefined>();
  const [showEstimatedMileageDialog, setShowEstimatedMileageDialog] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const hasCredits = useShallowEqualSelector(hasCreditsSelector);
  const vehicleList = useShallowEqualSelector(vehicleListSelector);
  const hasSubscription = useShallowEqualSelector(hasSubscriptionSelector);

  const { UserApi } = useUserContext();
  const { VehicleApi } = useVehicleContext();

  const vehicleListResponse = useResource(() => VehicleApi.getShortList(), []);
  const userDataResponse = useResource(() => UserApi.getUserData(), []);
  const userAccountResponse = useResource(() => UserApi.getUserAccount(), []);

  useResponseHandler(userDataResponse, (data, error) => {
    if (data && !error) {
      dispatch(setUserData({ data }));
    }
  });
  useResponseHandler(userAccountResponse, (data, error) => {
    if (data && !error) {
      dispatch(setUserAccount({ account: data }));
    }
  });

  useResponseHandler(vehicleListResponse, (data, err) => {
    if (data && !err) {
      dispatch(setVehicleList({ list: data }));

      setShowAddVehicleForm(data.length !== 1);
    }

    if (err) {
      setError(err);
    }
  });

  const showCompareHeader = useMemo(() => vehicleListResponse.data?.length! > 0, [
    vehicleListResponse.data,
  ]);
  const reportVehicleTitle = useMemo(
    () => [reportVehicle?.make, reportVehicle?.model].filter(Boolean).join(" "),
    [reportVehicle],
  );
  const showUpgradeDialog = useMemo(
    () => showBuyReportDialog || showUpgradePlanDialog || showRenewPlanDialog,
    [showBuyReportDialog, showUpgradePlanDialog, showRenewPlanDialog],
  );
  const upgradeType = useMemo(
    () =>
      showUpgradePlanDialog
        ? UpgradePlanType.Upgrade
        : showRenewPlanDialog
        ? UpgradePlanType.Renew
        : UpgradePlanType.Single,
    [showRenewPlanDialog, showUpgradePlanDialog],
  );

  const [isTrade, setIsTrade] = useState(false);
  useMemo(() => {
    Auth.currentAuthenticatedUser().then((d) => {
      if ("attributes" in d && "custom:tags" in d["attributes"]) {
        const tags_attr = d["attributes"]["custom:tags"];
        try {
          if (tags_attr) setIsTrade(JSON.parse(tags_attr).some((t) => "TRADE" === t));
        } catch (e) {
          setIsTrade(false);
        }
      }
    });
  }, [isTrade]);

  const findVehicleHandle = useCallback(
    async (vrm, after?: boolean) => {
      try {
        setFindVehicleVrmLoading(true);

        const response = await VehicleApi.findVehicle({ vrm });

        if (vrm.indexOf("motors.co.uk") >= 0 || vrm.indexOf("autotrager.co.uk") >= 0) {
          intercomEvent(IntercomActions.VehicleScrape, {
            url: vrm,
            status: "success",
            event_date: Math.round(Date.now() / 1000),
          });
        } else {
          intercomEvent(IntercomActions.VehicleLookup, {
            vrm: vrm,
            status: "success",
            event_date: Math.round(Date.now() / 1000),
          });
        }

        userDataResponse.refetch();
        userAccountResponse.refetch();

        setFindVehicle(response);
        setShowAddVehicleModal(true);
        setFindVehicleVrmLoading(false);
      } catch (e) {
        if (vrm.indexOf("motors.co.uk") >= 0 || vrm.indexOf("autotrager.co.uk") >= 0) {
          intercomEvent(IntercomActions.VehicleScrape, {
            status: "fail",
            url: vrm,
            message: e.message,
            event_date: Math.round(Date.now() / 1000),
          });
        } else {
          intercomEvent(IntercomActions.VehicleLookup, {
            status: "fail",
            vrm,
            message: e.message,
            event_date: Math.round(Date.now() / 1000),
          });
        }

        if (!hasSubscription && e.status === 402 && !after) {
          setFindVrmAfterUpgrade(vrm);
          setShowSelectPlanDialog(true);
        } else {
          setError(e);
        }

        setFindVehicleVrmLoading(false);
      }
    },
    [hasSubscription],
  );

  useGeneratingReportSocket({
    isApple: true,
    vrm: buyReport?.vrm,
    paymentType: "purchase",
    product: buyReport?.product,
    skip: !buyReport,
    onDone: (key) => {
      setBuyReport(null);
      setShowProgress(false);

      if (key) {
        history.push(`/car-report/${key}`);
      } else {
        vehicleListResponse.refetch();
      }
    },
    onError: () => {
      setBuyReport(null);
      setShowProgress(false);
    },
  });

  useOptimizeExperiment("fWQOMlygT6KAN5omXHcpTA");

  return (
    <AppRouteLayout
      title="Shortlist"
      withFooter={false}
      screenTitle="My Shortlist"
      contentClassName="position-relative short-list-container"
    >
      <AddVrmVehicleMobileForm
        show={showAddVehicleForm}
        loading={findVehicleVrmLoading}
        onSubmit={({ vrm }) => findVehicleHandle(vrm)}
      />

      <ShortListHeader onInfoPress={() => setShowInfo(true)} />

      <div className="d-flex flex-column">
        <AddVrmVehicleForm
          loading={findVehicleVrmLoading}
          className="d-none d-md-flex"
          onSubmit={({ vrm }) => findVehicleHandle(vrm)}
        />

        {showCompareHeader && (
          <CompareStickyHeader
            vehicleCount={vehicleList.length}
            onCompareClick={() => {
              if (vehicleList.length !== 1) {
                history.push("/comparison");
              } else {
                window.scrollTo({ top: 0, left: 0 });

                setShowAddVehicleForm(true);
              }
            }}
          />
        )}

        <div className="container d-flex flex-column pt-2 mb-6">
          {vehicleListResponse.loading && vehicleList.length === 0 && (
            <CircularProgress
              className="align-self-center mb-6"
              style={{ color: ColorPalette.Gray }}
            />
          )}

          {vehicleList.map((item, idx) => (
            <>
              <ShortListCard
                item={item}
                key={item.vrm}
                onComplete={(product) => {
                  setShowProgress(true);
                  setBuyReport({
                    vrm: item.vrm,
                    product: {
                      id: product,
                      priceFractional: 99,
                      priceWhole: product === ProductPlans.SubsSingleReport ? 4 : 5,
                    },
                  });
                }}
                onEditClick={() => setEditVehicle(item)}
                onDeleteClick={() => setDeleteVehicle(item)}
                onMorePress={() => setShowMoreInfo(true)}
                onSalvageRecordsClick={() => setSalvage(item.salvage)}
                onSalvageRecordsTitleClick={() => setSalvageInfo(true)}
                onMotClick={() => history.push(`/shortlist/${item.vrm}/mot`)}
                onSummaryClick={() => history.push(`/shortlist/${item.vrm}/summary`)}
                onEstimatedMileageClick={() => setShowEstimatedMileageDialog(true)}
                onViewReportClick={() => history.push(`/car-report/${item.reportId}`)}
                onBuyReportClick={() => {
                  setReportVehicle(item);

                  if (hasSubscription && !hasCredits) {
                    setShowRenewPlanDialog(true);
                  }

                  if (!hasSubscription && !hasCredits) {
                    setShowSelectPlanDialog(true);
                  }

                  if (hasCredits) {
                    setShowUseCreditDialog(true);
                  }
                }}
                isTrade={isTrade}
              />
              {!isTrade && idx === 0 && (
                <MotorwayWidget key={`motorway-widget-${item.vrm}`} defaultVrm={item.vrm} />
              )}
            </>
          ))}
        </div>
      </div>

      <ErrorModal error={error} onClose={() => setError(undefined)} />

      <SelectPlanDialogWrapper
        open={showSelectPlanDialog}
        onDismiss={() => {
          setReportVehicle(undefined);
          setShowSelectPlanDialog(false);
          setFindVrmAfterUpgrade(undefined);
        }}
        onUpgradeClick={() => {
          setShowUpgradePlanDialog(true);
          setShowSelectPlanDialog(false);
        }}
        onBuySingleClick={() => {
          setShowBuyReportDialog(true);
          setShowSelectPlanDialog(false);
        }}
      />

      <UpgradePlanDialogWrapper
        type={upgradeType}
        open={showUpgradeDialog}
        vrm={reportVehicle?.vrm!}
        isCredit={Boolean(findVrmAfterUpgrade)}
        onComplete={(key) => {
          setReportVehicle(undefined);

          setShowRenewPlanDialog(false);
          setShowBuyReportDialog(false);
          setShowUpgradePlanDialog(false);

          if (!findVrmAfterUpgrade) {
            if (key) {
              history.push(`/car-report/${key}`);
            } else {
              vehicleListResponse.refetch();
            }
          } else {
            userAccountResponse.refetch();

            setFindVrmAfterUpgrade(undefined);

            findVehicleHandle(findVrmAfterUpgrade, true);
          }
        }}
        onDismiss={() => {
          setReportVehicle(undefined);

          setShowRenewPlanDialog(false);
          setShowBuyReportDialog(false);
          setShowUpgradePlanDialog(false);

          setFindVrmAfterUpgrade(undefined);
        }}
      />

      <UseCreditDialogWrapper
        vrm={reportVehicle?.vrm!}
        open={showUseCreditDialog}
        title={reportVehicleTitle}
        onDismiss={() => {
          setReportVehicle(undefined);
          setShowUseCreditDialog(false);
        }}
        onComplete={(key) => {
          if (key) {
            history.push(`/car-report/${key}`);
          } else {
            vehicleListResponse.refetch();
          }

          setReportVehicle(undefined);
          setShowUseCreditDialog(false);
        }}
      />

      <EditVehicleDialog
        vehicle={editVehicle}
        open={Boolean(editVehicle)}
        onDismiss={() => setEditVehicle(undefined)}
        onSubmit={(values) =>
          VehicleApi.editVehicle({
            vrm: editVehicle?.vrm!,
            ...values,
          }).then(() => {
            vehicleListResponse.refetch();
            setEditVehicle(undefined);
          })
        }
      />

      <DeleteVehicleDialogWrapper
        vehicle={deleteVehicle!}
        open={Boolean(deleteVehicle)}
        onDismiss={() => setDeleteVehicle(undefined)}
        onSuccess={(vrm) => {
          vehicleListResponse.refetch();
          dispatch(changeSelectedVehicle({ vrm, state: false }));
        }}
      />

      <ModalDialog
        open={showInfo}
        handleClose={() => setShowInfo(false)}
        sourcePath={`${APP_WP_URL}/wp-json/wp/v2/posts/1597`}
      />

      <ModalDialog
        open={showMoreInfo}
        handleClose={() => setShowMoreInfo(false)}
        sourcePath={`${APP_WP_URL}/wp-json/wp/v2/posts/1357`}
      />

      <AddVehicleDialogWrapper
        vehicle={findVehicle}
        open={showAddVehicleModal}
        onDismiss={() => {
          vehicleListResponse.refetch();
          setShowAddVehicleModal(false);
        }}
      />

      <VehicleSalvageDialog
        salvage={salvage!}
        open={Boolean(salvage)}
        onClose={() => setSalvage(undefined)}
      />

      <VehicleSalvageInfoDialog open={salvageInfo} onClose={() => setSalvageInfo(false)} />

      <EstimatedMileageInfoDialog
        open={showEstimatedMileageDialog}
        onClose={() => setShowEstimatedMileageDialog(false)}
      />

      <Dialog contentClassName="p-3" open={showProgress}>
        <CircularProgress />
      </Dialog>
    </AppRouteLayout>
  );
}
