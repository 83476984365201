import { head, map } from "lodash";
import { useHistory } from "react-router";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useResource } from "../hooks/useResource";
import { ScrollBox } from "../components/ui/ScrollBox";
import { BackButton } from "../components/ui/BackButton";
import { VehicleProps } from "../api/vehicle/VehicleDTO";
import { useScrollMemory } from "../hooks/useScrollMemory";
import { useResponseHandler } from "../hooks/useResponseHandler";
import { AppRouteLayout } from "../components/app/AppRouteLayout";
import { useVehicleContext } from "../api/vehicle/VehicleContext";
import { SortingInfo } from "../components/comparison/SortingInfo";
import { VehicleList } from "../components/comparison/VehicleList";
import { formatComparisonVehicles } from "../utils/VehicleHalpers";
import { compareVehiclesSelector } from "../reducers/vehicleReducer";
import { useShallowEqualSelector } from "../hooks/useShallowSelector";
import { RotateMobileIcon } from "../components/icons/RotateMobileIcon";
import { AddVehicleButton } from "../components/comparison/AddVehicleButton";
import { HeaderVehicleInfo } from "../components/comparison/HeaderVehicleInfo";
import { UseCreditDialogWrapper } from "../components/ui/UseCreditDialogWrapper";
import { hasCreditsSelector, hasSubscriptionSelector } from "../reducers/userReducer";
import { DeviceType, OrientationType, useMediaQueryLegacy } from "../hooks/useMediaQueryLegacy";
import { COMPARISON_MAX_COUNT, COMPARISON_MOBILE_MAX_COUNT } from "../constants/VehicleConstants";
import {
  UpgradePlanDialogWrapper,
  UpgradePlanType,
} from "../components/ui/UpgradePlanDialogWrapper";
import { useUserContext } from "../api/user/UserContext";
import { SelectPlanDialogWrapper } from "../components/ui/SelectPlanDialogWrapper";
import { useGeneratingReportSocket } from "../hooks/useGeneratingReportSocket";
import { ProductPlans } from "../dto/EnumDTO";
import { CircularProgress } from "@material-ui/core";
import { Dialog } from "../components/ui/Dialog";

export function ComparisonContainer() {
  useScrollMemory();

  const { device, orientation } = useMediaQueryLegacy();

  const history = useHistory();

  const [buyReport, setBuyReport] = useState<any>(null);
  const [showProgress, setShowProgress] = useState(false);
  const [sortIndex, setSortIndex] = useState<string | undefined>();
  const [upgradeVrm, setUpgradeVrm] = useState<string | undefined>();
  const [upgradeType, setUpgradeType] = useState(UpgradePlanType.Single);
  const [showUpgradeDialog, setShowUpgradeDialog] = useState(false);
  const [showUseCreditDialog, setShowUseCreditDialog] = useState(false);
  const [showSelectPlanDialog, setShowSelectPlanDialog] = useState(false);

  const hasCredits = useShallowEqualSelector(hasCreditsSelector);
  const hasSubscription = useShallowEqualSelector(hasSubscriptionSelector);
  const selectedVehicles = useShallowEqualSelector(compareVehiclesSelector);

  const vrmList = useMemo(
    () =>
      map(selectedVehicles, (value, key) => {
        if (value) {
          return key;
        }

        return undefined;
      }).filter(Boolean) as string[],
    [selectedVehicles],
  );
  const hasVrmList = useMemo(() => Boolean(vrmList && vrmList.length > 0), [vrmList]);

  const { UserApi, setUserAccount } = useUserContext();
  const { VehicleApi, changeSelectedVehicle } = useVehicleContext();

  const userAccountResponse = useResource(() => UserApi.getUserAccount(), []);

  useResponseHandler(userAccountResponse, (data, error) => {
    if (data && !error) {
      setUserAccount({ account: data });
    }
  });

  const vehiclesResponse = useResource(() => {
    if (hasVrmList) {
      return VehicleApi.getVehiclesByVrms(vrmList);
    }
  }, [vrmList, hasVrmList]);

  useResponseHandler(vehiclesResponse);

  const compareColumn = useMemo(() => {
    if (vehiclesResponse.data) {
      return formatComparisonVehicles(vehiclesResponse.data, sortIndex);
    }

    return [];
  }, [vehiclesResponse.data, sortIndex]);

  const reportVehicleTitle = useMemo(() => {
    const vehicle = vehiclesResponse.data?.find(({ vrm }) => vrm === upgradeVrm);

    return [vehicle?.make, vehicle?.model].filter(Boolean).join(" ");
  }, [upgradeVrm, vehiclesResponse.data]);

  const buyReportHandler = useCallback(
    (vrm: string) => {
      setUpgradeVrm(vrm);

      if (hasSubscription && !hasCredits) {
        setShowUpgradeDialog(true);
        setUpgradeType(UpgradePlanType.Renew);
      }

      if (!hasSubscription && !hasCredits) {
        setShowSelectPlanDialog(true);
      }

      if (hasCredits) {
        setShowUseCreditDialog(true);
      }
    },
    [hasCredits, hasSubscription],
  );

  const vehicles = useMemo(
    () =>
      compareColumn
        .slice(1)
        .map((column) => head(column)?.vehicle)
        .filter(Boolean) as VehicleProps[],
    [compareColumn],
  );
  const isMobile = useMemo(() => device === DeviceType.Mobile, [device]);
  const isPortrait = useMemo(() => orientation === OrientationType.Portrait, [orientation]);

  useEffect(() => {
    const [cellIndex] = (sortIndex || "")?.split("-");

    const cellElement = document.querySelector<any>(`#cell-${cellIndex}`);

    if (cellElement?.offsetTop >= 0) {
      window.scrollTo({
        behavior: "smooth",
        top: isMobile ? cellElement?.offsetTop + 22 : cellElement?.offsetTop - 120,
      });
    }
  }, [sortIndex, isMobile]);

  const vehiclesComplete = useMemo(() => {
    if (isMobile && isPortrait) {
      return vehicles.length === COMPARISON_MOBILE_MAX_COUNT;
    }

    return vehicles.length === COMPARISON_MAX_COUNT;
  }, [isMobile, vehicles, isPortrait]);

  useGeneratingReportSocket({
    isApple: true,
    vrm: buyReport?.vrm,
    paymentType: "purchase",
    product: buyReport?.product,
    skip: !buyReport,
    onDone: () => {
      setBuyReport(null);
      setShowProgress(false);

      vehiclesResponse.refetch();
      userAccountResponse.refetch();
    },
    onError: () => {
      setBuyReport(null);
      setShowProgress(false);
    },
  });

  return (
    <AppRouteLayout
      buttonWidth={50}
      withFooter={false}
      headerClassName="mb-0"
      leftComponent={<BackButton />}
      loading={vehiclesResponse.loading}
      screenTitle="Compare my Shortlist"
      rightComponent={isMobile && <RotateMobileIcon />}
    >
      {!hasVrmList && (
        <div className="container">
          <h5 className="my-5 text-center">Please select vehicles</h5>
        </div>
      )}

      {hasVrmList && vehiclesResponse.data ? (
        <ScrollBox
          count={vehicles.length + 2}
          content={
            <>
              <VehicleList list={compareColumn} onBuyReportClick={buyReportHandler} />

              {!vehiclesComplete && <div className="track" />}
            </>
          }
          header={
            <>
              <SortingInfo onChangeFilter={setSortIndex} />

              {vehicles.map((vehicle) => (
                <HeaderVehicleInfo
                  key={vehicle.vrm}
                  vehicle={vehicle}
                  onComplete={(product) => {
                    setShowProgress(true);
                    setBuyReport({
                      vrm: vehicle.vrm,
                      product: {
                        id: product,
                        priceFractional: 99,
                        priceWhole: product === ProductPlans.SubsSingleReport ? 4 : 5,
                      },
                    });
                  }}
                  onBuyReportClick={() => buyReportHandler(vehicle.vrm)}
                  onViewReportClick={() => history.push(`/car-report/${vehicle.reportId}`)}
                  onRemoveClick={() => {
                    if (vehicles.length === 1) {
                      history.goBack();
                    }

                    changeSelectedVehicle({ vrm: vehicle.vrm, state: false });
                  }}
                />
              ))}

              {!vehiclesComplete && <AddVehicleButton onClick={() => history.goBack()} />}
            </>
          }
        />
      ) : null}

      <SelectPlanDialogWrapper
        open={showSelectPlanDialog}
        onDismiss={() => setShowSelectPlanDialog(false)}
        onUpgradeClick={() => {
          setShowUpgradeDialog(true);
          setShowSelectPlanDialog(false);
          setUpgradeType(UpgradePlanType.Upgrade);
        }}
        onBuySingleClick={() => {
          setShowUpgradeDialog(true);
          setShowSelectPlanDialog(false);
          setUpgradeType(UpgradePlanType.Single);
        }}
      />

      <UpgradePlanDialogWrapper
        vrm={upgradeVrm!}
        type={upgradeType}
        open={showUpgradeDialog}
        onComplete={() => {
          setUpgradeVrm(undefined);
          setShowUpgradeDialog(false);

          vehiclesResponse.refetch();
        }}
        onDismiss={() => {
          setUpgradeVrm(undefined);
          setShowUpgradeDialog(false);
        }}
      />

      <UseCreditDialogWrapper
        vrm={upgradeVrm}
        open={showUseCreditDialog}
        title={reportVehicleTitle}
        onDismiss={() => {
          setUpgradeVrm(undefined);
          setShowUseCreditDialog(false);
        }}
        onComplete={() => {
          setUpgradeVrm(undefined);
          setShowUseCreditDialog(false);

          vehiclesResponse.refetch();
          userAccountResponse.refetch();
        }}
      />

      <Dialog contentClassName="p-3" open={showProgress}>
        <CircularProgress />
      </Dialog>
    </AppRouteLayout>
  );
}
