import "./assets/basic-details-row.scss";

import cx from "classnames";
import React, { ReactNode } from "react";

interface Props {
  readonly className?: string;
  readonly children: ReactNode;
}

export function BasicDetailsRow({ children, className }: Props) {
  return <div className={cx("d-flex basic-details-row", className)}>{children}</div>;
}
