import "./assets/scroll-box.scss";

import cx from "classnames";
import React, { ReactNode, useEffect, useMemo } from "react";

interface Props {
  readonly count: number;
  readonly header: ReactNode;
  readonly content: ReactNode;
  readonly centered?: boolean;
}

export function ScrollBox({ count, header, content, centered = true }: Props) {
  useEffect(() => {
    // @ts-ignore
    window.resetSync();
  }, []);

  const isLarge = useMemo(() => window.innerWidth < count * 320, [count, window.innerWidth]);

  return (
    <div className="d-flex flex-column flex-1 scroll-box">
      <div className="headers">
        <div
          id="myElements"
          className={cx("d-flex scroller syncscroll", {
            "justify-content-center": !isLarge && centered,
          })}
        >
          {header}
        </div>
      </div>
      <div
        id="myElements"
        className={cx("d-flex flex-1 tracks syncscroll", {
          "justify-content-center": !isLarge && centered,
        })}
      >
        {content}
      </div>
    </div>
  );
}
