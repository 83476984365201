import React from "react";
import { Document, Font } from "@react-pdf/renderer";
import { font } from "./styles/fontDeclarated";

Font.register(font);

import { DetailsPage } from "./PDFPages/DetailsPage";
import { QuestionsToAskPage } from "./PDFPages/QuestionsToAskPage";
import { TheTestDrivePage } from "./PDFPages/TheTestDrivePage";
import { ServiceSchedulesPage } from "./PDFPages/ServiceSchedulesPage";
import { RunningCostsPage } from "./PDFPages/RunningCostsPage";
import { RepairsPage } from "./PDFPages/RepairsPage";
import { MileageAndRisksPage } from "./PDFPages/MileageAndRisksPage";
import { MOTsPage } from "./PDFPages/MOTsPage";

export const PDFReport = ({ report, carDetails }) => {
  return (
    <Document>
      <DetailsPage carDetails={carDetails} />

      <QuestionsToAskPage report={report} />

      <TheTestDrivePage report={report} />

      <ServiceSchedulesPage report={report} />

      <MOTsPage report={report} />

      <RunningCostsPage report={report} />

      <RepairsPage report={report} />

      <MileageAndRisksPage report={report} />
    </Document>
  );
};
