import "./assets/motorway-widget.scss";

import cx from "classnames";
import React, { useMemo, useState } from "react";

import { TrustpilotLogo } from "./TrustpilotLogo";
import { MotorwayButton } from "./MotorwayButton";
import { MotorwayPowered } from "./MotorwayPowered";
import { openMotorway } from "../../utils/VehicleHalpers";
import { DeviceType, useMediaQueryLegacy } from "../../hooks/useMediaQueryLegacy";
import { MotorwayActions } from "../../constants/AnalyticsConstants";

interface Props {
  readonly defaultVrm?: string;
}

export function MotorwayWidget({ defaultVrm = "" }: Props) {
  const [vrm, setVrm] = useState(defaultVrm);

  const { device } = useMediaQueryLegacy();

  const isMobile = useMemo(() => device === DeviceType.Mobile, [device]);

  return (
    <div className="d-flex position-relative align-self-center flex-column mb-6 mt-4 bg-yellow motorway-widget px-3 px-md-6 overflow-hidden">
      <div className="position-absolute widget-wrapper-container">
        <div className={cx("position-relative widget-wrapper", { "with-lines": !isMobile })} />
      </div>

      <div className="d-flex flex-column widget-container">
        <h1 className="fs-6 pr-5 text-center mt-3 mt-sm-4 mb-2 font-weight-bold text-black">
          Sell your car online
        </h1>
        <h2 className="fs-3 lh-4 text-center mt-2 text-black mb-4 mb-sm-6">
          Compare instant offers at Motorway and sell your car fast
        </h2>
        <div className="d-flex mb-4 flex-column flex-sm-row justify-content-center input-container">
          <input
            value={vrm}
            placeholder="Enter reg"
            onChange={({ target }) => setVrm(target.value)}
            className="form-control mr-sm-3 mb-2 mb-sm-0 fs-5 text-center text-uppercase vrm-input"
          />

          <MotorwayButton
            className="widget-button"
            onClick={() => {
              if (vrm) {
                openMotorway(vrm, MotorwayActions.ShortlistBannerValuation);
              }
            }}
          />
        </div>

        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mb-3 logo-container">
          <MotorwayPowered height={26} className="align-self-center mb-3 mb-md-0" />

          <TrustpilotLogo />
        </div>
      </div>
    </div>
  );
}
