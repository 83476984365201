import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  #root {
    overflow: visible;
  }
`;
